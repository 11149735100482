import React from "react";
import { Route, Routes } from "react-router-dom";
import Maindashboard from "../AffiliateSidebarComponents/main-dashboard/Maindashboard"
import Orders from "../AffiliateSidebarComponents/Orders/Orders";
// import Maindashboard from "../../Seller_Dashboard/SidebarComponents/main-dashboard/Maindashboard";
import Task from "../AffiliateSidebarComponents/Task/Task";
import InfluencerTask from "../AffiliateSidebarComponents/InfluencerTask/Task";
import Setting_page from "../AffiliateSidebarComponents/settings/Setting_page";
import Wallet from "../AffiliateSidebarComponents/Wallet/Wallet"


const AffiliateDashboardRoutes = () => {
  return (
    <>
      <Routes>
      <Route path="/" element={<Maindashboard />} />
        <Route path="/tracking" element={<Orders />} />
        <Route path="/task" element={<Task />} />
        <Route path="/influencertask" element={<InfluencerTask />} />
        <Route path="/settings" element={<Setting_page />} />
        <Route path="/affiliate-wallet" element={<Wallet />} />
      </Routes>
    </>
  );
};

export default AffiliateDashboardRoutes;
