import React from 'react'
import './PrivacyPolicy.css'
const TermsOfUse = () => {
    return (
        <>
            <section className='privacy-policy'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder'>
                            <h1>Terms of Use</h1>
                            <p>Welcome to Netpurti! These Terms of Use ("Terms") govern your access to and use of our business-to-business (B2B) e-commerce platform and any related services provided by Netpurti. By accessing or using our platform, you agree to comply with these Terms.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>1. Account Registration:</h2>
                            <p><span>a. Registration:</span> In order to access certain features of our platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated.</p>
                            <p><span>b. Account Security: </span> You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account. You agree to notify us immediately of any unauthorized access to or use of your account.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>2. Use of the Platform:</h2>
                            <p><span>a. License:</span> Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our platform for the purpose of conducting B2B transactions.</p>
                            <p><span>b. Prohibited Activities:</span>You agree not to engage in any activity that interferes with or disrupts the operation of our platform, including but not limited to:<br />
                                - Using automated means to access or interact with the platform.<br />
                                - Attempting to bypass any security measures or access any areas of the platform that you are not authorized to access.<br />
                                - Uploading or transmitting any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable.<br />
                            </p>
                            <p><span>c. Intellectual Property:</span>All content and materials available on our platform, including but not limited to text, graphics, logos, images, and software, are the property of [Your Company Name] or its licensors and are protected by intellectual property laws. You agree not to reproduce, modify, distribute, or create derivative works based on such content without our prior written consent.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>3. Transactions:</h2>
                            <p><span>a. Order Placement: </span>By placing an order through our platform, you agree to abide by the terms and conditions of the transaction, including but not limited to pricing, payment terms, and shipping arrangements.</p>
                            <p><span>b. Payment:</span>You agree to pay for all products or services ordered through our platform in accordance with the agreed-upon terms. Payment may be processed through third-party payment processors, and you agree to abide by their terms of service.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>4. Disclaimer of Warranties:</h2>
                            <p>OUR PLATFORM IS PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE CONTENT OR SERVICES PROVIDED THROUGH OUR PLATFORM.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>5. Limitation of Liability:</h2>
                            <p>IN NO EVENT SHALL [YOUR COMPANY NAME] BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OUR PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY TO YOU FOR ANY CLAIMS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS SHALL NOT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING OR USING OUR PLATFORM.</p>
                        </div>
                        <div className='heading-holder mb-3'>
                            <h2>6. Governing Law:</h2>
                            <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsOfUse