import React from 'react'
// import './FrequentlyBoughtCard.css'
const FrequentlyBoughtCard = ({content, rupees, orderdetails,
    image,
    imgClassName,
    className,
    textClassName,
    alt,}) => {

    return (
        <>
            <div className={className}>
                <img src={image} className={imgClassName} alt={alt} />
                <div className="text-holder">
                    <h4>{content}</h4>
                    <p>{rupees}</p>
                    <span>{orderdetails}</span>
                </div>

            </div>
        </>
    )
}

export default FrequentlyBoughtCard