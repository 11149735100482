
import React, { useState, useEffect, useContext } from 'react'
import '../MyWebsiteLogin/LoginPage.css'
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { RegxExpression } from "../../../../utils/common";
import { Errors, Placeholders } from "../../../../utils/errors";
import { userRegisterPost } from '../../../../utils/apis/myWebsite/mywebsite';
import PasswordChecklist from "react-password-checklist";

const SignupPage = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordOne, setShowPasswordOne] = useState(false);
    const [iconOne, setIconOne] = useState(faEye);
    const [iconTwo, setIconTwo] = useState(faEye);

    const togglePasswordVisibility = (field) => {
        if (field === "password") {
            setShowPassword(!showPassword);
            setIconOne(showPassword ? faEyeSlash : faEye);
        } else if (field === "reenterPassword") {
            setShowPasswordOne(!showPasswordOne);
            setIconTwo(showPasswordOne ? faEyeSlash : faEye);
        }
    };


    const [matcherror, setmatchErrors] = useState("");
    const {
        control,
        register,
        handleSubmit,
        setError,
        getValues,
        setValue,
        formState: { errors },
    } = useForm();


    const navigate = useNavigate();




    const onSubmit = async (data) => {
        if (data.password === data.re_password) {
            data.term_status = true;
            data.percentage = 10;
            const res = await userRegisterPost(data)
            if (res?.success) {
                console.log(data, "data");
                navigate('/mywebsites/login');
            }
        } else {
            setmatchErrors("Password Must Match");
        }
    }


    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    return (

        <section className='login-page' >
            <Container>
                <Link to="/mywebsites/home">
                    <div className="back-home">

                        <FontAwesomeIcon icon={["fas", "angle-left"]} />

                        <p className="back">Back to Home</p>
                    </div>
                </Link>
                <Row>
                    <Col xxl={6} xl={6} lg={8} md={12} sm={12} xs={12} >
                        <div className='login-white'>
                            <div className="log_in_form">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>First Name*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="First_Name"
                                            placeholder="Enter First Name"
                                            {...register("f_name", {
                                                required: "First name required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.f_name,
                                                "is-valid": getValues("f_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />

                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Last Name*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Last_Name"
                                            placeholder="Enter Last Name"
                                            {...register("l_name", {
                                                required: "Last name required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.l_name,
                                                "is-valid": getValues("l_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />

                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="designation"
                                            placeholder="Enter Designation"
                                            {...register("designation", {
                                                required: "designation is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.designation,
                                                "is-valid": getValues("designation"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />

                                    </Form.Group>
                                    <Form.Group className="mb-3 emailformpos" controlId="">
                                        <Form.Label>Email ID*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="Enter Email"
                                            {...register("email", {
                                                required: "Email is required",
                                                pattern: {
                                                    value: RegxExpression.email,
                                                    message: "Invalid email address",
                                                },
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.email,
                                            })}
                                        />
                                        {!errors?.email && (
                                            <div className='icoset'>
                                                <span style={{ color: 'green', fontSize: '1.2em' }}>✔</span>
                                            </div>
                                        )}
                                        {errors.email && (
                                            <span className="text-danger">
                                                {errors.email.message}
                                            </span>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3 emailformpos" controlId="">
                                        <Form.Label>Contact No*</Form.Label>
                                        <Controller
                                            name="contact_no"
                                            control={control}
                                            render={({ field }) => (
                                                <div style={{ position: 'relative' }}>
                                                    <PhoneInput
                                                        country="in"
                                                        name="contact_no"
                                                        value={field.value}
                                                        onChange={(value) => field.onChange(value)}
                                                        placeholder={Placeholders.phone}
                                                        inputStyle={{
                                                            borderColor: getValues('contact_no') ? "green": "grey" ,
                                                        }}
                                                    />
                                                    {getValues('contact_no') && (
                                                        <div className='icoset'>
                                                            <span style={{ color: 'green', fontSize: '1.2em' }}>✔</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            rules={{ required: Errors.phone }}
                                        />

                                        {errors.contact_no && (
                                            <span className="text-danger">
                                                {errors.contact_no.message}
                                            </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Enter Password*</Form.Label>
                                        <div className="StepsixPassword">
                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Password*"
                                                className={classNames("", {
                                                    "is-invalid": errors?.password,
                                                })}
                                                name="password"
                                                {...register("password", {
                                                    required: "Password is required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must be at least 8 characters long",
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                        message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                                    }
                                                })}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                            <PasswordChecklist
                                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                minLength={8}
                                                value={password}
                                                valueAgain={passwordAgain}
                                                onChange={(isValid) => { }}
                                            />
                                            <FontAwesomeIcon
                                                icon={iconOne}
                                                className="StepsixeditIcon"
                                                onClick={() => togglePasswordVisibility("password")}
                                            />
                                            
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="reenterPassword">
                                        <Form.Label>Re-Enter Password*</Form.Label>
                                        <div className="StepsixPassword">
                                            <Form.Control
                                                type={showPasswordOne ? "text" : "password"}
                                                placeholder="Re-Enter Password*"
                                                className={classNames("", {
                                                    "is-invalid": errors?.re_password,
                                                })}
                                                name="re_password"
                                                {...register("re_password", {
                                                    required: "Re-Password is required",
                                                })}
                                                onChange={e => setPasswordAgain(e.target.value)}
                                            />
                                            <FontAwesomeIcon
                                                icon={iconTwo}
                                                className="StepsixeditIcon"
                                                onClick={() =>
                                                    togglePasswordVisibility("reenterPassword")
                                                }
                                            />
                                           
                                        </div>
                                    </Form.Group>
                                    {matcherror && (
                                        <span className="text text-danger">{matcherror}</span>
                                    )}

                                    <div className="btn-holder text-center">
                                        <button className="submit_btn" type="submit">
                                            Sign up <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" className='ms-4' />
                                        </button>

                                    </div>
                                </Form>
                            </div>
                            <div className='text-center mt-3'>
                                <p className='login-txtt'>New to Netpurti's My Website? <Link to='/mywebsites/login'><span className='forget-txtt'>Login</span></Link></p>
                            </div>
                        </div>
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <div className='log-video-class'>
                            <video className='video-class' autoPlay loop mute   >
                                <source src={process.env.PUBLIC_URL + '/assest/images/MyWebsite/video/login.mp4'}></source>
                            </video>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SignupPage
