import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { RegxExpression } from "../../../../../src/utils/common";
import { bankVerify } from "../../../../utils/apis/third_party/third_party";

import { ShimmerTitle } from "react-shimmer-effects";
import classNames from "classnames";
import {
  firmBankDetailPost,
  getfirmBankDetail,
} from "../../../../utils/apis/transport/transport";
library.add(fas);

function Stepthree({ setPage, innerNext, userDetails }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isYesActive, setIsYesActive] = useState(true);
  const [startDate, setStartDate] = useState();
  const [modalShow, setModalShow] = useState(false);

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const options = [
    { value: " Pune", label: " Pune" },
    { value: "Mumbai (All areas)", label: "Mumbai (All areas)" },
    { value: "Bangalore", label: "Bangalore" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    if (firmBankId !== "") {
      data.id = firmBankId;
    }
    data.t_personal_details_id = userDetails?.t_personal_details_id;
    const res = await firmBankDetailPost(data);
    if (res?.success) {
      console.log(res);
      setPage(3);
    }
  };

  const [firmBankId, setFirmBankId] = useState("");

  const getFirmBankDetailIn = async () => {
    const res = await getfirmBankDetail(userDetails?.t_personal_details_id);
    if (res?.success) {
      console.log(res);
      setFirmBankId(res.data.id);
      if(res.data?.bank_account_no)
      setVerifyStatus(true);
      reset(res.data);
    }
  };

  const { shimmerLoader, setShimmerLoader } = useContext(Context);
  const [verifyStatus, setVerifyStatus] = useState(false);

  const verifyIfsc = async () => {
    setShimmerLoader(true);
    const res = await bankVerify(
      getValues("bank_account_no"),
      getValues("ifsc_code")
    );
    if (res?.success) {
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
      setValue("bank_name", res?.data?.data?.ifsc_details?.bank_name);
      setValue("branch_name", res?.data?.data?.ifsc_details?.branch);
      setValue("micr_code", res?.data?.data?.ifsc_details?.micr);
    } else {
      setVerifyStatus(false);
      setError("ifsc_code", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };


  useEffect(() => {
    getFirmBankDetailIn();
  }, []);

  return (
    <>
      <div className="personal-details-form ">
        {/* form-holder */}
        <div className="details-form-holder">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-container">
              {/* Current Industry , Job Title*/}

              <div className="row">
                {shimmerLoader ? (
                  <ShimmerTitle line={1} gap={10} variant="primary" />
                ) : (
                  <>
                    <div className="col-md-6">
                      <Form className="mb-3">
                        <Form.Group className="bankk-verrr" controlId="">
                          <Form.Label>Bank account number</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.bank_account_no,
                              "is-valid": verifyStatus,
                            })}
                            type="text"
                            name="bank_account_no"
                            placeholder="Enter Bank Account No."
                            {...register("bank_account_no", {
                              required: "Field is required",
                              pattern: {
                                value: RegxExpression.bank_number,
                                message: "Should be only Number",
                              },
                            })}
                            onChange={() => {
                              setError("ifsc_code", "");
                              setVerifyStatus(false);
                            }}
                            maxLength="14"
                          />{" "}
                          
                          {/* <span className="not-verified-text">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-circle-xmark"
                          />
                          not verified
                        </span> */}
                        </Form.Group>
                      </Form>
                    </div>

                    <div className="col-md-6">
                      <Form className="mb-3">
                        <Form.Group className="bankk-verrr" controlId="">
                          <Form.Label>IFSC code</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.ifsc_code,
                              "is-valid": verifyStatus,
                            })}
                            type="text"
                            name="ifsc_code"
                            placeholder="Enter IFSC Code."
                            {...register("ifsc_code", {
                              required: "Field is required",
                              pattern: {
                                value: RegxExpression.ifsc,
                                message: "Invalid IFSC code",
                              },
                            })}
                            onChange={() => {
                              setError("ifsc_code", "");
                              setVerifyStatus(false);
                            }}
                          />{" "}
                          
                          {/* <span className="verified-text">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-circle-check"
                          />
                          verified
                        </span> */}
                         
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={verifyIfsc}
                      >
                        Verify Bank Details
                      </button>
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <Form className="mb-3">
                    <Form.Group controlId="">
                      <Form.Label>Bank name</Form.Label>
                      <div className="verified">
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.bank_name,
                            "is-valid": getValues('bank_name'),
                          })}
                          type="text"
                          name="bank_name"
                          placeholder="Enter Bank Name"
                          {...register("bank_name", {
                            required: "Field is required",
                          })}
                        />
                        {/* <span className="verified-text">
                          <FontAwesomeIcon
                            className="me-2"
                            icon="fa-solid fa-circle-check"
                          />
                          verified
                        </span> */}
                      </div>
                    </Form.Group>
                  </Form>
                </div>

             
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}

                <Button onClick={() => setPage(1)} className="back-btn">
                  Back
                </Button>

                <Button
                  // onClick={extStep}
                  className=" tabs-btn"
                  type="submit"
                >
                  Register & Continue
                </Button>
              </div>
            </div>
          </Form>

          <TermsAndConditionModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Stepthree;
