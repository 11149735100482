import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { firmPasswordPost } from "../../../../utils/apis/transport/transport";
import CreateAccountSeccseccfullyModal from "../../../CommonForAll/CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal";
const Stepsix = ({ nextStep, prevStep, userDetails }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [error, setErrors] = useState("");

  const onSubmit = async (data) => {
    console.log(data);
    data.t_personal_details_id = userDetails?.t_personal_details_id;
    if (data.password === data.re_password) {
      const res = await firmPasswordPost(data);
      if (res?.success) {
        console.log(res);
        Cookies.remove('transport_id');
        setModalSuccess(true);
        setTimeout(() => {
          setModalSuccess(false);
          navigate("/logistic/logistic-home");
        }, 2000);
      }
    }else{
      setErrors("Password and Re-password should match");
    }
  };

    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="personal-details-form  employee-details-step">
            <div>
              <div className="row stepsixform">
                <div className="col-lg-6">
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Enter Password*</Form.Label>
                    <div className="StepsixPassword">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password*"
                        className={classNames("", {
                          "is-invalid": errors?.password,
                        })}
                        name="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <FontAwesomeIcon
                        icon={iconOne}
                        className="StepsixeditIcon"
                        onClick={() => togglePasswordVisibility("password")}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3" controlId="reenterPassword">
                    <Form.Label>Re-Enter Password*</Form.Label>
                    <div className="StepsixPassword">
                      <Form.Control
                        type={showPasswordOne ? "text" : "password"}
                        placeholder="Re-Enter Password*"
                        className={classNames("", {
                          "is-invalid": errors?.re_password,
                        })}
                        name="re_password"
                        {...register("re_password", {
                          required: "Password is required",
                        })}
                      />
                      <FontAwesomeIcon
                        icon={iconTwo}
                        className="StepsixeditIcon"
                        onClick={() =>
                          togglePasswordVisibility("reenterPassword")
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
                {error && <span className="text text-danger">{error}</span>}
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}
                {/* <Link to={"/seller/seller-dashboard/"}>
                  <Button className="back-btn me-3">Skip</Button>
                </Link> */}
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>
                <Button className="tabs-btn" type="submit">
                  Register & Continue
                </Button>
                <TermsAndConditionModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <CreateAccountSeccseccfullyModal
                  show={modalSuccess}
                  onHide={() => setModalSuccess(false)}
                />
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  };

  export default Stepsix;
