import React, { useContext, useEffect, useState } from "react";
import "./B2bBestSeller.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import "swiper/css/pagination";
import Select from "react-select";
import ProductTab from "./ProductTab/ProductTab";
import AllSuppliers from "./AllSuppliers/AllSuppliers";
import BreadcrumComponent from "../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent";
import {
  Attributes,
  Customization,
  SubAttributes,
  SubCategory,
  getWorkType,
} from "../../../utils/apis/master/Master";
import { useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../utils/context";
import classNames from "classnames";
import { getProducts } from "../../../utils/apis/btb/Best_Seller";
import { ShimmerContentBlock } from "react-shimmer-effects";

import B2bProductCard from "./B2bProductCard/B2bProductCard";
import HelpSlider from "../HelpCenter/HelpSlider/HelpSlider";
import SuggestedProduct from "./SuggestedProduct/SuggestedProduct";

const B2bBestSeller = () => {
  const { Select2Data, IMG_URL, shimmerLoader, setShimmerLoader } =
    useContext(Context);
  const { category, category_name } = useParams();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      min: "",
      max: "",
      quantity: "",
      attributes_id: "",
      sub_attributes_id: [],
    },
  });

  const onSubmit = async (type = "Product") => {
    const data = getValues();
    setShimmerLoader(true);
    await setData(
      await getProducts(
        category,
        data.sub_category_id,
        data.min,
        data.max,
        data.quantity,
        data.working_type_id,
        data.customization_id,
        data.attributes_id,
        data.sub_attributes_id
      )
    );
    setShimmerLoader(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  const options = [
    { value: "red", label: "Red" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
  ];

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/b2bbuyer/buyer-home" },
    { text: category_name, link: "#" },
    { text: "Armchair", link: "#" },
  ]);

  const [workingtype, setworkingtype] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [customization, setCustomization] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [subAttributes, setSubAttributes] = useState([]);
  const getDataAll = async () => {
    {
      const res = await SubCategory(category);
      await setSubCategory(res);
      if (res?.data && res?.data?.length > 0) {
        await setbreadcrumbItems([
          ...breadcrumbItems.slice(0, 2),
          { ...breadcrumbItems[2], text: res.data[0].name },
          ...breadcrumbItems.slice(3),
        ]);
      }
    }
    {
      setworkingtype(await getWorkType());
    }
    {
      setCustomization(await Customization());
    }
    {
      const res = await Attributes();
      setAttributes(await Select2Data(res.data, "attributes_id", false));
    }
  };

  const [data, setData] = useState([]);
  const getProductData = async () => {
    setShimmerLoader(true);
    await setData(await getProducts(category));
    setShimmerLoader(false);
  };

  useEffect(() => {
    getDataAll();
    getProductData();
  }, []);

  // console.log(data);

  // useEffect(() => {
  //   getProductData();
  // }, []);

  // Function to reset the form
  const resetForm = () => {
    reset({
      min: "",
      max: "",
      quantity: "",
      attributes_id: "",
      sub_attributes_id: [],
    });
    handleSubmit(onSubmit());
  };

  return (
    <>
      <section className="b2bBestSeller">
        <div className="bestSellerMain">
          <div className="b2bbestsellsec">
            <div className="container">
              {shimmerLoader ? (
                <ShimmerContentBlock
                  title
                  text
                  cta
                  thumbnailWidth={370}
                  thumbnailHeight={370}
                />
              ) : (
                <div className="row bestsellermainrow">
                  {activeTab === "1" && (
                    <div className="col-lg-3 col-md-3">
                      <div>
                        <BreadcrumComponent items={breadcrumbItems} />
                      </div>

                      <div className="card bestSellercard ">
                        <div className="subCategorieshead ProductmainCard">
                          <h3>Sub Categories</h3>

                          <Nav variant="pills" className="flex-column">
                            {subCategory.data?.map((subcategory, index) => (
                              <div className="name-div">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey={index}
                                    onClick={async () => {
                                      await setValue(
                                        "sub_category_id",
                                        subcategory.id
                                      );
                                      await setbreadcrumbItems([
                                        ...breadcrumbItems.slice(0, 2),
                                        {
                                          ...breadcrumbItems[2],
                                          text: subcategory?.name,
                                        },
                                        ...breadcrumbItems.slice(3),
                                      ]);
                                      // handleSubmit(onSubmit());
                                    }}
                                  >
                                    {subcategory?.name}
                                  </Nav.Link>
                                </Nav.Item>
                              </div>
                            ))}

                            {/* <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list2">Loveseat</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list3">Coffee Table</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list4">
                                Television Stand
                              </Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list5">Cupboard</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list6">Sofa</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list7">Recliner</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list8">Dining Table</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list9">Cabinet</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list10">
                                Dining Chair
                              </Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list11">Bed</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="name-div">
                            <Nav.Item>
                              <Nav.Link eventKey="list12">Lamp</Nav.Link>
                            </Nav.Item>
                          </div> */}
                          </Nav>
                        </div>
                      </div>

                      <div className="card bestSellercard ">
                        <div className="card-body">
                          <div className="subCategorieshead">
                            <h3>Filters</h3>
                          </div>
                          <div className="Pricemainsec">
                            <div className="searchtextsec">
                              <p>Price</p>
                              <p>
                                <FontAwesomeIcon
                                  icon="fa-solid fa-magnifying-glass"
                                  onClick={handleSubmit(onSubmit)}
                                />
                              </p>
                            </div>

                            <Form>
                              <div className="row MinMaxrowmain">
                                <div class="qty-input">
                                  {/* <Form.Control
                                  required
                                  type="number"
                                  placeholder="Min"
                                  className="qty-count qty-count--minus"
                                /> */}

                                  <Form.Control
                                    type="number"
                                    name="min"
                                    placeholder="Min"
                                    className={classNames(
                                      "qty-count qty-count--minus",
                                      {
                                        "is-invalid": errors?.min,
                                      }
                                    )}
                                    {...register("min", {
                                      min: {
                                        value: 0,
                                        message: "Minimum value is 0",
                                      },
                                    })}
                                  />
                                  <div
                                    className="product-qty"
                                    data-action="add"
                                    type="button"
                                  >
                                    -
                                  </div>

                                  <Form.Control
                                    type="number"
                                    name="max"
                                    placeholder="Max"
                                    className={classNames(
                                      "qty-count qty-count--add",
                                      {
                                        "is-invalid": errors.max,
                                      }
                                    )}
                                    {...register("max", {
                                      min: {
                                        value: 1,
                                        message: "Value must be greater than 0",
                                      },
                                    })}
                                  />

                                  {/* <Form.Control
                                  type="text"
                                  name="max"
                                  placeholder="Max"
                                  className="qty-count qty-count--add"
                                  {...register("max")}
                                /> */}

                                  {/* <Form.Control
                                  required
                                  type="number"
                                  placeholder="Max"
                                  className="qty-count qty-count--add"
                                /> */}
                                </div>
                              </div>
                            </Form>

                            <div className="MinMaxrowmain">
                              <div className="subCategorieshead ">
                                <h3>Ratings</h3>
                              </div>

                              <div className="">
                                <Form>
                                  <div className="mb-3">
                                    <Form.Check type="checkbox" label="1" />
                                    <Form.Check type="checkbox" label="2" />
                                    <Form.Check type="checkbox" label="3" />
                                    <Form.Check type="checkbox" label="4" />
                                    <Form.Check type="checkbox" label="5" />
                                    <Form.Check type="checkbox" label="6" />
                                  </div>
                                </Form>
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div className="subCategorieshead ">
                                <h3>Minimun Order</h3>
                              </div>
                              <div>
                                <Controller
                                  name="quantity"
                                  control={control}
                                  defaultValue={getValues("quantity")}
                                  render={({ field }) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      placeholder="Enter Quantity"
                                    />
                                  )}
                                />
                                {/* <Form.Control
                                type="text"
                                name="quantity"
                                value={getValues("quantity")}
                                placeholder="Enter Quantity"
                                {...register("quantity")}
                              /> */}

                                {/* <Form.Control
                                required
                                type="text"
                                placeholder="Enter Quantity"
                                defaultValue="Mark"
                              /> */}
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div>
                                <Controller
                                  name="working_type_id"
                                  control={control}
                                  defaultValue={[]}
                                  render={({ field }) => (
                                    <>
                                      {workingtype?.data?.map((data, index) => (
                                        <Form.Check
                                          type="checkbox"
                                          value={data?.id}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;
                                            const newValue = checked
                                              ? [...field.value, Number(value)]
                                              : field.value.filter(
                                                  (id) => id !== Number(value)
                                                );

                                            field.onChange(newValue);
                                            // handleSubmit(onSubmit());
                                          }}
                                          checked={field.value.includes(
                                            data?.id
                                          )}
                                          label={data?.name}
                                        />
                                      ))}
                                    </>
                                  )}
                                />

                                {/* {/* <Form.Check
                                type="checkbox"
                                label="Manufacturer"
                              /> 
                              <Form.Check type="checkbox" label="General" />*/}
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div>
                                <Controller
                                  name="customization_id"
                                  control={control}
                                  defaultValue={[]}
                                  render={({ field }) => (
                                    <>
                                      {customization?.data?.map(
                                        (data, index) => (
                                          <Form.Check
                                            type="checkbox"
                                            value={data?.id}
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              const newValue = checked
                                                ? [
                                                    ...field.value,
                                                    Number(value),
                                                  ]
                                                : field.value.filter(
                                                    (id) => id !== Number(value)
                                                  );

                                              field.onChange(newValue);
                                              // handleSubmit(onSubmit());
                                            }}
                                            checked={field.value.includes(
                                              data?.id
                                            )}
                                            label={data?.name}
                                          />
                                        )
                                      )}
                                    </>
                                  )}
                                />
                                {/* <Form.Check
                                type="checkbox"
                                label="Customization & Packaging"
                              />
                              <Form.Check
                                type="checkbox"
                                label="Customization"
                              />
                              <Form.Check type="checkbox" label="Packaging" /> */}
                              </div>
                            </div>

                            {/* <div className="MinMaxrowmain">
                              <div className="subCategorieshead ">
                                <h3>Attributes</h3>
                              </div>
                              <div>
                                <Controller
                                  name="attributes_id"
                                  {...register("attributes_id")}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors.attributes_id
                                            ? "red"
                                            : baseStyles,
                                        }),
                                      }}
                                      {...field}
                                      options={attributes}
                                      value={getValues("attributes_id")}
                                      onChange={async (selectedOption) => {
                                        field.onChange(selectedOption.value);
                                        setValue("sub_attributes_id", []);
                                        if (selectedOption.value) {
                                          const res = await SubAttributes(
                                            selectedOption.value
                                          );
                                          setSubAttributes(
                                            await Select2Data(
                                              res.data,
                                              "sub_attributes_id",
                                              false
                                            )
                                          );
                                        }
                                        setValue(
                                          "attributes_id",
                                          selectedOption
                                        );
                                        // handleSubmit(onSubmit());
                                      }}
                                    />
                                  )}
                                />
                              
                              </div>
                            </div>

                            {getValues("attributes_id")?.label && (
                              <div className="MinMaxrowmain">
                                <div className="subCategorieshead ">
                                  <h3>{getValues("attributes_id")?.label}</h3>
                                </div>
                                <div>
                                  <Controller
                                    name="sub_attributes_id"
                                    {...register("sub_attributes_id")}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        isMulti="true"
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor:
                                              errors.sub_attributes_id
                                                ? "red"
                                                : baseStyles,
                                          }),
                                        }}
                                        {...field}
                                        onChange={(selectedOption) => {
                                          field.onChange(selectedOption);
                                          // handleSubmit(onSubmit());
                                        }}
                                        options={subAttributes}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            )} */}
                            <Button
                              type="button"
                              variant="danger"
                              className="me-2"
                              onClick={resetForm}
                            >
                              Reset
                            </Button>
                            <Button
                              type="button"
                              variant="info"
                              onClick={() => handleSubmit(onSubmit())}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === "2" && (
                    <div className="col-lg-3 col-md-3">
                      <div>
                        <BreadcrumComponent items={breadcrumbItems} />
                      </div>

                      <div className="card bestSellercard ">
                        <div className="card-body ">
                          <div className="verifyFiltersHead">
                            <h3>Filters</h3>
                            <p>Store reviews</p>
                            <p>Based on a 5-star rating system</p>
                          </div>
                          <div className="Pricemainsec">
                            <div className="MinMaxrowmain">
                              <div className="verifiedRadios">
                                <Form>
                                  <div className="mb-3">
                                    <Form.Check
                                      type="checkbox"
                                      label="4.0 & up"
                                    />

                                    <Form.Check
                                      type="checkbox"
                                      label="4.5 & up"
                                    />
                                    <Form.Check type="checkbox" label="5.0" />
                                  </div>
                                </Form>
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div className="AgileSupplyhead">
                                <h3>Capabilities</h3>
                              </div>
                              <div className="verifiedRadios">
                                <Form.Check
                                  type="checkbox"
                                  label="Supplies Fortune 500 companies"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="R&D capabilities"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="On-site material inspection"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="ODM service available"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Project solutions"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Design services"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Agile supply chain"
                                />
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div className="AgileSupplyhead">
                                <h3>Management Certifications</h3>
                              </div>
                              <div className="verifiedRadios">
                                <Form.Check type="checkbox" label="ISO" />

                                <Form.Check type="checkbox" label="FSC" />
                                <Form.Check type="checkbox" label="BSCI" />
                                <Form.Check type="checkbox" label="ICTI" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === "3" && (
                    <div className="col-lg-3 col-md-3">
                      <div>
                        <BreadcrumComponent items={breadcrumbItems} />
                      </div>

                      <div className="card bestSellercard ">
                        <div className="card-body ">
                          <div className="verifyFiltersHead">
                            <h3>Filters</h3>
                            <p>Store reviews</p>
                            <p>Based on a 5-star rating system</p>
                          </div>
                          <div className="Pricemainsec">
                            <div className="MinMaxrowmain">
                              <div className="verifiedRadios">
                                <Form>
                                  <div className="mb-3">
                                    <Form.Check
                                      type="checkbox"
                                      label="4.0 & up"
                                    />

                                    <Form.Check
                                      type="checkbox"
                                      label="4.5 & up"
                                    />
                                    <Form.Check type="checkbox" label="5.0" />
                                  </div>
                                </Form>
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div className="AgileSupplyhead">
                                <h3>Capabilities</h3>
                              </div>
                              <div className="verifiedRadios">
                                <Form.Check
                                  type="checkbox"
                                  label="Supplies Fortune 500 companies"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="R&D capabilities"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="On-site material inspection"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="ODM service available"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Project solutions"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Design services"
                                />
                                <Form.Check
                                  type="checkbox"
                                  label="Agile supply chain"
                                />
                              </div>
                            </div>

                            <div className="MinMaxrowmain">
                              <div className="AgileSupplyhead">
                                <h3>Management Certifications</h3>
                              </div>
                              <div className="verifiedRadios">
                                <Form.Check type="checkbox" label="ISO" />

                                <Form.Check type="checkbox" label="FSC" />
                                <Form.Check type="checkbox" label="BSCI" />
                                <Form.Check type="checkbox" label="ICTI" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-9 col-md-9">
                    <Tab.Container
                      id="left-tabs-example"
                      activeKey={activeTab}
                      onSelect={(key) => setActiveTab(key)}
                    >
                      <div className="card bestSellerrightcard ">
                        <Nav variant="pills" className="flex-row">
                          <Nav.Item>
                            <Nav.Link eventKey="1">Products</Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                            <Nav.Link eventKey="2">All Suppliers</Nav.Link>
                          </Nav.Item> */}
                          {/* <Nav.Item>
                            <Nav.Link eventKey="3">
                              Verified Manufacturers
                            </Nav.Link>
                          </Nav.Item> */}
                        </Nav>
                      </div>
                      <Tab.Content>
                        <Tab.Pane eventKey="1">
                          <ProductTab data={data} IMG_URL={IMG_URL} />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="2">
                          <AllSuppliers />
                        </Tab.Pane> */}
                        {/* <Tab.Pane eventKey="3">
                          <VerifiedManufacture />
                        </Tab.Pane> */}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default B2bBestSeller;
