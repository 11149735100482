import React, { useContext, useEffect, useState } from "react";
import "./Order.css";
import Table from "react-bootstrap/Table";
import CancelOrderModal from "../../common-components/AllModal/CancelOrderModal/CancelOrderModal";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { getAllOrders } from "../../../../utils/apis/btb/buy_order_details";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { IDS } from "../../../../utils/common";

const ordersData = [
  {
    sellerName: "Sidior Furniture Company Limited",
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName:
        "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17",
    },
    amount: "₹60,000",
    status: "Dispatched",
    buttons: {
      makePayment: false,
      cancelOrder: false,
      downloadInvoice: true,
      changeShippingAddress: true,
      orderAgain: false,
      viewMore: false,
      returnOrder: false,
    },
  },
  {
    sellerName: "Sidior Furniture Company Limited",
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName:
        "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17",
    },
    amount: "₹60,000",
    status: "waiting",
    buttons: {
      makePayment: false,
      cancelOrder: false,
      downloadInvoice: false,
      changeShippingAddress: false,
      orderAgain: false,
      viewMore: true,
      returnOrder: true,
    },
  },
  {
    sellerName: "Sidior Furniture Company Limited",
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName:
        "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17",
    },
    amount: "₹60,000",
    status: "Dispatched",
    buttons: {
      makePayment: true,
      cancelOrder: false,
      downloadInvoice: false,
      changeShippingAddress: true,
      orderAgain: false,
      viewMore: false,
      returnOrder: false,
      tracking: true,
    },
  },
  {
    sellerName: "Sidior Furniture Company Limited",
    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName:
        "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "2.0 piece",
      deliveryDate: "Estimated delivery by Apr 26-May 17",
    },
    amount: "₹60,000",
    status: "Dispatched",
    buttons: {
      makePayment: false,
      cancelOrder: true,
      downloadInvoice: false,
      changeShippingAddress: false,
      orderAgain: false,
      viewMore: false,
      returnOrder: false,
    },
  },
];

const OrderCard = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const { IMG_URL } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const getOrders = async () => {
    const res = await getAllOrders();
    reset(res?.data);
    setOrders(res?.data);
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <section className="B2b-buyer-order-sec ">
      <div className="main-table-div">
        <Table>
          <thead>
            <tr>
              <th>Seller Name</th>
              <th>Product information</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {orders?.map((order, index) => (
            <tbody className="bd-right">
              <tr key={index}>
                <td>
                  <p>
                    {order?.s_personal_detail?.f_name}{" "}
                    {order?.s_personal_detail?.l_name}
                  </p>
                </td>
                <td>
                  <div className="product-info-div">
                    {/* <div>
                                            <img src={order.productInfo.imageSrc} alt="Product" />
                                        </div> */}
                    <div>
                      <h6>{order.s_product.product_title}</h6>
                      <p className="grey-text">{order.quantity}</p>
                      {/* <p className='grey-text'>{order.productInfo.deliveryDate}</p> */}
                    </div>
                  </div>
                </td>
                <td>
                  <p className="price-text">{order.total}</p>
                </td>
                <td>
                  <p>{order?.btb_order_status?.name}</p>
                </td>
                <td className="text-center">
                  {/* <div>
                    <button className="payment-btn">Make Payment</button>
                  </div> */}

                  {IDS?.btb_order_status?.cancelled !==
                    order?.order_status_id && (
                    <div>
                      <button
                        className="common-btn"
                        onClick={() => setModalShow(order.id)}
                      >
                        Cancel Order
                      </button>
                    </div>
                  )}

                  {/* <div>
                    <button className="common-btn">Download Invoice</button>
                  </div>

                  <div>
                    <button className="common-btn">
                      Change Shipping Address
                    </button>
                  </div>

                  <div>
                    <button className="common-btn">Order Again</button>
                  </div>

                  <div>
                    <button className="common-btn">View More</button>
                  </div>

                  <div>
                    <Link to="/b2bbuyer/return-order">
                      <button className="common-btn">Return Order</button>
                    </Link>
                  </div>
                  <div>
                    <Link to="/b2bbuyer/tracking">
                      <button className="common-btn">Track Shipment</button>
                    </Link>
                  </div> */}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </div>
      <CancelOrderModal
        getOrders={getOrders}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </section>
  );
};

export default OrderCard;
