import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

import { formatDate } from "../../seller/Product";

export const getBTBAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/seller/orders/btb/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getBTBAllOrderStatus = async () => {
  try {
    return await getData(`/seller/orders/btb/all-order-status`);
  } catch (error) {
    console.log(error);
  }
};

export const postBTBAllOrderStatus = async (data, order) => {
  try {
    return await postData(`/seller/orders/btb/all-order-status`, {
      ids: data,
      order: order?.value,
    });
  } catch (error) {
    console.log(error);
  }
};
