import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import B2bbuyerstepForm from "../B2b_stepFormMain/B2bbuyerstepForm";
import B2b_checker_buyerstepForm from "../B2b_checker_stepForm/B2b_checker_buyerstepForm";
import B2b_media_buyerstepForm from "../B2b_media_stepForm/B2b_media_buyerstepForm";
import B2bBestSeller from "../B2bBestSeller/B2bBestSeller";

// import B2bSellerDetailPage from "../B2bSellerDetailPage/B2bSellerDetail/B2bSellerDetail";
import Payment_stepform from "../payment-stepform/Payment_stepform";
import B2bSellerDetailPage from "../B2bSellerDetailPage/B2bSellerDetailPage";
import B2b_all_categories from "../B2b_all_categories/B2b_all_categories";
import MyRfq from "../RFQ/MyRfq/MyRfq";
import MyInquiry from "../RFQ/MyInquiry/MyInquiry";
import Rfq from "../RFQ/Rfq";
import Contactus from "../Contactus/Contactus";
import Accounts from "../Accounts/Accounts";
import MyProfile from "../Accounts/MyProfile/MyProfile";
import HelpCenter from "../HelpCenter/HelpCenter";
import InquireNow from "../InquireNow/InquireNow";
import PrivacyPolicy from "../Privacy-Policy/PrivacyPolicy";
import CancelationAndReturn from "../Privacy-Policy/CancelationAndReturn";
import TermsOfUse from "../Privacy-Policy/TermsOfUse";
import ShoppingCart from "../ShoppingCartMain/ShoppingCart/ShoppingCart";
import StartOrder from "../ShoppingCartMain/StartOrder/StartOrder";
import MakePayment from "../ShoppingCartMain/MakePayment/MakePayment";
import Order from "../Accounts/Order/Order";
import ReturnOrderStepForm from "../Accounts/ReturnOrderStepForm/ReturnOrderStepForm";
import ShipmentTracking from "../Accounts/ShipmentTracking/ShipmentTracking";
import B2bMainLogin from "../B2b_login/B2bMainLogin/B2bMainLogin";
import B2bForgetPass from "../B2b_login/B2bForgetPass/B2bForgetPass";
import B2bChechMail from "../B2b_login/B2bChechMail/B2bChechMail";
import B2bSetPass from "../B2b_login/B2bSetPass/B2bSetPass";
import B2bMainDashboard from "../Accounts/B2bDeshboard/B2bMainDashboard/B2bMainDashboard";
import Categorione from "../HeaderAllCategories/Categorione/Categorione";
import Categoritwo from "../HeaderAllCategories/Categoritwo/Categoritwo";
import MyWallet from "../Accounts/B2bWallet/MyWallet/MyWallet";
import MakePaymentWallet from "../Accounts/B2bWallet/Make-payment-wallet/MakePaymentWallet";

const B2b_Routes_main = () => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname.startsWith("/b2b-buyer") ? "" : <Header />}

      <Routes>
        <Route path="/buyer-home" element={<Home />} />
        <Route path="/b2bstepform" element={<B2bbuyerstepForm />} />
        <Route
          path="/b2b-checker-stepform"
          element={<B2b_checker_buyerstepForm />}
        />
        <Route
          path="/b2b-media-stepform"
          element={<B2b_media_buyerstepForm />}
        />
        <Route
          path="/b2b-bestSeller/:category/:category_name"
          element={<B2bBestSeller />}
        />
        <Route path="/b2b-seller-Detail" element={<B2bSellerDetailPage />} />
        <Route path="/b2b-payment" element={<Payment_stepform />} />
        <Route
          path="/b2b-productMain/:product_id"
          element={<B2bSellerDetailPage />}
        />
        <Route path="/b2b-all-categories" element={<B2b_all_categories />} />
        <Route path="/rfq" element={<Rfq />} />
        <Route path="/b2bcontact" element={<Contactus />} />
        <Route>
          <Route path="/accounts/*" element={<Accounts />} />
          <Route path="/b2bmyprofile" element={<MyProfile />} />
          <Route path="/order" element={<Order />} />
          <Route path="/b2bdeshboard/*" element={<B2bMainDashboard />} />
        </Route>
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/inquire-now" element={<InquireNow />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancelation-return" element={<CancelationAndReturn />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/shopping-card" element={<ShoppingCart />} />
        <Route path="/start-order" element={<StartOrder />} />
        <Route path="/make-payment" element={<MakePayment />} />
        <Route path="/return-order" element={<ReturnOrderStepForm />} />
        <Route path="/tracking" element={<ShipmentTracking />} />
        <Route path="/login" element={<B2bMainLogin />} />
        <Route path="/forgetpassword" element={<B2bForgetPass />} />
        <Route path="/checkmain" element={<B2bChechMail />} />
        <Route path="/setnewpassword" element={<B2bSetPass />} />
        <Route path="/first-categori" element={<Categorione />} />
        <Route path="/second-categori" element={<Categoritwo />} />
        <Route path="/my-wallet" element={<MyWallet />} />
        <Route path="/my-wallet-payment" element={<MakePaymentWallet />} />
      </Routes>

      {pathname.startsWith("/b2b-buyer") ? "" : <Footer />}
    </>
  );
};

export default B2b_Routes_main;
