import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllBrand = async (
  currentPage,
  perPage,
  search,
  searchStatus
) => {
  try {
    let url = `/seller/info-website/masters/brands?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeBrandStatus = async (id) => {
  try {
    return await editStatusData(`/seller/info-website/masters/brands/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteBrand = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/info-website/masters/brands/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addBrand = async (finalData) => {
  try {
    return await postData(`/seller/info-website/masters/brands`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const editBrand = async (id, finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/brands/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/seller/info-website/masters/brands/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
