// InnerPageTwo.js

import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import HomeAddress from "./Industry-Details-Tab-Forms/HomeAddress/HomeAddress";
import ShopAddress from "./Industry-Details-Tab-Forms/ShopAddress/ShopAddress";
import BranchAddress from "./Industry-Details-Tab-Forms/BranchAddress/BranchAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessModal from "../../../../CommonForAll/SuccessModal/SuccessModal";

import Select from "react-select";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";
import { Errors, Placeholders } from "../../../../../utils/errors";
import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import { Context } from "../../../../../utils/context";
import {
  firmDetailNamePost,
  getFirmDetailName,
  btbPercentageSend,
} from "../../../../../utils/apis/btb/B2b";
import DropAddressDetails from "./Industry-Details-Tab-Forms/DropAddressDetails";

const InnerPageTwo = ({
  setPage,
  handleBack,
  handleSaveAndNext,
  activeTab,
  navItems,
  innerNext,
  userID,
  firmName,
}) => {
  const [dropCount, setDropCount] = useState(0);
  const [pickupCount, setPickupCount] = useState(0);
  const [homeAddressId, setHomeAddressId] = useState("");
  const [successModalShow, setSuccessModalShow] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    const data = {
      name: d.name,
      firm_type_id: d.firm_type_id.value,
      btb_personal_details_id: userID,
      percentage: btbPercentageSend.stepFour,
    };
    if (firmDetailNameId != "") {
      data.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(data);
    if (res?.success) {
      console.log(res);
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  // console.log(firmName,"firmNamefirmNamefirmNamefirmNamefirmNamefirmNamefirmNamefirmName");

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [firmAddressTypes, setFirmAddressType] = useState([]);

  const [firmTypes, setFirmType] = useState([]);
  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "firm_type_id", false);
        setFirmType(data);
      }
    }
    {
      const res = await firmAddressType();
      if (res?.success) {
        setFirmAddressType(res.data);
      }
    }
  };

  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(userID);
    if (res?.success) {
      setFirmDetailNameId(res.data.btb_personal_details_id);
      reset(res.data);
    }else{
        setValue('name',firmName);
    } 
  };

  const [error, setErrors] = useState("");
  const handleNext = async () => {
    if (dropCount > 0 && firmDetailNameId !== "" && homeAddressId !== "") {
      setPage(4);
    } else {
      if (dropCount === 0) {
        setErrors("Drop Address Required");
      }
      if (firmDetailNameId === "") {
        setErrors("Firm name Required");
      }
      if (homeAddressId === "") {
        setErrors("Firm Address Required");
      }
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
  }, []);

  return (
    <div className="form-container">
      <h6 className="ms-3 firmmmdd">
        {" "}
        <FontAwesomeIcon
          className="left-arrow  me-2"
          icon="fa-solid fa-arrow-left "
          onClick={handleBack}
        />
        Firm Details
      </h6>
      <div className="tab-form-container firstthreerrr">
        <Form onSubmit={handleSubmit(onFirmNameSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter the firm name</Form.Label>
                {/* <Form.Control type="text" placeholder="Enter firm name" /> */}
                <Form.Control
                  className={classNames("", {
                    "is-invalid": errors?.name,
                  })}
                  type="text"
                  {...register("name", {
                    required: Errors.firmName,
                  })}
                  placeholder={Placeholders.firmName}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select your firm type</Form.Label>
                <Controller
                  name="firm_type_id"
                  {...register("firm_type_id", {
                    required: Errors.firmType,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.firm_type_id ? "red" : baseStyles,
                        }),
                      }}
                      {...field}
                      options={firmTypes}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-end">
                <Button className="btn StepfoursaveBtn" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <div className="form-heading-text">
          <h6 className=" firmmmdd">Communication Address Details</h6>
        </div>
        <div className="tab-form-container">
          <div className="stepthreeMain">
            {/* form-holder */}
            <div className="form-container">
              <HomeAddress
                userID={userID}
                setHomeAddressId={setHomeAddressId}
                setSuccessModalShow={setSuccessModalShow}
              />
              <DropAddressDetails
                userID={userID}
                setDropCount={setDropCount}
                setSuccessModalShow={setSuccessModalShow}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="Common-details-step m-3">
        <Form.Check
          inline
          type="checkbox"
          label="Is Firm Address Details  are same ?"
          name=""
          className="my-2"
        />
      </div>
       */}

      <div className="education-step-save-and-next">
        <div className="apply-btn-holder btnMainn apply_now_btn  text-end">
          {/* <Button className="back-btn me-3"> Skip </Button> */}
          <Button
           className="back-btn"
           onClick={handleBack}          
          >
            Back
          </Button>
          <Button className="tabs-btn" 
          // onClick={handleNext}
          onClick={() => setPage()}
          >
            Register and Continue
          </Button>

          <SuccessModal show={successModalShow} />
        </div>
      </div>
    </div>
  );
};

export default InnerPageTwo;
