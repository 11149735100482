import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Stepfour.css";
import InnerPageOne from "./InnerPageOne/InnerPageOne";
import InnerPageTwo from "./InnerPageTwo/InnerPageTwo";

function Stepfour({ setPage, innerNext, userDetails }) {
  const [activeTab, setActiveTab] = useState("Home_address");
  const [selectedOption, setSelectedOption] = useState(null);

  const [firmName, setFirmname] = useState("");
  const [panData, setPanData] = useState();

  const navItems = [
    { eventKey: "Home_address", label: "Home address" },
    { eventKey: "shop_address", label: "Shop address" },
    { eventKey: "branch_office", label: "Office address" },
  ];

  const handleSaveAndNext = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );

    if (currentIndex < navItems.length - 1) {
      const nextTab = navItems[currentIndex + 1].eventKey;
      setActiveTab(nextTab);
    } else {
      setPage(4);
    }
  };

  const handleBack = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );

    if (currentIndex > 0) {
      const prevTab = navItems[currentIndex - 1].eventKey;
      setActiveTab(prevTab);
    } else {
      setPage(2);
    }
  };
  const handleInnerBack = () => {
    setInnerPage(0);
  };

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [innerPage, setInnerPage] = useState(0);

  const nextInnerPage = () => {
    setInnerPage((currPage) => currPage + 1);
  };

  const prevInnerPage = () => {
    setInnerPage((currPage) => currPage - 1);
  };

  return (
    <>
      <div className="step-4">
        <div className="personal-details-form ">
          {innerPage === 0 ? (
            <InnerPageOne
              userID={userDetails?.s_personal_details_id}
              nextInnerPage={nextInnerPage}
              prevInnerPage={prevInnerPage}
              handleBack={handleBack}
              nextStep={innerNext}
              setPanData={setPanData}
              firmName={firmName}
              setFirmname={setFirmname}
            />
          ) : (
            <InnerPageTwo
              userID={userDetails?.s_personal_details_id}
              setPage={setPage}
              handleBack={handleInnerBack}
              handleSaveAndNext={handleSaveAndNext}
              activeTab={activeTab}
              navItems={navItems}
              handleTabClick={handleTabClick}
              innerNext={innerNext}
              panData={panData}
              firmName={firmName}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Stepfour;
