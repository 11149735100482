import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "./Wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";

const Wallet = () => {
  const [filterText, setFilterText] = useState("");
  const columns = [
    {
      name: "Transaction Id",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Coin",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.year,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };
  return (
    <>
      <section className="Walletdashboard">
        <div className="datatableMain">
          <div className="row">
            <div className="col-lg-4">
              <input
                className="imppppp"
                type="text"
                placeholder="Search by title..."
                value={filterText}
                onChange={handleFilterChange}
              />
            </div>

            <div className="col-lg-8 text-end">
              <div className="d-flex justify-content-end">
                <div className="">
                  <button className="Getrepbtnn btn">
                    <FontAwesomeIcon icon="fa-solid fa-download" /> Get Reports
                  </button>
                </div>

                <div className="">
                  <button className="filterperrrbtnn btn">
                    <FontAwesomeIcon icon="fa-solid fa-filter" /> Filter Periods
                  </button>
                </div>

                <div className="">
                  <Form.Select
                    className="selectoldenew"
                    aria-label="Default select example"
                  >
                    <option>Select</option>
                    <option value="1">Newest</option>
                    <option value="2">Oldest</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>

          {/* <DataTable
                        columns={columns}
                        data={filteredData}
                        selectableRows
                        pagination
                        defaultSortFieldId={1}
                    /> */}

          <div className="wallet-table-sccc">
            <div className="table-responsive">
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th className="wallselecttt">
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="">
                            <Form.Check
                              inline
                              label=""
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                            />
                          </div>
                        ))}
                      </Form>
                    </th>
                    <th>Transaction Id</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Coin</th>
                    <th>Amount</th>
                    <th>Note</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <FontAwesomeIcon
                        className="misscallicon"
                        icon="fa-solid fa-arrow-trend-up"
                      />
                    </td>
                    <td>123456789</td>
                    <td>22/05/2024</td>
                    <td>Aditya</td>
                    <td>Prajwal </td>
                    <td>Rupees</td>
                    <td>4566</td>
                    <td>Lorem ipsum</td>
                    <td className="cancc-app">Cancel</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon
                        className="upcomimgcallicon"
                        icon="fa-solid fa-arrow-trend-down"
                      />
                    </td>
                    <td>123456789</td>
                    <td>22/05/2024</td>
                    <td>Aditya</td>
                    <td>Prajwal </td>
                    <td>Rupees</td>
                    <td>4566</td>
                    <td>Lorem ipsum</td>
                    <td className="compp-app">Completed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wallet;
