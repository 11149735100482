import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllChildCategory = async (currentPage, perPage, search,searchCategary,searchSubCategary,searchStatus,searchOrder) => {
  try {
    let url = `/seller/inquiry-website/masters/child-category?page=${currentPage}&per_page=${perPage}&term=${search}&category=${searchCategary?.value || ""}&subcategory=${searchSubCategary?.value || ""}&order=${searchOrder.value || ""}`;
    if(searchStatus?.value || searchStatus?.value == 0){
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeChildCategoryStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/inquiry-website/masters/child-category/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteChildCategory = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/inquiry-website/masters/child-category/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addChildCategory = async (finalData) => {
  try {
    return await postData(
      `/seller/inquiry-website/masters/child-category`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const editChildCategory = async (id, finalData) => {
  try {
    return await postData(
      `/seller/inquiry-website/masters/child-category/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleChildCategory = async (id) => {
  try {
    return await getData(`/seller/inquiry-website/masters/child-category/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
