import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../api";


export const allCategoryData = async (data) => {
  try {
    const res = await getData(`/outer-website/dashboard/category`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const allCounter = async (data) => {
  try {
    const res = await getData(`/outer-website/dashboard/all-count`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const allBarChart = async (data) => {
  try {
    const res = await getData(`/outer-website/dashboard/bar`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
