import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "../../ShoppingCartMain/ShoppingCartMain.css";
import ShoppingCartCard from "../ShoppingCartCard/ShoppingCartCard";
import SummaryCard from "../SummaryCard/SummaryCard";
import {
  btobCartList,
  btobseletedCartList,
} from "../../../../utils/apis/btb/Cart";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import { Link, useLocation, useParams } from "react-router-dom";
const ShoppingCart = () => {
  const { signin } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [cart, setcart] = useState([]);
  const location = useLocation();
  console.log(location);
  const getData = async () => {
    if (signin) {
      let res;
      console.log("Kiran");
      console.log(location.pathname == "/b2bbuyer/start-order");
      if (location.pathname == "/b2bbuyer/start-order") {
        res = await btobseletedCartList();
      } else {
        res = await btobCartList();
      }
      console.log("CartResponse", res);
      setValue("total_items", res?.data?.length);

      let total_tax = 0;
      let total_mrp = 0;
      let total_selling = 0;
      for (const data of res?.data) {
        total_tax += data?.total_tax;
        total_mrp += data?.total_mrp;
        total_selling += data?.total_selling;
      }
      setValue("status", res?.data?.status);

      setValue("total_tax", total_tax);
      setValue("total_selling", total_selling);
      setValue("total_mrp", total_mrp);
      setValue("total", total_selling + total_tax);
      setValue("discount", total_mrp - total_selling);
      setcart(res?.data);
    } else {
      if (Cookies.get("btb_cart")) {
        if (JSON.parse(Cookies.get("btb_cart")).length === 0) {
          setValue("total_tax", 0);
          setValue("total_selling", 0);
          setValue("total_mrp", 0);
          setValue("total", 0);
          setValue("discount", 0);
          setcart([]);
        }
        await setcart(JSON.parse(Cookies.get("btb_cart")));
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="ShoppingCart-sec">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
              <div className="heading">
                <h1>Shopping Cart</h1>
              </div>
              {/* <Form.Group className="my-2">
                <Form.Check
                  label="Select all items"
                  feedback="You must agree before submitting."
                />
              </Form.Group> */}
              <ShoppingCartCard cardDataArray={cart} getData={getData} />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              {signin && (
                <SummaryCard
                  getValues={getValues}
                  link={"/b2bbuyer/start-order"}
                  text={"Proceed to Checkout"}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShoppingCart;
