import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllHomePageBanner = async (
  currentPage,
  perPage,
  search,
  searchStatus
) => {
  try {
    let url = `/seller/info-website/masters/social-media?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeHomePageBannerStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/info-website/masters/social-media/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteHomePageBanner = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/info-website/masters/social-media/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addHomePageBanner = async (finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/social-media`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const editHomePageBanner = async (id, finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/social-media/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleHomePageBanner = async (id) => {
  try {
    return await getData(`/seller/info-website/masters/social-media/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
