import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { RegxExpression } from "../../../../../src/utils/common";
import Select from "react-select";
import classNames from "classnames";
import { checkerPasswordPost } from "../../../../utils/apis/checker/checker";
import PasswordChecklist from "react-password-checklist";
import CreateAccountSeccseccfullyModal from "../../../CommonForAll/CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal";
import Cookies from "js-cookie";

const Stepseven = ({ nextStep, prevStep, checkerDetails, checkerID }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };
  const [modalShow, setModalShow] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [matcherror, setmatchErrors] = useState("");

  const onSubmit = async (data) => {
    console.log(data);
    data.c_personal_details_id = checkerID;
    // data.percentage = sellerPercentageSend.stepSix;
    if (data.password === data.re_password) {
      const res = await checkerPasswordPost(data);
      if (res?.success) {
        setmatchErrors("");
        console.log(res);
        Cookies.remove("checker_id");
        // nextStep();
        setModalSuccess(true);
        setTimeout(() => {
          setModalSuccess(false);
          navigate("/b2bbuyer/buyer-home");
        }, 3000);
      }
    } else {
      setmatchErrors("Password Must Match");
    }
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  return (
    <>
      <div className="personal-details-form  employee-details-step">
        <div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row stepsixform">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.password,
                      })}
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message:
                            "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                        },
                      })}
                      onChange={(e) => setPassword(e.target?.value)}
                    />
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                      ]}
                      minLength={8}
                      value={password}
                      valueAgain={passwordAgain}
                      onChange={(isValid) => {}}
                    />
                    <FontAwesomeIcon
                      icon={iconOne}
                      className="StepsixeditIcon"
                      onClick={() => togglePasswordVisibility("password")}
                    />
                    {errors?.password && (
                      <span className="text text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="reenterPassword">
                  <Form.Label>Re-Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPasswordOne ? "text" : "password"}
                      placeholder="Re-Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.re_password,
                      })}
                      name="re_password"
                      {...register("re_password", {
                        required: "Re-Password is required",
                      })}
                      onChange={(e) => setPasswordAgain(e.target?.value)}
                    />
                    <FontAwesomeIcon
                      icon={iconTwo}
                      className="StepsixeditIcon"
                      onClick={() =>
                        togglePasswordVisibility("reenterPassword")
                      }
                    />
                    {errors?.re_password && (
                      <span className="text text-danger">
                        {errors.re_password.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
              {matcherror && (
                <span className="text text-danger">{matcherror}</span>
              )}
            </div>
            <div className="text-end apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}
              <Button onClick={prevStep} className="back-btn">
                Back
              </Button>
              {/* <Button
                className="tabs-btn"
                onClick={() => {
                  setModalShow2(true);
                  setTimeout(() => {
                    setModalShow2(false);
                    window.location.href = "/b2bbuyer/buyer-home";
                  }, 3000);
                }}
              >
                Submit
              </Button> */}

              <Button className="tabs-btn" type="submit">
                Submit
              </Button>

              <CreateAccountSeccseccfullyModal
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
              />
              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Form>
        </div>
        <div className="col-md-12">
          {/* <div className="text-end apply_now_btn">
            <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p>

            <Button onClick={prevStep} className="back-btn">
              Back
            </Button>
            <Button className="tabs-btn" onClick={() => setModalSuccess(true)}>
              Submit
            </Button>

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />

            <CreateAccountSeccseccfullyModal show={modalSuccess}
              onHide={() => setModalSuccess(false)} />


          </div> */}
        </div>
      </div>
    </>
  );
};

export default Stepseven;
