import { React, useState } from "react";
import "./B2bSellerProduct.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Container, Row, Col } from "react-bootstrap";
import "./B2bSellerProduct.css";
import Table from "react-bootstrap/Table";
import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
const B2bSellerProduct = (props) => {
  const { data, singleVariant, IMG_URL, getValues } = props;
  const [activeTab, setActiveTab] = useState("first");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const now = 60;
  const [showSeeking, setShowSeeking] = useState(false);
  // Eight tab section
  const ReviewsDetails = [
    {
      name: "Shubham Kumar",
      date: "May 16, 2023",
      text: "very nice experience....super quality materials.",

      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2bSellerProduct/review1.png",
    },
    {
      name: "Rohit Verma",
      date: "June 10, 2023",
      text: "very helpful and good stuffs thank you so much",

      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2bSellerProduct/review2.png",
    },
    {
      name: "Rohit Verma",
      date: "June 10, 2023",
      text: "very helpful and good stuffs thank you so much",

      image:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2bSellerProduct/review3.png",
    },
  ];

  const QutionsDetails = [
    {
      customer:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      name: "Oshin Kawale",
      question:
        "What factors should I consider before purchasing a product online?",
      customertwo:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      customername: "Pratiksha Pethe",
      ans: "Before making a purchase online, consider factors such as product reviews, seller reputation, return policies, shipping costs, and product specifications to ensure you make an informed decision.",
    },
    {
      customer:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      name: "Oshin Kawale",
      question:
        "What factors should I consider before purchasing a product online?",
      customertwo:
        process.env.PUBLIC_URL +
        "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png",
      customername: "Pratiksha Pethe",
      ans: "Before making a purchase online, consider factors such as product reviews, seller reputation, return policies, shipping costs, and product specifications to ensure you make an informed decision.",
    },
  ];

  return (
    <>
      {/* B2bSellerProduct section started */}
      <section className="B2bSeller-Product">
        <Container>
          <Row>
            <Col xxl={9}>
              <div className="Product-section">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                >
                  <div className="main-nav">
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link eventKey="first">About Product</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Product Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Technical Specification
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Customization</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth">
                          Warranty & Product Caution
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="six">Ratings & Reviews</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {/* Content of first tab */}
                      <div className="Content-First-Tab">
                        <div
                          className="Content-First-Tab"
                          dangerouslySetInnerHTML={{ __html: data?.about }}
                        ></div>
                        {/* <Row>
                                                    <Col xxl={5}>
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assest/images/B2Bbuyer/B2BSellerDetail/product.png"
                                                            }
                                                            alt=""
                                                            className="product-image"
                                                        />
                                                    </Col>
                                                    <Col xxl={7}>
                                                        <p className="text-lorem">
                                                            Lorem Ipsum is simply dummy text of the printing and
                                                            typesetting industry. Lorem Ipsum has been the industry's
                                                            standard dummy text ever since the 1500s, when an unknown
                                                            printer took a galley of type and scrambled it to make a
                                                            type specimen book. It has survived not only five centuries,
                                                            but also the leap into electronic typesetting, remaining
                                                            essentially unchanged. It was popularised in the 1960s with
                                                            the release of Letraset sheets containing Lorem Ipsum
                                                            passages, and more recently with desktop publishing software
                                                            like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </p>
                                                        <p className="text-lorem"> Sed
                                                            ut perspiciatis unde omnis iste natus error sit voluptatem
                                                            accusantium doloremque laudantium, totam rem aperiam, eaque
                                                            ipsa quae ab illo inventore veritatis et quasi architecto
                                                            beatae vitae dicta sunt explicabo. Nemo enim ipsam
                                                            voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                                                            sed quia consequuntur magni dolores eos qui ratione
                                                            voluptatem sequi nesciunt. Neque porro quisquam est, qui
                                                            dolorem ipsum quia dolor sit amet, consectetur, adipisci
                                                            velit, sed quia non numquam</p>
                                                    </Col>
                                                </Row> */}
                      </div>

                      {/* Content of second tab */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      {/* Content of second tab started*/}
                      <div className="Content-Second-Tab">
                        <div className="product-table">
                          <Row>
                            <Col xxl={12}>
                              <h4 className="title-tab">Other attributes</h4>
                              <Table className="tableDdemo mt-3" bordered>
                                <tbody>
                                  {data?.s_product_information?.s_product_descriptions?.map(
                                    (name, index) => (
                                      <tr className="name" key={index}>
                                        <td>{name?.name}</td>
                                        <td>{name?.description}</td>
                                      </tr>
                                    )
                                  )}

                                  {/* <tr className="name">
                                    <td>Specific Use</td>
                                    <td>Living Room Chair</td>
                                  </tr>
                                  <tr className="name">
                                    <td>General Use</td>
                                    <td>Home Furniture</td>
                                  </tr>
                                  <tr className="name">
                                    <td>Type</td>
                                    <td>Living Room Furniture</td>
                                  </tr>
                                  <tr className="name">
                                    <td>Appearance</td>
                                    <td>Modern</td>
                                  </tr>
                                  <tr className="name">
                                    <td>Style</td>
                                    <td>Leisure Chair</td>
                                  </tr>
                                  <tr className="name">
                                    <td>Folded</td>
                                    <td>NO</td>
                                  </tr>
                                  <tr className="name">
                                    <td>Brand Name</td>
                                    <td>OEM</td>
                                  </tr> */}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {/* Content of second tab end*/}
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      {/* <div
                        className="Content-third-Tab"
                        dangerouslySetInnerHTML={{ __html: data?.about }}
                      ></div> */}

                      <div className="product-table">
                        <Row>
                          <Col xxl={12}>
                            <h4 className="title-tab">Other attributes</h4>
                            <Table className="tableDdemo" bordered>
                              <tbody>
                                {getValues("s_p_t_descriptions")?.map(
                                  (name, index) => (
                                    <tr className="name" key={index}>
                                      <td>{name?.name}</td>
                                      <td>{name?.description}</td>
                                    </tr>
                                  )
                                )}
                                {/* <tr className="name">
                                  <td>Place of Origin</td>
                                  <td>India</td>
                                </tr>
                                <tr className="name">
                                  <td>Specific Use</td>
                                  <td>Living Room Chair</td>
                                </tr>
                                <tr className="name">
                                  <td>General Use</td>
                                  <td>Home Furniture</td>
                                </tr>
                                <tr className="name">
                                  <td>Type</td>
                                  <td>Living Room Furniture</td>
                                </tr>
                                <tr className="name">
                                  <td>Appearance</td>
                                  <td>Modern</td>
                                </tr> */}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>

                      {/* Content of third tab end */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="fourth">
                      <div className="product-table">
                        <Row>
                          {data?.s_product_packaging_details?.length ? (
                            <Col xl={6}>
                              <h4 className="title-tab">Packaging</h4>
                              <Table bordered>
                                <tbody>
                                  {data?.s_product_packaging_details?.map(
                                    (packaging, index) => (
                                      <tr className="name" key={index}>
                                        <td>
                                          {packaging?.packaging_attribute?.name}
                                        </td>
                                        <td>{packaging?.description}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          ) : (
                            <></>
                          )}

                          {data?.s_p_customize_p_details?.length ? (
                            <Col xxl={6}>
                              <h4 className="title-tab">Product Caution</h4>
                              <Table className="tableDdemo" bordered>
                                <tbody>
                                  {data?.s_p_customize_p_details?.map(
                                    (packaging, index) => (
                                      <tr className="name" key={index}>
                                        <td>
                                          {
                                            packaging
                                              ?.customize_product_attribute
                                              ?.name
                                          }
                                        </td>
                                        <td>{packaging?.description}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </div>
                      {/* <Row>
                        <Col xxl={12}>
                          <h4 className="title-tab">Other attributes</h4>
                          <Table className="tableDdemo" bordered>
                            <tbody>
                              <tr className="name">
                                <td>Place of Origin</td>
                                <td>India</td>
                              </tr>
                              <tr className="name">
                                <td>Specific Use</td>
                                <td>Living Room Chair</td>
                              </tr>
                              <tr className="name">
                                <td>General Use</td>
                                <td>Home Furniture</td>
                              </tr>
                              <tr className="name">
                                <td>Type</td>
                                <td>Living Room Furniture</td>
                              </tr>
                              <tr className="name">
                                <td>Appearance</td>
                                <td>Modern</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row> */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="fifth">
                      {/* Fifth Tab Content started */}

                      {/* <div
                        className="Content-fifth-Tab"
                        dangerouslySetInnerHTML={{
                          __html: data?.s_product_information?.p_hightlights,
                        }}
                      ></div> */}

                      <div className="product-table">
                        <Row>
                          {data?.s_product_service_terms?.map(
                            (service_terms, index) => (
                              <Col xl={6} key={index}>
                                <h4 className="title-tab">
                                  {" "}
                                  {service_terms?.s_service_term?.name}
                                </h4>
                                <Table bordered>
                                  <tbody>
                                    {service_terms?.s_product_service_term_detail?.s_product_warrenty_details?.map(
                                      (warrenty, w_index) => (
                                        <tr className="name" key={w_index}>
                                          <td>{warrenty?.name}</td>
                                        </tr>
                                      )
                                    )}

                                    {/* <tr className="name">
                                      <td>1 Yr</td>
                                    </tr>
                                    <tr className="name">
                                      <td>1 Yr</td>
                                    </tr>
                                    <tr className="name">
                                      <td>1 Yr</td>
                                    </tr>
                                    <tr className="name">
                                      <td>1 Yr</td>
                                    </tr> */}
                                  </tbody>
                                </Table>
                              </Col>
                            )
                          )}

                          <Col xxl={6}>
                            <h4 className="title-tab">Product Caution</h4>
                            <Table className="tableDdemo" bordered>
                              <tbody>
                                {data?.s_product_caution_details?.map(
                                  (name, index) => (
                                    <tr className="name" key={index}>
                                      <td>
                                        <img
                                          src={
                                            IMG_URL + name?.caution_icon?.image
                                          }
                                          className="table-imggg"
                                          alt={name?.caution_icon?.name}
                                        />
                                      </td>
                                      <td>{name?.description}</td>
                                    </tr>
                                  )
                                )}

                                {/* <tr className="name">
                                  <td>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/B2BSellerDetail/quality.png"
                                      }
                                      className="table-imggg"
                                      alt="..."
                                    />
                                  </td>

                                  <td>Specific Use</td>
                                </tr>
                                <tr className="name">
                                  <td>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/B2BSellerDetail/quality.png"
                                      }
                                      className="table-imggg"
                                      alt="..."
                                    />
                                  </td>

                                  <td>General Use</td>
                                </tr>
                                <tr className="name">
                                  <td>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/B2BSellerDetail/quality.png"
                                      }
                                      className="table-imggg"
                                      alt="..."
                                    />
                                  </td>

                                  <td>Type</td>
                                </tr>
                                <tr className="name">
                                  <td>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/B2BSellerDetail/quality.png"
                                      }
                                      className="table-imggg"
                                      alt="..."
                                    />
                                  </td>
                                  <td>Appearance</td>
                                </tr> */}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>
                      {/* Fifth Tab Content end */}
                    </Tab.Pane>

                    <Tab.Pane eventKey="six">
                      {/* Sixth Tab section started */}

                      <div className="Content-Six-Tab">
                        <div className="d-flex">
                          <p className="key">Ratings & Review</p>
                          <div className="Review-btn">
                            <Button variant="review">Write a review</Button>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="star">
                            <Rating
                              initialValue={0}
                              SVGclassName="star-svg"
                              fillColor="#00000"
                            />
                          </div>
                          <div className="star">
                            <p className="rating-number">4.3</p>
                          </div>
                        </div>
                        <div className="rating-progressbar">
                          <p className="rating-text">5 Star</p>
                          <ProgressBar now={60} variant="secondary" />
                          <p className="rating-text">117</p>
                        </div>
                        <div className="rating-progressbar">
                          <p className="rating-text">4 Star</p>

                          <ProgressBar now={60} variant="secondary" />
                          <p className="rating-text">226</p>
                        </div>
                        <div className="rating-progressbar">
                          <p className="rating-text">3 Star</p>
                          <ProgressBar now={60} variant="secondary" />
                          <p className="rating-text">75</p>
                        </div>
                        <div className="rating-progressbar">
                          <p className="rating-text">2 Star</p>
                          <ProgressBar now={60} variant="secondary" />
                          <p className="rating-text">30</p>
                        </div>
                        <div className="rating-progressbar">
                          <p className="rating-text">1 Star</p>
                          <ProgressBar now={60} variant="secondary" />
                          <p className="rating-text">5</p>
                        </div>

                        {ReviewsDetails.map((item, index) => (
                          <Row>
                            <Col xxl={9} xl={9} lg={9} md={9} sm={12}>
                              <div className="Reviews-Section">
                                <div className="review-image">
                                  <img src={item.image} className="review" />
                                </div>
                                <div className="content-review">
                                  <h4 className="title-tab">{item.name}</h4>
                                  <div className="stars">
                                    <p className="name">{item.date}</p>
                                    <div className="App">
                                      <Rating
                                        initialValue={0}
                                        SVGclassName="star-svg"
                                        fillColor="#00000"
                                      />
                                    </div>
                                  </div>
                                  <p className="name">{item.text}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>

                      {/* Sixth Tab section End */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
            <Col xxl={3}>
              <div className="Customer-Service">
                {activeTab === "first" ? (
                  <div className="Seeking">
                    <h4 className="heading-seeking">
                      Seeking particular information?
                    </h4>
                    <div className="content">
                      <p className="customer">Customer Q&As</p>
                      <p className="text">
                        There were 0 results in Customer Questions & Answers.
                      </p>
                      <p className="customer">
                        Unable to find the answer you seek?
                      </p>

                      <Button variant="primary" className="post">
                        Post your question
                      </Button>
                    </div>
                  </div>
                ) : null}

                {activeTab === "second" ? (
                  <div className="Customer-Question">
                    <h4 className="heading-seeking">Customer Q&As</h4>
                    <div className="content">
                      <p className="customer">
                        Unable to find the answer you seek?
                      </p>

                      <Button variant="primary" className="post">
                        Post your question
                      </Button>
                      {QutionsDetails.map((item, index) => (
                        <div className="question-customer">
                          <div className="d-flex">
                            <img
                              src={item.customer}
                              alt=""
                              className="customer"
                            />

                            <p className="custome-name">{item.name}</p>
                          </div>
                          <p className="customer-Question">{item.question}</p>
                          <div className="d-flex">
                            <img
                              src={item.customertwo}
                              alt=""
                              className="customer"
                            />

                            <p className="custome-name">{item.customername}</p>
                          </div>
                          <p className="ans">{item.ans}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* B2bSellerProduct section End */}
    </>
  );
};

export default B2bSellerProduct;
