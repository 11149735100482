import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { ShimmerTitle } from "react-shimmer-effects";
import {
  btobGstDetailsPost,
  btobGstDetailsGet,
  btbPercentageSend
} from "../../../../../utils/apis/btb/B2b.js";
import { Context } from "../../../../../utils/context.js";
import { gstVerify } from "../../../../../utils/apis/third_party/third_party";
const InnerPageOne = ({ nextInnerPage, prevInnerPage, handleBack, userID, setFirmname, firmName }) => {
  const [modalShow, setModalShow] = useState(false);
  const handleNext = () => {
    nextInnerPage();
  };

  const { getData, postData, IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);

  const uploader = Uploader({
    apiKey: "free",
  });
  const options = { multi: true };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  // console.log(errors);

  // console.log(userID,"userID?.btb_personal_details_iduserDetails?.btb_personal_details_id");
  const onSubmit = async (d) => {
    if (verifyStatus) {
      const data = new FormData();
      if (firmId != "") {
        data.append("id", firmId);
      }
      data.append("gst_image", d.gst_image[0]);
      data.append("gst_no", d.gst_no);
      data.append("btb_personal_details_id", userID);
      data.append("name", firmName);
      data.append("percentage", btbPercentageSend.stepFour);


      const res = await btobGstDetailsPost(data);
      if (res?.success) {
        console.log(res, "success");
        getUserFirmDetails();
        handleNext();
      }
    } else {
      if (!verifyStatus) {
        setError("gst_no", {
          type: "manual",
          message: "Verify your GST No.",
        });
      }
    }
  };

  const [selectedGSTImages, setSelectedGSTImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedGSTImage(file);
    clearErrors("gst_image");
    const previewImg = document.querySelector(".previewImg");
    if (previewImg) {
      previewImg.src = "";
    }
  };

  const handleRemoveFile = () => {
    setSelectedGSTImage(null);
    const fileInput = document.getElementById("fileGSTInput");
    fileInput.value = "";
  };

  const [firmId, setfirmId] = useState("");
  const [storeGst, setStoreGst] = useState(null);

  const getUserFirmDetails = async () => {
    const res = await btobGstDetailsGet(userID);
    if (res?.success) {
      setVerifyStatus(true);
      setStoreGst(IMG_URL + res.data.gst_image);
      setfirmId(res.data.id);
      reset(res.data);
    }
  };

  const [verifyStatus, setVerifyStatus] = useState(false);

  const verifyGst = async () => {
    setShimmerLoader(true);
    var data = {
      btb_personal_details_id: userID,
      gst_no: getValues("gst_no"),
    }
    const res = await gstVerify(data);
    if (res?.success) {
      console.log(res);
      setFirmname(res?.data?.data?.business_name);
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
    } else {
      setVerifyStatus(false);
      setError("gst_no", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };
  useEffect(() => {
    getUserFirmDetails();
  }, []);

  return (
    <div className="firmDetailsFirst">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <Form.Group className="mb-4  bankk-verrr" controlId="gst_no">
              <Form.Label>GST Number</Form.Label>
              {shimmerLoader ? (
                <ShimmerTitle line={1} gap={10} variant="primary" />
              ) : (
                <>
                  <Form.Control
                    type="text"
                    name="gst_no"
                    placeholder="Enter your GST number"
                    {...register("gst_no", {
                      required: "GST Number is required",
                      pattern: {
                        value:
                          /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                        message: "Invalid GST Number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.gst_no,
                      "is-valid": verifyStatus,
                    })}
                    onChange={() => {
                      setError("gst_no", "");
                      setVerifyStatus(false);
                    }}
                  />
                  {errors.gst_no && (
                    <span className="text-danger">{errors.gst_no.message}</span>
                  )}
                  {!verifyStatus && (
                    <button
                      type="button"
                      className="verrrr-bttt btn-info"
                      onClick={verifyGst}
                    >
                      Verify
                    </button>
                  )}
                </>
              )}
            </Form.Group>

            <br></br>
            <div className="col-lg-6">
              <div className="uploadGsthead">
                <h3>Upload GST Document</h3>
              </div>
              <div className="d-flex">
                <div className="uploadBtnsec">
                  {storeGst !== null ? (
                    <input
                      type="file"
                      id="fileGSTInput"
                      accept="image/*"
                      {...register("gst_image")}
                      onChange={handleFileChange}
                    />
                  ) : (
                    <input
                      type="file"
                      id="fileGSTInput"
                      accept="image/*"
                      {...register("gst_image", {
                        required: "GST card image is required",
                      })}
                      onChange={handleFileChange}
                    />
                  )}
                </div>
                {selectedGSTImages && (
                  <div className="previewImgmain">
                    <img
                      src={URL.createObjectURL(selectedGSTImages)}
                      alt={`Preview of ${selectedGSTImages.name}`}
                      className="previewImg"
                    />
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-xmark"
                        className="circlecrossMark"
                        onClick={handleRemoveFile}
                      />
                    </div>
                  </div>
                )}
                {!selectedGSTImages && storeGst !== null && (
                  <div className="previewImgmain">
                    <img src={storeGst} className="previewImg" />
                  </div>
                )}
              </div>
              {errors.gst_image && (
                <span className="text-danger">{errors.gst_image.message}</span>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="text-end btnMainn apply_now_btn">
            {/* <p onClick={() => setModalShow(true)}>
            By continuing, I agree to Netpurti's
            <span className="termstxt">Terms of Use & Privacy Policy</span>
          </p> */}
            <div className="registerContinueMain">
              <Button onClick={handleBack} className="back-btn">
                Back
              </Button>

            </div>
            <div className="registerContinueMain">
              <Button
                // onClick={handleNext}
                type="submit"
                className="tabs-btn"
              >
                Register & Continue
              </Button>

            </div>

            {/* <Button onClick={handleNext} className="tabs-btn">
              Next
            </Button> */}

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InnerPageOne;
