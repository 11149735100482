import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../api";

export const allCounter = async (data) => {
  try {
    const res = await getData(`/inquiry-website/dashboard/all-count`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const Login = async (data) => {
  try {
    const res = await postData(`/inquiry-website/auth/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
