import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import SummaryCard from "../SummaryCard/SummaryCard";
import "../../ShoppingCartMain/ShoppingCartMain.css";
import BreadcrumComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
import { btobCartList } from "../../../../utils/apis/btb/Cart";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";

const MakePayment = () => {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "shopping Cart", link: "/b2bbuyer/shopping-card" },
    { text: "start Order", link: "/b2bbuyer/start-order" },
    { text: "Make Payment", link: "#" },
  ]);

  const { signin } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [cart, setcart] = useState([]);
  const getData = async () => {
    if (signin) {
      const res = await btobCartList();
      // console.log(res);
      setValue("total_items", res?.data?.length);
      let total = 0;
      let totaltax = 0;
      for (const data of res?.data) {
        total += data?.total_price;
        totaltax += data?.taxvalue;
      }
      setValue("total_price", total);
      setValue("total_tax", totaltax);
      setValue("total", total + totaltax);
      setcart(res?.data);
    } else {
      setValue("total_items", 0);
      setValue("total_price", 0);
      setValue("total_tax", 0);
      setValue("total", 0);
      setcart([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="ShoppingCart-sec">
        <div className="container">
          <BreadcrumComponent items={breadcrumbItems} />
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
              <div className="heading">
                <h1>Make Payment</h1>
              </div>
              <div className="payment-sec  ">
                <div className="my-3">
                  <Form.Check
                    label="Add a new card"
                    name="delivery"
                    type="radio"
                  />
                  <div className="ms-4">
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/B2Bbuyer/icons/visa.png"
                      }
                      alt="Logo"
                    />
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/B2Bbuyer/icons/mastercard.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="row form-div ">
                    <div className="col-xxl-10 col-md-10 col-12">
                      <div className="row ms-3">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Card Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Card Number"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Given name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Given name"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Surname</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Surname"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Month</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Month"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Year</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Year"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>CVV</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="CVV"
                                aria-label="CVV"
                                className="form-cvv"
                                aria-describedby="basic-addon1"
                              />
                              <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon
                                  icon="fa-solid fa-eye"
                                  className="eye-icon"
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Form.Check
                    label="Other Payment Methods"
                    name="delivery"
                    type="radio"
                  />
                  <div className="ms-4">
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/B2Bbuyer/icons/paypal.png"
                      }
                      alt="Logo"
                    />
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/B2Bbuyer/icons/apple.png"
                      }
                      alt="Logo"
                    />
                    <p className="other-pay-text mt-2">
                      Order now and pay later using your preferred payment
                      method.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              {/* <SummaryCard text={"Proceed to Checkout"} getValues={getValues} /> */}
              <button 
              text={"Proceed to Checkout"} 
              getValues={getValues} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MakePayment;
