import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

import { formatDate } from "../Product.js";

export const getOuterWebSiteAllProducts = async (
  seller_id,
  page = 1,
  title = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    if (status !== "" && category_id !== "") {
      return await getData(
        `/seller/outer-website/products/all-products?seller_id=${seller_id}&page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
      );
    }
    if (status === "" && category_id !== "") {
      return await getData(
        `/seller/outer-website/products/all-products?seller_id=${seller_id}&page=${page}&product_title=${title}&category_id=${category_id}`
      );
    }
    if (status !== "") {
      return await getData(
        `/seller/outer-website/products/all-products?seller_id=${seller_id}&page=${page}&product_title=${title}&product_status=${status}`
      );
    }
    if (startDate !== "" && endDate !== "") {
      return await getData(
        `/seller/outer-website/products/all-products?seller_id=${seller_id}&page=${page}&product_title=${title}&from=${formatDate(
          startDate
        )}&to=${formatDate(endDate)}`
      );
    }

    return await getData(
      `/seller/outer-website/products/all-products?seller_id=${seller_id}&page=${page}&product_title=${title}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getOuterWebSiteAcceptedProducts = async (
  seller_id,
  page = 1,
  title = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    if (status !== "" && category_id !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/accepted?seller_id=${seller_id}&page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
      );
    }
    if (status === "" && category_id !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/accepted?seller_id=${seller_id}&page=${page}&product_title=${title}&category_id=${category_id}`
      );
    }
    if (status !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/accepted?seller_id=${seller_id}&page=${page}&product_title=${title}&product_status=${status}`
      );
    }
    if (startDate !== "" && endDate !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/accepted?seller_id=${seller_id}&page=${page}&product_title=${title}&from=${formatDate(
          startDate
        )}&to=${formatDate(endDate)}`
      );
    }

    return await getData(
      `/seller/outer-website/products/all-products/accepted?seller_id=${seller_id}&page=${page}&product_title=${title}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getOuterWebSiteRejectedProducts = async (
  seller_id,
  page = 1,
  title = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    if (status !== "" && category_id !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/rejected?seller_id=${seller_id}&page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
      );
    }
    if (status === "" && category_id !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/rejected?seller_id=${seller_id}&page=${page}&product_title=${title}&category_id=${category_id}`
      );
    }
    if (status !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/rejected?seller_id=${seller_id}&page=${page}&product_title=${title}&product_status=${status}`
      );
    }
    if (startDate !== "" && endDate !== "") {
      return await getData(
        `/seller/outer-website/products/all-products/rejected?seller_id=${seller_id}&page=${page}&product_title=${title}&from=${formatDate(
          startDate
        )}&to=${formatDate(endDate)}`
      );
    }

    return await getData(
      `/seller/outer-website/products/all-products/rejected?seller_id=${seller_id}&page=${page}&product_title=${title}`
    );
  } catch (error) {
    console.log(error);
  }
};
