import React from 'react'
import './EditPersonalDetailsModal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeModal from './ChangeModal';
const EditPersonalDetailsModal = (props) => {
    const [modalChange, setModalChange] = React.useState(false);
    
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Personal Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={4} controlId="formGridEmail">
                                <div className='d-flex'>
                                    <Form.Label>Email </Form.Label> <span className='change' onClick={() => { setModalChange(true); props.onHide(); }}>Change</span>
                                </div>


                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Recipient's username"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className='email-text'
                                    />
                                    <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon="fa-solid fa-circle-check" className='me-1' />Verified</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Disha" />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Marathe" />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridContact">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control type="text" placeholder="+91 8745345678" />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="formGridDesignation">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control type="text" placeholder="Designation" />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent onClick={props.onHide} className={'btn-update'} > Update</ButtonComponent>
                </Modal.Footer>
            </Modal>
            <ChangeModal
                show={modalChange}
                onHide={() => setModalChange(false)}
            />
        </>
    )
}

export default EditPersonalDetailsModal