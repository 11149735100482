import React from "react";
import Empower_banner from "./banner/Empower_banner";
import All_product_count_list from "./all-product-count/All_product_count_list";
import "./main-dashboard.css";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";

const Maindashboard = () => {
  return (
    <>
      <Empower_banner />
      <All_product_count_list />

      <section className="chartt-main-div">
        <div className="row">
          <div className=" col-xxl-6 col-xl-12 col-lg-12 mt-xl-0 my-5">
            <div className="innerbox">
              <h6 className="box-name">Earning overview</h6>
              <div>
                <LineChart
                  xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                  series={[
                    {
                      data: [2, 5.5, 2, 8.5, 1.5, 5],
                    },
                  ]}
                  width={500}
                  height={300}
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12  col-lg-12 mt-xl-0 mt-lg-0 mt-md-5 my-5">
            <div className="innerbox">
              <h6 className="box-name">Sales By Category</h6>
              <div>
                <PieChart
                  series={[
                    {
                      data: [
                        { id: 0, value: 10, label: "series A" },
                        { id: 1, value: 15, label: "series B" },
                        { id: 2, value: 20, label: "series C" },
                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-lg-12 mt-xl-0 mt-md-5 my-5">
            <div className="innerbox">
              <h6 className="box-name">My Messages</h6>
              <hr></hr>

              <div className="message-overflow">
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-8">
                    <p className="mb-0 ">
                      <img
                        className="user-imgg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/seller-image/Header/user.png"
                        }
                      />
                      <span className="name">Disha Marathe</span>
                    </p>
                  </div>
                  <div className="col-4 my-auto">
                    <p className="time mb-0 ">11 min ago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Maindashboard;
