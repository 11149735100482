import React, { useEffect, useState } from "react"
import "./MyProfile.css";
import { Scrollchor } from 'react-scrollchor';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import EditPersonalDetailsModal from "../../common-components/AllModal/Editpersonal-Detailmodal/EditPersonalDetailsModal";
import WorkingTypeModal from "../../common-components/AllModal/Editpersonal-Detailmodal/WorkingTypeModal";
import { faUser, faBriefcase, faIndustry, faFilm, faLock, faChevronRight, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const MyProfile = () => {
    const [activeTab, setActiveTab] = useState("");
    const [modalEditDetails, setModalEditDetails] = useState(false);
    const [modalWorkingType, setModalWorkingType] = useState(false);

    useEffect(() => {
        if (activeTab) {
            const element = document.getElementById(activeTab.slice(1)); // Remove '#' from activeTab
            if (element) {
                const yOffset = -80;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    }, [activeTab]);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    return (
        <>
          <section className="MyprofileSec">
                <div className="container">
                    <div className="row scrollable-content-div">
                        <div className="col-lg-2 col-12">
                            <div id="list-example" className="list-group  acounttab-stick">
                                <h1 className="account-text mb-4
                                ">Account</h1>
                                <Scrollchor to="#sample-code1" className={`nav-link ${activeTab === "#sample-code1" ? "active" : ""}`} onClick={() => handleTabClick("#sample-code1")}>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon={faUser} className="me-2" />
                                        <h6 className="list-name"> Personal Details</h6>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Scrollchor>

                                <Scrollchor to="#sample-code2" className={`nav-link ${activeTab === "#sample-code2" ? "active" : ""}`} onClick={() => handleTabClick("#sample-code2")}>

                                    <div className="d-flex">
                                        <FontAwesomeIcon icon={faBriefcase} className="me-2" />
                                        <h6 className="list-name"> Working Type</h6>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Scrollchor>
                                <Scrollchor to="#sample-code3" className={`nav-link ${activeTab === "#sample-code3" ? "active" : ""}`} onClick={() => handleTabClick("#sample-code3")}>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon={faIndustry} className="me-2" />
                                        <h6 className="list-name">Industry Details</h6>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Scrollchor>
                                <Scrollchor to="#sample-code4" className={`nav-link ${activeTab === "#sample-code4" ? "active" : ""}`} onClick={() => handleTabClick("#sample-code4")}>

                                    <div className="d-flex">
                                        <FontAwesomeIcon icon={faFilm} className="me-2" />
                                        <h6 className="list-name">Firm Details</h6>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Scrollchor>

                                <Scrollchor to="#sample-code5" className={`nav-link ${activeTab === "#sample-code5" ? "active" : ""}`} onClick={() => handleTabClick("#sample-code5")}>

                                    <div className="d-flex">
                                        <FontAwesomeIcon icon={faLock} className="me-2" />
                                        <h6 className="list-name">Change Password</h6>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Scrollchor>
                            </div>
                        </div>

                        <div className="col-lg-8 col-12">
                            <div className="middle-div">


                                <div id="sample-code2">
                                    <div className="card b2bprofileCard">
                                        <div className="card-body">
                                            <div className="personalDetail">
                                                <h4>Personal Details</h4>
                                            </div>
                                            <div className="row">
                                                <div className=" col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                                                    <div className="Innernamee">
                                                        <p>First Name</p>
                                                        <h3>Disha</h3>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                                                    <div className="Innernamee">
                                                        <p>Last Name</p>
                                                        <h3>Marathe</h3>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-3">
                                                    <div className="Innernamee">
                                                        <p>Contact Number</p>
                                                        <h3>+91 8745345678</h3>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                                                    <div className="Innernamee">
                                                        <p>Email</p>
                                                        <h3>dis***@profcyma.com</h3>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-3 col-lg-3  col-md-3">
                                                    <div className="Innernamee">
                                                        <p>Designation</p>
                                                        <h3>UI UX Team Lead</h3>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <button className="edit-btn" onClick={() => { setModalEditDetails(true) }}><FontAwesomeIcon icon={faPen} /></button>
                                    </div>
                                </div>
                                <div id="sample-code3" >
                                    <div className="card b2bprofileCard">
                                        <div className="card-body">
                                            <div className="personalDetail">
                                                <h4>Working Type</h4>
                                                <div className="Innernamee">
                                                    <p>Working Type</p>
                                                    <h3>Supplier</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="edit-btn" onClick={() => { setModalWorkingType(true) }}><FontAwesomeIcon icon={faPen} /></button>
                                    </div>
                                </div>
                                <div id="sample-code4">
                                    <div className="card b2bprofileCard">
                                        <div className="card-body">
                                            <div className="personalDetail">
                                                <h4>Industry Details</h4>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="Innernamee">
                                                            <p>Category</p>
                                                            <h3>Applincess</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="Innernamee">
                                                            <p>Sub Categories</p>
                                                            <h3>Microwave, Refrigerator, Washing Machine</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="Innernamee">
                                                            <p>Child Categories</p>
                                                            <h3>Over the Range, Built-in, Under Counter, Wall Oven and Microwave Combination</h3>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <button className="edit-btn"><FontAwesomeIcon icon={faPen} /></button>
                                    </div>
                                </div>
                                <div id="sample-code4">
                                    <div className="card b2bprofileCard">
                                        <div className="card-body">
                                            <div className="personalDetail">
                                                <h4>Firm Details</h4>
                                                <div className="row">
                                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12 ">
                                                        <div className="Innernamee">
                                                            <p>GST</p>
                                                            <h3>27AAACH7409R1Z1</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12 ">
                                                        <div className="Innernamee">
                                                            <p>Attachment</p>
                                                            <div className="img-div">
                                                                <img className="pdf-img" src={process.env.PUBLIC_URL + '/assest/images/B2B_panel/Home/Procurement_Customized/Industry.png'} alt="Logo" />
                                                                <button className="view-icon"><FontAwesomeIcon icon={faEye} /></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12 ">
                                                        <div className="Innernamee">
                                                            <p>Enter the firm name</p>
                                                            <h3>Globex Corporation</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-4 col-lg-3 col-md-3 col-12 ">
                                                        <div className="Innernamee">
                                                            <p>Enter the firm name</p>
                                                            <h3>Globex Corporation</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border-div">
                                                    <p className="small-heading-text">Communication Address Details</p>
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12 ">
                                                            <div className="Innernamee">
                                                                <p>Address Type</p>
                                                                <h3>Home Address</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-8 col-lg-6 col-md-6 col-12 ">
                                                            <div className="Innernamee">
                                                                <p>Address</p>
                                                                <h3>8370, Ram Nagar, Arakashan Road, Pahar Ganj, Bangalore, Karnataka - 110055</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <p className="small-heading-text">Drop address 1</p>
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12 ">
                                                            <div className="Innernamee">
                                                                <p>Address Type</p>
                                                                <h3>Office</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-8 col-lg-6 col-md-6 col-12 ">
                                                            <div className="Innernamee">
                                                                <p>Address</p>
                                                                <h3>8370, Ram Nagar, Arakashan Road, Pahar Ganj, Bangalore, Karnataka - 110055</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <p className="small-heading-text">Drop address 2</p>
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12 ">
                                                            <div className="Innernamee">
                                                                <p>Address Type</p>
                                                                <h3>Office</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-8 col-lg-6 col-md-6 col-12 ">
                                                            <div className="Innernamee">
                                                                <p>Address</p>
                                                                <h3>8370, Ram Nagar, Arakashan Road, Pahar Ganj, Bangalore, Karnataka - 110055</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="edit-btn"><FontAwesomeIcon icon={faPen} /></button>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div className="col-lg-2 col-md-5 col-sm-5 col-12">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/accountbanner.png"} className="accountbanner" />

                            </div>
                        </div>
                    </div>


                </div>
            </section> 

       

            <EditPersonalDetailsModal
                show={modalEditDetails}
                onHide={() => setModalEditDetails(false)}
            />

            <WorkingTypeModal
                show={modalWorkingType}
                onHide={() => setModalWorkingType(false)}
            />
        </>
    )
}

export default MyProfile








