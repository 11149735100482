import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { motion } from "framer-motion";
import Affiliate_add_task_modal from "../../../../common-components/modals/advertisement_add_task_modal/Affiliate_add_task_modal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AnimatePresence } from "framer-motion";
import { ShimmerTable } from "react-shimmer-effects";
import { type } from "@testing-library/user-event/dist/type";
import {
  Category,
  ProductStatus,
} from "../../../../../../../utils/apis/master/Master";
// import { getAllProducts, productStatusChange } from "../../../../../../../utils/apis/seller/Product";
import CategoryDetails from "../../../../../../CommonForAll/CategoryDetails/CategoryDetails";
import View_all_varients_offcanvas from "../../../../common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";
import CustomPaginate from "../../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import VeiwEditModal from "../../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Mrp_modal from "../../../../../../CommonForAll/mrp-modal/Mrp_modal";
import { Context } from "../../../../../../../utils/context";
import { Rating } from "react-simple-star-rating";
import SuccessModal from "../../../../../../CommonForAll/SuccessModal/SuccessModal";
import DeleteModal from "../../../../../../CommonForAll/affiliateDeleteModel/Delete_modal";
import {
  getActiveTaskApi,
  getAdvertisementAllProducts,
  productStatusChange,
} from "../../../../../../../utils/apis/seller/advertisement";
import Affiliate_edit_task_modal from "../../../../common-components/modals/advertisement_add_task_modal/Affiliate_edit_task_modal";
const Advertisementtable = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const { getData, postData, IMG_URL, Select2Data, userdata, putData } =
    useContext(Context);
  const [products, setProducts] = useState([]);
  const [activeTasks, setActiveTasks] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [allData, setAllData] = useState([]);

  const { shimmerLoader, setShimmerLoader, Id } = useContext(Context);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");
  const [modalaaffilate, setModalaaffilate] = useState(false);

  const [modaleye, setModaleye] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [key, setKey] = useState("home");
  const [deleteshow, setdeleteShow] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [successModalShow, setSuccessModalShow] = useState(false);
  // const [show1, setShow1] = useState(false);
  const [modalaaffilateEdit, setModalaaffilateEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalaaffilateDelete, setModalaaffilateDelete] = useState(false);
  const [newTypes, setType] = useState("affiliate");

  console.log(newTypes, "newTypes newTypes");
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const InitialTransition = () => {};
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };

  const getTasks = async (page) => {
    // setShimmerLoader(true);
    const res = await getAdvertisementAllProducts(
      page,
      newTypes,
      productTitle,
      startDate,
      endDate
    );
    if (res?.success) {
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res?.data);
      // setShimmerLoader(false);
    }
  };
  const getActiveTasks = async (page) => {
    // setShimmerLoader(true);
    const res = await getActiveTaskApi(
      page,
      newTypes,
      productTitle,
      startDate,
      endDate
    );
    if (res?.success) {
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setActiveTasks(res?.data);
      // setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getTasks(currentPage);
    getActiveTasks(currentPage);
  }, [newTypes, currentPage, productTitle, searchStatus, startDate, endDate]);

  useEffect(() => {
    getTasks(currentPage);
  }, []);

  const handleStatus = async (id) => {
    setShimmerLoader(true);
    const res = await productStatusChange(id);
    if (res?.success) {
      getTasks(currentPage);
      setShimmerLoader(false);
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const [rating, setRating] = useState(0);
  const handleRating = () => {
    setRating(rating === 1 ? 0 : 1);
  };

  const [editId, setEditId] = useState(0);
  function handleClick(id) {
    setModalaaffilateEdit(true);
    setEditId(id);
  }
  function handleDeleteClick(id) {
    setModalaaffilateDelete(true);
    setEditId(id);
  }
  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        {/* <h6 className="pdlsis">Product List</h6> */}
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row">
                <div className="col-xxl-2 co-xl-2 col-lg-3 col-md-6 my-6">
                  <div className="Addtaskbtn">
                    <Button
                      className="btn addtaskbtn"
                      onClick={() => setModalaaffilate(true)}
                    >
                      Add Task
                    </Button>
                  </div>
                </div>
                <div className="col-xxl-2 co-xl-2 col-lg-3 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      value={productTitle}
                      type="search"
                      placeholder="Search by product title"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>

                <div className="col-xxl-5 co-xl-5 col-lg-6 col-md-6">
                  <div className="d-flex product-status-div">
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                        />
                      </Form.Group>
                    </div>

                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Reset Search</Form.Label>
                    <button
                      className="resetsearch-btn"
                      type="button"
                      onClick={() => {
                        // setSearchStatus("");
                        setProductTitle("");
                        setStartDate("");
                        setEndDate("");
                        // setCategory("");
                      }}
                    >
                      Reset
                    </button>
                  </Form.Group>
                </div>

                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group dowwnloaddata">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <hr></hr>
              {/* <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-3">
                                    <button
                                        className="status-change-btn"
                                        type="button"
                                        onClick={changeStatus}
                                    >
                                        Status Change
                                    </button>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-6 text-end">
                                    <div className="d-flex justify-content-end">

                                        <div className="">
                                            <Link to="/seller/seller-dashboard/btobprod">
                                                <button className="listnewpro-btn">
                                                    <FontAwesomeIcon
                                                        className="me-2"
                                                        icon="fa-solid fa-plus"
                                                    />
                                                    List New Product
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="OrderTabs mb-3"
              >
                <Tab eventKey="home" title="Tasks">
                  <div className="table-responsive table-overflow">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          {/* <td className="tdd-checkkkk">
                            <input
                              type="checkbox"
                              value="selectAll"
                              checked={
                                allChecked.length === selectAllChecked.length
                              }
                              onChange={handleChange}
                              id="selectAll"
                              className="me-1"
                            ></input>
                          </td> */}

                          <td>Task ID</td>

                          <td>Product Type</td>

                          <td>Incentive</td>

                          <td>Brand name</td>

                          <td>Img</td>

                          <td>Category</td>

                          <td>Validity</td>

                          <td>Product available for review</td>

                          <td>Status</td>

                          <td>Action</td>

                          {/* <td>
                            Discountable Product

                            </td> */}
                        </tr>
                      </thead>

                      <tbody>
                        {products?.data?.map((val, index) => (
                          <tr>
                            {/* <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                // value={value?.id}
                                name="perselected"
                                onChange={handleChange}
                                id="flexCheckDefault"
                              ></input>
                            </td> */}

                            <td> {val?.task_id}</td>

                            <td>{val?.s_product?.product_title}</td>
                            <td>₹ {val?.insentive}</td>
                            <td>{val?.s_product?.s_brand?.name}</td>
                            <td>
                              <div className=" imgMain">
                                {val?.s_product?.s_brand?.image ? (
                                  <img
                                    className="bjvbhbvh-img"
                                    src={
                                      IMG_URL + val?.s_product?.s_brand?.image
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                {/* <div className="col-lg-2 my-auto">
                                <FontAwesomeIcon icon="fa-solid fa-eye" className="eyeIcon" onClick={() => setModaleye(true)} /> 
                                </div> */}
                              </div>
                            </td>
                            <td>{val?.s_product?.s_category?.name}</td>
                            <td>{val?.validity} Days</td>

                            <td>
                              {val?.product_available == 1 ? "Yes" : "NO"}
                            </td>
                            <td>
                              {/* {val?.status === 1
                                ? <span style={{ color: 'green' }}>Active</span>
                                : <span style={{ color: 'red' }}>Inactive</span>
                              } */}
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={val?.status}
                                  onChange={() => {
                                    handleStatus(val?.id);
                                  }}
                                  id={`flexSwitchCheckDefault${val?.id}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexSwitchCheckDefault${val?.id}`}
                                >
                                  {val?.status ? "Active" : "Inactive"}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                {/* <FontAwesomeIcon icon={["fas", "pen"]} className="actionIcon" onClick={setModalaaffilateEdit(true)} /> */}
                                <FontAwesomeIcon
                                  icon="fa-solid fa-pen"
                                  className="actionIcon"
                                  onClick={() => handleClick(val?.id)}
                                />

                                {/* <FontAwesomeIcon icon="fa-solid fa-pen" className="actionIcon" /> */}
                                {/* <FontAwesomeIcon icon="fa-solid fa-eye" className="actionIcon" /> */}
                                <FontAwesomeIcon
                                  icon="fa-solid fa-trash"
                                  className="actionIcon"
                                  onClick={() => handleDeleteClick(val?.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Tab>

                <Tab eventKey="profile" title="Active Tasks">
                  <div className="table-responsive table-overflow">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          <td className="tdd-checkkkk">
                            <input
                              type="checkbox"
                              value="selectAll"
                              checked={
                                allChecked.length === selectAllChecked.length
                              }
                              onChange={handleChange}
                              id="selectAll"
                              className="me-1"
                            ></input>
                          </td>

                          <td>Ad ID</td>

                          {/* <td>Star Mark</td> */}

                          <td>Product ID</td>

                          <td>Name</td>

                          <td>Total Link</td>

                          <td>View</td>

                          <td>Total Buy</td>

                          <td>Amount</td>

                          <td>Status</td>

                          {/* <td>
                            Discountable Product

                            </td> */}
                        </tr>
                      </thead>

                      <tbody>
                        {activeTasks?.data?.map((val, index) => (
                          <>
                            <tr>
                              <td className="tdd-checkkkk">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  // value={value?.id}
                                  name="perselected"
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                              </td>

                              <td> {val?.task_id}</td>

                              {/* <td>
                                <div className='one-star-rating' onClick={handleRating}>
                                  <span className={`star ${rating === 1 ? 'rated' : ''}`}>★</span>
                                </div>
                              </td> */}
                              <td>{val?.s_product?.id}</td>

                              <td>
                                <div className="row">
                                  <div className="col-lg-10">
                                    {val?.s_product?.product_title}
                                  </div>
                                  <div className="col-lg-2 my-auto">
                                    {/* <FontAwesomeIcon icon="fa-solid fa-eye" className="eyeIcon" onClick={() => setModaleye(true)} /> */}
                                  </div>
                                </div>
                              </td>

                              <td>{val?.affiliate_tasks.length}</td>
                              <td>100 </td>
                              <td>
                                {val?.affiliate_tasks?.map(
                                  (affi) =>
                                    affi?.o_w_orders_details?.[0]?.totalQuantity
                                )}
                              </td>

                              <td>
                                ₹
                                {val?.affiliate_tasks?.map(
                                  (affi) =>
                                    val?.insentive *
                                    affi?.o_w_orders_details?.[0]?.totalQuantity
                                )}
                              </td>
                              <td>Active</td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              </Tabs>
            </Form>
          </div>

          <CategoryDetails show={modaleye} onHide={() => setModaleye(false)} />

          <div className="pagination-div">
            <CustomPaginate
              currentPage={currentPage}
              totalPages={products?.totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          {/* <View_all_varients_offcanvas
            show={varientsoffcanvas}
            variantData={variantData}
            getVariantProducts={getVariantProducts}
            onHide={() => setVarientsoffcanvas(false)}
            placement="top"
          /> */}

          {/* <VeiwEditModal
            show={modalShowedit}
            getAllProducts={getAllProducts}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
          /> */}

          <Affiliate_add_task_modal
            show={modalaaffilate}
            onHide={() => setModalaaffilate(false)}
            // getAdvertisement={props.getAdvertisement}
            getAllProducts={getTasks}
          />

          <Affiliate_edit_task_modal
            editId={editId}
            show={modalaaffilateEdit}
            onHide={() => setModalaaffilateEdit(false)}
            // getAdvertisement={props.getAdvertisement}
            getAllProducts={getTasks}
          />

          <DeleteModal
            editId={editId}
            show={modalaaffilateDelete}
            onHide={() => setModalaaffilateDelete(false)}
            // getAdvertisement={props.getAdvertisement}
            getAllProducts={getTasks}
          />

          <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
        <SuccessModal show={successModalShow} />
      </section>
    </>
  );
};

export default Advertisementtable;
