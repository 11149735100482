import React, { useEffect, useState } from "react";
import './Social_media_links.css'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
import {
    SocialPost,
    getSocial,

    getSocialUrl,
    SocialUrlPost
} from "../../../../../../utils/apis/influncer/influncer";
import { Context } from "../../../../../../utils/context";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
const Social_media_links = (props) => {

    const {
        control,
        register,
        handleSubmit,
        getValues,

        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm();
    const [socialLink, setSocialLink] = useState([]);

    const getSocialIn = async () => {
        try {
            const res1 = await SocialUrlPost();
            setSocialLink(res1?.data)
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        // getSocialIn();
    }, []);

    const onSubmit = async (d) => {
        try {
            const res1 = await SocialUrlPost(d);
            props.onHide();
            props.getAllSocialLink();
            // setSocialLink(res1?.data)
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <>
            <section className=''>
                <Modal
                    className="modal-dialog-scrollable Social_media_links_mod"
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-sec-start">
                            <Form onSubmit={handleSubmit(onSubmit)}>


                                <Form.Group>
                                    <Form.Label>Social Media</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="platform"
                                        placeholder="Social Media Name"
                                        className={classNames("", {
                                            "is-invalid": errors?.platform,
                                        })}
                                        {...register("platform", {
                                            required: "Social Media is required",
                                        })}
                                    />
                                    {errors.platform && (
                                        <span className="text-danger">
                                            {errors.platform.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Page Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="page_link"
                                        placeholder="Page Link"
                                        className={classNames("", {
                                            "is-invalid": errors?.page_link,
                                        })}
                                        {...register("page_link", {
                                            required: "Page Link is required",
                                        })}
                                    />
                                    {errors.page_link && (
                                        <span className="text-danger">
                                            {errors.page_link.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Page Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="page_name"
                                        placeholder="Page Name"
                                        className={classNames("", {
                                            "is-invalid": errors?.page_name,
                                        })}
                                        {...register("page_name", {
                                            required: "Page Name is required",
                                        })}
                                    />
                                    {errors.page_name && (
                                        <span className="text-danger">
                                            {errors.page_name.message}
                                        </span>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Total Subscriber</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subscriber"
                                        placeholder="Total Subscriber / Follower"
                                        className={classNames("", {
                                            "is-invalid": errors?.subscriber,
                                        })}
                                        {...register("subscriber", {
                                            required: "Total Subscriber / Follower is required",
                                        })}
                                    />
                                    {errors.subscriber && (
                                        <span className="text-danger">
                                            {errors.subscriber.message}
                                        </span>
                                    )}
                                </Form.Group>

                                {/* <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Social media link</Form.Label>
                                    <Form.Control type="text" placeholder="Enter social media link" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Page name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter page name" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Total subscriber</Form.Label>
                                    <Form.Control type="text" placeholder="Enter total subscriber" />
                                </Form.Group> */}

                                <div className="button-holder text-center mb-5">
                                    <Button className="btn submitt-btn me-3" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default Social_media_links