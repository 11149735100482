import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import './AddMoneywalletModal.css'
import MoneyLottie from './Money-Lottie/MoneyLottie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const AddMoneywalletModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='add-money-modal'
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className="lottie-holder">
                        <MoneyLottie />
                    </div>
                    <Form>
                        <p >Add money to your wallet</p>
                        <Form.Control type="email" placeholder="Enter amount to be added in wallet" />

                    </Form>
                    <Link to="/b2bbuyer/my-wallet-payment">
                        <div className='text-center mt-3'>
                            <ButtonComponent onClick={props.onHide} className={'btn-continue'}>Continue <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='ms-1' /></ButtonComponent>
                        </div>
                    </Link>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddMoneywalletModal