import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import "./ShoppingCartCard.css";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import DeleteModal from "../../common-components/AllModal/Delete-modal/DeleteModal";
import AddNoteModal from "../../common-components/AllModal/Add_note_modal/AddNoteModal";
import { Context } from "../../../../utils/context";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  btobCartQuentity,
  btobCartRemove,
  btobCartStatus,
} from "../../../../utils/apis/btb/Cart";
library.add(fas);

const ShoppingCartCard = ({ cardDataArray, getData }) => {
  const { IMG_URL } = useContext(Context);
  const [showNote, setShowNote] = useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [values, setValues] = useState(cardDataArray.map(() => 0));
  const location = useLocation();

  const [startOrder, setStartOrder] = useState(false);
  useEffect(() => {
    if (location.pathname === "/b2bbuyer/start-order") {
      setStartOrder(true);
    }
  }, [location]);


  const Quentity = async (cart_id, type) => {
    try {
      const res = await btobCartQuentity({ cart_id: cart_id, type: type });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  const RemoveFromCart = async (id) => {
    await btobCartRemove(id);
    await getData();
    await setModalDelete(false);
  };

  const handleCloseNote = () => setShowNote(false);
  const handleShowNote = () => setShowNote(true);



  return (
    <>
      {cardDataArray
        .filter((cardData) => startOrder ? cardData.status : true)
        .map((cardData, index) => (
          <div className="shopping-card-main-card" key={index}>
            <Form.Group className="mb-3">
              {cardData?.formcheck && (
                <Form.Check
                  label={cardData?.product_title}
                  name="delivery"
                  inline
                />
              )}
            </Form.Group>
            <div className="sec-card-head">
              <div>
                <p>{cardData?.order}</p>
              </div>
              {cardData?.buttonNote && (
                <div onClick={() => setModalShow(true)}>
                  <span>Any note for supplier ?</span>
                  <button>Add note</button>
                </div>
              )}
            </div>
            <div className="card-sec">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="left-sec">
                    {location.pathname !== "/b2bbuyer/start-order" &&
                      <Form.Check
                        name="delivery"
                        checked={cardData?.status}
                        // inline
                        onChange={(e) => {
                          btobCartStatus(cardData?.id, { status: e.target.checked });
                          getData(); // Refresh data after status change
                        }}
                      />
                    }
                    <div className="me-3">
                      <img
                        src={IMG_URL + cardData?.thumbnail}
                        className="prduct-img"
                      />
                    </div>
                    <div className="details">
                      <h6>{cardData?.product_title}</h6>
                      <p>{cardData?.product_generic_name}&</p>
                      <p>{cardData?.minOrder}</p>
                      <p>{cardData?.deliveryDate}</p>
                    </div>
                  </div>
                </div>
                {startOrder ?
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="right-sec">
                      <p className="price-text">Quanitity : {cardData?.quantity}</p>
                    </div>
                  </div> :
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="right-sec">
                      <div>
                        {/* {cardData?.buttonGroup && ( */}

                        <ButtonGroup aria-label="First group" className="btn-grp">
                          {/* {cardData?.min_quantity < cardData?.quantity && ( */}
                          <Button
                            className="add-btns"
                            disabled={cardData?.min_quantity >= cardData?.quantity}
                            onClick={() => {
                              Quentity(cardData?.cart_id, "minus");
                            }}
                          >
                            -
                          </Button>
                          {/* )} */}

                          <p className="price-text">{cardData?.quantity}</p>
                          <Button
                            className="add-btns"
                            onClick={() => {
                              Quentity(cardData?.cart_id, "add");
                            }}
                          >
                            +
                          </Button>
                        </ButtonGroup>


                        {/* )} */}

                        <div className="count">
                          <p>Mini Quentity Required {cardData?.min_quantity}</p>
                        </div>


                      </div>
                      <div>
                        <p className="price-text">₹{cardData?.selling_price}</p>
                      </div>
                      <div
                        onClick={() => {
                          setModalDelete(cardData?.cart_id);
                        }}
                      >
                        {/* {cardData?.trash && ( */}
                        <button className="trash-btn">
                          <FontAwesomeIcon icon={fas.faTrash} />
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
      <DeleteModal
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        RemoveFromCart={RemoveFromCart}
      />

      <AddNoteModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ShoppingCartCard;
