import React, { useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import "./SellerPackage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import CompareAll from "../SellerPackage/CompareAll/CompareAll";
import NewPlanPricing from "../SellerPackage/NewPlanPricing/NewPlanPricing";
const SellerPackage = () => {
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    return (
        <section className='explore-package'>
            <Container>
                <div className='title-explore'>
                    <h4>Explore Our Packages</h4>
                    <p>Embark on a Journey Tailored Just For You!</p>
                </div>
                {/* <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    initialSlide={1}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    pagination={false}
                    modules={[EffectCoverflow, Pagination]}
                    slideShadows={false}
                    className="jp-package-slider"
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            centeredSlides: false,
                        },
                        769: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                        },
                    }}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                    <SwiperSlide >
                        <div className="main-card">
                            <Card>
                                <Card.Title>Plan 1</Card.Title>
                                <Card.Body>
                                    <Card.Text>
                                        <Table >
                                            <tbody>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Monthly Visitor Set Limit</td>
                                                    <td className="number-td">300</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Space Limit</td>
                                                    <td className="number-td">1 GB</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Whatsapp/OTP/Email Limit</td>
                                                    <td className="number-td">300 msg</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Product Limit</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Plan Limit</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Auto Extension</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide >
                        <div className="main-card">
                            <Card>
                                <Card.Title>Plan 2</Card.Title>
                                <Card.Body>
                                    <Card.Text>
                                        <Table >
                                            <tbody>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Monthly Visitor Set Limit</td>
                                                    <td className="number-td">300</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Space Limit</td>
                                                    <td className="number-td">1 GB</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Whatsapp/OTP/Email Limit</td>
                                                    <td className="number-td">300 msg</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Product Limit</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Plan Limit</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Auto Extension</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="main-card">
                            <Card>
                                <Card.Title>Plan 3</Card.Title>
                                <Card.Body>
                                    <Card.Text>
                                        <Table >
                                            <tbody>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Monthly Visitor Set Limit</td>
                                                    <td className="number-td">300</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Space Limit</td>
                                                    <td className="number-td">1 GB</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Whatsapp/OTP/Email Limit</td>
                                                    <td className="number-td">300 msg</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Product Limit</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Plan Limit</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon="fa-solid fa-check" className="mark-check" /></td>
                                                    <td>Auto Extension</td>
                                                    <td className="number-td">10</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </SwiperSlide>
             
                </Swiper>
                <div
                    className="main-slider mt-5"
                    onClick={() => handleMainSliderClick("prev")}
                >
                    <div className="main-arrow-back">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                    </div>
                </div>
                <div
                    className="main-slider mt-5"
                    onClick={() => handleMainSliderClick("next")}
                >
                    <div className="main-arrow-next">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </div>
                </div> */}

                <CompareAll />
                <NewPlanPricing />
            </Container>
        </section>
    )
}

export default SellerPackage