import React, { useState } from "react";
import "./AddProductInfo.css";
import CommonDetails from "./CommonDetails/CommonDetails";
import Variants from "./Variants/Variants";
import ProductInfo from "./ProductInfo/ProductInfo";

const AddProductInfo = (props) => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      setStep(4);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <CommonDetails
            handlePrevious={props.handlePrevious}
            nextStep={nextStep}
          />
        );
      case 2:
        return <Variants nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <ProductInfo prevStep={prevStep} />;
      default:
        return null;
    }
  };

  const handleStepClick = (clickedStep) => {
    switch (clickedStep) {
      case "Common Details":
        setStep(1);
        break;
      case "Variants":
        setStep(2);
        break;
      case "Product Info":
        setStep(3);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <section className="add-productinfo-innerstepform">
        <div className="row">
          <div className="col-xxl-8">
            {step <= 3 && (
              <>
                <div className="steps-div">
                  <p
                    className={`steps-name ${step === 1 && "active"}`}
                    onClick={() => handleStepClick("Common Details")}
                  >
                    Common Details
                  </p>
                  <p
                    className={`steps-name ${step === 2 && "active"}`}
                    onClick={() => handleStepClick("Variants")}
                  >
                    Variants
                  </p>
                  <p
                    className={`steps-name ${step === 3 && "active"}`}
                    onClick={() => handleStepClick("Product Info")}
                  >
                    Product Information
                  </p>
                </div>
                <hr className="my-0 mx-3" />
              </>
            )}
          </div>
        </div>

        {renderStep()}
      </section>
    </>
  );
};

export default AddProductInfo;
