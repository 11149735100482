import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
const SettingModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='selldash-commonmodal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Setting
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='Settingmodalcontent'>
                        <div className='settingHeading'>
                            <h3>Active affiliate marketing</h3>
                            <div className='radiosMain'>
                                <Form.Check
                                    type="radio"
                                    label="Active"
                                    name='radiothree'
                                />

                                <Form.Check
                                    type="radio"
                                    label="Choosing products only"
                                    name='radiothree'
                                />

                                <Form.Check
                                    type="radio"
                                    label="Inactive"
                                    name='radiothree'
                                />
                            </div>

                        </div>

                        <div className='settingHeading'>
                            <h3>Add affiliate cost on product</h3>
                            <div className='radiosMain'>
                                <Form.Check
                                    type="radio"
                                    label="Active"
                                    name='Yes'
                                />

                                <Form.Check
                                    type="radio"
                                    label="Choosing products only"
                                    name='No'
                                />


                            </div>

                        </div>

                        <div className='settingHeading'>
                            <h3>Affiliate marketing for all product</h3>
                            <div>

                                <div className='radiosMain'>
                                    <Form.Check
                                        type="radio"
                                        label="Yes"
                                        name='radiotwo'
                                    />

                                    <Form.Check
                                        type="radio"
                                        label="No"
                                        name='radiotwo'
                                    />

                                </div>
                                <p className='adddefaulttxt'>Add default incentive on all product</p>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="email" placeholder="name@example.com" />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Percentage</Form.Label>
                                            <Form.Control type="email" placeholder="name@example.com" />
                                        </Form.Group>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} className='closebtn'>Close</Button>
                    <Button onClick={props.onHide} className='savebtn'>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SettingModal