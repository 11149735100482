import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './MyWalletCard.css';
import Table from 'react-bootstrap/Table';

const ordersData = [

  {

    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName: "Armchair New Arrival Promotion Fabric Material Adjustable Leisure Single Power Electric Relax",
      minOrder: "Transaction ID : 6756453456",
      deliveryDate: "Apr. 10 2024"
    },

    status: 'Deposit',
    buttons: {
      makePayment: true,

    }
  },
  {

    productInfo: {
      imageSrc: "/assest/images/B2Bbuyer/BestSeller/productImg1.png",
      productName: "Modern Minimalist White Half Circle Designer Couches Luxury",
      minOrder: "Transaction ID : 6756453456",
      deliveryDate: "Apr. 10 2024"
    },

    status: 'Spend',
    buttons: {
      makePayment: true,

    }
  },

];
const MyWalletCard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orders, setOrders] = useState(ordersData);

  return (
    <>
      <section className='transection-details '>
        <div className='row'>
          <div className='col-xxl-5 col-xl-5 col-lg-12'>
            <div className='heading-holder'>
              <h2>All transaction Details</h2>
            </div>
          </div>
          <div className='col-xxl-7 col-xl-7 col-lg-12'>
            <Form>
              <Row>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6'>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalFrom">
                    <Form.Label column sm={2}>
                      From
                    </Form.Label>
                    <Col sm={10}>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                      />
                    </Col>
                  </Form.Group>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 col-6'>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalTo">
                    <Form.Label column sm={2}>
                      To
                    </Form.Label>
                    <Col sm={10}>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control"
                      />
                    </Col>
                  </Form.Group>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4'>
                  <p >Download Statement<FontAwesomeIcon icon="fa-solid fa-download" className='ms-1' /></p>
                </div>
              </Row>
            </Form>
          </div>
          <div className='main-table-div' >
            <Table responsive>
              <thead>
                <tr>
                  <th>Product name</th>
                  <th>Status</th>
                  <th>Payment Status</th>
                </tr>
              </thead>

              {orders.map((order, index) => (
                <tbody className='bd-right'>
                  <tr key={index}>
                    <td>
                      <div className='product-info-div'>
                        <div>
                          <img src={order.productInfo.imageSrc} alt="Product" />
                        </div>
                        <div>
                          <h6>{order.productInfo.productName}</h6>
                          <p className='grey-text'>{order.productInfo.minOrder}</p>
                          <p className='grey-text'>{order.productInfo.deliveryDate} <span>01:23 pm</span></p>
                        </div>
                      </div>
                    </td>

                    <td className={order.status === 'Deposit' ? 'status-green' : 'status-red'}>
                      <p className='status-text'>{order.status}</p>
                    </td>
                    <td className='text-center'>
                      <div className='status-text'>
                        {order.buttons.makePayment && <p className='success-text '><FontAwesomeIcon icon="fa-solid fa-circle-check" className='me-1' />Success</p>}
                      </div>
                    </td>
                  </tr>
                </tbody>


              ))}

            </Table>
          </div>

        </div>
      </section>

    </>
  );
};

export default MyWalletCard;
