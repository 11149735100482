import React from 'react'
import Subscription from './Subscription/Subscription'

const NewPlanPricing = () => {
    return (
        <>
            <Subscription />
        </>
    )
}

export default NewPlanPricing