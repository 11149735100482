import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllSubFaq = async (
  currentPage,
  perPage,
  search,
  searchFAQ,
  searchStatus
) => {
  try {
    let url = `/seller/info-website/masters/product-deal?page=${currentPage}&per_page=${perPage}&term=${search}&faq=${
      searchFAQ?.label || ""
    }`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeSubFaqStatus = async (id) => {
  try {
    return await editStatusData(`/seller/info-website/masters/product-deal/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteSubFaq = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/info-website/masters/product-deal/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addSubFaq = async (finalData) => {
  try {
    return await postData(`/seller/info-website/masters/product-deal`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const editSubFaq = async (id, finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/product-deal/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleSubFaq = async (id) => {
  try {
    return await getData(`/seller/info-website/masters/product-deal/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
