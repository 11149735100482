import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Select from "react-select";
import JoditEditor from "jodit-react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./CommonDetails.css";
import Warrentytab from "../CommonDetails/Warrentytab/Warrentytab";
import Guarenteetab from "./Guarenteetab/Guarenteetab";
import Noservice from "./Noservice/Noservice";
import ProductCautionSafety from "./ProductCautionSafety/ProductCautionSafety";
import Expiry from "./Expiry/Expiry";
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import Cookies from "js-cookie";
import Live_preview from "../../../SellerPanel/Seller_Dashboard/SidebarComponents/Inventory/B2BProduct/Live-preview/Live_preview";
import { Context } from "../../../../utils/context";
import {
  sellerCheckBrand,
  productBrandPost,
  productBrandGet,
  productCommonPost,
  productCommonGet,
} from "../../../../utils/apis/seller/BTBProduct";
import { getCustomNames } from "../../../../utils/apis/autosuggest/AutoSuggest";
import {
  Brand,
  Days,
  Area,
  Customization,
  Attributes,
  ProductTypes,
  ServiceType,
  AttributesPackaging,
  ProductInfoIcon,
  ProductVideoSettings,
} from "../../../../utils/apis/master/Master";
import { RequiredIs, validateVideo } from "../../../../utils/common";
import Packaging from "./Packaging/Packaging";
import Customize_product from "./Customize_product/Customize_product";
const CommonDetails = (props) => {
  const [content, setContent] = useState("");

  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [videoStatus, setVideoStatus] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null);

  // Function to handle file change
  const handleFileChange = (event) => {
    setVideoPreview(null);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const videoElement = document.createElement("video");
        videoElement.src = e.target.result;
        videoElement.addEventListener("loadedmetadata", () => {
          // setVideoDimensions({
          //   width: videoElement.videoWidth,
          //   height: videoElement.videoHeight,
          // });
          // if (videoElement.videoWidth < 512 && videoElement.videoHeight < 512) {
          setVideoPreview(videoElement.src);
          // }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      s_product_customizations_details: [
        {
          s_attribute_id: "",
          description: "",
          document: false,
        },
      ],
      warrenty_details: [
        {
          name: "",
        },
      ],
      caution_details: [
        {
          caution_id: null,
          description: "",
        },
      ],
      packaging_details: [
        {
          packaging_id: null,
          description: "",
          document: false,
          disable: false,
        },
      ],
      customize_product_details: [
        {
          customize_product_id: null,
          description: "",
          document: false,
          disable: false,
        },
      ],
    },
  });

  // const { append, fields, remove } = useFieldArray({
  //   control,
  //   name: "s_product_customizations_details",
  // });
  const [service_term_child, setServiceTermChild] = useState("");
  const onSubmit = async (data) => {
    data.s_area_id = getValues("s_area_id").value;
    data.s_product_service_terms_details = [];

    if (getValues("s_service_term_id") == 1) {
      let warantyData = {
        s_service_term_id: 1,
        w_provided_by:
          data.w_provided_by.value === "other"
            ? data.w_other_provided_by
            : data.w_provided_by.value,
        w_period: data.w_period,
        w_helpline: data.w_helpline,
        w_address: data.w_address,
        w_term: data.w_term,
        s_service_type_id: data.s_service_type_id,
      };
      data.s_product_service_terms_details.push(warantyData);
    }
    if (getValues("s_service_term_id") == 2) {
      let guaranteeData = {
        s_service_term_id: 2,
        g_provided_by:
          data.g_provided_by.value === "other"
            ? data.g_other_provided_by
            : data.g_provided_by.value,
        g_period: data.g_period,
        g_helpline: data.g_helpline,
        g_term: data.g_term,
      };
      data.s_product_service_terms_details.push(guaranteeData);
    }

    if (getValues("s_service_term_id") == 3) {
      let noService = {
        s_service_term_id: 3,
      };
      data.s_product_service_terms_details.push(noService);
    }

    if (getValues("s_service_term_id") == 4) {
      let expiryData = {
        s_service_term_id: 4,
        expiry_day: data.expiry_day,
        // expiry_date: data.expiry_date,
      };
      data.s_product_service_terms_details.push(expiryData);
    }

    var sendData = new FormData();

    sendData.append("video", data.video[0]);
    sendData.append(
      "s_product_service_terms_details",
      JSON.stringify(data.s_product_service_terms_details)
    );
    sendData.append("s_product_id", props.productId);
    sendData.append("s_area_id", data.s_area_id);

    sendData.append("s_area", data.s_area);
    sendData.append("is_returnable", data.is_returnable);
    sendData.append("is_replaceable", data.is_replaceable);
    sendData.append("about", data.about);
    sendData.append("caution_details", JSON.stringify(data.caution_details));
    sendData.append("caution_and_safety", data.caution_and_safety);
    sendData.append("s_product_type_id", data.s_product_type_id);

    // if (data.s_product_type_id == 1) {
    //   sendData.append("s_customization_id", data.s_customization_id);
    //   sendData.append(
    //     "s_product_customizations_details",
    //     JSON.stringify(data.s_product_customizations_details)
    //   );
    // }

    if (data.s_product_type_id == 1 && data.s_customization_id == 1) {
      sendData.append("s_customization_id", data.s_customization_id);
      sendData.append(
        "packaging_details",
        JSON.stringify(data.packaging_details)
      );
    }

    if (data.s_product_type_id == 1 && data.s_customization_id == 2) {
      sendData.append("s_customization_id", data.s_customization_id);
      sendData.append(
        "packaging_details",
        JSON.stringify(data.packaging_details)
      );

      sendData.append(
        "customize_product_details",
        JSON.stringify(data.customize_product_details)
      );
    }

    sendData.append("warrenty_details", JSON.stringify(data.warrenty_details));

    if (data.is_returnable)
      sendData.append("is_returnable_day", data.is_returnable_day);
    if (data.is_replaceable)
      sendData.append("is_replaceable_day", data.is_replaceable_day);

    // Assign the modified array to data.s_product_customizations_details

    const res = await productCommonPost(sendData);
    if (res?.success) {
      props.nextStep();
    }
  };

  const validateInputs = (data) => {
    // Perform your validation logic here
    // Return true if validation passes, false otherwise
    // Example validation:
    if (!data.name) {
      setError("name", { type: "required", message: "Name is required" });
      return false;
    }

    // Add more validation logic as needed

    return true;
  };

  const { getData, IMG_URL, Select2Data, working_type } = useContext(Context);
  const [days, setDay] = useState([]);
  const [supplyAreas, setSupplyArea] = useState([]);
  const [customizations, setCustomization] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [productTypes, setProductType] = useState([]);
  const [serviceTypes, setServiceType] = useState([]);
  const [attributespackaging, setAttributePackaging] = useState([]);
  const [productInFoIcon, setProductInfoIcon] = useState([]);
  const [productsettings, setproductsettings] = useState([]);
  const getMasters = async () => {
    {
      const res = await ProductVideoSettings();

      if (res?.success) {
        setproductsettings(res?.data);
      }
    }
    {
      const res = await Days();
      if (res?.success) {
        var data = await Select2Data(res.data, "select_day", false);
        setDay(data);
      }
    }
    {
      const res = await Area();
      if (res?.success) {
        var data = await Select2Data(res.data, "supply_area", false);
        setSupplyArea(data);
      }
    }
    {
      const res = await Customization();
      if (res?.success) {
        setCustomization(res.data);
      }
    }
    {
      const res = await ProductTypes();
      if (res?.success) {
        setProductType(res.data);
      }
    }
    {
      const res = await ServiceType();
      if (res?.success) {
        setServiceType(res.data);
      }
    }
    {
      const res = await Attributes();
      if (res?.success) {
        var data = await Select2Data(res.data, "s_attribute_id ", false);
        setAttribute(data);
      }
    }
    {
      const res = await AttributesPackaging();
      if (res?.success) {
        var data = await Select2Data(res.data, "s_attribute_id ", false);
        setAttributePackaging(data);
      }
    }
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };

  const [returnable, setReturnable] = useState(false);
  const [replaceable, setReplaceable] = useState(false);
  const [addressStatus, setAddressStatus] = useState(false);

  const getProductData = async (id) => {
    const res = await productBrandGet(id);

    if (res?.success) {
      setValue("product_title", res.data.product_title);
      setValue("s_category", res.data.s_category?.name);

      setValue(
        "s_brand",
        res.data.supply_type == "Custom" ||
          res.data.supply_type == "Self Manufactured"
          ? res.data?.s_products_supply_type?.s_custom?.name
          : res.data?.s_brand?.name
      );
    }
  };

  const getProductCommon = async (id) => {
    const res = await productCommonGet(id);

    if (res?.success) {
      reset(res.data);
      var arra =
        res.data?.s_product_customization?.s_p_customization_attributes?.map(
          (value) => ({
            s_attribute_id: {
              value: value?.attribute_packaging?.id,
              label: value?.attribute_packaging?.name,
            },
            description: value.description,
            document: value.document,
          })
        );

      if (!arra?.length) {
        arra = [
          {
            s_attribute_id: "",
            description: "",
            document: false,
          },
        ];
      }

      var arrCaution = res.data?.s_product_caution_details?.map((value) => ({
        description: value?.description,
        caution_id: {
          value: value?.caution_id,
          label: value?.caution_icon?.name,
          image: value?.caution_icon?.image,
          name: "caution_id",
        },
      }));

      if (!arrCaution?.length) {
        arrCaution = [
          {
            caution_id: null,
            description: "",
          },
        ];
      }

      var packaging_details = [];

      packaging_details =
        res.data?.s_product_customization?.s_product_packaging_details?.map(
          (value) => ({
            packaging_id: {
              value: value?.packaging_attribute?.id,
              label: value?.packaging_attribute?.name,
              document: value?.packaging_attribute?.document,
            },
            description: value?.description,
            document: value?.document,
            disable: value?.disable,
          })
        );

      if (!packaging_details?.length) {
        packaging_details = [
          {
            packaging_id: null,
            description: "",
            disable: false,
            document: false,
          },
        ];
      }

      var customize_product_details = [];
      if (res.data?.s_product_customization?.s_customization_id == 2) {
        customize_product_details =
          res.data?.s_product_customization?.s_p_customize_p_details?.map(
            (value) => ({
              customize_product_id: {
                value: value?.customize_product_attribute?.id,
                label: value?.customize_product_attribute?.name,
                document: value?.customize_product_attribute?.document,
              },
              description: value?.description,
              document: value?.document,
              disable: value?.disable,
            })
          );

        if (!customize_product_details?.length) {
          customize_product_details = [
            {
              customize_product_id: null,
              description: "",
              disable: false,
              document: false,
            },
          ];
        }
      }

      reset({
        packaging_details: packaging_details,
        customize_product_details: customize_product_details,
        s_product_customizations_details: arra,
        caution_details: arrCaution,
      });
      setValue("about", res.data.about);

      setValue("caution_and_safety", res.data.caution_and_safety);

      setValue(
        "s_product_type_id",
        res.data.s_product_customization?.s_product_type_id
          ? res.data.s_product_customization?.s_product_type_id
          : RequiredIs.product_type.ganeral
      );
      // setValue(
      //   "s_product_type_id",
      //   res.data.s_product_customization?.s_product_type_id
      // );
      setValue(
        "s_customization_id",
        res.data.s_product_customization?.s_customization_id
      );
      setValue("s_area_id", {
        value: res.data.s_area?.id,
        label: res.data.s_area?.name,
      });
      setValue("s_area", res.data.supply_area);
      setValue("is_returnable", res.data.s_child_category?.is_returnable);
      setValue("is_replaceable", res.data.s_child_category?.is_replacable);

      setValue(
        "returnable_min_days",
        res.data.s_child_category?.returnable_min_days
      );
      setValue(
        "returnable_max_days",
        res.data.s_child_category?.returnable_max_days
      );
      setValue(
        "replacable_min_days",
        res.data.s_child_category?.replacable_min_days
      );
      setValue(
        "replacable_max_days",
        res.data.s_child_category?.replacable_max_days
      );

      setReturnable(res.data.s_child_category?.is_returnable);
      setReplaceable(res.data.s_child_category?.is_replacable);
      await setServiceTermChild(res.data.s_child_category?.service_term_id);

      if (res.data.s_product_policy?.is_returnable_day) {
        setValue(
          "is_returnable_day",
          res.data.s_product_policy?.is_returnable_day
        );
      } else
        setValue(
          "is_returnable_day",
          res.data.s_child_category?.returnable_min_days
        );

      if (res.data.s_product_policy?.is_replaceable_day)
        setValue(
          "is_replaceable_day",
          res.data.s_product_policy?.is_replaceable_day
        );
      else
        setValue(
          "is_replaceable_day",
          res.data.s_child_category?.replacable_min_days
        );
      setVideoPreview(IMG_URL + res.data.s_product_video?.video);

      if (!res.data.s_product_service_terms?.length) {
        setValue("s_service_term_id", 1);
        setValue("warrenty_details", [
          {
            name: "",
          },
        ]);
      }

      res.data?.s_brand_id &&
        setValue("g_helpline", res.data?.s_brand?.service_no);

      res.data?.s_brand_id &&
        setValue("w_helpline", res.data?.s_brand?.service_no);

      res.data.s_product_service_terms?.map((value, index) => {
        if (value.s_service_term.name == "Warranty") {
          setValue("s_service_term_id", 1);
          setValue("w_term", value.s_product_service_term_detail?.w_term);
          if (value.s_product_service_term_detail?.w_provided_by === "Brand") {
            setValue("w_provided_by", { value: "Brand", label: "Brand" });
          } else {
            setValue("w_provided_by", { value: "other", label: "Other" });
            setValue(
              "w_other_provided_by",
              value.s_product_service_term_detail?.w_provided_by
            );
          }

          setValue("w_period", value.s_product_service_term_detail?.w_period);

          setValue(
            "w_helpline",
            value.s_product_service_term_detail?.w_helpline
          );

          setValue("w_address", value.s_product_service_term_detail?.w_address);
          setValue(
            "s_service_type_id",
            value.s_product_service_term_detail?.s_service_type_id
          );

          var arra2 =
            value?.s_product_service_term_detail?.s_product_warrenty_details?.map(
              (value) => ({
                name: value.name,
              })
            );

          if (!arra2?.length) {
            arra2 = [
              {
                name: "",
              },
            ];
          }

          setValue("warrenty_details", arra2);

          setAddressStatus(value.s_product_service_term_detail?.w_address);
        } else if (value.s_service_term.name == "Guarantee") {
          setValue("s_service_term_id", 2);
          setValue("g_term", value.s_product_service_term_detail?.g_term);

          if (value.s_product_service_term_detail?.g_provided_by === "Brand") {
            setValue("g_provided_by", { value: "Brand", label: "Brand" });
          } else {
            setValue("g_provided_by", { value: "other", label: "Other" });
            setValue(
              "g_other_provided_by",
              value.s_product_service_term_detail?.g_provided_by
            );
          }
          setValue("g_period", value.s_product_service_term_detail?.g_period);

          setValue(
            "g_helpline",
            value.s_product_service_term_detail?.g_helpline
          );

          var arra2 =
            value?.s_product_service_term_detail?.s_product_warrenty_details?.map(
              (value) => ({
                name: value.name,
              })
            );

          if (!arra2?.length) {
            arra2 = [
              {
                name: "",
              },
            ];
          }

          setValue("warrenty_details", arra2);
        } else if (value.s_service_term.name == "No Service") {
          setValue("s_service_term_id", 3);
        } else if (value.s_service_term.name == "Expiry") {
          setValue("s_service_term_id", 4);
          setValue(
            "expiry_day",
            value.s_product_service_term_detail.expiry_day
          );
          // setValue(
          //   "expiry_date",
          //   new Date(
          //     value.s_product_service_term_detail.expiry_date
          //   ).toLocaleDateString("en-CA", {
          //     day: "2-digit",
          //     month: "2-digit",
          //     year: "numeric",
          //   })
          // );
        } else {
          setValue("s_service_term_id", 1);
        }
      });
    }
  };
  useEffect(() => {
    if (props.productId) {
      getProductData(props.productId);
      getProductCommon(props.productId);
    }
    getMasters();
  }, [props.productId]);

  return (
    <>
      <section className="Common-details-step ">
        <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="main-card">
                <div className="row">
                  <div className="col-xxl-4 col-lg-4 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Product Title <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">
                              {productInFoIcon?.product_title}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Product Title"
                        className={classNames("", {
                          "is-invalid": errors?.product_title,
                        })}
                        disabled
                        name="product_title"
                        {...register("product_title")}
                      />
                      {errors.name && (
                        <span className="text-danger">
                          {errors.product_title.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-xxl-4 col-lg-4 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Category <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-category">
                              {productInFoIcon?.category}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Category"
                        className={classNames("", {
                          "is-invalid": errors?.s_category,
                        })}
                        disabled
                        name="s_category"
                        {...register("s_category")}
                      />
                      {errors.category && (
                        <span className="text-danger">
                          {errors.s_category.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-xxl-4 col-lg-4 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Brand <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-brand">
                              {productInFoIcon?.brand}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Brand"
                        className={classNames("", {
                          "is-invalid": errors?.s_brand,
                        })}
                        disabled
                        name="s_brand"
                        {...register("s_brand")}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="main-card">
                <h5 className="text-heading">Policy</h5>
                <div className="row">
                  <div className="col-xxl-6 col-lg-6 col-md-6">
                    {/* <div className="d-flex">
                      <p className="me-2 minitext">Is Returnable ?</p>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className={classNames("", {
                          "is-invalid": errors?.is_returnable,
                        })}
                        name="is_returnable"
                        {...register("is_returnable")}
                        onChange={(e) => setReturnable(!returnable)}
                      />
                    </div> */}

                    {returnable ? (
                      <div className="select-to-div">
                        <Form.Label htmlFor="inputPassword5">
                          Select Days
                          <span className="span-text">(If Returnable ?)</span>
                        </Form.Label>

                        <Form.Control
                          type="number"
                          placeholder="Returnable Days"
                          className={classNames("", {
                            "is-invalid": errors?.is_returnable_day,
                          })}
                          min={getValues("returnable_min_days")}
                          max={getValues("returnable_max_days")}
                          name="is_returnable_day"
                          {...register("is_returnable_day", {
                            required: "Returnable Days is required",
                            min: {
                              value: getValues("returnable_min_days"),
                              message: `Returnable Days must be at least ${getValues(
                                "returnable_min_days"
                              )}`,
                            },
                            max: {
                              value: getValues("returnable_max_days"),
                              message: `Returnable Days cannot exceed ${getValues(
                                "returnable_max_days"
                              )}`,
                            },
                          })}
                        />
                        {errors.is_returnable_day && (
                          <span className="text-danger">
                            {errors.is_returnable_day.message}
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xxl-6 col-lg-6 col-md-6">
                    {/* <div className="d-flex">
                      <p className="me-2 minitext">Is Replaceable ?</p>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className={classNames("", {
                          "is-invalid": errors?.is_replaceable,
                        })}
                        name="is_replaceable"
                        {...register("is_replaceable")}
                        onChange={(e) => setReplaceable(!replaceable)}
                      />
                    </div> */}
                    {replaceable ? (
                      <div className="select-to-div">
                        <Form.Label htmlFor="inputPassword5">
                          Select Days
                          <span className="span-text">(If Replaceable ?)</span>
                        </Form.Label>

                        <Form.Control
                          type="number"
                          placeholder="Replaceable Days"
                          min={getValues("replacable_min_days")}
                          max={getValues("replacable_max_days")}
                          className={classNames("", {
                            "is-invalid": errors?.is_replaceable_day,
                          })}
                          name="is_replaceable_day"
                          {...register("is_replaceable_day", {
                            required: "Replaceable Days is required",
                            min: {
                              value: getValues("replacable_min_days"),
                              message: `Replaceable Days must be at least ${getValues(
                                "replacable_min_days"
                              )}`,
                            },
                            max: {
                              value: getValues("replacable_max_days"),
                              message: `Replaceable Days cannot exceed ${getValues(
                                "replacable_max_days"
                              )}`,
                            },
                          })}
                        />
                        {errors.is_replaceable_day && (
                          <span className="text-danger">
                            {errors.is_replaceable_day.message}
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="main-card">
                <div className="row">
                  <div className="select-to-div">
                    <Form.Label>
                      Supply Area <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">Supply Area</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Supply Area"
                      className={classNames("", {
                        "is-invalid": errors?.s_area,
                      })}
                      name="s_area"
                      {...register("s_area", {
                        required: "Supply Area is required",
                      })}
                      // onKeyDown={(e) => handleSupplyChange(e)}
                    />

                    {/* <Controller
                      name="s_area_id"
                      {...register("s_area_id", {
                        required: "Supply Area is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.s_area_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={supplyAreas}
                        />
                      )}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="main-card">
                <div className="row">
                  <div className="select-to-div">
                    <Form.Label>Product About</Form.Label>
                    <Controller
                      name="about"
                      {...register("about", {
                        required: "about is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <JoditEditor
                          value={content}
                          tabIndex={1}
                          onBlur={(newContent) => setContent(newContent)}
                          {...field}
                        />
                      )}
                    />
                    {errors.about && (
                      <span className="text-danger">
                        {errors.about.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="main-card tab-section px-3">
                <Tab.Container
                  id="left-tabs-example"
                  activeKey={getValues("s_service_term_id")}
                >
                  <h6>After Sale Services :- Any One</h6>
                  <Nav variant="pills" className="">
                    {/* {getValues("s_service_term_id") == 1 && ( */}
                    {[1, 2].includes(service_term_child) && (
                      <Nav.Item
                        onClick={() => {
                          setValue("s_service_term_id", 1);
                          reset(getValues());
                        }}
                      >
                        <Nav.Link eventKey="1">Warranty</Nav.Link>
                      </Nav.Item>
                    )}

                    {/* {getValues("s_service_term_id") == 2 && ( */}
                    {[1, 2].includes(service_term_child) && (
                      <Nav.Item
                        onClick={() => {
                          setValue("s_service_term_id", 2);
                          reset(getValues());
                        }}
                      >
                        <Nav.Link eventKey="2">Guarantee</Nav.Link>
                      </Nav.Item>
                    )}
                    {/* {getValues("s_service_term_id") == 3 && ( */}
                    <Nav.Item
                      onClick={() => {
                        setValue("s_service_term_id", 3);
                        reset(getValues());
                      }}
                    >
                      <Nav.Link eventKey="3">No Service</Nav.Link>
                    </Nav.Item>
                    {/* )} */}

                    {/* {getValues("s_service_term_id") == 4 && ( */}
                    {[4].includes(service_term_child) && (
                      <Nav.Item
                        onClick={() => {
                          setValue("s_service_term_id", 4);
                          reset(getValues());
                        }}
                      >
                        <Nav.Link eventKey="4">Expiry</Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      {getValues("s_service_term_id") == 1 ? (
                        <Warrentytab
                          register={register}
                          reset={reset}
                          errors={errors}
                          Controller={Controller}
                          control={control}
                          serviceTypes={serviceTypes}
                          addressStatus={addressStatus}
                          setAddressStatus={setAddressStatus}
                          getValues={getValues}
                          setValue={setValue}
                          useFieldArray={useFieldArray}
                          working_type={working_type}
                        />
                      ) : null}
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      {getValues("s_service_term_id") == 2 ? (
                        <Guarenteetab
                          register={register}
                          reset={reset}
                          Controller={Controller}
                          control={control}
                          getValues={getValues}
                          setValue={setValue}
                          useFieldArray={useFieldArray}
                          working_type={working_type}
                          errors={errors}
                        />
                      ) : null}
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      {getValues("s_service_term_id") == 3 ? (
                        <Noservice
                          register={register}
                          reset={reset}
                          Controller={Controller}
                          control={control}
                        />
                      ) : null}
                    </Tab.Pane>
                    <Tab.Pane eventKey="4">
                      {getValues("s_service_term_id") == 4 ? (
                        <Expiry
                          register={register}
                          reset={reset}
                          Controller={Controller}
                          control={control}
                        />
                      ) : null}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>

              <div className="main-card video-sec">
                <h4 className="cust-text">Product Caution & Safety</h4>
                Maximum 100 words are allowed
                <ProductCautionSafety
                  register={register}
                  errors={errors}
                  reset={reset}
                  Controller={Controller}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  useFieldArray={useFieldArray}
                  working_type={working_type}
                  IMG_URL={IMG_URL}
                />
                {/* <Controller
                  name="caution_and_safety"
                  {...register("caution_and_safety", {
                    required: "Caution and Safety is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <JoditEditor
                      value={content}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      {...field}
                    />
                  )}
                /> */}
              </div>

              <div className="main-card video-sec">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-md-6 col-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Product Video<span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">Product Video</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                    </Form.Group>
                    {/* <div className="video-descriptn">
                      <h6 className="video-title">Video Resolution</h6>
                      <p className="sub-title">
                        Use clear video with a minimum resolution of 512*512 px.
                      </p>
                    </div> */}
                    <div className="video-descriptn">
                      <h6 className="video-title">Video Guidelines</h6>
                      <p className="sub-title">
                        Upload authentic product Video taken in bright lighting
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-md-6 col-3">
                    {videoPreview ? (
                      <input
                        type="file"
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                        accept="video/*"
                        name="video"
                        {...register("video")}
                        onChange={async (e) => {
                          const result = await validateVideo(
                            e.target.files[0],
                            productsettings?.size,
                            productsettings?.time
                          );
                          setVideoStatus(!result);
                          result && handleFileChange(e);
                        }}
                      />
                    ) : (
                      <input
                        type="file"
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                        accept="video/*"
                        name="video"
                        {...register("video", {
                          required: "Product Video is required",
                        })}
                        onChange={async (e) => {
                          const result = await validateVideo(
                            e.target.files[0],
                            5,
                            40
                          );
                          setVideoStatus(!result);
                          result && handleFileChange(e);
                        }}
                      />
                    )}

                    {videoStatus && (
                      <sub className="text-danger">
                        Video Size should be 5MB and less than 40 sec.
                      </sub>
                    )}

                    {/* {videoDimensions.width > 512 ||
                    videoDimensions.height > 512 ? (
                      <sub className="text-danger">
                        Video dimensions should not exceed 512 x 512.
                      </sub>
                    ) : null} */}

                    {/* <p className="sub-title m-0">Video.mp4</p> */}
                    {/* <p className="itlic-text">File attached</p> */}
                  </div>
                  {videoPreview && (
                    <div className="col-xxl-6 col-xl-6  col-md-6 col-6">
                      <div className="video-div ">
                        <video
                          className="online-video"
                          playsInline
                          autoPlay
                          mute
                          controls
                        >
                          <source src={videoPreview}></source>
                        </video>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="main-card ">
                <h4 className="cust-text">Product Type</h4>
                <div className="">
                  {productTypes?.map((value, index) =>
                    RequiredIs.working_type[working_type] ? (
                      !RequiredIs.working_type[working_type].includes(
                        index
                      ) && (
                        <Form.Check
                          inline
                          type="radio"
                          label={value?.name}
                          value={value?.id}
                          defaultChecked={
                            value.id === getValues("s_product_type_id")
                              ? true
                              : false
                          }
                          name="s_product_type_id"
                          className={classNames("my-2", {
                            "is-invalid": errors?.s_product_type_id,
                          })}
                          {...register("s_product_type_id", {
                            required: "Customization is required",
                          })}
                          onChange={(e) => {
                            setValue("s_product_type_id", e.target.value);
                            reset(getValues());
                          }}
                        />
                      )
                    ) : (
                      <Form.Check
                        inline
                        type="radio"
                        label={value?.name}
                        value={value?.id}
                        defaultChecked={
                          value.id === getValues("s_product_type_id")
                            ? true
                            : false
                        }
                        name="s_product_type_id"
                        className={classNames("my-2", {
                          "is-invalid": errors?.s_product_type_id,
                        })}
                        {...register("s_product_type_id", {
                          required: "Customization is required",
                        })}
                        onChange={(e) => {
                          setValue("s_product_type_id", e.target.value);
                          reset(getValues());
                        }}
                      />
                    )
                  )}
                </div>

                {getValues("s_product_type_id") == 1 && (
                  <>
                    <h4 className="cust-text">Customization</h4>
                    <div className="">
                      {customizations?.map((value, index) => (
                        <Form.Check
                          inline
                          type="radio"
                          label={value?.name}
                          value={value?.id}
                          defaultChecked={
                            value.id === getValues("s_customization_id")
                              ? true
                              : false
                          }
                          name="s_customization_id"
                          className={classNames("my-2", {
                            "is-invalid": errors?.s_customization_id,
                          })}
                          {...register("s_customization_id", {
                            required: "Customization is required",
                          })}
                          onChange={(e) => {
                            setValue("s_customization_id", e.target.value);
                            reset(getValues());
                          }}
                        />
                      ))}
                    </div>
                  </>
                )}

                {getValues("s_customization_id") == 1 && (
                  <>
                    <h4 className="cust-text">Packaging</h4>
                    <Packaging
                      register={register}
                      errors={errors}
                      reset={reset}
                      Controller={Controller}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      useFieldArray={useFieldArray}
                      working_type={working_type}
                      IMG_URL={IMG_URL}
                    />
                    {/* {fields?.map((value, index) => (
                      <>
                        <div className="row mt-3">
                          <div className="col-xxl-5 col-md-5 col-5">
                            <div className="select-to-div">
                              <Form.Label htmlFor="inputPassword5">
                                Select Attributes for Packaging{" "}
                                <span className="star">*</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-category">
                                      Attributes
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>
                           
                              <Controller
                                name={`s_product_customizations_details[${index}].s_attribute_id`}
                                {...register(
                                  `s_product_customizations_details[${index}].s_attribute_id`,
                                  {
                                    required: "Packaging is required",
                                  }
                                )}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.s_attribute_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={attributespackaging}
                                  />
                                )}
                              />
                            </div>
                            <div className="mt-3">
                              <p className="mb-1 minitext">Document</p>

                              <Controller
                                control={control}
                                name={`s_product_customizations_details[${index}].document`}
                                render={({ field }) => (
                                  <Form.Check
                                    type="switch"
                                    id={`custom-switch-${index}`}
                                    className={classNames("", {
                                      "is-invalid":
                                        errors.data &&
                                        errors.data[index] &&
                                        errors.data[index].document,
                                    })}
                                    name={`s_product_customizations_details[${index}].document`}
                                    checked={field.value} // Set the checked attribute based on field value
                                    onChange={(e) =>
                                      field.onChange(e.target.checked)
                                    } // Call field.onChange to update value
                                    {...field} // Spread the field object to ensure onChange, onBlur, and value are passed
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-md-6 col-6">
                            <div>
                              <Form.Label>
                                Attributes for Packaging Description
                              </Form.Label>

                              <Controller
                                name={`s_product_customizations_details[${index}].description`}
                                {...register(
                                  `s_product_customizations_details[${index}].description`,
                                  {
                                    required: "Description is required",
                                  }
                                )}
                                control={control}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={content}
                                    tabIndex={1}
                                    onBlur={(newContent) =>
                                      setContent(newContent)
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-1 col-md-1 col-1 align-items-center d-flex mt-auto">
                            <button
                              className="addrowiccc"
                              type="button"
                              onClick={() =>
                                append({
                                  s_attribute_id: "",
                                  description: "",
                                  document: false,
                                })
                              }
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                                }
                              />
                            </button>
                            {index !== 0 && (
                              <button
                                className="delet-row"
                                onClick={() => remove(index)}
                                type="button"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-minus" />
                              </button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))} */}
                  </>
                )}

                {getValues("s_customization_id") == 2 && (
                  <>
                    <h4 className="cust-text">Customize Product</h4>
                    <Customize_product
                      register={register}
                      errors={errors}
                      reset={reset}
                      Controller={Controller}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      useFieldArray={useFieldArray}
                      working_type={working_type}
                      IMG_URL={IMG_URL}
                    />
                    <h4 className="cust-text">Packaging</h4>
                    <Packaging
                      register={register}
                      errors={errors}
                      reset={reset}
                      Controller={Controller}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      useFieldArray={useFieldArray}
                      working_type={working_type}
                      IMG_URL={IMG_URL}
                    />
                  </>
                )}
              </div>

              <div className="common-steps-btn">
                <Button onClick={props.handlePrevious} className=" back-btn">
                  back
                </Button>

                <Button
                  className="next-btn"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save & Next
                </Button>
              </div>
            </div>

            {/* <div className="col-xxl-4 col-md-4">
              <Live_preview />
            </div> */}
          </div>
        </Form>
      </section>
    </>
  );
};

export default CommonDetails;
