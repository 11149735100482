import React, { useState,useEffect,useContext } from 'react'
import { Col, Container, Nav, Tab } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import Purchased from './Purchased'
import './HelpCenterTab.css'
import { Context } from '../../../../../utils/context';
import { Pagination } from 'swiper/modules';

const HelpCenterTab = ({ fAQSData }) => {


    
    const { IMG_URL } = useContext(Context);

    const [activekey, setActiveKey] = useState(0)
    const [Id, setId] = useState()
    
    return (
        <section className='help-center-tab'>
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey={activekey}>
                    <Nav variant="pills" className="flex-row">
                        <Swiper
                            pagination={true}
                            modules={[Pagination]}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            className='help-tab-swiper'
                            spaceBetween={50}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                380: {
                                    slidesPerView: 1,
                                },
                                485: {
                                    slidesPerView: 1,
                                },
                                575: {
                                    slidesPerView: 2,
                                },

                                768: {
                                    slidesPerView: 3,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                                1200: {
                                    slidesPerView: 3,
                                },
                                1440: {
                                    slidesPerView: 4,
                                },
                                2000: {
                                    slidesPerView: 4,
                                },
                            }}
                        >
                            {fAQSData?.map((data, index) => (
                                <SwiperSlide onClick={() => { setActiveKey(index)}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey={index}>
                                            <div className='d-flex'>
                                                <img src={IMG_URL + data?.image} className="icon-img" alt="..." />
                                                <p className='main-text'>{data?.name}</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </SwiperSlide>
                            ))}

                            {/* <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <div className='d-flex'>
                                            <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Help-Center/customer_service.png"} className="icon-img" alt="..." />
                                            <p className='main-text'>Product Assistance</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">
                                        <div className='d-flex'>
                                            <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Help-Center/access.png"} className="icon-img" alt="..." />
                                            <p className='main-text'>Access & Password Help</p>
                                        </div>

                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">
                                        <div className='d-flex'>
                                            <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Help-Center/Icon material-account-circle.png"} className="icon-img" alt="..." />
                                            <p className='main-text'>Profile Management</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide> */}
                        </Swiper>
                    </Nav>
                    <Tab.Content>
                        {fAQSData?.map((data, index) => (
                            <Tab.Pane eventKey={index}>
                                <Purchased data={data}/>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default HelpCenterTab