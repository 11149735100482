import { useState } from "react";
import BreadcrumComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent"
import "./MyprofileBanner.css"
const MyprofileBanner = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/b2bbuyer/buyer-home" },
        { text: "My Profile", link: "#" },


    ]);
    return (
        <>
            <section className="MyprofileBanner">
                <div className="container-fluid p-0 me-0">
                    <div className="Bannermain">
                        <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Accounts/banner.png"} className="profileBanner" />
                        <div className="breadcrum-sec">
                        <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                      
                        <div className="secMainprofile">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 mx-auto">
                                        <div className="card profilebannercard">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-3 my-auto">
                                                        <div className="text-center">
                                                            <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png"} className="userImg" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-10 col-9">
                                                        <div className="d-flex profileContent">
                                                            <h3>Your Member Id : </h3>
                                                            <p>in96325413wkwj</p>
                                                        </div>

                                                        <div className="d-flex profileContent">
                                                            <h3>Email : </h3>
                                                            <p>saurav@gmail.com</p>
                                                        </div>

                                                        <div className="d-flex profileContent">
                                                            <h3>Linked Mobile :</h3>
                                                            <p>+91 8745345678</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyprofileBanner