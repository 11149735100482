import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import JoditEditor from "jodit-react";
import Nav from "react-bootstrap/Nav";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tab from "react-bootstrap/Tab";
import "./ProductInfo.css";
import ProductSave from "../../../../../../../CommonForAll/PreviewSubmitmodal/ProductSave";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  ProductInfoPost,
  getProductInfo,
} from "../../../../../../../../utils/apis/seller/BTBProduct";
import classNames from "classnames";
import Cookies from "js-cookie";

import { ProductInfoIcon } from "../../../../../../../../utils/apis/master/Master";
import ProductHighlights from "./ProductHighlights/ProductHighlights";
import ProductDescription from "./ProductDescription/ProductDescription";
import { Context } from "../../../../../../../../utils/context";
const ProductInfo = ({ nextStep, prevStep }) => {
  const [modalShow, setModalShow] = useState(false);
  const [content, setContent] = useState("");
  const { working_type } = useContext(Context);
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      highlights: [
        {
          // name: "",
          description: "",
        },
      ],
      description: [
        {
          name: "",
          description: "",
        },
      ],
    },
  });

  const onSubmit = async (d) => {
    d.s_product_id = Cookies.get("s_product_id");

    d.s_product_status_id = 2;
    const res = await ProductInfoPost(d);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        Cookies.remove("s_product_id");
        navigate("/seller/seller-dashboard/b2b-products");
        setModalShow(false);
      }, 3000);
    }
  };
  const onDraftSubmit = async (d) => {
    d.s_product_id = Cookies.get("s_product_id");
    d.s_product_status_id = 1;
    const res = await ProductInfoPost(d);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        Cookies.remove("s_product_id");
        navigate("/seller/seller-dashboard/b2b-products");
        setModalShow(false);
      }, 3000);
    }
  };

  const getData = async (id) => {
    const res = await getProductInfo(id);
    if (res?.success) {
      reset(res.data);
    }
  };

  const [productInfoIcon, setProductInfoIcon] = useState([]);
  const getDataAll = async () => {
    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };
  useEffect(() => {
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      getData(cookie);
    }
    getDataAll();
  }, []);

  return (
    <>
      <section className="product-info-sec">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12">
              <div className="main-card">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="heighlight"
                >
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      <Nav.Link eventKey="heighlight">
                        Product Highlights
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">
                              {productInfoIcon?.product_highlight}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="description">
                        Product Description
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">
                              {productInfoIcon?.product_description}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="heighlight">
                      <div className="tab-content">
                        <ProductHighlights
                          register={register}
                          control={control}
                          useFieldArray={useFieldArray}
                          errors={errors}
                          setValue={setValue}
                        />
                        {/* <Controller
                            name={`highlights`}
                            control={control}
                            // rules={{ required: "Description is required" }}
                            render={({ field }) => (
                              <div>
                                <JoditEditor
                                  value={content}
                                  tabIndex={1}
                                  onBlur={(newContent) =>
                                    setContent(newContent)
                                  }
                                  {...field}
                                />
                                {errors?.highlights && (
                                  <span className="text-danger">
                                    {errors?.highlights.message}
                                  </span>
                                )}
                              </div>
                            )}
                          /> */}
                      </div>
                      {/* <div className=" mb-5">
                        <Controller
                          name={`p_hightlights`}
                          control={control}
                          rules={{ required: "Description is required" }}
                          render={({ field }) => (
                            <div>
                              <JoditEditor
                                value={content}
                                tabIndex={1}
                                onBlur={(newContent) => setContent(newContent)}
                                {...field}
                              />
                              {errors?.p_hightlights && (
                                <span className="text-danger">
                                  {errors?.p_hightlights.message}
                                </span>
                              )}
                            </div>
                          )}
                        />
                      </div> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="description">
                      <div className="tab-content">
                        <ProductDescription
                          register={register}
                          control={control}
                          useFieldArray={useFieldArray}
                          errors={errors}
                          setValue={setValue}
                        />
                      </div>

                      {/* <div className="mb-5">
                        <Controller
                          name={`p_description`}
                          control={control}
                          rules={{ required: "Description is required" }}
                          render={({ field }) => (
                            <div>
                              <JoditEditor
                                value={content}
                                tabIndex={1}
                                onBlur={(newContent) => setContent(newContent)}
                                {...field}
                              />
                              {errors?.p_description && (
                                <span className="text-danger">
                                  {errors?.p_description.message}
                                </span>
                              )}
                            </div>
                          )}
                        />
                      </div> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
              <div className="main-card">
                <div className="row">
                  {working_type == 1 && (
                    <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Manufacturing Capacity (Monthly){" "}
                          <span className="star">*</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">
                                {productInfoIcon?.manufacturing_capacity}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Manufacturing Capacity                                        
                                        "
                          className={classNames("", {
                            "is-invalid": errors?.manufacturing_capacity,
                          })}
                          name="manufacturing_capacity"
                          {...register("manufacturing_capacity", {
                            required: "manufacturing capacity is required",
                          })}
                        />
                      </Form.Group>
                    </div>
                  )}

                  <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Used Monthly Capacity <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-category">
                              {productInfoIcon?.use_monthly_capacity}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Use Monthly Capacity"
                        className={classNames("", {
                          "is-invalid": errors?.supply_capacity_a_month,
                        })}
                        name="supply_capacity_a_month"
                        {...register("supply_capacity_a_month", {
                          required: "use monthly capacity is required",
                          pattern: {
                            value: /^[1-9][0-9]?$|^100$/,
                            message: "Please enter a number between 1 and 100",
                          },
                        })}
                      />
                      {errors.supply_capacity_a_month && (
                        <div className="invalid-feedback">
                          {errors.supply_capacity_a_month.message}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Meta Tag <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-brand">
                              {productInfoIcon?.meta_tag}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Meta Tag"
                        className={classNames("", {
                          "is-invalid": errors?.meta_tag,
                        })}
                        name="meta_tag"
                        {...register("meta_tag", {
                          required: "meta_tag is required",
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xxl-4 col-xl-4  col-lg-6 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Meta Description <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">
                              {productInfoIcon?.meta_description}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Meta Description "
                        className={classNames("", {
                          "is-invalid": errors?.meta_description,
                        })}
                        name="meta_description"
                        {...register("meta_description", {
                          required: "meta_description is required",
                        })}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="common-steps-btn">
                <button
                  className="draftbtn"
                  type="submit"
                  onClick={handleSubmit(onDraftSubmit)}
                >
                  Save as draft
                </button>
                <button type="button" onClick={prevStep} className="back-btn">
                  Back
                </button>
                <button
                  type="button"
                  //  onClick={() => setModalShow(true)}
                  onClick={handleSubmit(onSubmit)}
                  className="next-btn"
                >
                  Submit
                </button>
              </div>

              <ProductSave
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};

export default ProductInfo;
