import React, { useContext, useEffect, useState } from "react";
import "./Addvertise_Slider.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import { Context } from "../../../../../utils/context";
import { getbtb_adv_two_section } from "../../../../../utils/apis/btb/Home_Page";

function Addvertise_Slider() {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getbtb_adv_two_section());
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="B2B_Addvertise">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="Addvertise_Slider"
        >
          {data?.data?.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="Addvertise_Slider_img_holder">
                <img
                  className="Addvertise_Slider_img"
                  src={IMG_URL + image.image}
                  alt={`Slide ${index + 1}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Addvertise_Slider;
