import React from "react";
import "./Procurement_Customized.css";
import Procurement_Customized_Slider from "./Procurement_Customized_Slider/Procurement_Customized_Slider";

function Procurement_Customized() {
  return (
    <>
      <div className="Procurement_Customized">
        <div className="container">
          <div className="heading_holder">
            <h4 className="heading">
              Procurement Solutions and Customized Services
            </h4>
            <p>
              Optimize Your Supply Chain Efficiency with Our Tailored Approach
            </p>
          </div>
        </div>

        <Procurement_Customized_Slider />
      </div>
    </>
  );
}

export default Procurement_Customized;
