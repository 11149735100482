import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const affiliateLogin = async (data) => {
  try {
    const res = await postData(`/affiliate/affiliate-login/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const taskStatusChange = async (id, status) => {
  try {
    const res = await postData(`/affiliate/affiliate-task/task/update-status/${id}`, {status:status});

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const AffiliatePersonalDetailsPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-personal-details`, data)

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const PasswordPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-personal-details/password`, data)

    return res;
  } catch (error) {
    console.log(error);
  }
};

// export const getSectorCategory = async (user_id) => {
//   try {

//     const res = await getData(`/seller/seller-create/s-sector-category/${user_id}`)

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getAffiliateDetails = async (user_id) => {
  try {
    const res = await getData(`/affiliate/affiliate-create/affiliate-personal-details/${user_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmDetailsPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-firm-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getfirmDetails = async (CheckerId) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-firm-details/${CheckerId}`);
    console.log("res firm :-", CheckerId);
    console.log("res firm :-", res);
    return res;

  } catch (error) {
    console.log(error);
  }
};

export const firmDetailNamePost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-firm-details/name`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFirmDetailName = async (user_id) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-firm-details/name/${user_id}`);
    console.log("res firm :-", user_id);
    console.log("res firm :-", res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmAddressDetailPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-firm-address`, data);


    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmAddressDetail = async (user_id) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-firm-address/${user_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const firmBankDetailPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-bank-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getfirmBankDetail = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-bank-details/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const AttachmentPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-attachment`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAttachment = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-attachment/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const SocialPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-social`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSocial = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-social/${data}`);

    return res;
  } catch (error) {
    console.log(error); 
  }
};

export const SocialUrlPost = async (data) => {
  try {

    const res = await postData(`/affiliate/affiliate-create/affiliate-social-url`, data);
    console.log("Got Method");
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSocialUrl = async (data) => {
  try {

    const res = await getData(`/affiliate/affiliate-create/affiliate-social-url/${data}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllInfluencerApi = async () => {
  try {

    const res = await getData(`/seller/seller-task/influencer-task/allinfluencer/data`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getInfluencerSocialLinksApi = async (product_id) => {
  try {

    const res = await getData(`/seller/seller-task/influencer-task/allsocialLink/data/${product_id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};



