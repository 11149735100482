import React from 'react'
import './MyFaqBanner.css'
import { Breadcrumb, Container } from 'react-bootstrap'

const MyFaqBanner = () => {
    return (
        <section className='faq-banner'>
            <Container>
            <div className="d-md-flex justify-content-md-between text-center">
            
                <Breadcrumb>
                    <Breadcrumb.Item href="/mywebsites/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                </Breadcrumb>
                <div className='heading text-center'>
                <h3 className='faq-title'>FAQ</h3>
            </div>
                </div>
            </Container>
           
        </section>
    )
}

export default MyFaqBanner