import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./Wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { depositAmountPost, getSellerWalletApi } from "../../../../../utils/apis/seller/wallet/wallet";

import DepositModal from "../../../../CommonForAll/mrp-modal/DepositModal";

import useRazorpay from "react-razorpay";
import SuccessModalDynamic from "../../../../CommonForAll/SuccessModalDynamic/SuccessModalDynamic";
const Wallet = () => {
  const [filterText, setFilterText] = useState("");
  const columns = [
    {
      name: "Transaction Id",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Coin",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.year,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const [sellerWallet, setSellerWallet] = useState();
  const getWalletHistory = async () => {
    const res = await getSellerWalletApi();
    if (res?.success) {
      setSellerWallet(res?.data);
    }
  };

  const [depositAmount, setAmount] = useState(0);
  const [amountModal, setAmountModal] = useState(false);
  const addWallet = () => {
    setAmountModal(true);
  }


  const [Razorpay, openRazorpay] = useRazorpay();
  const handleRazorpay = async () => {
    
    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: depositAmount * 100,
      currency: "INR",
      name: "Netpurti",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        depositRazor(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };


  const [successModalShow, setSuccessModalShow] = useState(false);
  const depositRazor = async (razor) => {
      const res = await depositAmountPost(depositAmount,razor);
      if(res?.success){
        setSuccessModalShow(true);
        setTimeout(() => {
          setAmount(0);
          getWalletHistory();
          setSuccessModalShow(false);
        }, 2000);
      }
  }

  useEffect(() => {
    getWalletHistory();
  }, []);
  return (
    <>
      <section className="Walletdashboard">
        <div className="datatableMain">
          <div className="row">
            <div className="col-lg-4 ">
              {/* <input
                                className='imppppp'
                                type='text'
                                placeholder='Search by title...'
                                value={filterText}
                                onChange={handleFilterChange}
                            /> */}

              <p>
                <b>Wallet Amount:</b> ₹ {sellerWallet?.wallet?.amount}
              </p>
            </div>

            <div className="col-lg-8 text-end">
              <div className="d-flex justify-content-end">
              <div className="">
                  <button className="Getrepbtnn btn" onClick={() => addWallet()}>
                    Deposit
                  </button>
                </div>
                {/* <div className="">
                  <button className="Getrepbtnn btn">
                    <FontAwesomeIcon icon="fa-solid fa-download" /> Get Reports
                  </button>
                </div> */}

                {/* <div className=''>
                                    <button className='filterperrrbtnn btn'><FontAwesomeIcon icon="fa-solid fa-filter" /> Filter Periods</button>
                                </div> */}

                {/* <div className="">
                  <Form.Select
                    className="selectoldenew"
                    aria-label="Default select example"
                  >
                    <option>Select</option>
                    <option value="1">Newest</option>
                    <option value="2">Oldest</option>
                  </Form.Select>
                </div> */}
              </div>
            </div>
          </div>

          {/* <DataTable
                        columns={columns}
                        data={filteredData}
                        selectableRows
                        pagination
                        defaultSortFieldId={1}
                    /> */}

          <div className="wallet-table-sccc mt-4">
            <div className="table-responsive">
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th className="wallselecttt">
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="">
                            <Form.Check
                              inline
                              label=""
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                            />
                          </div>
                        ))}
                      </Form>
                    </th>
                    <th>Transaction Id</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Task Id</th>
                    <th>Amount</th>
                    <th>Note</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {sellerWallet?.wallet?.wallet_histories?.map(
                    (value, index) => (
                      <tr>
                        <td>
                          <FontAwesomeIcon
                            className={
                              value?.transaction_type === "debit"
                                ? "misscallicon"
                                : value?.transaction_type === "block"
                                ? "blockcallicon"
                                : "upcomimgcallicon"
                            }
                            icon={
                              value?.transaction_type === "debit"
                                ? "fa-solid fa-arrow-trend-up"
                                : value?.transaction_type === "block"
                                ? "fa-solid fa-circle-stop"
                                : "fa-solid fa-arrow-trend-down"
                            }
                          />
                        </td>
                        <td>{value?.razor_id}</td>
                        <td>{value?.createdAt}</td>
                        <td>
                          {value?.transaction_type === "block" &&
                            sellerWallet?.f_name + " " + sellerWallet?.l_name}
                          {value?.transaction_type === "debit" &&
                            sellerWallet?.f_name + " " + sellerWallet?.l_name}
                        </td>
                        <td>
                          {value?.transaction_type === "block" &&
                            value?.a_personal_detail?.f_name +
                              " " +
                              value?.a_personal_detail?.l_name}
                          {value?.transaction_type === "debit" &&
                            (value?.task_id !== null ? (
                              value?.a_personal_detail?.f_name +
                              " " +
                              value?.a_personal_detail?.l_name
                            ) : (
                              <></>
                            ))}
                        </td>
                        <td>{value?.seller_task?.task_id}</td>
                        <td>₹ {value?.amount}</td>
                        <td>{value?.remark}</td>
                        <td className={value?.transaction_type === 'debit' || value?.transaction_type === 'block' ? "cancc-app" : "compp-app"}>{value?.transaction_type}</td>
                      </tr>
                    )
                  )}

                  {/* <tr>
                                        <td><FontAwesomeIcon className='upcomimgcallicon' icon="fa-solid fa-arrow-trend-down" /></td>
                                        <td>123456789</td>
                                        <td>22/05/2024</td>
                                        <td>Aditya</td>
                                        <td>Prajwal </td>
                                        <td>Rupees</td>
                                        <td>4566</td>
                                        <td>Lorem ipsum</td>
                                        <td className='compp-app'>Completed</td>
                                    </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <DepositModal show={amountModal} onHide={() => setAmountModal(false)} depositAmount={depositAmount} setAmount={setAmount} handleRazorpay={handleRazorpay}/>
      <SuccessModalDynamic show={successModalShow} onHide={() => setSuccessModalShow(false)} text={"Amount Deposited to your wallet"}/>
    </>
  );
};

export default Wallet;
