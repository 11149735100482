import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";

import {
  getsingledata,
  edit,
} from "../../../../../../utils/apis/seller/outer-website/masters/about-us/banner/banner";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
    getValues,
  } = useForm();

  const imageFile = watch("image");
  const descriptionValue = watch("description");
  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("name", data?.name);
      sendData.append("description", data?.description);
      sendData.append("image", data?.image[0]);
      const response = await edit(sendData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        // props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getsingledata();
    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      {/* <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
      <div class="mt-4">
        <h3>About US</h3>
      </div>
      <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="main-form-section mt-3">
              <Form.Group>
                <Form.Label>Name</Form.Label>

                <InputGroup>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={classNames("", {
                      "is-invalid": errors?.name,
                    })}
                    {...register("name", {
                      required: "Name is required",
                    })}
                  />
                </InputGroup>
                {errors.name && (
                  <span className="text-danger">{errors.name.message}</span>
                )}
              </Form.Group>
            </div>

            <div className="main-form-section mt-3">
              <Row className="justify-content-start">
                <Form.Label className="text-left">Image</Form.Label>

                <Form.Group>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image,
                    })}
                    type="file"
                    {...register("image", {
                      validate: async (value) => {
                        if (typeof value !== "string") {
                          const fileTypes = ["jpg", "png", "jpeg"];
                          const fileType = value[0].name?.split(".")[1];

                          if (!fileTypes.includes(fileType)) {
                            return `please upload a valid file format. (${fileTypes})`;
                          }

                          // const sizes = await getDimension(value[0]);
                          // if (
                          //   sizes.width !== 2575 &&
                          //   sizes.height !== 600
                          // ) {
                          //   return "Image width and height must be 2575 px and 600 px";
                          // }

                          // const fileSize = Math.round(value[0].size / 1024);
                          // if (fileSize > 500) {
                          //   return "file size must be lower than 500kb";
                          // }
                        }
                      },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                </Form.Group>
                {errors.image && (
                  <span className="text-danger">{errors.image.message}</span>
                )}
              </Row>
            </div>
          </Col>

          <Col lg={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label column sm={4} className="text-center">
                  Image Preview
                </Form.Label>
                <Col sm={9}>
                  {typeof getValues("image") == "string" ? (
                    <div className="image-preview-container">
                      <img
                        src={IMG_URL + getValues("image")}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "130px" }}
                      />
                    </div>
                  ) : (
                    imageFile &&
                    imageFile?.length > 0 && (
                      <div className="image-preview-container">
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg={12}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-start">
                {" "}
                {/* Align to left */}
                {/* Description */}
                <Col sm={12}>
                  <Form.Label className="text-center">Description</Form.Label>
                  <Form.Group>
                    <Controller
                      name="description" // Provide the field name
                      control={control} // Pass the control object from useForm()
                      rules={{ required: "Description is required" }} // Validation rules
                      render={({ field }) => (
                        <JoditEditor
                          value={descriptionValue || ""}
                          onBlur={(newContent) => {
                            setValue("description", newContent);
                            trigger("description");
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              <Link>
                <CancelButton name={"cancel"} handleClose={props.handleClose} />
              </Link>

              <SaveButton name={"save"} handleSubmit={handleSubmit(onSubmit)} />
            </div>
          </Row>
        </Row>
      </Form>
      {/* </Modal.Body>
      </Modal> */}

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
