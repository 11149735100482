import { Routes, Route, useLocation } from 'react-router-dom'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import Pricing from '../Pricing/Pricing';
import PortfolioMain from '../PortfolioMain/PortfolioMain';
import PortfolioCards from '../CommonComponents/PortfolioCards/PortfolioCards';
import CheckoutForm from '../MyWebsiteForm/CheckoutForm/CheckoutForm';
import PortfolioCart from '../PortfolioMain/PortfolioCart/PortfolioCart';
import PortfolioContent from '../PortfolioMain/PortfolioContent/PortfolioContent';
import LoginPage from '../LoginSignup/MyWebsiteLogin/LoginPage';
import SignupPage from '../LoginSignup/MyWebsiteSignup/SignupPage';
import ForgetPassword from '../LoginSignup/ForgetPassword/ForgetPassword';
import CheckEmail from '../LoginSignup/CheckEmail/CheckEmail';
import ResetPassword from '../LoginSignup/ResetPassword/ResetPassword';
import HelpCenterMyWebSite from '../UserDetails/HelpCenter/HelpCenterMyWebSite';
import MyWishlist from '../UserDetails/MyWishlist/MyWishlist';
import Resources from '../Resources/Resources';
import MyFaqPage from '../MyFaqPage/MyFaqPage';
import MyPackage from '../UserDetails/MyPackages/MyPackage';
import NewPlanPricing from '../Pricing/NewPlanPricing/NewPlanPricing';
import BlogsPage from '../BlogsPage/BlogsPage';
import LivePreview from '../Pricing/LivePreview/LivePreview';
import PreviewDetails from '../Pricing/PreviewDetails/PreviewDetails';

const MywebsitesRoutemain = () => {
    const { pathname } = useLocation();
    const hideHeaderFooterRoutes = [
        '/mywebsites/login',
        '/mywebsites/signup',
        '/mywebsites/forget-pass',
        '/mywebsites/check-email',
        '/mywebsites/reset-pass'
    ];

    const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(pathname);

    return (
        <>
            {!shouldHideHeaderFooter && <Header />}
            <Routes>
                <Route>
                    <Route path="/home" element={<Home />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/portfolio" element={<PortfolioMain />} />
                    <Route path="/portfolio-cards" element={<PortfolioCards />} />
                    <Route path="/checkout-form" element={<CheckoutForm />} />
                    <Route path="/portfolio-cart" element={<PortfolioCart />} />
                    <Route path="/portfolio-cart/portfolio-content" element={<PortfolioContent />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/forget-pass" element={<ForgetPassword />} />
                    <Route path="/check-email" element={<CheckEmail />} />
                    <Route path="/reset-pass" element={<ResetPassword />} />
                    <Route path="/help-center-mywebsite" element={<HelpCenterMyWebSite />} />
                    <Route path="/user-details/wishlist" element={<MyWishlist />} />
                    <Route path="/resources" element={<Resources />} />
                    <Route path="/my-faq" element={<MyFaqPage />} />
                    <Route path="/my-package" element={<MyPackage />} />
                    <Route path="/new-plan" element={<NewPlanPricing />} />
                    <Route path="/blogs-page" element={<BlogsPage />} />
                    <Route path="/livepreview/:id" element={<LivePreview />} />
                    <Route path="/previewdetails/:id" element={<PreviewDetails />} />
                </Route>
            </Routes>
            {!shouldHideHeaderFooter && <Footer />}
        </>
    )
}

export default MywebsitesRoutemain;
