import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
// import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../Master/Brand/Tables";
import FAQ from "../../Master/Faq/Table";
import SubFaq from "../../Master/SubFaq/Table";
// import WorkingType from "../Master/WorkingType/Table";
import Category from "../../Master/Category/Table";
import SubCategory from "../../Master/SubCategory/Table";
import ChildCategory from "../../Master/ChildCategory/Table";
import ChildSubCategory from "../../Master/ChildSubCategory/Table";
import Blogs from "../../Master/Blogs/Tables";
// import CouponCodes from "../../Master/CouponCodes/Table";
// import TaxType from "../Master/Tax_Types/Tables";
// import TaxPercentage from "../Master/Tax_Percentage/Tables";
import WebHeader from "../../Master/WebHeader/Tables";
import AppSetup from "../../Master/AppSetup/Edit";
// import ContactUs from "../Master/contact_us/Tables";
import Users from "../../Master/users/Tables";
import CrazyDeals from "../../Master/CrazyDeals/Table";
import ExclusiveCollection from "../../Master/ExclusiveCollection/Table";
import Attribute from "../../Master/Attribute/Table";
import SubAttribute from "../../Master/SubAttribute/Table";
import AttributePackaging from "../../Master/AttributePackaging/Table";
import ShopTrends from "../../Master/ShopTrends/Table";
import HomePageBanner from "../../Master/HomePageBanner/Table";
import AboutUsBanner from "../../Master/banner/Tables";
import OurStory from "../../Master/OurStory/Tables";
import HappyCustomer from "../../Master/HappyCustomer/Tables";
import WhyChoose from "../../Master/whyChoose/Tables";
import PopularProduct from "../../Master/PopularProduct/Table";
import SaleBanner from "../../Master/SaleBanner/Table";
import CategoryBanner from "../../Master/CategoryBanner/Table";
import UserMotivation from "../../Master/UserMotivation/Table";
import SocialMedia from "../../Master/SocialMedia/Table";
import PrivacyPolicy from "../../Master/PrivacyPolicy/Edit";
import ReturnPolicy from "../../Master/ReturnPolicy/Edit";
import TermCondition from "../../Master/TermCondition/Edit";

import "./Setting.css";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faAppStore } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";

const Setting = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const tabsArray = [
    {
      key: 0,
      name: (
        <>
          <FontAwesomeIcon icon={faTrophy} />
        </>
      ),
      link: "masters/privacy-policy",
      component: <PrivacyPolicy />,
      tooltip: "privacy policy",
    },
    {
      key: 1,
      name: (
        <>
          <FontAwesomeIcon icon={faAppStore} />
        </>
      ),
      link: "masters/return-policy",
      component: <ReturnPolicy />,
      tooltip: "Return Policy",
    },

    {
      key: 2,
      name: (
        <>
          <FontAwesomeIcon icon={faAppStore} />
        </>
      ),
      link: "masters/term-condition",
      component: <TermCondition />,
      tooltip: "Term Condition",
    },
  ];

  // const tabContent = [
  //   { key: 0, component: <WebHeader /> },
  //   { key: 1, component: <AppSetup /> },
  // ];

  const [activeTab, setActiveTab] = useState(0);
  const onTabClick = (e, index) => {
    console.log(e);
    setActiveTab(index);
  };
  // const tabsArray = [...Array(20).keys()];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );

  return (
    <>
      {/* <Tabs activeTab={activeTab} onTabClick={onTabClick}>
        {tabsArray.map((item, index) => (
          <Tab key={item.eventKey} eventKey={item.key}>
            {item.name}
          </Tab>
        ))}
      </Tabs>

      {tabContent.map((item) => (
        <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
          {item.component}
        </TabScreen>
      ))} */}
      <div className="sidebar-tab-holder">
      <Tab.Container
        className="sidebar-tab-holder"
        id="left-tabs-example"
        activeKey={activeTab}
        onSelect={(e) => setActiveTab(e)}
      >
        <Row>
          <Col sm={1} className="col-width">
            <Nav variant="pills" className="flex-column">
              {tabsArray.map((item) => (
                <OverlayTrigger
                  key={item.key}
                  placement="right"
                  delay={{ show: 200, hide: 0 }}
                  overlay={renderTooltip({ tooltip: item.tooltip })}
                >
                  <Nav.Item>
                    <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              ))}
            </Nav>
          </Col>
          <Col sm={11} className="col2-width">
            <Tab.Content>
              {tabsArray.map((item) => (
                <Tab.Pane eventKey={item.key} key={item.key}>
                  {item.component}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </div>
    </>
  );
};

export default Setting;
