import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Service from "./Service";
import { Errors, Placeholders } from "../../../../utils/errors";
import { FirmType, firmAddressType, City, State, Country, Pincode, pincodeWiseAll } from "../../../../utils/apis/master/Master";
import { Context } from "../../../../utils/context";

import { getServiceAddress, postServiceAddress } from "../../../../utils/apis/transport/transport";
import SuccessModal from "../../../CommonForAll/SuccessModal/SuccessModal";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";

function Stepsix({ nextStep, prevStep, userDetails }) {
  const [modalShow, setModalShow] = useState(false);
  const [addressCount, setAddressCount] = useState(1);
  const [successModalShow, setSuccessModalShow] = useState(false);

  const handleAddAddress = () => {
    setAddressCount((prevCount) => prevCount + 1);
  };

  const handleRemoveAddress = () => {
    setAddressCount((prevCount) => prevCount - 1);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];


  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [
        {
          l_name: "",
          u_name: "",
          l_city_id: "",
          l_state_id: "",
          loading_pincodes: "",
          l_country_id: "",
          isChecked: false,
          u_city_id: "",
          u_state_id: "",
          unloading_pincodes: "",
          u_country_id: "",
        },
      ],
    },
  });


  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [shipmentId, setShipmentId] = useState("");

  const GetAllCountries = async () => {
    const response = await Country();

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id, index, type) => {
    const response = await State(id);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
      if (type === 'click') {

      } else {
        if (type === 'load') {
          await setValue(`data[${index}].l_state_id`, "");
          await setValue(`data[${index}].l_city_id`, "");
          await setValue(`data[${index}].loading_pincodes`, "");
        } else {
          await setValue(`data[${index}].u_state_id`, "");
          await setValue(`data[${index}].u_city_id`, "");
          await setValue(`data[${index}].unloading_pincodes`, "");
        }
      }


    }
  };

  const GetAllCity = async (id, index, type) => {
    const response = await City(id);

    if (response?.success) {
      console.log(getValues('data'), "ddddddddd");
      setCities(await Select2Data(response?.data, "city_id"));
      if (type === 'click') {

      } else {
      if (type === 'load') {
        await setValue(`data[${index}].l_city_id`, "");
        await setValue(`data[${index}].loading_pincodes`, "");
      } else {
        await setValue(`data[${index}].u_city_id`, "");
        await setValue(`data[${index}].unloading_pincodes`, "");
      }
    }


    }
  };

  const GetAllPincode = async (id, index, type) => {
    const response = await Pincode(id);

    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
      if (type === 'click') {

      } else {
      if (type === 'load') {
        await setValue(`data[${index}].loading_pincodes`, "");
      } else {
        await setValue(`data[${index}].unloading_pincodes`, "");
      }
    }
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const [serviceStatus, setServiceStatus] = useState(false);

  const handleCheckChange = async (e, index) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      await setValue(`data[${index}].isChecked`, true);
      await setValue(`data[${index}].u_name`, getValues(`data[${index}].l_name`));
      await setValue(`data[${index}].u_city_id`, getValues(`data[${index}].l_city_id`));
      await setValue(`data[${index}].u_state_id`, getValues(`data[${index}].l_state_id`));
      await setValue(`data[${index}].u_country_id`, getValues(`data[${index}].l_country_id`));
      await setValue(`data[${index}].unloading_pincodes`, getValues(`data[${index}].loading_pincodes`));
    } else {
      await setValue(`data[${index}].isChecked`, false);
      await setValue(`data[${index}].u_name`, "");
      await setValue(`data[${index}].u_city_id`, "");
      await setValue(`data[${index}].u_state_id`, "");
      await setValue(`data[${index}].u_country_id`, "");
      await setValue(`data[${index}].unloading_pincodes`, "");
    }
  };


  useEffect(() => {
    GetAllCountries();
    GetAllStates();
    GetAllCity();
  }, []);


const [error, setErrors] = useState("");
  const handleNext = () => {
    if(shipmentId && serviceStatus){
      nextStep();
    }else{
      setErrors("Please Fill The Forms First");
    }
  }


  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });


  const getfirmDropAddressIn = async () => {
    const res = await getServiceAddress(userDetails?.t_personal_details_id);
    if (res?.success) {
      if (res.data.length > 0) {
        reset({ data: res.data });
        res.data.forEach((value, index) => {

          console.log(value?.t_s_loading_pincodes);
          console.log(index);
          setValue(`data[${index}].l_country_id`, { value: value?.l_country?.id, label: value?.l_country?.name });
          setValue(`data[${index}].l_city_id`, { value: value?.l_city?.id, label: value?.l_city?.name });
          setValue(`data[${index}].l_state_id`, { value: value?.l_state?.id, label: value?.l_state?.name });
          setValue(`data[${index}].u_country_id`, { value: value?.u_country?.id, label: value?.u_country?.name });
          setValue(`data[${index}].u_city_id`, { value: value?.u_city?.id, label: value?.u_city?.name });
          setValue(`data[${index}].u_state_id`, { value: value?.u_state?.id, label: value?.u_state?.name });


          setValue(`data[${index}].loading_pincodes`, value?.t_s_loading_pincodes?.map(pincode => ({
            value: pincode?.loading_pincode?.id,
            label: pincode?.loading_pincode?.name
          })));

          setValue(`data[${index}].unloading_pincodes`, value?.t_s_unloading_pincodes?.map(pincode => ({
            value: pincode?.unloading_pincode?.id,
            label: pincode?.unloading_pincode?.name
          })));


        });
        setServiceStatus(true);
      } else {
        reset({
          data: [
            {
              l_name: "",
              u_name: "",
              l_city_id: "",
              l_state_id: "",
              loading_pincodes: "",
              l_country_id: "",
              isChecked: false,
              u_city_id: "",
              u_state_id: "",
              unloading_pincodes: "",
              u_country_id: "",
            },
          ]
        });
      }

    }
  }

  useEffect(() => {
    getfirmDropAddressIn();
  }, []);


  const onSubmit = async (d) => {


    // console.log(d, "datadatadataform");
    // const keys = Object.keys(d.data);
    // var data = [];
    // keys.forEach(key => {
    //   const value = d.data[key];
    //   var cityData = {
    //     l_city_id: value?.city_id?.value,
    //     l_state_id: value?.state_id?.value,      
    //     u_city_id: value?.city_id?.value,
    //     u_state_id: value?.state_id?.value,     
    //     l_country_id: value?.country_id?.value,     
    //     u_country_id: value?.country_id?.value,
    //   }
    //   data.push(cityData);
    // });
    // var data = [];
    // var pincode ={
    //   loading_pincodes: d?.loading_pincodes?.value,     
    //   unloading_pincodes: d?.unloading_pincodes?.value,   
    // }
    // data.push(pincode);
    var sendData = {
      // country_id: d?.country_id?.value,
      // l_city_id: d?.l_city_id?.value,
      // l_state_id: d?.l_state_id?.value,      
      // u_city_id: d?.u_city_id?.value,
      // u_state_id: d?.u_state_id?.value,     
      // l_country_id: d?.l_country_id?.value,     
      // u_country_id: d?.u_country_id?.value, 
      // unloading_pincodes: d?.unloading_pincodes,
      //  loading_pincodes: d?.loading_pincodes,       
      // u_name: d?.u_name,
      // l_name: d?.l_name,
      data: d.data,
      t_personal_details_id: userDetails?.t_personal_details_id,
    }
    const res = await postServiceAddress(sendData);
    if (res?.success) {
      console.log(res, "ddddd");
      setServiceStatus(true);
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  }

  return (
    <>
      <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
        <div className="form-container">

          {fields.map((item, index) => (
            <>
              <div className="mb-5">
                <div><h6 className="ms-3 firmmmdd">Service Area (Loading)</h6></div>
                <div className="tab-form-container firstthreerrr">
                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Service Area Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].l_name,

                            "is-valid":
                              getValues(`data[${index}].l_name`),
                          })}
                          name={`data[${index}].l_name`}
                          placeholder="Service Area Name"
                          defaultValue={item.l_name}
                          {...register(`data[${index}].l_name`, {
                            required: "Service Area Name is required",
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Country</Form.Label>
                        {/* <Select options={options} /> */}

                        <Controller
                          name={`data[${index}].l_country_id`}
                          {...register(`data[${index}].l_country_id`, {
                            required: Errors.country,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputStyle={{
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].l_country_id
                                  ? "red"
                                  : getValues(`data[${index}].l_country_id`)
                                    ? "green"
                                    : "",
                              }}
                              {...field}
                              options={countries}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                GetAllStates(selectedOption.value, index, 'load');
                                setValue("l_country_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>State</Form.Label>
                        {/* <Select options={options} /> */}
                        <Controller
                          name={`data[${index}].l_state_id`}
                          {...register(`data[${index}].l_state_id`, {
                            required: Errors.state,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputStyle={{
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].l_state_id
                                  ? "red"
                                  : getValues(`data[${index}].l_state_id`)
                                    ? "green"
                                    : "",
                              }}
                              {...field}
                              // defaultValue={item.state_id}
                              options={states}
                              onFocus={() => GetAllStates(getValues(`data[${index}].l_country_id.value`), index, 'click')}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption); // Update Controller's value
                                GetAllCity(selectedOption.value, index, 'load');
                                setValue("l_state_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>City</Form.Label>
                        {/* <Select options={options} /> */}

                        <Controller
                          name={`data[${index}].l_city_id`}
                          {...register(`data[${index}].l_city_id`, {
                            required: Errors.city,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputStyle={{
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].l_city_id
                                  ? "red"
                                  : getValues(`data[${index}].l_city_id`)
                                    ? "green"
                                    : "",
                              }}
                              {...field}
                              options={cities}
                              // defaultValue={item.city_id}
                              onFocus={() => GetAllCity(getValues(`data[${index}].l_state_id.value`), index, 'click')}
                              onChange={(selectedOption) => {

                                field.onChange(selectedOption); // Update Controller's value
                                GetAllPincode(selectedOption.value, index, 'load');
                                setValue("l_city_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Pincode <span>(It can be multiple)</span>
                        </Form.Label>
                        {/* <Select options={options} /> */}

                        <Controller
                          name={`data[${index}].loading_pincodes`}
                          {...register(`data[${index}].loading_pincodes`, {
                            required: Errors.city,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputStyle={{
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].loading_pincodes
                                  ? "red"
                                  : getValues(`data[${index}].loading_pincodes`)
                                    ? "green"
                                    : "",
                              }}
                              {...field}
                              isMulti
                              options={pincode}
                              onFocus={() => GetAllPincode(getValues(`data[${index}].l_city_id.value`), index, 'click')}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                setValue("loading_pincodes", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="Common-details-step mb-3">
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Is loading & Unloading Service area are same ?"
                      name={`data[${index}].isChecked`}
                      {...register(`data[${index}].isChecked`)}
                      className="my-2"
                      onChange={(e) => handleCheckChange(e, index)}
                    />
                  </div>

                  <h6 className=" firmmmdd mb-3">Service Area (Unloading)</h6>
                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Service Area Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={classNames("", {
                            "is-invalid":
                              errors.data &&
                              errors.data[index] &&
                              errors.data[index].u_name,

                            "is-valid":
                              getValues(`data[${index}].u_name`),
                          })}
                          name={`data[${index}].u_name`}
                          placeholder="Service Area Name"
                          defaultValue={item.u_name}
                          {...register(`data[${index}].u_name`, {
                            required: "Service Area Name is required",
                          })}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <Form>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>Country</Form.Label>
                          <Controller
                            name={`data[${index}].u_country_id`}
                            {...register(`data[${index}].u_country_id`, {
                              required: Errors.country,
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                inputStyle={{
                                  borderColor: errors.data &&
                                    errors.data[index] &&
                                    errors.data[index].u_country_id
                                    ? "red"
                                    : getValues(`data[${index}].u_country_id`)
                                      ? "green"
                                      : "",
                                }}
                                {...field}
                                options={countries}
                                // defaultValue={item.city_id}
                                onChange={(selectedOption) => {

                                  field.onChange(selectedOption); // Update Controller's value
                                  GetAllStates(selectedOption.value, index, 'unload');
                                  setValue("u_country_id", selectedOption);
                                }}
                              />
                            )}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>State</Form.Label>
                        <Controller
                          name={`data[${index}].u_state_id`}
                          {...register(`data[${index}].u_state_id`, {
                            required: Errors.state,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputStyle={{
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].u_state_id
                                  ? "red"
                                  : getValues(`data[${index}].u_state_id`)
                                    ? "green"
                                    : "",
                              }}
                              {...field}
                              // defaultValue={item.state_id}
                              options={states}
                              onFocus={() => GetAllStates(getValues(`data[${index}].u_country_id.value`), index, 'click')}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption); // Update Controller's value
                                GetAllCity(selectedOption.value, index, 'unload');
                                setValue("u_state_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <Form>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>City</Form.Label>
                          <Controller
                            name={`data[${index}].u_city_id`}
                            {...register(`data[${index}].u_city_id`, {
                              required: Errors.city,
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                inputStyle={{
                                  borderColor: errors.data &&
                                    errors.data[index] &&
                                    errors.data[index].u_city_id
                                    ? "red"
                                    : getValues(`data[${index}].u_city_id`)
                                      ? "green"
                                      : "",
                                }}
                                {...field}
                                options={cities}
                                onFocus={() => GetAllCity(getValues(`data[${index}].u_state_id.value`), index, 'click')}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  GetAllPincode(selectedOption.value, index, 'unload');
                                  setValue("u_city_id", selectedOption);
                                }}
                              />
                            )}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Pincode <span>(It can be multiple)</span>
                        </Form.Label>
                        {/* <Select options={options} /> */}
                        <Controller
                          name={`data[${index}].unloading_pincodes`}
                          {...register(`data[${index}].unloading_pincodes`, {
                            required: Errors.city,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              inputStyle={{
                                borderColor: errors.data &&
                                  errors.data[index] &&
                                  errors.data[index].unloading_pincodes
                                  ? "red"
                                  : getValues(`data[${index}].unloading_pincodes`)
                                    ? "green"
                                    : "",
                              }}
                              {...field}
                              isMulti
                              options={pincode}
                              // defaultValue={item.city_id}
                              onFocus={() => GetAllPincode(getValues(`data[${index}].u_city_id.value`), index, 'click')}
                              onChange={(selectedOption) => {

                                field.onChange(selectedOption); // Update Controller's value
                                // GetAllPincode(selectedOption.value);
                                setValue("unloading_pincodes", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>

                  </div>
                </div>
                {index !== 0 && (
                  <div className="addremoveadress">
                    <div className="text-end Addnewadresstxt">
                      <p className="" onClick={() => remove(index)}>
                        Remove Address{" "}
                        <FontAwesomeIcon icon="fa-solid fa-minus" />
                      </p>
                    </div>
                  </div>
                )}
              </div>



            </>
          ))}
          <div className="col-lg-6 p-0 ">
            <div className="text-end">
              <Button
                className="btn StepfoursaveBtn"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </div>
          </div>

          {/* <div className="addremoveadress">
          <div
            className="text-end Addnewadresstxt"
            onClick={handleRemoveAddress}
          >
            <p className="">
              Remove new Service area{" "}
              <FontAwesomeIcon icon="fa-solid fa-minus" />
            </p>
          </div>
          <div className="text-end Addnewadresstxt" onClick={handleAddAddress}>
            <p className="">
              Add new Service area <FontAwesomeIcon icon="fa-solid fa-plus" />
            </p>
          </div>
        </div> */}

          <div className="addremoveadress">
            <div className="text-end Addnewadresstxt">
              <p
                onClick={() =>
                  append({
                    l_name: "",
                    u_name: "",
                    l_city_id: "",
                    l_state_id: "",
                    loading_pincodes: "",
                    l_country_id: "",
                    isChecked: false,
                    u_city_id: "",
                    u_state_id: "",
                    unloading_pincodes: "",
                    u_country_id: "",
                  })
                }
              >
                Add New Address <FontAwesomeIcon icon="fa-solid fa-plus" />
              </p>
            </div>
          </div>

          <div className="mb-5">
            <h6 className="ms-3 firmmmdd">Service Type</h6>
            <div className="tab-form-container firstthreerrr">
              <div className="row">
                <Service userDetails={userDetails} setShipmentId={setShipmentId} setSuccessModalShow={setSuccessModalShow}/>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}
              <span className="text-danger">{error}</span>

              <Button onClick={prevStep} className="back-btn">
                Back
              </Button>

              <Button onClick={handleNext} className="tabs-btn">
                Register & Continue
              </Button>
            </div>
            <SuccessModal show={successModalShow} />
            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </Form>
    </>
  );
}

export default Stepsix;