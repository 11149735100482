import { React, useRef, useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import "./SelectCategory.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Cookies from "js-cookie";
import { Context } from "../../../../../../../utils/context";
import {
  AllCategory,
  AllChildCategory,
  AllSubCategory,
  Category,
  ChildCategory,
  SubCategory,
} from "../../../../../../../utils/apis/master/Master";
import {
  sellerProductCreateandUpdate,
  sellerProductGet,
} from "../../../../../../../utils/apis/seller/Product";
import { IDS } from "../../../../../../../utils/common";
const SelectCategory = (props, handleCategorySelect) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedSubChildCategory, setSelectedSubChildCategory] = useState("");

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { getData, IMG_URL, userdata, postData, Id, ProductNarrationChange } =
    useContext(Context);

  const [subCategaryData, setsubcategaryData] = useState([]);
  const [subchildCategaryData, setchildsubcategaryData] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCategory = async () => {
    const response = await AllCategory(Id, IDS?.web_site_type?.outer);
    if (response?.success) {
      setCategories(response?.data);
    }
  };

  const getDataAllsubcategary = async (id, category_id) => {
    try {
      setSelectedCategory(category_id);
      reset({
        ...getValues(),
        s_category_id: category_id,
        s_sub_category_id: "",
        s_child_category_id: "",
      });
      setCategoryError("");
      const response = await AllSubCategory(Id, IDS.web_site_type.outer, id);
      setsubcategaryData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDataAllclildsubcategary = async (id, sub_category_id) => {
    try {
      setSelectedSubCategory(sub_category_id);
      reset({
        ...getValues(),
        s_sub_category_id: sub_category_id,
        s_child_category_id: "",
      });
      setSubCategoryError("");
      const response = await AllChildCategory(Id, IDS.web_site_type.outer, id);
      setchildsubcategaryData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [searchValueCategary, setSearchValuecategary] = useState("");
  // Function to handle search input change
  const handleSearchcategary = (event) => {
    setSearchValuecategary(event.target.value);
  };

  // Filtered subcategories based on search input
  const filterCategories = categories?.filter((categories) =>
    categories?.name.toLowerCase().includes(searchValueCategary.toLowerCase())
  );

  const [searchValueSubCategary, setSearchValuesubcategary] = useState("");
  // Function to handle search input change
  const handleSearchsubcategary = (event) => {
    setSearchValuesubcategary(event.target.value);
  };

  // Filtered subcategories based on search input
  const filteredSubCategories = subCategaryData?.filter((subCategaryData) =>
    subCategaryData.name
      .toLowerCase()
      .includes(searchValueSubCategary.toLowerCase())
  );

  const [searchValueSubChildCategary, setSearchValuesubChildcategary] =
    useState("");
  // Function to handle search input change
  const handleSearchsubChildcategary = (event) => {
    setSearchValuesubChildcategary(event.target.value);
  };

  // Filtered subcategories based on search input
  const filterSubChildCategories = subchildCategaryData?.filter(
    (subchildCategaryData) =>
      subchildCategaryData.name
        .toLowerCase()
        .includes(searchValueSubChildCategary.toLowerCase())
  );

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      s_category_id: "",
      s_child_category_id: "",
      s_sub_category_id: "",
    },
  });

  const getDataProduct = async (id) => {
    const res = await sellerProductGet(id);
    if (res?.success) {
      getDataAllsubcategary(res.data.outer_category_id, res.data.s_category_id);
      getDataAllclildsubcategary(
        res.data.outer_sub_category_id,
        res.data.s_sub_category_id
      );
      setSelectedSubChildCategory(
        res.data.outer_child_category_id,
        res.data.s_child_category_id
      );

      setSelectedSubChildCategory(res.data.s_child_category_id);
      reset(res.data);
    }
  };

  useEffect(() => {
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      getDataProduct(cookie);
    }
    getCategory();
  }, [userdata]);

  const [categoryError, setCategoryError] = useState("");

  const [subCategoryError, setSubCategoryError] = useState("");

  const [subChildCategoryError, setSubChildCategoryError] = useState("");

  const onSubmit = async (d) => {
    if (
      d.s_category_id &&
      d.s_child_category_id &&
      d.s_sub_category_id &&
      d.product_generic_name &&
      d.product_title
    ) {
      d.added_by = "BTC";
      const res = await sellerProductCreateandUpdate(d);
      if (res.success) {
        Cookies.set("s_product_id", res.data.id, { expires: 1 });
        props.handleNext();
      }
    } else {
      if (!d.s_category_id) {
        setCategoryError("Select Category");
      }
      if (!d.s_sub_category_id) {
        setSubCategoryError("Select Sub Category");
      }
      if (!d.s_child_category_id) {
        setSubChildCategoryError("Select Child Category");
      }
    }
  };

  return (
    <>
      <section className="SelectCategory">
        <div className="row">
          <div className="col-xxl-2 col-xl-4 col-lg-4  mt-2">
            <sub className="text-danger">{categoryError}</sub>
            <div className="card categoryCard">
              <div className="card-body">
                <h5 className="card-title">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/icons/categories.png"
                    }
                    className="categoriesHeadIcon"
                  />
                  <span className="ms-2">Category</span>
                </h5>
                <div>
                  <Form className="categorySearch">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="search"
                        placeholder="Search category.."
                        // onChange={handleSearch}
                        value={searchValueCategary}
                        onChange={handleSearchcategary}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      icon="fa-solid fa-magnifying-glass"
                      className="searchIcon"
                    />
                  </Form>
                </div>
                {filterCategories?.map((category, index) => (
                  <div
                    className={`categoryList ${
                      selectedCategory === category?.category_id
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                    // onClick={({ target }) => handleClick(target)}
                    onClick={() =>
                      getDataAllsubcategary(category?.id, category?.category_id)
                    }
                  >
                    {/* <img
                      src={process.env.PUBLIC_URL + category.imgUrl}
                      className="categoryImg1"
                      alt={category.name}
                    /> */}
                    <div className="category_txt">
                      <p>{category?.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-4 col-lg-4  mt-2">
            <sub className="text-danger">{subCategoryError}</sub>
            <div className="card categoryCard">
              <div className="card-body">
                <Form className="categorySearch">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="search"
                      placeholder="Search Sub Category.."
                      // onChange={handleSearch}
                      value={searchValueSubCategary}
                      onChange={handleSearchsubcategary}
                    />
                  </Form.Group>
                  <FontAwesomeIcon
                    icon="fa-solid fa-magnifying-glass"
                    className="searchIcon"
                  />
                </Form>

                {filteredSubCategories?.map((val, index) => (
                  <div
                    className={`categoryList ${
                      selectedSubCategory === val?.sub_category_id
                        ? "selected"
                        : ""
                    }`}
                  >
                    {/* <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/images/categoryImg1.png"
                    }
                    className="categoryImg1"
                  /> */}

                    <div
                      className="category_txt"
                      onClick={() =>
                        getDataAllclildsubcategary(
                          val?.id,
                          val?.sub_category_id
                        )
                      }
                    >
                      <p>{val?.name}</p>
                    </div>
                  </div>
                ))}

                {/* <div className="categoryList" onClick={({ target }) => handleClick(target)}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/images/categoryImg1.png"
                    }
                    className="categoryImg1"
                  />

                  <div className="category_txt">
                    <p>Gallery</p>
                  </div>
                </div>

                <div className="categoryList" onClick={handleClick}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/images/categoryImg1.png"
                    }
                    className="categoryImg1"
                  />

                  <div className="category_txt">
                    <p>Automobile</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-4 col-lg-4  mt-2">
            <sub className="text-danger">{subChildCategoryError}</sub>
            <div className="card categoryCard">
              <div className="card-body">
                <Form className="categorySearch">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Search.."
                      value={searchValueSubChildCategary}
                      onChange={handleSearchsubChildcategary}
                    />
                  </Form.Group>
                  <FontAwesomeIcon
                    icon="fa-solid fa-magnifying-glass"
                    className="searchIcon"
                  />
                </Form>

                {filterSubChildCategories?.map((val, index) => (
                  <div
                    className={`categoryList ${
                      selectedSubChildCategory === val?.child_category_id
                        ? "selected"
                        : ""
                    }`}
                    onClick={({ target }) => {
                      // console.log(val);
                      reset({
                        ...getValues(),
                        s_child_category_id: val?.child_category_id,
                      });
                      setSubChildCategoryError("");
                      setSelectedSubChildCategory(val.child_category_id);
                    }}
                  >
                    {/* <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/images/categoryImg1.png"
                    }
                    className="categoryImg1"
                  /> */}

                    <div className="category_txt">
                      <p defaultValue={val?.id}>{val?.name}</p>
                    </div>
                  </div>
                ))}

                {/* <div className="categoryList " onClick={({ target }) => handleClick(target)}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/images/categoryImg1.png"
                    }
                    className="categoryImg1"
                  />

                  <div className="category_txt">
                    <p>Automobile</p>
                  </div>
                </div>

                <div className="categoryList" onClick={handleClick}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/dashboard/images/categoryImg1.png"
                    }
                    className="categoryImg1"
                  />

                  <div className="category_txt">
                    <p>Automobile</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-lg-12 mt-5">
            <div className="card categorySliderCard">
              <div className="card-body">
                <div className="row sliderMainrow">
                  <div className="col-lg-4 my-auto">
                    <div className="categorySlidertxt">
                      <p>
                        A keyboard is a set of keys used to operate a computer
                        or typewriter.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div>
                      <Swiper
                        spaceBetween={20}
                        slidesPerView={2}
                        onSlideChange={() => console.log("slide change")}
                        className="CategorySwiper"
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                          0: {
                            slidesPerView: 3,
                          },

                          1200: {
                            slidesPerView: 3,
                          },

                          1300: {
                            slidesPerView: 3,
                          },
                          1400: {
                            slidesPerView: 2,
                          },
                        }}
                      >
                        <SwiperSlide>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/Seller_panel/dashboard/images/SliderImg1.png"
                            }
                            className="sliderImg1"
                          />
                        </SwiperSlide>

                        <SwiperSlide>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/Seller_panel/dashboard/images/SliderImg1.png"
                            }
                            className="sliderImg1"
                          />
                        </SwiperSlide>

                        <SwiperSlide>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/Seller_panel/dashboard/images/SliderImg1.png"
                            }
                            className="sliderImg1"
                          />
                        </SwiperSlide>

                        <SwiperSlide>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/Seller_panel/dashboard/images/SliderImg1.png"
                            }
                            className="sliderImg1"
                          />
                        </SwiperSlide>
                      </Swiper>

                      <div
                        className="main-slider"
                        onClick={() => handleMainSliderClick("prev")}
                      >
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-arrow-left"
                            className="arrow-back "
                          />
                        </div>
                      </div>
                      <div
                        className="main-slider"
                        onClick={() => handleMainSliderClick("next")}
                      >
                        <div className="arrow-next">
                          <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row fieldsRow">
                  <div className="col-lg-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Title</Form.Label>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">Product Title</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                      <Form.Control
                        type="text"
                        placeholder="Enter product title"
                        className={classNames("", {
                          "is-invalid": errors?.message,
                        })}
                        name="product_title"
                        {...register("product_title", {
                          required: "product title is required",
                        })}
                        onChange={(e) => {
                          reset({
                            ...getValues(),
                            product_title: e.target.value,
                          });
                          setValue("product_title", ProductNarrationChange(e));
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Generic Name</Form.Label>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">
                            Product Generic Name
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                      <Form.Control
                        type="text"
                        placeholder="Enter product title"
                        className={classNames("", {
                          "is-invalid": errors?.product_generic_name,
                        })}
                        name="product_generic_name"
                        {...register("product_generic_name", {
                          required: "product generic name is required",
                        })}
                        onChange={(e) => {
                          reset({
                            ...getValues(),
                            product_generic_name: e.target.value,
                          });
                          setValue(
                            "product_generic_name",
                            ProductNarrationChange(e)
                          );
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="textbtn">
                    <p>
                      Kindly choose a brand to initiate sales within this
                      category.
                    </p>
                    <Button
                      // onClick={props.handleNext}
                      onClick={handleSubmit(onSubmit)}
                      className="selectBrandbtn"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-plus" /> Select Brand
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectCategory;
