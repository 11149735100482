import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

library.add(fas);

const Footer = () => {

    return (
        <>
          <section className="footer-section">
                <Container fluid>
                    <div className="footer-color">
                        <Container>
                            <div className="footer-submain">
                                <div className="section-rows">
                                    <Row>
                                        <Col xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                                            <div className="dp-items">
                                                <div className="footer-heads">
                                                    <p className="raleway-family">Sell Online</p>
                                                </div>
                                                <div className="footer-txts">
                                                    <Link to="">
                                                        <p className="roboto-family">Create Account</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">List Products</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">Storage & Shipping</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">Fees & Commission</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">Help & Support</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                                            <div className="dp-items">
                                                <div className="footer-heads">
                                                    <p className="raleway-family">Grow Your Business</p>
                                                </div>
                                                <div className="footer-txts ">
                                                    <Link to="">
                                                        <p className="roboto-family">Insights & Tools</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">
                                                            Netpurti Value Services
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                                            <div className="dp-items">
                                                <div className="footer-heads">
                                                    <p className="raleway-family">Learn More</p>
                                                </div>
                                                <div className="footer-txts">
                                                    <Link to="/mywebsites/my-faq">
                                                        <p className="roboto-family">FAQs</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">
                                                            Seller Success Stories
                                                        </p>
                                                    </Link>
                                                    <Link to="/mywebsites/help-center-mywebsite">
                                                        <p className="roboto-family">
                                                            Help Center
                                                        </p>
                                                    </Link>
                                                    <Link to="#">
                                                        <p className="roboto-family">
                                                            Privacy Policy
                                                        </p>
                                                    </Link>
                                                    <Link to="/b2bbuyer/cancelation-return">
                                                        <p className="roboto-family">
                                                            Cancellation & Returns
                                                        </p>
                                                    </Link>
                                                    <Link to="/b2bbuyer/terms">
                                                        <p className="roboto-family">
                                                            Terms of Use
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                                            <div className="dp-items">
                                                <div className="footer-heads">
                                                    <p className="raleway-family">Other Users</p>
                                                </div>
                                                <div className="footer-txts">
                                                    <Link to="">
                                                        <p className="roboto-family">Start as Media</p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">
                                                            Start as Checker
                                                        </p>
                                                    </Link>
                                                    <Link to="/b2bbuyer/">
                                                        <p className="roboto-family">
                                                            Start as Transporter
                                                        </p>
                                                    </Link>
                                                    <Link to="/b2bbuyer/">
                                                        <p className="roboto-family">
                                                            Start as Affiliate
                                                        </p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">
                                                            Start as Brand
                                                        </p>
                                                    </Link>
                                                    <Link to="">
                                                        <p className="roboto-family">
                                                            Start as 3p Support
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                                            <div className="dp-items">
                                                <div className="footer-heads">
                                                    <p className="raleway-family">Download Mobile App</p>
                                                </div>
                                                <div className="footer-txts">
                                                    <img
                                                        className="footer-logo"
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/assest/images/Seller_panel/seller-image/logo/footerbtn.png"
                                                        }
                                                        alt="Logo"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} lg={2} md={4} sm={6} xs={12}>
                                            <div className="dp-items">
                                                <div className="footer-heads">
                                                    <p className="raleway-family">Keep in touch</p>
                                                </div>
                                                <div className="footer-txts">
                                                    <div>
                                                        <p className="roboto-family">
                                                            {" "}
                                                            <FontAwesomeIcon
                                                                icon="fa-solid fa-envelope"
                                                                className="cont-icn"
                                                            />{" "}
                                                            inquiry@netpurti.com
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="roboto-family">
                                                            <FontAwesomeIcon
                                                                icon="fa-solid fa-phone"
                                                                className="cont-icn"
                                                            />
                                                            +91 8756345686
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="footer-iconss">
                                                    <div className="footer-icn">
                                                        <FontAwesomeIcon icon={faFacebookF} />
                                                    </div>
                                                    <div className="footer-icn">
                                                        <FontAwesomeIcon icon={faInstagram} />
                                                    </div>
                                                    <div className="footer-icn">
                                                        <FontAwesomeIcon icon={faYoutube} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="bottoms-texts">
                                    <div className="bottoms-rights">
                                        <p className="roboto-family">
                                            © 2024 Netpurti. All Rights Reserved. Developed by
                                            <span>
                                                {" "}
                                                <a href="" target="blank" className="profcyma">
                                                    Profcyma
                                                </a>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="bottoms-lefts">
                                        <div className="privacy">
                                            <Link to="/b2bbuyer/privacy-policy">
                                                <p className="roboto-family">Privacy policy</p>
                                            </Link>
                                        </div>
                                        <div className="terms">
                                            <Link to="/b2bbuyer/terms">
                                                <p className="roboto-family">Terms & Condition</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Container >
            </section>


        </>
    );
};

export default Footer;
