import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-step-progress-bar/styles.css";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const SellerForgetPass = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);

  return (
    <>
      <section className="SellerLogin">
        <div className="container">
          <div className="loginHead">
            <Link to={"/seller/seller_home"}>
              <p>
                <FontAwesomeIcon
                  icon="fa-solid fa-less-than"
                  className="lessThanSymbol"
                />
                Go to Home
              </p>
            </Link>
            <h3>Seller Login</h3>
          </div>

          <div className="col-lg-6 mx-auto">
            <div className="personal-details-form ">
              {/* form-holder */}

              <div className="details-form-holder">
                <div className="form-container">
                  <Form>
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom">
                            <Form.Group controlId="Last_Name">
                              <Form.Label className="required form-field">
                                Email
                              </Form.Label>
                              <div className="get-otp-text">
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="Enter Email"
                                  className="is-invalid"
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <div className="text-center ">
                              <Link to={"/seller/verfiypassword"}>
                                <Button
                                  className="tabs-btn login_now_btn"
                                  type="submit"
                                >
                                  Reset password
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellerForgetPass;
