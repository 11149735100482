import React, { useContext, useEffect, useState } from "react";
import ShoppingCartCard from "../ShoppingCartCard/ShoppingCartCard";
import SummaryCard from "../SummaryCard/SummaryCard";
import BreadcrumComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/BreadcrumComponent/BreadcrumComponent";
import Form from "react-bootstrap/Form";
import "../../ShoppingCartMain/ShoppingCartMain.css";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import {
  btobCartList,
  btobseletedCartList,
} from "../../../../utils/apis/btb/Cart";
import { SelectAdress } from "../../common-components/AllModal/SelectAdress/SelectAdress";
import { btobAddressList } from "../../../../utils/apis/btb/Shipping_Address";
import useRazorpay from "react-razorpay";
import { postData } from "../../../../utils/api";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
library.add(fas);

const StartOrder = () => {
  const [modalShowchange, setModalShowchange] = React.useState(false);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "shopping Cart", link: "/b2bbuyer/shopping-card" },
    { text: "start Order", link: "#" },
  ]);
  // const Cartdata = [
  //   {
  //     order: "Order 1 : Sidior Furniture Company Limited",
  //     label: "Sidior Furniture Company Limited",
  //     image: "/assest/images/B2Bbuyer/products/Sofa_2.png",
  //     title: "Modern Minimalist White Half Circle Designer Couches Luxury",
  //     minOrder: "Min. order: 2.0 piece",
  //     deliveryDate: "Estimated delivery by Apr 26-May 17",
  //     price: "₹60,000",
  //     buttonGroup: false,
  //     trash: false,
  //     buttonNote: true,
  //     quantity: "X4",
  //     formcheck: false,
  //   },
  //   {
  //     order: "Order 2 : Sidior Furniture Company Limited",
  //     label: "Sidior Furniture Company Limited",
  //     image: "/assest/images/B2Bbuyer/products/Sofa_2.png",
  //     title: "Modern Minimalist White Half Circle Designer Couches Luxury",
  //     minOrder: "Min. order: 2.0 piece",
  //     deliveryDate: "Estimated delivery by Apr 26-May 17",
  //     price: "₹60,000",
  //     buttonGroup: false,
  //     trash: false,
  //     buttonNote: true,
  //     quantity: "X4",
  //     formcheck: false,
  //   },
  // ];
  const [modalShow, setModalShow] = useState(false);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
      // navigate("/");
    }, 3000);
  };

  const { signin } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [cart, setcart] = useState([]);
  const [address, setaddress] = useState([]);
  const [addressId, setSelectedAddressId] = useState(null);

  const handleRadioChange = (id) => {
    setSelectedAddressId(id);
  };

  const getData = async () => {
    if (signin) {
      // const res = await btobCartList();
      const res = await btobseletedCartList();
      // console.log(res);
      setValue("total_items", res?.data?.length);

      let total_tax = 0;
      let total_mrp = 0;
      let total_selling = 0;
      for (const data of res?.data) {
        total_tax += data?.total_tax;
        total_mrp += data?.total_mrp;
        total_selling += data?.total_selling;
      }
      setValue("status", res?.data?.status);
      setValue("total_tax", total_tax);
      setValue("total_selling", total_selling);
      setValue("total_mrp", total_mrp);
      setValue("total", total_selling + total_tax);
      setValue("discount", total_mrp - total_selling);
      setcart(res?.data);
    } else {
      setValue("total_tax", 0);
      setValue("total_selling", 0);
      setValue("total_mrp", 0);
      setValue("total", 0);
      setValue("discount", 0);
      setcart([]);
    }

    {
      const res = await btobAddressList();
      setaddress(res.data);
      setSelectedAddressId(res.data[0]?.id);
    }
  };

  console.log("getVaues", getValues("total"));

  useEffect(() => {
    getData();
  }, []);

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const [discount, setDiscount] = useState(0);
  const handleRazorpay = async () => {
    const storedValue = getValues("total");
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Net Purti Pay",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const navigate = useNavigate();

  const finalFunction = async (razor = null) => {
    const data = {
      payment_id: razor,
      address_id: addressId,
      total_selling: getValues("total_selling"),
      total_mrp: getValues("total_mrp"),
      total: getValues("total"),
      discount: getValues("discount"),
      total_tax: getValues("total_tax"),
      seller_id: cart?.seller_id,
      // discount: getValues("discount"),
      payment_mode: razor ? "online" : "cod",
      delivery_insurance: getValues("delivery_insurance"),
      checker: getValues("checker"),
    };

    // if (coupon) {
    //   data.code_id = coupon;
    // }
    // if (razor) {
    //   data.payment_id = razor;
    // }
    const res = await postData("/btb/order-process/order", data);
    if (res?.success) {
      setModalShow(true);
      hideModalWithDelay();
      navigate("/b2bbuyer/order");
    }
  };

  useEffect(() => {
    console.log("checker", getValues("checker"));
  }, [getValues()]);

  return (
    <>
      <section className="ShoppingCart-sec">
        <div className="container">
          <BreadcrumComponent items={breadcrumbItems} />
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
              <div className="heading">
                <h1>Start Order</h1>
              </div>
              <div className="shipping-div my-3">
                <div className="border-div">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="icon-bg-div me-2">
                        <FontAwesomeIcon
                          icon="fa-solid fa-location-dot"
                          className="icon"
                        />
                      </div>
                      <p className="ship-text">Shipping address</p>
                    </div>
                    <button
                      className=""
                      onClick={() => setModalShowchange(true)}
                    >
                      Change{" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        className="edit-icon"
                      />
                    </button>
                  </div>

                  {address?.map((data, index) => (
                    <div key={index} className="main-address ms-4">
                      <input
                        type="radio"
                        name="address"
                        value={data.id}
                        checked={addressId === data.id}
                        onChange={() => handleRadioChange(data.id)}
                      />
                      <button className="resi-btn">{data?.address_type}</button>
                      <h6>{data?.name}</h6>
                      <p className="ship-text m-0">
                        {data?.address}
                        {data?.city?.name}, {data?.state?.name},{" "}
                        {data?.pincode?.name}, {data?.country?.name},{" "}
                        {data?.phone}
                      </p>
                    </div>
                  ))}

                  <SelectAdress
                    address={address}
                    getData={getData}
                    show={modalShowchange}
                    onHide={() => setModalShowchange(false)}
                  />
                </div>
                <div className="d-flex">
                  <div className="icon-bg-div me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-location-dot"
                      className="icon"
                    />
                  </div>
                  <p className="ship-text">Delivery options</p>
                </div>
              </div>
              <div className="ms-4">
                <ShoppingCartCard cardDataArray={cart} getData={getData} />
              </div>
              <div className="check-btn-div">
                <Form.Group className="mb-3">
                  <Form.Check
                    label="Need delivery insurance?"
                    name="delivery_insurance"
                    inline
                    // checked={getValues("delivery_insurance")}
                    onChange={(e) =>
                      setValue("delivery_insurance", e.target.checked)
                    }
                  />
                  <Form.Check
                    label="Need Checker?"
                    name="checker"
                    inline
                    // checked={getValues("checker")}
                    onChange={(e) => setValue("checker", e.target.checked)}
                  />
                </Form.Group>
              </div>
              {/* <div className="radio-btns">
                <div className="me-3">
                  <Form.Check
                    label="Project Safety Charges"
                    name="delivery"
                    type="radio"
                  />
                  <span>( it incl. 20% of total charges)</span>
                </div>

                <div className="me-3">
                  <Form.Check
                    label="Product Design"
                    name="delivery"
                    type="radio"
                  />
                  <span>( it incl. 20% of total charges)</span>
                </div>
                <div className="me-3">
                  <Form.Check label="Both" name="delivery" type="radio" />
                  <span>( it incl. 25% of total charges)</span>
                </div>
              </div> */}
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <SummaryCard
                getValues={getValues}
                // link={"/b2bbuyer/make-payment"}
                text={"Proceed to Pay"}
                handleRazorpay={handleRazorpay}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StartOrder;
