import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";

import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

import {
  CheckerAttachmentPost,
  getCheckerAttachment,
} from "../../../../utils/apis/checker/checker";

library.add(fas);

function Stepfive(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isYesActive, setIsYesActive] = useState(true);
  const [startDate, setStartDate] = useState();
  const [modalShow, setModalShow] = useState(false);

  const { IMG_URL } = useContext(Context);
  const options = [
    { value: " Pune", label: " Pune" },
    { value: "Mumbai (All areas)", label: "Mumbai (All areas)" },
    { value: "Bangalore", label: "Bangalore" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploader = Uploader({
    apiKey: "free",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(
      "checkerDetails.c_personal_details_id:- ",
      props.checkerDetails.c_personal_details_id
    );

    const formData = new FormData();

    if (attachmentId != "") {
      formData.append("id", attachmentId);
    }
    formData.append(
      "c_personal_details_id",
      props.checkerDetails.c_personal_details_id
    );
    formData.append("logo", data.logo[0]);
    formData.append("banner", data.banner[0]);
    formData.append("agreement_copy", data.agreement_copy[0]);

    const res = await CheckerAttachmentPost(formData);
    console.log("formData", formData);
    if (res?.success) {
      console.log(res, "success");
      // handleNext();
      props.nextStep();
    }
  };

 

  const [attachmentId, setAttachmentId] = useState("");

  const getMediaAttechments = async () => {

    const res = await getCheckerAttachment(
      props.checkerDetails?.c_personal_details_id
    );
    if (res?.success) {
      console.log(res);
      setAttachmentId(res.data.id);
      setStoreLogo(IMG_URL + res.data.logo);
      setStoreBanner(IMG_URL + res.data.banner);
      setStoreAgreement(IMG_URL + res.data.agreement_copy);
      reset(res.data);
    }
  };

  useEffect(() => {
    getMediaAttechments();
  }, []);


  const [selectedAgreementImages, setSelectedAgreementImage] = useState(null);
  const [storeAgreement, setStoreAgreement] = useState(null);
  const handleFileAgreementChange = (e) => {
    const file = e.target.files[0];
    setSelectedAgreementImage(file);
    setStoreAgreement(null);
    clearErrors('agreement_copy');
  };

  const handleAgreementRemoveFile = () => {
    setSelectedAgreementImage(null);
    const fileInput = document.getElementById('fileAgreementInput');
    fileInput.value = '';
  };

  const [selectedBannerImages, setSelectedBannerImage] = useState(null);
  const [storeBanner, setStoreBanner] = useState(null);

  const handleFileBannerChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected banner image:", file);
    setSelectedBannerImage(file);
    setStoreBanner(null);
    clearErrors('banner');
  };

  const handleRemoveBannerFile = () => {
    setSelectedBannerImage(null);
    const fileInput = document.getElementById('filebannerInput');
    fileInput.value = '';
  };

  const [selectedLogoImages, setSelectedLogoImage] = useState(null);
  const [storeLogo, setStoreLogo] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogoImage(file);
    setStoreLogo(null);
    clearErrors('logo');
  };

  const handleRemoveFile = () => {
    setSelectedLogoImage(null);
    const fileInput = document.getElementById('fileLogoInput');
    fileInput.value = '';
  };
  return (
    <>
      <div className="personal-details-form ">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="tab-form-container firstthreerrr">
              <div className="row">
                <div className="col-lg-4">
                  <div className="uploadGsthead">
                    <h3>Upload Logo</h3>
                  </div>

                  <div className="image-preview">
                    <div className="uploadBtnsec">
                      {storeLogo !== null ? (
                        <input
                          type="file"
                          id="fileLogoInput"
                          accept="image/*"
                          {...register("logo")}
                          onChange={handleFileChange}
                        />
                      ) : (
                        <input
                          type="file"
                          id="fileLogoInput"
                          accept="image/*"
                          {...register("logo", {
                            required: "Logo image is required",
                          })}
                          onChange={handleFileChange}
                        />
                      )}

                    </div>
                    {selectedLogoImages && (
                      <div className="previewImgmain">
                        <img
                          src={URL.createObjectURL(selectedLogoImages)}
                          alt={`Preview of ${selectedLogoImages.name}`}
                          className="previewImg"
                        />
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            className="circlecrossMark"
                            onClick={handleRemoveFile}
                          />
                        </div>
                      </div>
                    )}
                    {!selectedLogoImages && storeLogo !== null && (
                      <div className="previewImgmain">
                        <img
                          src={storeLogo}
                          className="previewImg"
                        />
                      </div>
                    )}

                  </div>
                  {errors.logo && (
                    <span className="text-danger">{errors.logo.message}</span>
                  )}

                </div>

                <div className="col-lg-4">
                  <div className="uploadGsthead">
                    <h3>Upload Banner</h3>
                  </div>

                  <div className="image-preview">
                    <div className="uploadBtnsec">

                      {storeBanner !== null || selectedBannerImages !== null ? (
                        <input
                          type="file"
                          id="filebannerInput"
                          accept="image/*"
                          {...register("banner")}
                          onChange={handleFileBannerChange} />
                      ) : (
                        <input type="file" id="filebannerInput" accept="image/*" {...register("banner", {
                          required: "Banner image is required",
                        })} onChange={handleFileBannerChange} />
                      )}

                    </div>
                    {selectedBannerImages && (
                      <div className="previewImgmain">
                        <img
                          src={URL.createObjectURL(selectedBannerImages)}
                          alt={`Preview of ${selectedBannerImages.name}`}
                          className="previewImg"
                        />
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            className="circlecrossMark"
                            onClick={handleRemoveBannerFile}
                          />
                        </div>
                      </div>
                    )}
                    {!selectedBannerImages && storeBanner !== null && (
                      <div className="previewImgmain">
                        <img
                          src={storeBanner}
                          className="previewImg"
                        />
                      </div>
                    )}

                  </div>
                  {errors.banner && (
                    <span className="text-danger">{errors.banner.message}</span>
                  )}
                </div>

                <div className="col-lg-4">
                  <div className="uploadGsthead">
                    <h3>Upload Agreement Copy</h3>
                  </div>

                  <div className="image-preview">
                    <div className="uploadBtnsec">

                      {storeAgreement !== null ? (
                        <input type="file" id="fileAgreementInput" accept="image/*" {...register("agreement_copy")} onChange={handleFileAgreementChange} />
                      ) : (
                        <input type="file" id="fileAgreementInput" accept="image/*" {...register("agreement_copy", {
                          required: "agreement_copy image is required",
                        })} onChange={handleFileAgreementChange} />
                      )}

                    </div>
                    {selectedAgreementImages && (
                      <div className="previewImgmain">
                        <img
                          src={URL.createObjectURL(selectedAgreementImages)}
                          alt={`Preview of ${selectedAgreementImages.name}`}
                          className="previewImg"
                        />
                        <div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            className="circlecrossMark"
                            onClick={handleAgreementRemoveFile}
                          />
                        </div>
                      </div>
                    )}
                    {!selectedAgreementImages && storeAgreement !== null && (
                      <div className="previewImgmain">
                        <img
                          src={storeAgreement}
                          className="previewImg"
                        />
                      </div>
                    )}

                  </div>
                  {errors.agreement_copy && (
                    <span className="text-danger">{errors.agreement_copy.message}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                {/* <p onClick={() => setModalShow(true)}>
                  By continuing, I agree to Netpurti's
                  <span className="termstxt">Terms of Use & Privacy Policy</span>
                </p> */}

                <Button onClick={props.prevStep} className="back-btn">
                  Back
                </Button>

                <Button
                  // onClick={props.nextStep}
                  className="tabs-btn"
                  type="submit"
                >
                  Register & Continue
                </Button>
              </div>

              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Stepfive;
