import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import "./Contactus.css";
import ButtonComponent from "../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import PhoneInput from "react-phone-input-2";
import InquiryModal from "../common-components/AllModal/inquiry-Modal/InquiryModal";

const Contactus = () => {
    const [modalInquiry, setModalInquiry] = React.useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [phone, setPhone] = useState('');
    const [phoneone, setPhoneone] = useState('');
    const submitModal = () => {
        setModalInquiry(true);
        setTimeout(() => {
            //   window.location = "/b2bbuyer/buyer-home"
            setModalInquiry(false);
        }, 3000);
    };
    const onSubmit = (data) => {
        console.log(data);
    };


    const handlePhoneChange = (value) => {
        setPhone(value);
    };
    const handlePhoneChangeone = (value) => {
        setPhoneone(value);
    };
    return (
        <>
            <section className="B2bContact">
                <div className="container">
                    <div className="row b2bContactMain">
                        <div className="col-lg-6">
                            <div className="Contactbanner">
                                <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/Contact/ContactBanner.png"} alt="Contact Banner" />
                            </div>
                        </div>
                        <div className="col-lg-6 my-auto">
                            <div>
                                <div className="contactHead">
                                    <h3>Contact Us</h3>
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("fullName", { required: true })}
                                                    placeholder="Enter Full Name"
                                                />
                                                {errors.fullName && <span className="error-message">Full Name is required</span>}
                                            </Form.Group>

                                            <div className="phoneInputsec mb-3">
                                                <Form.Label>Personal Contact Number</Form.Label>
                                                <PhoneInput
                                                    country={'in'}
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                />

                                            </div>

                                            {/* <Form.Group className="mb-3" controlId="country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("country", { required: true })}
                                                    placeholder="Enter Country Name"
                                                />
                                                {errors.country && <span className="error-message">Country Name is required</span>}
                                            </Form.Group> */}
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Company</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("company", { required: true })}
                                                    placeholder="Enter Company Name"
                                                />
                                                {errors.company && <span className="error-message">Company Name is required</span>}
                                            </Form.Group>


                                        </div>
                                        <div className="col-lg-6 col-md-6">

                                            <Form.Group className="mb-3" controlId="revenueVolume">
                                                <Form.Label>Business Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("revenueVolume", { required: true })}
                                                    placeholder="Enter Business Email"
                                                />
                                                {errors.revenueVolume && <span className="error-message">Business Email is required</span>}
                                            </Form.Group>
                                            {/* <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Company</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("company", { required: true })}
                                                    placeholder="Enter Company Name"
                                                />
                                                {errors.company && <span className="error-message">Company Name is required</span>}
                                            </Form.Group> */}
                                            <div className="phoneInputsec mb-3">
                                                <Form.Label>Business Contact Number</Form.Label>
                                                <PhoneInput
                                                    country={'in'}
                                                    value={phoneone}
                                                    onChange={handlePhoneChangeone}
                                                />

                                            </div>



                                            {/* <Form.Group className="mb-3" controlId="revenueVolume">
                                                <Form.Label>Annual revenue volume</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("revenueVolume", { required: true })}
                                                    placeholder="Enter Annual Revenue Volume"
                                                />
                                                {errors.revenueVolume && <span className="error-message">Annual Revenue Volume is required</span>}
                                            </Form.Group> */}
                                            <Form.Group className="mb-3" controlId="country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    {...register("country", { required: true })}
                                                    placeholder="Enter Country Name"
                                                />
                                                {errors.country && <span className="error-message">Country Name is required</span>}
                                            </Form.Group>
                                        </div>

                                        <Form.Group className="mb-3 teradata-div" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows={4} placeholder="Enter message" />
                                        </Form.Group>
                                    </div>

                                    <div className="Contactsubmitbtn">
                                        <ButtonComponent type="submit" className="submit" onClick={() => { submitModal(true) }}>Submit Form</ButtonComponent>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <InquiryModal
                message="Submitted successfully"
                show={modalInquiry}
                onHide={() => setModalInquiry(false)}
            />
        </>
    );
};

export default Contactus;
