import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

export const getSellerWalletApi = async (id) => {
  try {
    const res = await getData(`/seller/seller-wallet/wallet`);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const depositAmountPost = async (amount, razor) => {
  try {
    const res = await postData(`/seller/seller-wallet/wallet`,{amount:amount,razor_id:razor});

    return res;
  } catch (error) {
    console.log(error);
  }
};
