import React, { useState, useContext } from "react";
import "./Header_seller_dashboard.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification_offcanvas from "../common-components/offcanvas/notification-offcanvas/Notification_offcanvas";
import Logout_modal from "../../../CommonForAll/logout-modal/Logout_modal";

import { Context } from "../../../../utils/context";

const Header_seller_dashboard = ({ setSidebarclosed, sidebarclosed }) => {
  const { userdata } = useContext(Context);

  const [notShow, setNotShow] = useState(false);

  const [smShow, setSmShow] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [nottificationshow, setNotificationShow] = useState(false);
  const [logoutshow, setLogoutShow] = useState(false);

  const toggleSidebar = () => {
    setSidebarclosed(!sidebarclosed);
  };

  return ( 
    <>
      <section className="Header_seller_dashboard container-fluid p-0">
        <div className="bg-body-tertiary ">
          <div className="row me-0">
            <div className="col-md-3 my-auto">
              <div className="user-image d-flex">
                {/* <div className="ms-5 me-2 ">
                  <FontAwesomeIcon
                    icon="fa-solid fa-bars"
                    className="me-3"
                    onClick={toggleSidebar}
                  />
                </div> */}
                <div className="me-5 mt-3">
                  <p className="header-text">
                    Hello,
                    <br />
                    <span>
                      {userdata?.f_name} {userdata?.l_name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-9 my-auto">
              <div className="search-notification">
                <div className="search-bar">
                  <Form inline>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Type here to search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </Form>
                </div>
                <div className="">
                  <div className="d-flex head-notti-flex justify-content-end">
                    <div className="space-bd">
                      <div
                        className="noti-div"
                        onClick={() => setNotificationShow(true)}
                      >
                        <FontAwesomeIcon
                          className="nottibel-icco"
                          icon="fa-solid fa-bell"
                        />
                        <span className="not-contt">7</span>
                      </div>

                      <Notification_offcanvas
                        show={nottificationshow}
                        onHide={() => setNotificationShow(false)}
                      />
                    </div>
                    <div className="space-bd2">
                      <div
                        className="noti-div "
                        onClick={() => setLogoutShow(true)}
                      >
                        <FontAwesomeIcon
                          className="nottibel-icco"
                          icon="fa-solid fa-right-from-bracket"
                        />
                      </div>

                      <Logout_modal
                        show={logoutshow}
                        onHide={() => setLogoutShow(false)}
                        title="Are you sure you want to logout?"
                        btnname="Logout"
                      />

                      {/* <Logout_modal
                show={deleteshow}
                productId={productId}
                getAllProducts={props.getAllProducts}
                onHide={() => setdeleteShow(false)}
                title="Are you sure you want to delete this product from listing?"
                btnname="Delete"
              /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header_seller_dashboard;
