import React from "react";
import HomeBanner from "./HomeBanner/HomeBanner";
import SelectedTrendingProducts_NewArrivals from "./SelectedTrendingProducts_NewArrivals/SelectedTrendingProducts_NewArrivals";
import BestSellersCategories from "./BestSellersCategories/BestSellersCategories";
import Addvertise_Offerings from "./Addvertise_Offerings/Addvertise_Offerings";
import Business_Requirements from "./Business_Requirements/Business_Requirements";
import Procurement_Customized from "./Procurement_Customized/Procurement_Customized";
import PCS_Services from "./Procurement_Customized_Services_Process/PCS_Services";
import SkinCare from "./Skin_Care/SkinCare";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <SkinCare />
      <SelectedTrendingProducts_NewArrivals />
      <BestSellersCategories />
      <Addvertise_Offerings />
      <Business_Requirements />
      <Procurement_Customized />
      <PCS_Services />
    </>
  );
};

export default Home;
  