import { useState, useEffect, useContext } from "react";
import "./B2b_checker_buyerstepForm.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import Stepsix from "./Stepsix/Stepsix";
import Stepseven from "./Stepseven/Stepseven";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OverviewPage from "./OverviewPage/OverviewPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
function B2b_checker_buyerstepForm({ onNext, onPrev }) {
  const [startDate, setStartDate] = useState();

  const [checkerDetails, setCheckerDetails] = useState();
  const [checkerID, setCheckerId] = useState();
  const [bankID, setBankId] = useState();

  const { getData, postData, IMG_URL } = useContext(Context);
  const getCheckerDetails = async () => {
    console.log(Cookies.get("checker_id"), "llllllll");
    const res = await getData(
      `/checker/checker-create/checker-personal-details/${Cookies.get(
        "checker_id"
      )}`
    );
    if (res?.success) {
      console.log(res, "Checker");
      setCheckerDetails(res.data);
      setCheckerId(res.data.c_personal_details_id);
    }
  };

  useEffect(() => {
    getCheckerDetails();
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState(0);

  const nextStep = () => {
    getCheckerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage + 1);
    if (page === 6 && onNext) {
      onNext();
    }
  };

  const prevStep = () => {
    getCheckerDetails();
    window.scrollTo(0, 0);
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev();
    }
  };
  const prevStepInner = () => {
    window.scrollTo(0, 0);
  };

  const calculatePercentage = () => {
    const totalSteps = stepNames.length;
    const currentPage = page + 1;
    return (currentPage / totalSteps) * 100;
  };

  const calculateProgressPercentage = () => {
    const stepProgressPercentages = [0, 15, 35, 50, 65, 85, 99];

    return stepProgressPercentages[page];
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
          />
        );
      case 1:
        return (
          <Stepfour
            prevStep={() => prevStep(2)}
            setPage={setPage}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
          />
        );
      case 2:
        return (
          <Stepthree
            nextStep={nextStep}
            prevStep={() => prevStep(1)}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
            setBankId={setBankId}
          />
        );
      case 3:
        return (
          <Stepsix
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
          />
        );
      case 4:
        return (
          <Steptwo
            nextStep={nextStep}
            prevStep={() => prevStep(0)}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
          />
        );

      case 5:
        return (
          <Stepfive
            nextStep={nextStep}
            prevStep={() => prevStep(3)}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
          />
        );

      case 6:
        return (
          <Stepseven
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            checkerDetails={checkerDetails}
            checkerID={checkerID}
          />
        );

      // case 6:
      //   return (
      //     <OverviewPage prevStep={() => prevStep(5)} goToStep={goToStep} />
      //   );
      default:
        return null;
    }
  };

  const stepNames = [
    "Personal Details",
    "Firm Details",
    "Bank Details",
    "Service Details",
    "Branch Details",
    "Attachment",
    "Password",
  ];

  return (
    <>
      <section className="B2bmainchecker container">
        <div className=" mt-md-5 mt-4">
          <div className="d-md-flex d-flex-unset">
            <div className="">
              <Link to="/seller/seller_home" className="left-goto-homee">
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go to home
              </Link>
            </div>
            <div className="title-stttee-align">
              <h6>Checker Registration Form</h6>
            </div>
          </div>
        </div>

        {page !== 7 ? (
          <div className="ProgressBar">
            <ProgressBar
              percent={calculatePercentage()}
              filledBackground="#1C42A9"
              height="3px"
              style={{ margin: "auto" }}
            >
              {[1, 2, 3, 4, 5, 6, 7].map((icon, index) => (
                <Step key={index}>
                  {({ accomplished }) => (
                    <div
                      style={{
                        backgroundColor: accomplished ? "#1C42A9" : "#ffffff",
                        color: accomplished ? "#fff" : "#000000",
                        border: accomplished
                          ? "1px solid #1C42A9"
                          : "1px solid #FFFFFF",
                        boxShadow: accomplished
                          ? "none"
                          : " 0px 0px 6px #00000029",
                      }}
                      className={`step ${accomplished ? "completed" : null}`}
                    >
                      <p className="mb-0">{icon}</p>
                      <div className="step-name">{stepNames[index]}</div>
                    </div>
                  )}
                </Step>
              ))}
            </ProgressBar>
          </div>
        ) : (
          <></>
        )}

        <div className={page !== 7 ? "userForm" : "overviewMain"}>
          <div className="personal-details-form step-Form-Conteiner ">
            {/* form-heading-holder */}
            <div className="form-header-holder">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-12">
                  <div className="heading-holder">
                    <h6>
                      {page === 0
                        ? "Personal Details"
                        : page === 1
                        ? "Please fill the below fields"
                        : page === 2
                        ? "Please fill the below fields"
                        : page === 3
                        ? "Please fill the below fields"
                        : page === 4
                        ? "Please fill the below fields"
                        : page === 5
                        ? "Please fill the below fields"
                        : page === 6
                        ? "Create password for your account"
                        : null}
                    </h6>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="span-holder">
                    <span>Just a few steps to go</span>
                    <div style={{ width: "50px", height: "50px" }}>
                      <CircularProgressbar
                        value={calculateProgressPercentage()}
                        text={`${calculateProgressPercentage()}%`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">{PageDisplay()}</div>
          </div>
        </div>
      </section>
    </>
  );
}

export default B2b_checker_buyerstepForm;
