import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const pulledCategory = async (id) => {
  try {
    return await getData(
      `/seller/outer-website/masters/super-admin/pulled-category`
    );
  } catch (error) {
    console.log(error);
  }
};

export const pulledInfoCategory = async (id) => {
  try {
    return await getData(
      `/seller/info-website/masters/super-admin/pulled-category`
    );
  } catch (error) {
    console.log(error);
  }
};

export const pulledInquiryCategory = async (id) => {
  try {
    return await getData(
      `/seller/inquiry-website/masters/super-admin/pulled-category`
    );
  } catch (error) {
    console.log(error);
  }
};

export const hsnCodes = async () => {
  try {
    return await getData(`/seller/masters/all-hsn-code`);
  } catch (error) {
    console.log(error);
  }
};
