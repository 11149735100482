import React, { useState } from 'react'
import './B2bFrequentlyCard.css';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import { Pagination } from "swiper/modules";
import FrequentlyBoughtCard from '../../common-components/Frequently-bought-card/FrequentlyBoughtCard';
const B2bFrequentlyCard = ({ headingName }) => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const frequentlycardsDetails = [
        {
            id: 1,
            content: "Modern Minimalist White Half Circle Designer Couches Luxury...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa.png",
            detailsLink: "",
        },
        {
            id: 2,
            content: "White Boucle Dinner Chairs Sedie Tavoli Da Pranzo Leather Dining...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/white-ashcroft.png",
            detailsLink: "",
        },
        {
            content: "Factory OEM Fabric Leather Stainless Steel Metal Leg Chairs...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa1.png",
        },
        {
            content: "New High End Queen King Size Bed Frame Luxury Bed Frame...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa2.png",
        },
        {
            content: "Nordic Design Postmodern Marble Round Coffee Table...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa3.png",
        },
        {
            content: "Luxury Nordic Arm Chair Dinner Gold Metal Legs Furniture...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa4.png",
        },
        {
            content: "Luxury Nordic Arm Chair Dinner Gold Metal Legs Furniture...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa5.png",
        },
        {
            content: "High Quality Living Room Modern Leisure Style Lounge Chair...",
            rupees: "₹15,000",
            orderdetails: "Min Order: 2 Pieces",
            image: process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/B2BSellerDetail/seater_Sofa6.png",
        },
    ];
    return (
        <>
            <section className='frequently-bought-together'>
                <div className='container'>
                    <div className='row'>
                        <div className='hediang-holder mb-3'>
                            <h2>{headingName}</h2>
                        </div>
                        <div >
                            <Swiper
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    420: {
                                        slidesPerView: 1.7,
                                        spaceBetween: 20,
                                    },
                                    486: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: 4,
                                        spaceBetween: 10,
                                    },
                                    1200: {
                                        slidesPerView: 4.5,
                                        spaceBetween: 10,
                                    },
                                    1400: {
                                        slidesPerView: 5.5,
                                        spaceBetween: 10,
                                    },

                                }}
                                // pagination={{
                                //     dynamicBullets: true,
                                // }}
                                // modules={[Pagination]}
                                className="mySwiper"
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                            >
                                {frequentlycardsDetails.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <FrequentlyBoughtCard
                                            className="main-section mb-5"
                                            content={item.content}
                                            image={item.image}
                                            imgClassName="seller-img"
                                            rupees={item.rupees}
                                            orderdetails={item.orderdetails}
                                        />

                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div class="silder-btn">
                            <div
                                className="back-btn"
                                onClick={() => handleMainSliderClick("prev")}
                            ></div>
                            <div
                                className="next-btn"
                                onClick={() => handleMainSliderClick("next")}
                            ></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default B2bFrequentlyCard