import React from "react";
import "./All_product_count_list.css";

const All_product_count_list = () => {
  const product_list = [
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box.png",
      name: "All Products",
      number: "300",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/shopping-bag.png",
      name: "New Orders",
      number: "150",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/box2.png",
      name: "Sales Products",
      number: "500",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/sales.png",
      name: "Monthly Sales",
      number: "89K",
    },
    {
      icon: "/assest/images/Seller_panel/dashboard/icons/shopping-bag2.png",
      name: "Active Orders",
      number: "30",
    },
  ];

  return (
    <>
      <section className="All_product_count_list">
        <div className="row">
          {product_list.map((data, index) => (
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-widthhhh">
              <div className="list-flex">
                <div className="img-div">
                  <div className="circle-ico ico1">
                    <img className="icon" src={data.icon} />
                  </div>
                </div>
                <div className="cont-div">
                  <p className="name">{data.name}</p>
                  <p className="number">{data.number}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default All_product_count_list;
