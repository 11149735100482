import React from "react";
import "./Header.css";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <section className="seller-header-section">
        <Container fluid>
          <Container>
            <div className="header-main roboto-family">
              <Navbar
                collapseOnSelect
                expand="lg"
                className="p-0 m-0"
              >
                <Navbar.Brand className="header-img">
                  <Link to={"/seller/seller_home"}>
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/seller-image/logo/netpurtilogo.png"
                      }
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Link to={""}>
                      <Nav.Link className="header-lists">Sell Online</Nav.Link>
                    </Link>
                    <Link to={""}>
                      <Nav.Link className="header-lists">
                        Fees & Comission
                      </Nav.Link>
                    </Link>
                    <NavDropdown
                      title="Learn"
                      id="collapsible-nav-dropdown"
                      className="header-lists "
                    >
                      <div className="dropdown-mains">
                        <Row>
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="dp-items rightside-border">
                              <Link to={"/seller/faq"}>
                                <NavDropdown.Item
                                  href="/seller/faq"
                                  className="drop-links"
                                >
                                  FAQ
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/seller/seller-success-story"}>
                                <NavDropdown.Item
                                  href="/seller/seller-success-story"
                                  className="drop-links"
                                >
                                  Seller success stories
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/seller/seller-blogs"}>
                                <NavDropdown.Item
                                  href="/seller/seller-blogs"
                                  className="drop-links"
                                >
                                  Seller Blogs
                                </NavDropdown.Item>
                              </Link>
                            </div>
                          </Col>
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="dp-mains">
                              <div className="drop-imgs">
                                <img
                                  className="dropdown-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/images/seller-image/Header/drop.png"
                                  }
                                  alt="Logo"
                                />
                              </div>
                              <div className="dp-text">
                                <p className="dp-txt-p">
                                  BigCommerce Black History Month Spotlight: Sam
                                  McGee of...
                                </p>
                              </div>
                              <div className="dp-icn">
                                <Link to={""} className="aro-icn">
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className="dp-mains">
                              <div className="drop-imgs">
                                <img
                                  className="dropdown-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assest/images/seller-image/Header/drop2.png"
                                  }
                                  alt="Logo"
                                />
                              </div>
                              <div className="dp-text">
                                <p className="dp-txt-p">
                                  BigCommerce Black History Month Spotlight: Sam
                                  McGee of...
                                </p>
                              </div>
                              <div className="dp-icn">
                                <Link to={""} className="aro-icn">
                                  {" "}
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <Link to={"/seller/selleraffiliatestepForm"}>
                      <Nav.Link
                        href="/seller/selleraffiliatestepForm"
                        className="header-lists2 "
                      >
                        Affiliate
                      </Nav.Link>
                    </Link>
                    <Link to={"/seller/sellerbrandstepForm"}>
                      <Nav.Link
                        href="/seller/sellerbrandstepForm"
                        className="header-lists2 "
                      >
                        Brand
                      </Nav.Link>
                    </Link>

                    <Link to={"/seller/seller3PstepForm"}>
                      <Nav.Link
                        href="/seller/seller3PstepForm"
                        className="header-lists2 "
                      >
                        Start As Third Party Support
                      </Nav.Link>
                    </Link>

                    <Link to={"/seller/seller_stepform"}>
                      <Nav.Link
                        href="/seller/seller_stepform"
                        className="header-lists2 "
                      >
                        Start Selling
                      </Nav.Link>
                    </Link>

                    <div className="btndiv">
                      <Link to={"/seller/sellerlogin"}>
                        <button className="btnss">Login</button>
                      </Link>
                    </div>

                    <div className="header-lists2 btndiv">
                      <Link to={"/affiliatelogin"}>
                        <button className="btnss">Affiliate Login</button>
                      </Link>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default Header;
