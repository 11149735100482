import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import { getSellerDetails } from "./apis/seller/Seller";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
  getDimension,
} from "./api";

import {
  Per_Page_Dropdown,
  Select2Data,
  Select2AttributeData,
  SelectHsnData,
  RegxExpression,
  Select3Data,
  Select2CategoryData,
  ProductNarrationChange,
  ProductNarrationChangeComa,
  Select2SubCategoryData,
  formatDateExpire,
  Select2CurrencyData,
  formatDate,
} from "./common";
import { getAffiliateDetails } from "./apis/affiliate/affiliate";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:3000";
  // const IMG_URL = "http://192.168.1.48:3000";
  // const IMG_URL = "http://192.168.16.53:3000";
  const IMG_URL = "http://netpurtinode.profcymabackend.com:3434";

  const navigate = useNavigate();
  const [Id, setId] = useState(null);
  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [working_type, setWorking_type] = useState(0);
  const [isAllow, setisAllow] = useState([]);
  const minLength = 2;
  const maxLength = 30;
  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const getSellerData = async (id) => {
    const response = await getSellerDetails(id);
    await setUserData(response?.data);
    await setWorking_type(response?.data?.s_sector?.s_working_type_id);
  };

  const getAffiliateData = async (id) => {
    const response = await getAffiliateDetails(id);
    await setUserData(response?.data);
  };

  const isTokenValid = async () => {
    const token = Cookies.get("net_purti_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token?.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("net_purti_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/seller/sellerlogin");
      } else {
        setisAllow(decodedToken?.permissions);
        // getSellerData(decodedToken.user);
        setUsertype(decodedToken.type);
        setId(decodedToken.user);
        if (decodedToken.type === "Affiliate") {
          await getAffiliateData(decodedToken.user);
        }
        if (decodedToken.type === "Seller") {
          await getSellerData(decodedToken.user);
        }
        setSignin(true);
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  // const isTokenValid = async () => {
  //   const token = Cookies.get("net_purti_security");

  //   if (token) {
  //     const decodedToken = JSON.parse(atob(token.split(".")[1]));
  //     const currentTime = Date.now() / 1000;

  //     if (decodedToken.exp < currentTime) {
  //       Cookies.remove("net_purti_security", { path: "/" });
  //       navigate("/Components/DashBoard/DashBoard");
  //     } else {
  //       getSellerData(decodedToken.user);
  //       setUsertype(decodedToken.role);
  //       setSignin(true);
  //     }
  //   } else {
  //     navigate("/Components/DashBoard/DashBoard");
  //   }
  // };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  const [shimmerLoader, setShimmerLoader] = useState(false);
  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const [buyNowData, setBuyNowData] = useState({});
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        // token,
        // setToken,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        SelectHsnData,
        Per_Page_Dropdown,
        Select3Data,
        RegxExpression,
        shimmerLoader,
        setShimmerLoader,
        editStatusData,
        getDimension,
        Select2CategoryData,
        Select2AttributeData,
        ProductNarrationChange,
        ProductNarrationChangeComa,
        Id,
        Select2SubCategoryData,
        formatDateExpire,
        formatDate,
        working_type,
        Select2CurrencyData,
        setBuyNowData,
        buyNowData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
