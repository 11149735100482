import React, { useState, useEffect, useContext } from "react";
// import "./b2b-table.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from "framer-motion";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AnimatePresence } from "framer-motion";

// import {
//     getAllProducts,
//     productStatusChange,
//     historiesGet,
// } from "../../../../../../../utils/apis/seller/Product";

// import {
//     ProductStatus,
//     Category,
// } from "../../../../../../../utils/apis/master/Master";
import { ShimmerTable } from "react-shimmer-effects";
import { type } from "@testing-library/user-event/dist/type";
import { Context } from "../../../../../../utils/context";
import CustomPaginate from "../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import VeiwEditModal from "../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Mrp_modal from "../../../../../CommonForAll/mrp-modal/Mrp_modal";

import {
  getAllProducts,
  productStatusChange,
} from "../../../../../../utils/apis/seller/Product";
import {
  Category,
  ProductStatus,
} from "../../../../../../utils/apis/master/Master";
import CategoryDetails from "../../../../../CommonForAll/CategoryDetails/CategoryDetails";
import View_all_varients_offcanvas from "../../../../Seller_Dashboard/common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";

const Ordertable = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader } = useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modaleye, setModaleye] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");
  const [key, setKey] = useState("home");
  const [deleteshow, setdeleteShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const InitialTransition = () => {};
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };

  const { IMG_URL, userdata } = useContext(Context);
  const [products, setProducts] = useState([]);

  const getProducts = async (page) => {
    setShimmerLoader(true);
    const res = await getAllProducts(
      page,
      productTitle,
      searchStatus,
      category,
      startDate,
      endDate
    );
    if (res?.success) {
      console.log(res);
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res.data);
      setShimmerLoader(false);
    }
  };
  const [varIndex, setIndex] = useState("");
  const [variantData, setVariantData] = useState();
  const handleVariantModal = async (data, index) => {
    await setIndex(index);
    await setVariantData(data);
    setVarientsoffcanvas(true);
  };

  const getVariantProducts = async () => {
    const res = await getAllProducts(currentPage);
    if (res?.success) {
      console.log(res);
      setProducts(res?.data);
      await setVariantData(res?.data?.data[varIndex]);
    }
  };

  const handleStatus = async (id) => {
    setShimmerLoader(true);
    const res = await productStatusChange(id);
    if (res?.success) {
      getProducts(currentPage);
      setShimmerLoader(false);
    }
  };

  const [productStatus, setProductStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const getMasters = async () => {
    {
      const res = await ProductStatus();
      if (res?.success) {
        setProductStatus(res?.data);
      }
    }
    {
      const res = await Category(userdata?.s_sector?.s_working_type_id);
      if (res?.success) {
        setCategories(res?.data);
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  console.log(selectAllChecked, "ttt");

  const changeStatus = async () => {
    setShimmerLoader(true);

    if (selectAllChecked.length > 0) {
      for (const value of selectAllChecked) {
        await productStatusChange(value);
      }
    }

    setShimmerLoader(false);
    getProducts(currentPage);
  };

  useEffect(() => {
    getMasters();
    getProducts(currentPage);
  }, [currentPage, productTitle, searchStatus, category, startDate, endDate]);

  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        <h6 className="pdlsis">Tracking</h6>
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row">
                <div className="col-xxl-2 co-xl-2 col-lg-3 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search by product title"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-5 co-xl-5 col-lg-6 col-md-6">
                  <div className="d-flex product-status-div">
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                        />
                      </Form.Group>
                    </div>
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <hr></hr>

              <div className="table-responsive table-overflow">
                <table className="table table-bordered">
                  <thead>
                    <tr className="">
                      <td>Adv ID</td>

                      <td>Date & Time</td>

                      <td>Expiry</td>

                      <td>Category</td>

                      <td>Item Name</td>

                      <td>Incentive On</td>

                      <td>Total Click</td>

                      <td>Conversion</td>

                      <td>Earning Amount</td>

                      <td>Status</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td> 1527</td>

                      <td>WR suresh</td>
                      <td>#1122</td>

                      <td>*******678</td>

                      <td>1286.2</td>
                      <td>100 </td>
                      <td>106 (9%)</td>
                      <td>200</td>

                      <td>5165.4</td>

                      <td>
                        <Button className="acceptbtn" type="button">
                          Paid
                        </Button>
                      </td>
                    </tr>

                    <tr>
                      <td> 1527</td>

                      <td>WR suresh</td>
                      <td>#1122</td>

                      <td>*******678</td>

                      <td>1286.2</td>
                      <td>100 </td>
                      <td>106 (9%)</td>
                      <td>200</td>

                      <td>5165.4</td>

                      <td>
                        <Button className="rejectbtn" type="button">
                          Pending
                        </Button>
                      </td>
                    </tr>

                    <tr>
                      <td> 1527</td>

                      <td>WR suresh</td>
                      <td>#1122</td>

                      <td>*******678</td>

                      <td>1286.2</td>
                      <td>100 </td>
                      <td>106 (9%)</td>
                      <td>200</td>

                      <td>5165.4</td>

                      <td>
                        <Button className="progressbtn" type="button">
                          Transaction In Process
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Form>
          </div>

          <CategoryDetails show={modaleye} onHide={() => setModaleye(false)} />

          <div className="pagination-div">
            <CustomPaginate
              currentPage={currentPage}
              totalPages={products?.totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          <View_all_varients_offcanvas
            show={varientsoffcanvas}
            variantData={variantData}
            getVariantProducts={getVariantProducts}
            onHide={() => setVarientsoffcanvas(false)}
            placement="top"
          />

          <VeiwEditModal
            show={modalShowedit}
            getAllProducts={getAllProducts}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
          />

          <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </section>
    </>
  );
};

export default Ordertable;
