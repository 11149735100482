import React from "react";
import "./InfoWebsite.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Product from "../InfoWebsite/Product/Product";
import Setting from "../../SidebarComponents/InfoWebsite/Setting/Setting";
import Banner from "../../SidebarComponents/InfoWebsite/Banner/Setting";
import Content from "../../SidebarComponents/InfoWebsite/Content/Setting";
import Category from "../../SidebarComponents/InfoWebsite/Category/Setting";
import Orders from "../../SidebarComponents/InfoWebsite/Orders/Orders";
import LookingFor from "../../SidebarComponents/InfoWebsite/LookingFor/Table";
import ContactUsOuterWebsite from "../../SidebarComponents/InfoWebsite/ContactUsOuterWebsite/Table";
import QueryManagement from "../../SidebarComponents/InfoWebsite/QueryManagement/Table";
import RejectedProducts from "../../SidebarComponents/InfoWebsite/RejectedProducts/Table";

import WebsiteSetup from "../../SidebarComponents/InfoWebsite/WebsiteSetup/Setting";
import Dashboard from "../InfoWebsite/Dashboard/Dashboard";
import {
  faAddressBook,
  faBox,
  faCreditCard,
  faEye,
  faHeadset,
  faHouse,
  faLaptop,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Mywebsite = ({ setSidebarclosed, sidebarclosed }) => {
  const renderTooltip = (name) => (props) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {name}
      </Tooltip>
    );

    const closeSidebar = () => {
      if (!sidebarclosed) {
        setSidebarclosed(true);
      }
    };
  return (
    <>
      <section className="Mywebsite">
        <div className="mywebsiteMain">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
              <Col sm={1} className="col-width scale-up-hor-left">
                <div className="websitestab scale-up-hor-left">
                  <Nav variant="pills" className="flex-column">
                    {/* Dashboard */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Dashboard")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="1">Dashboard</Nav.Link> */}
                        <Nav.Link eventKey="1">
                          {" "}
                          <FontAwesomeIcon icon={faHouse} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Product */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Product")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        <Nav.Link eventKey="2">
                          <FontAwesomeIcon icon={faBox} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/*   <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="3">Setting</Nav.Link>
                    </Nav.Item> */}

                    {/* Category Section */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Category Section")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        <Nav.Link eventKey="4">
                          <FontAwesomeIcon icon={faList} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Website Setup */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Website Setup")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="5">Website Setup</Nav.Link> */}
                        <Nav.Link eventKey="5">
                          <FontAwesomeIcon icon={faLaptop} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Banner Section */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Banner Section")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="6">Banner Section</Nav.Link> */}
                        <Nav.Link eventKey="6">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Content Section */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Content Section")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="7">Content Section</Nav.Link> */}
                        <Nav.Link eventKey="7">
                          <FontAwesomeIcon icon={faAddressBook} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/*   <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="3">Orders</Nav.Link>
                    </Nav.Item> */}

                    {/*   <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="4">InQuiry</Nav.Link>
                    </Nav.Item> */}

                    {/* Looking For */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Looking For")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        <Nav.Link eventKey="8">
                          <FontAwesomeIcon icon={faEye} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/* Contact Us */}
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 0 }}
                      overlay={renderTooltip("Contact Us")}
                    >
                        <Nav.Item onClick={closeSidebar}>
                        {/* <Nav.Link eventKey="9">Contact Us</Nav.Link> */}
                        <Nav.Link eventKey="9">
                          <FontAwesomeIcon icon={faHeadset} />
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    {/*   <Nav.Item onClick={closeSidebar}>
                      <Nav.Link eventKey="7">Rejected Products</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
              </Col>
              <Col sm={11} className="col2-width">
                <Tab.Content className="tab-content-holder">
                  <Tab.Pane eventKey="1">
                    <Dashboard />
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    {/* <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="9">
                        <Link to={"/seller/seller-dashboard/sellerproduct"}>
                          <Accordion.Header>Product</Accordion.Header>
                        </Link>
                      </Accordion.Item>

                      <Accordion.Item eventKey="11">
                        <Link to={"/seller/seller-dashboard/sellersetting"}>
                          <Accordion.Header>Setting</Accordion.Header>
                        </Link>
                      </Accordion.Item>

                      <Accordion.Item eventKey="13">
                        <Link to={"/seller/seller-dashboard/order_management"}>
                          <Accordion.Header>Orders</Accordion.Header>
                        </Link>
                      </Accordion.Item>

                      <Accordion.Item eventKey="14">
                        <Link to={"/seller/seller-dashboard/query"}>
                          <Accordion.Header>InQuiry</Accordion.Header>
                        </Link>
                      </Accordion.Item>

                      <Accordion.Item eventKey="15">
                        <Link to={"/seller/seller-dashboard/looking-for"}>
                          <Accordion.Header>looking For</Accordion.Header>
                        </Link>
                      </Accordion.Item>

                      <Accordion.Item eventKey="16">
                        <Link to={"/seller/seller-dashboard/contact-us"}>
                          <Accordion.Header>Contact Us</Accordion.Header>
                        </Link>
                      </Accordion.Item>

                      <Accordion.Item eventKey="40">
                        <Accordion.Header>All Admin Category</Accordion.Header>
                        <Accordion.Body>
                          <Accordion>
                            <Accordion.Item eventKey="21">
                              <Link to={"/seller/seller-dashboard/category"}>
                                <Accordion.Header>Category</Accordion.Header>
                              </Link>
                            </Accordion.Item>

                            <Accordion.Item eventKey="22">
                              <Link
                                to={"/seller/seller-dashboard/sub-category"}
                              >
                                <Accordion.Header>
                                  Sub Category
                                </Accordion.Header>
                              </Link>
                            </Accordion.Item>

                            <Accordion.Item eventKey="23">
                              <Link
                                to={"/seller/seller-dashboard/child-category"}
                              >
                                <Accordion.Header>
                                  Child Category
                                </Accordion.Header>
                              </Link>
                            </Accordion.Item>

                            <Accordion.Item eventKey="24">
                              <Link
                                to={
                                  "/seller/seller-dashboard/child-subcategory"
                                }
                              >
                                <Accordion.Header>
                                  Child Sub Category
                                </Accordion.Header>
                              </Link>
                            </Accordion.Item>
                          </Accordion>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion> */}
                    <Product />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="3">
                    <Setting />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="4">
                    <Category />
                  </Tab.Pane>
                  <Tab.Pane eventKey="5">
                    <WebsiteSetup />
                  </Tab.Pane>
                  <Tab.Pane eventKey="6">
                    <Banner />
                  </Tab.Pane>
                  <Tab.Pane eventKey="7">
                    <Content />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="3">
                    <Orders />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="8">
                    <QueryManagement />
                  </Tab.Pane>
                  <Tab.Pane eventKey="9">
                    <LookingFor />
                  </Tab.Pane>
                  <Tab.Pane eventKey="10">
                    <ContactUsOuterWebsite />
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="7">
                    <RejectedProducts />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Mywebsite;
