import React,{useEffect,useState,useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeReasonslider from "./HomereasonSlider/HomeReasonslider";
import HomeReasonImage from "./HomeReasonImage/HomeReasonImage";
import ButtonComponent from "../../CommonComponents/ButtonComponent/ButtonComponent";
import { HomeReasonsGet } from "../../../../utils/apis/myWebsite/mywebsite";

const HomeReason = () => {
  const handleButtonClick = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };


  const [homeReasonsData, setHomeReasonsData] = useState();
  const getHomeReasonsData = async () => {

    const res = await HomeReasonsGet();
    if (res?.success) {
      setHomeReasonsData(res?.data);
    }
  }

  useEffect(() => {
    getHomeReasonsData();
  }, [])


  return (
    <section className="HomeReason">
      <Container>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <HomeReasonImage />
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <HomeReasonslider homeReasonsData={homeReasonsData} />
            <div className="Plan-Btn text-center">
              <ButtonComponent variant="" className="primary maintenance-text" onClick={handleButtonClick}>
                Get Your Free Migration Plan Now!
              </ButtonComponent>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeReason;
