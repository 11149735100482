import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./Dashboard.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faStar } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { BarChart } from "@mui/x-charts/BarChart";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { allCounter } from "../../../../../../utils/apis/seller/Inquiry-website/dashborad/dashboard";
import { Context } from "../../../../../../utils/context";
const Dashboard = () => {
  const [data, setData] = useState();
  const { userdata } = useContext(Context);
  const GetEditData = async () => {
    const response = await allCounter();
    setData(response?.data);
  };
  console.log(data, "data");
  useEffect(() => {
    GetEditData();
  }, []);

  const now = 60;
  return (
    <>
      <section className="WebsiteDashboard">
        <div className="dashboardMain">
          <div className="card searchCard">
            <div className="row searchrow">
              <div className="col-xxl-10 col-xl-8 col-lg-6">
                <InputGroup className="searchInput">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search.."
                    aria-label="Amount (to the nearest dollar)"
                  />
                </InputGroup>
              </div>
              <div className="col-xxl-2 col-xl-4 col-lg-6">
                <div className="endCont">
                  <div className="">
                    {/* <div><FontAwesomeIcon icon="fa-regular fa-star" className='me-2' />Star</div>
                                        <div>884</div> */}

                    <InputGroup className="">
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faStar} className="startxt" />
                        Star
                      </InputGroup.Text>
                      <InputGroup.Text>884</InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="profileImgMain">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/MyWebsite/Dashboard/profileImg.png"
                      }
                      className="profileImg"
                    />
                    <div className="bottomCircle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-7 col-xl-12 col-lg-12">
              <div className="card cardtwo">
                <div className="d-flex align-items-end row">
                  <div className="col-sm-7">
                    <div className="card-body">
                      <h5 className="cardhead card-title text-primary">
                        Congratulations {userdata?.f_name} {userdata?.l_name}!
                        🎉
                      </h5>
                      <p className="mb-4">
                        You have done <span className="fw-medium">72%</span>{" "}
                        more sales today. Check your new badge in your profile.
                      </p>

                      <Button className="badgesbtn btn">View Badges</Button>
                    </div>
                  </div>
                  <div className="col-sm-5 text-center text-sm-left">
                    <div className="card-body pb-0 px-0 px-md-4">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/MyWebsite/Dashboard/manImage.png"
                        }
                        height="140"
                        alt="View Badge User"
                        data-app-dark-img="illustrations/man-with-laptop-dark.png"
                        data-app-light-img="illustrations/man-with-laptop-light.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-12 col-lg-12">
              <div className="row">
                <div className="col-xxl-6 col-xl-4 col-lg-4">
                  <div className="card cardtwo">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className=" flex-shrink-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/MyWebsite/Dashboard/chart-success.png"
                            }
                            alt="chart success"
                            className="avatar"
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="cardOpt3"
                            className="p-0"
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="end"
                            aria-labelledby="cardOpt3"
                            className="carddrop"
                          >
                            <Dropdown.Item href="#">View More</Dropdown.Item>
                            <Dropdown.Item href="#">Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <span className="fw-medium d-block mb-1">Inquiry</span>
                      <h3 className="card-title mb-2 notxt">
                        {data?.total_query}
                      </h3>
                      {/* <small className="text-success fw-medium">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-up" className='me-3' />
                                                +72.80%
                                            </small> */}
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-4 col-lg-4">
                  <div className="card cardtwo">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className=" flex-shrink-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/MyWebsite/Dashboard/wallet-info.png"
                            }
                            alt="chart success"
                            className="avatar"
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="cardOpt3"
                            className="p-0"
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="end"
                            aria-labelledby="cardOpt3"
                            className="carddrop"
                          >
                            <Dropdown.Item href="#">View More</Dropdown.Item>
                            <Dropdown.Item href="#">Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <span className="fw-medium d-block mb-1">Looking Us</span>
                      <h3 className="card-title mb-2 notxt">
                        {data?.total_looking_for}
                      </h3>
                      {/* <small className="text-success fw-medium">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-up" className='me-3' />
                                                +72.80%
                                            </small> */}
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-4 col-lg-4">
                  <div className="card cardtwo">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className=" flex-shrink-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assest/images/MyWebsite/Dashboard/paypal.png"
                            }
                            alt="chart success"
                            className="avatar"
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="cardOpt3"
                            className="p-0"
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="end"
                            aria-labelledby="cardOpt3"
                            className="carddrop"
                          >
                            <Dropdown.Item href="#">View More</Dropdown.Item>
                            <Dropdown.Item href="#">Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <span className="fw-medium d-block mb-1">Contacts</span>
                      <h3 className="card-title mb-2 notxt">
                        {data?.total_contact_us}
                      </h3>
                      {/* <small className="text-success fw-medium">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-up" className='me-3' />
                                                +72.80%
                                            </small> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-8 col-xl-12 col-lg-12">
              {/* <div className='row dashboard'>
                                <div className='col-xxl-7 col-xl-7 col-lg-6'>
                                    <div className="revenue-chart">
                                        <h2>Total Revenue</h2>
                                        <BarChart
                                            xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                                            series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                                            width={480}
                                            height={300}
                                        />
                                    </div>
                                </div>
                                <div className='col-xxl-5 col-xl-5 col-lg-6'>
                                    <div className="growth">
                                        <div className="year-dropdown">
                                            <Dropdown className='yeardrop'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    2022
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">2020</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">2021</Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <CircularProgressbar
                                            value={78}
                                            text={`78%`}
                                            styles={buildStyles({
                                                pathColor: '#9b72ff',
                                                textColor: '#9b72ff',
                                            })}
                                        />
                                        <p>62% Company Growth</p>
                                        <div className="revenue-info">
                                            <div className='d-flex imgtxtinfo'>
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/paypal.png"} className='avatar' />
                                                <div className='dollarinfo'>
                                                    <p>2022</p>
                                                    <p>$32.5k</p>

                                                </div>
                                            </div>
                                            <div className='d-flex dollarinfo'>
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/paypal.png"} className='avatar' />
                                                <div className='dollarinfo'>
                                                    <p>2022</p>
                                                    <p>$32.5k</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className="col-xxl-4 col-xl-12 col-lg-12">
              <div className="row">
                {/* <div className='col-lg-6'>
                                    <div className="card cardtwo">
                                        <div className="card-body">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className=" flex-shrink-0">
                                                    <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/paypal.png"} alt="chart success" className="avatar" />
                                                </div>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="link" id="cardOpt3" className="p-0">
                                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu align="end" aria-labelledby="cardOpt3" className="carddrop">
                                                        <Dropdown.Item href="#">View More</Dropdown.Item>
                                                        <Dropdown.Item href="#">Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <span className="fw-medium d-block mb-1">Contacts</span>
                                            <h3 className="card-title mb-2 notxt">$2,456</h3>
                                            <small className="text-success fw-medium">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-up" className='me-3' />
                                                +72.80%
                                            </small>
                                        </div>
                                    </div>

                                </div> */}

                {/* <div className='col-lg-6'>
                                    <div className="card cardtwo">
                                        <div className="card-body">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className=" flex-shrink-0">
                                                    <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                                </div>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="link" id="cardOpt3" className="p-0">
                                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu align="end" aria-labelledby="cardOpt3" className="carddrop">
                                                        <Dropdown.Item href="#">View More</Dropdown.Item>
                                                        <Dropdown.Item href="#">Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <span className="fw-medium d-block mb-1">Transactions</span>
                                            <h3 className="card-title mb-2 notxt">$14,857</h3>
                                            <small className="text-success fw-medium">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-up" className='me-3' />
                                                +72.80%
                                            </small>
                                        </div>
                                    </div>
                                </div> */}

                {/* <div className='col-lg-12'>
                                    <div className="card cardtwo">
                                        <div className="card-body">
                                            <span className="fw-medium d-block mb-1">Profile Report</span>
                                            <span className="yeartxt">Year 2021</span>
                                            <div className="text-success percentagetxt fw-medium">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-up" className='me-3' />
                                                +72.80%
                                            </div>
                                            <h3 className="card-title mb-2 notxt">$12,628</h3>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>

          <div className="row">
            {/* <div className='col-lg-4 '>

                            <div className="card cardthree h-100">
                                <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                    <div className="card-title mb-0">
                                        <h5 className="m-0 me-2">Order Statistics</h5>
                                        <small className="text-muted">42.82k Total Sales</small>
                                    </div>
                                    <div className="dropdown">
                                        <button
                                            className="btn p-0"
                                            type="button"
                                            id="orderStatistics"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                                        </button>
                                        <div
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="orderStatistics"
                                        >
                                            <a className="dropdown-item" href="javascript:void(0);">
                                                Select All
                                            </a>
                                            <a className="dropdown-item" href="javascript:void(0);">
                                                Refresh
                                            </a>
                                            <a className="dropdown-item" href="javascript:void(0);">
                                                Share
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div
                                        className="d-flex justify-content-between align-items-center mb-3"
                                        style={{ position: 'relative' }}
                                    >
                                        <div className="d-flex flex-column 
 gap-1">
                                            <h2 className="mb-2 numbertext-holder">8,258</h2>
                                            <span>Total Orders</span>
                                        </div>
                                        <div className="resize-triggers">

                                            <div className="contract-trigger"></div>
                                        </div>
                                    </div>
                                    <ul className="p-0 m-0">
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Electronic</h6>
                                                    <small className="text-muted">Mobile, Earbuds, TV</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">82.5k</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Fashion</h6>
                                                    <small className="text-muted">T-shirt, Jeans, Shoes</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">23.8k</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Decor</h6>
                                                    <small className="text-muted">Fine Art, Dining</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">849k</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Sports</h6>
                                                    <small className="text-muted">Football, Cricket Kit</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">99</small>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div> */}

            <div className="col-xxl-4 col-lg-6 mb-2">
              <div class="card cardthree h-100">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h5 class="card-title m-0 me-2">Reports</h5>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="transactionID"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="transactionID"
                    >
                      <a class="dropdown-item" href="javascript:void(0);">
                        Last 28 Days
                      </a>
                      <a class="dropdown-item" href="javascript:void(0);">
                        Last Month
                      </a>
                      <a class="dropdown-item" href="javascript:void(0);">
                        Last Year
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <ul class="p-0 m-0">
                    <li class="d-flex mb-4 pb-1">
                      <div class="avatar flex-shrink-0 me-3">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/images/MyWebsite/Dashboard/chart-success.png"
                          }
                          alt="chart success"
                          className="avatar"
                        />
                      </div>
                      <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div class="me-2">
                          <small class="text-muted d-block mb-1">Inquiry</small>
                        </div>
                        <div class="user-progress d-flex align-items-center gap-1">
                          <ProgressBar
                            now={data?.total_query}
                            label={`${data?.total_query}%`}
                            visuallyHidden
                          />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mb-4 pb-1">
                      <div class="avatar flex-shrink-0 me-3">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/images/MyWebsite/Dashboard/chart-success.png"
                          }
                          alt="chart success"
                          className="avatar"
                        />
                      </div>
                      <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div class="me-2">
                          <small class="text-muted d-block mb-1">
                            Looking Us
                          </small>
                        </div>
                        <div class="user-progress d-flex align-items-center gap-1">
                          <ProgressBar
                            now={data?.total_looking_for}
                            label={`${data?.total_looking_for}%`}
                            visuallyHidden
                          />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mb-4 pb-1">
                      <div class="avatar flex-shrink-0 me-3">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/images/MyWebsite/Dashboard/chart-success.png"
                          }
                          alt="chart success"
                          className="avatar"
                        />
                      </div>
                      <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                        <div class="me-2">
                          <small class="text-muted d-block mb-1">
                            Contacts
                          </small>
                        </div>
                        <div class="user-progress d-flex align-items-center gap-1">
                          <ProgressBar
                            now={data?.total_contact_us}
                            label={`${data?.total_contact_us}%`}
                            visuallyHidden
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className='col-xxl-4 col-lg-6'>
                            <div className="card cardthree h-100">
                                <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                    <div className="card-title mb-0">
                                        <h5 className="m-0 me-2">Order Statistics</h5>
                                        <small className="text-muted">42.82k Total Sales</small>
                                    </div>
                                    <div className="dropdown">
                                        <button
                                            className="btn p-0"
                                            type="button"
                                            id="orderStatistics"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                                        </button>
                                        <div
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="orderStatistics"
                                        >
                                            <a className="dropdown-item" href="javascript:void(0);">
                                                Select All
                                            </a>
                                            <a className="dropdown-item" href="javascript:void(0);">
                                                Refresh
                                            </a>
                                            <a className="dropdown-item" href="javascript:void(0);">
                                                Share
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div
                                        className="d-flex justify-content-between align-items-center mb-3"
                                        style={{ position: 'relative' }}
                                    >
                                        <div className="d-flex flex-column 
 gap-1">
                                            <h2 className="mb-2 numbertext-holder">8,258</h2>
                                            <span>Total Orders</span>
                                        </div>
                                        <div className="resize-triggers">

                                            <div className="contract-trigger"></div>
                                        </div>
                                    </div>
                                    <ul className="p-0 m-0">
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Electronic</h6>
                                                    <small className="text-muted">Mobile, Earbuds, TV</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">82.5k</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Fashion</h6>
                                                    <small className="text-muted">T-shirt, Jeans, Shoes</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">23.8k</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Decor</h6>
                                                    <small className="text-muted">Fine Art, Dining</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">849k</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <img src={process.env.PUBLIC_URL + "/assest/images/MyWebsite/Dashboard/chart-success.png"} alt="chart success" className="avatar" />
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">Sports</h6>
                                                    <small className="text-muted">Football, Cricket Kit</small>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-medium">99</small>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
