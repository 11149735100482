import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Select from "react-select";
import JoditEditor from "jodit-react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./CommonDetails.css";
import Warrentytab from "../CommonDetails/Warrentytab/Warrentytab";
import Guarenteetab from "./Guarenteetab/Guarenteetab";
import Noservice from "./Noservice/Noservice";
import Expiry from "./Expiry/Expiry";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import Cookies from "js-cookie";
import { Context } from "../../../../../../../../utils/context";
import {
  productBrandGet,
  productCommonGet,
  productCommonPost,
} from "../../../../../../../../utils/apis/seller/Product";
import {
  Area,
  Attributes,
  Customization,
  Days,
  ProductTypes,
  ServiceType,
  AttributesPackaging,
} from "../../../../../../../../utils/apis/master/Master";
import { IDS, Paths } from "../../../../../../../../utils/common";
const CommonDetails = (props) => {
  const location = useLocation();
  const [content, setContent] = useState("");

  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [videoPreview, setVideoPreview] = useState(null);

  // Function to handle file change
  const handleFileChange = (event) => {
    setVideoPreview(null);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const videoElement = document.createElement("video");
        videoElement.src = e.target.result;
        videoElement.addEventListener("loadedmetadata", () => {
          setVideoDimensions({
            width: videoElement.videoWidth,
            height: videoElement.videoHeight,
          });
          if (videoElement.videoWidth < 512 && videoElement.videoHeight < 512) {
            setVideoPreview(videoElement.src);
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      s_product_customizations_details: [
        {
          s_attribute_id: "",
          description: "",
          document: false,
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "s_product_customizations_details",
  });

  const onSubmit = async (data) => {
    data.s_area_id = getValues("s_area_id")?.value;
    data.s_product_service_terms_details = [];

    if (getValues("s_service_term_id") == 1) {
      let warantyData = {
        s_service_term_id: 1,
        w_provided_by: data.w_provided_by,
        w_period: data.w_period,
        w_helpline: data.w_helpline,
        w_address: data.w_address,
        w_term: data.w_term,
        s_service_type_id: data.s_service_type_id,
      };
      if (data.w_provided_by.value === "other") {
        warantyData.w_provided_by = data.w_other_provided_by;
      } else {
        warantyData.w_provided_by = data.w_provided_by.value;
      }
      data.s_product_service_terms_details.push(warantyData);
    }
    if (getValues("s_service_term_id") == 2) {
      let guaranteeData = {
        s_service_term_id: 2,
        g_provided_by: data.g_provided_by,
        g_period: data.g_period,
        g_helpline: data.g_helpline,
        g_term: data.g_term,
      };
      if (data.g_provided_by.value === "other") {
        guaranteeData.g_provided_by = data.g_other_provided_by;
      } else {
        guaranteeData.g_provided_by = data.g_provided_by.value;
      }
      data.s_product_service_terms_details.push(guaranteeData);
    }

    if (getValues("s_service_term_id") == 3) {
      let noService = {
        s_service_term_id: 3,
      };
      data.s_product_service_terms_details.push(noService);
    }

    if (getValues("s_service_term_id") == 4) {
      let expiryData = {
        s_service_term_id: 4,
        expiry_date: data.expiry_date,
      };
      data.s_product_service_terms_details.push(expiryData);
    }

    var sendData = new FormData();

    sendData.append("video", data.video[0]);
    sendData.append(
      "s_product_service_terms_details",
      JSON.stringify(data.s_product_service_terms_details)
    );
    sendData.append("s_product_id", Cookies.get("s_product_id"));
    sendData.append("s_area_id", data.s_area_id);

    sendData.append("s_area", data.s_area);
    sendData.append("is_returnable", data.is_returnable);
    sendData.append("is_replaceable", data.is_replaceable);
    sendData.append("about", data.about);
    sendData.append("caution_and_safety", data.caution_and_safety);
    sendData.append("s_product_type_id", data.s_product_type_id);

    if (data.s_product_type_id == 1) {
      sendData.append("s_customization_id", data.s_customization_id);
      sendData.append(
        "s_product_customizations_details",
        JSON.stringify(data.s_product_customizations_details)
      );
    }

    if (data.is_returnable)
      sendData.append("is_returnable_day", data.is_returnable_day);
    if (data.is_replaceable)
      sendData.append("is_replaceable_day", data.is_replaceable_day);

    // Assign the modified array to data.s_product_customizations_details

    const res = await productCommonPost(sendData);
    if (res?.success) {
      props.nextStep();
    }
  };

  const validateInputs = (data) => {
    // Perform your validation logic here
    // Return true if validation passes, false otherwise
    // Example validation:
    if (!data.name) {
      setError("name", { type: "required", message: "Name is required" });
      return false;
    }

    // Add more validation logic as needed

    return true;
  };

  const { getData, IMG_URL, Select2Data, Id } = useContext(Context);
  const [days, setDay] = useState([]);
  const [supplyAreas, setSupplyArea] = useState([]);
  const [customizations, setCustomization] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [productTypes, setProductType] = useState([]);
  const [serviceTypes, setServiceType] = useState([]);
  const [attributespackaging, setAttributePackaging] = useState([]);

  const getMasters = async () => {
    {
      const res = await Days();
      if (res?.success) {
        var data = await Select2Data(res.data, "select_day", false);
        setDay(data);
      }
    }
    {
      const res = await Area();
      if (res?.success) {
        var data = await Select2Data(res.data, "supply_area", false);
        setSupplyArea(data);
      }
    }
    {
      const res = await Customization();
      if (res?.success) {
        setCustomization(res.data);
      }
    }
    {
      const res = await ProductTypes();
      if (res?.success) {
        setProductType(res.data);
      }
    }
    {
      const res = await ServiceType();
      if (res?.success) {
        setServiceType(res.data);
      }
    }
    {
      const res = await Attributes(Id, IDS.web_site_type?.outer);
      if (res?.success) {
        var data = await Select2Data(res.data, "s_attribute_id ", false);
        setAttribute(data);
      }
    }
    {
      const id = Cookies.get("s_product_id");
      if (location.pathname == Paths.o_w_seller_product) {
        const res = await AttributesPackaging(Id);
        if (res?.success) {
          var data = await Select2Data(res.data, "s_attribute_id ", false);
          setAttributePackaging(data);
        }
      }
    }
  };

  const [returnable, setReturnable] = useState(false);
  const [replaceable, setReplaceable] = useState(false);
  const [addressStatus, setAddressStatus] = useState(false);

  const getProductData = async (id) => {
    const res = await productBrandGet(id);
    if (res?.success) {
      setValue("product_title", res.data.product_title);
      setValue("s_category", res.data.s_category?.category_of_seller?.name);

      setValue(
        "s_brand",
        res.data.supply_type == "Custom" ||
          res.data.supply_type == "Self Manufactured"
          ? res.data?.s_products_supply_type?.s_custom?.name
          : res.data?.s_brand?.name
      );
    }
  };

  const getProductCommon = async (id) => {
    const res = await productCommonGet(id);
    if (res?.success) {
      reset(res.data);
      var arra =
        res.data?.s_product_customization?.s_p_customization_attributes?.map(
          (value) => ({
            s_attribute_id: {
              value: value?.attribute_packaging?.id,
              label: value?.attribute_packaging?.name,
            },
            description: value.description,
            document: value.document,
          })
        );

      if (!arra?.length) {
        arra = [
          {
            s_attribute_id: "",
            description: "",
            document: false,
          },
        ];
      }

      reset({ s_product_customizations_details: arra });
      setValue("about", res.data.about);
      setValue("caution_and_safety", res.data.caution_and_safety);
      setValue(
        "s_product_type_id",
        res.data.s_product_customization?.s_product_type_id
      );
      // setValue(
      //   "s_product_type_id",
      //   res.data.s_product_customization?.s_product_type_id
      // );
      setValue(
        "s_customization_id",
        res.data.s_product_customization?.s_customization_id
      );
      setValue("s_area_id", {
        value: res.data.s_area?.id,
        label: res.data.s_area?.name,
      });

      setValue("s_area", res.data.supply_area);
      setValue("is_returnable", res.data.s_product_policy?.is_returnable);
      setValue("is_replaceable", res.data.s_product_policy?.is_replaceable);

      setReturnable(res.data.s_product_policy?.is_returnable);
      setReplaceable(res.data.s_product_policy?.is_replaceable);

      if (res.data.s_product_policy?.is_returnable)
        setValue(
          "is_returnable_day",
          res.data.s_product_policy?.is_returnable_day
        );
      if (res.data.s_product_policy?.is_replaceable)
        setValue(
          "is_replaceable_day",
          res.data.s_product_policy?.is_replaceable_day
        );

      setVideoPreview(IMG_URL + res.data.s_product_video?.video);

      if (!res.data.s_product_service_terms?.length) {
        setValue("s_service_term_id", 1);
        setValue("s_service_term", "Warranty");
      }

      res.data.s_product_service_terms?.map((value, index) => {
        if (value.s_service_term.name === "Warranty") {
          setValue("s_service_term_id", 1);
          setValue("s_service_term", "Warranty");
          setValue("w_term", value.s_product_service_term_detail?.w_term);
          setValue(
            "w_provided_by",
            value.s_product_service_term_detail?.w_provided_by
          );
          setValue("w_period", value.s_product_service_term_detail?.w_period);
          setValue(
            "w_helpline",
            value.s_product_service_term_detail?.w_helpline
          );
          setValue("w_address", value.s_product_service_term_detail?.w_address);
          setValue(
            "s_service_type_id",
            value.s_product_service_term_detail?.s_service_type_id
          );

          setAddressStatus(value.s_product_service_term_detail?.w_address);
        } else if (value.s_service_term.name === "Guarantee") {
          setValue("s_service_term_id", 2);
          setValue("s_service_term", "Guarantee");
          setValue("g_term", value.s_product_service_term_detail?.g_term);
          setValue(
            "g_provided_by",
            value.s_product_service_term_detail?.g_provided_by
          );
          setValue("g_period", value.s_product_service_term_detail?.g_period);
          setValue(
            "g_helpline",
            value.s_product_service_term_detail?.g_helpline
          );
        } else if (value.s_service_term.name === "No Service") {
          setValue("s_service_term_id", 3);
          setValue("s_service_term", "No Service");
        } else if (value.s_service_term.name === "Expiry") {
          setValue("s_service_term_id", 4);
          setValue("s_service_term", "Expiry");
          setValue(
            "expiry_date",
            value.s_product_service_term_detail.expiry_date
          );
        } else {
          setValue("s_service_term_id", 1);
        }
      });
    }
  };
  useEffect(() => {
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      getProductData(cookie);
      getProductCommon(cookie);
    }
    getMasters();
  }, []);

  return (
    <>
      <section className="Common-details-step ">
        <div className="row">
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <div className="col-xxl-9 col-md-8">
              <div className="main-card">
                <div className="row">
                  <div className="col-xxl-4 col-lg-4 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Product Title <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">Product</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Product Title"
                        className={classNames("", {
                          "is-invalid": errors?.product_title,
                        })}
                        disabled
                        name="product_title"
                        {...register("product_title")}
                      />
                      {errors.name && (
                        <span className="text-danger">
                          {errors.product_title.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-xxl-4 col-lg-4 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Category <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-category">Category</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Category"
                        className={classNames("", {
                          "is-invalid": errors?.s_category,
                        })}
                        disabled
                        name="s_category"
                        {...register("s_category")}
                      />
                      {errors.category && (
                        <span className="text-danger">
                          {errors.s_category.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-xxl-4 col-lg-4 col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Brand <span className="star">*</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-brand">Brand</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Brand"
                        className={classNames("", {
                          "is-invalid": errors?.s_brand,
                        })}
                        disabled
                        name="s_brand"
                        {...register("s_brand")}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="main-card">
                <h5 className="text-heading">Policy</h5>
                <div className="row">
                  <div className="col-xxl-6 col-lg-6 col-md-6">
                    <div className="d-flex">
                      <p className="me-2 minitext">Is Returnable ?</p>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className={classNames("", {
                          "is-invalid": errors?.is_returnable,
                        })}
                        name="is_returnable"
                        {...register("is_returnable")}
                        onChange={(e) => setReturnable(!returnable)}
                      />
                    </div>

                    {returnable ? (
                      <div className="select-to-div">
                        <Form.Label htmlFor="inputPassword5">
                          Select Days
                          <span className="span-text">(If Returnable ?)</span>
                        </Form.Label>
                        {/* <Controller
                          name="is_returnable_day"
                          {...register("is_returnable_day", {
                            required: "selectday is required",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.is_returnable_day
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={days}
                            />
                          )}
                        /> */}
                        <Form.Control
                          type="number"
                          placeholder="Returnable Days"
                          className={classNames("", {
                            "is-invalid": errors?.is_returnable_day,
                          })}
                          name="is_returnable_day"
                          {...register("is_returnable_day", {
                            required: "Returnable Days is required",
                          })}
                          // onKeyDown={(e) => handleSupplyChange(e)}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xxl-6 col-lg-6 col-md-6">
                    <div className="d-flex">
                      <p className="me-2 minitext">Is Replaceable ?</p>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className={classNames("", {
                          "is-invalid": errors?.is_replaceable,
                        })}
                        name="is_replaceable"
                        {...register("is_replaceable")}
                        onChange={(e) => setReplaceable(!replaceable)}
                      />
                    </div>
                    {replaceable ? (
                      <div className="select-to-div">
                        <Form.Label htmlFor="inputPassword5">
                          Select Days
                          <span className="span-text">(If Replaceable ?)</span>
                        </Form.Label>
                        {/* <Controller
                          name="is_replaceable_day"
                          {...register("is_replaceable_day", {
                            required: "selectday is required",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.is_replaceable_day
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={days}
                            />
                          )}
                        /> */}
                        <Form.Control
                          type="number"
                          placeholder="Replaceable Days"
                          className={classNames("", {
                            "is-invalid": errors?.is_replaceable_day,
                          })}
                          name="is_replaceable_day"
                          {...register("is_replaceable_day", {
                            required: "Replaceable Days is required",
                          })}
                          // onKeyDown={(e) => handleSupplyChange(e)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="main-card">
                <div className="row">
                  <div className="select-to-div">
                    <Form.Label>
                      Supply Area <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">Supply Area</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Supply Area"
                      className={classNames("", {
                        "is-invalid": errors?.s_area,
                      })}
                      name="s_area"
                      {...register("s_area", {
                        required: "Supply Area is required",
                      })}
                      // onKeyDown={(e) => handleSupplyChange(e)}
                    />

                    {/* <Controller
                      name="s_area_id"
                      {...register("s_area_id", {
                        required: "Supply Area is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.s_area_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={supplyAreas}
                        />
                      )}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="main-card">
                <div className="row">
                  <div className="select-to-div">
                    <Form.Label>Product About</Form.Label>
                    <Controller
                      name="about"
                      {...register("about", {
                        required: "about is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <JoditEditor
                          value={content}
                          tabIndex={1}
                          onBlur={(newContent) => setContent(newContent)}
                          {...field}
                        />
                      )}
                    />
                    {errors.about && (
                      <span className="text-danger">
                        {errors.about.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="main-card tab-section px-3">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="Warranty"
                  activeKey={getValues("s_service_term")}
                >
                  <h6>Service Term</h6>
                  <Nav variant="pills" className="">
                    <Nav.Item
                      onClick={() => {
                        setValue("s_service_term_id", 1);
                        reset(getValues());
                      }}
                    >
                      <Nav.Link eventKey="Warranty">Warranty</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        setValue("s_service_term_id", 2);
                        reset(getValues());
                      }}
                    >
                      <Nav.Link eventKey="Guarantee">Guarantee</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        setValue("s_service_term_id", 3);
                        reset(getValues());
                      }}
                    >
                      <Nav.Link eventKey="NoService">No Service</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        setValue("s_service_term_id", 4);
                        reset(getValues());
                      }}
                    >
                      <Nav.Link eventKey="Expiry">Expiry</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="Warranty">
                      {getValues("s_service_term_id") == 1 ? (
                        <Warrentytab
                          register={register}
                          reset={reset}
                          errors={errors}
                          Controller={Controller}
                          control={control}
                          serviceTypes={serviceTypes}
                          addressStatus={addressStatus}
                          setAddressStatus={setAddressStatus}
                          getValues={getValues}
                          setValue={setValue}
                        />
                      ) : null}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Guarantee">
                      {getValues("s_service_term_id") == 2 ? (
                        <Guarenteetab
                          register={register}
                          reset={reset}
                          Controller={Controller}
                          control={control}
                          getValues={getValues}
                          setValue={setValue}
                        />
                      ) : null}
                    </Tab.Pane>
                    <Tab.Pane eventKey="NoService">
                      {getValues("s_service_term_id") == 3 ? (
                        <Noservice
                          register={register}
                          reset={reset}
                          Controller={Controller}
                          control={control}
                        />
                      ) : null}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Expiry">
                      {getValues("s_service_term_id") == 4 ? (
                        <Expiry
                          register={register}
                          reset={reset}
                          Controller={Controller}
                          control={control}
                          getValues={getValues}
                        />
                      ) : null}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>

              <div className="main-card video-sec">
                <h4 className="cust-text">Product Caution & Safety</h4>

                <Controller
                  name="caution_and_safety"
                  {...register("caution_and_safety", {
                    required: "Caution and Safety is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <JoditEditor
                      value={content}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      {...field}
                    />
                  )}
                />
              </div>

              <div className="main-card video-sec">
                <div className="row">
                  <div className="col-xxl-3 col-md-3 col-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Product Video<span className="star"></span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-title">Product Video</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-info"
                            className="ms-3"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                    </Form.Group>
                    <div className="video-descriptn">
                      {/* <h6 className="video-title">Video Resolution</h6>
                      <p className="sub-title">
                        Use clear video with a minimum resolution of 512*512 px.
                      </p> */}
                    </div>
                    <div className="video-descriptn">
                      <h6 className="video-title">Video Guidelines</h6>
                      <p className="sub-title">
                        Upload authentic product Video taken in bright lighting
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-md-6 col-3">
                    {videoPreview ? (
                      <input
                        type="file"
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                        accept="video/*"
                        name="video"
                        {...register("video")}
                        onChange={handleFileChange}
                      />
                    ) : (
                      <input
                        type="file"
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                        accept="video/*"
                        name="video"
                        {...register("video", {
                          // required: "Product Video is required",
                        })}
                        onChange={handleFileChange}
                      />
                    )}

                    {/* {videoDimensions.width > 512 ||
                    videoDimensions.height > 512 ? (
                      <sub className="text-danger">
                        Video dimensions should not exceed 512 x 512.
                      </sub>
                    ) : null} */}

                    {/* <p className="sub-title m-0">Video.mp4</p> */}
                    {/* <p className="itlic-text">File attached</p> */}
                  </div>
                  {videoPreview && (
                    <div className="col-xxl-6 col-xl-6  col-md-6 col-6">
                      <div className="video-div ">
                        <video
                          className="online-video"
                          playsInline
                          autoPlay
                          mute
                          controls
                        >
                          <source src={videoPreview}></source>
                        </video>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="main-card custooo-overflow">
                <h4 className="cust-text">Product Type</h4>
                <div className="">
                  {productTypes?.map(
                    (value, index) =>
                      (value?.name === "General" ||
                        value?.name === "On-Demand Product") && (
                        <Form.Check
                          inline
                          type="radio"
                          label={value?.name}
                          value={value?.id}
                          defaultChecked={
                            value.id === getValues("s_product_type_id")
                              ? true
                              : false
                          }
                          name="s_product_type_id"
                          className={classNames("my-2", {
                            "is-invalid": errors?.s_product_type_id,
                          })}
                          {...register("s_product_type_id", {
                            required: "Customization is required",
                          })}
                          onChange={(e) => {
                            setValue("s_product_type_id", e.target.value);
                            reset(getValues());
                          }}
                        />
                      )
                  )}
                </div>

                {getValues("s_product_type_id") == 1 ? (
                  <>
                    <h4 className="cust-text">Customization</h4>
                    <div className="">
                      {customizations?.map((value, index) => (
                        <Form.Check
                          inline
                          type="radio"
                          label={value?.name}
                          value={value?.id}
                          defaultChecked={
                            value.id === getValues("s_customization_id")
                              ? true
                              : false
                          }
                          name="s_customization_id"
                          className={classNames("my-2", {
                            "is-invalid": errors?.s_customization_id,
                          })}
                          {...register("s_customization_id", {
                            required: "Customization is required",
                          })}
                        />
                      ))}
                    </div>

                    {fields?.map((value, index) => (
                      <>
                        <div className="row mt-3">
                          <div className="col-xxl-5 col-md-5 col-5">
                            <div className="select-to-div">
                              <Form.Label htmlFor="inputPassword5">
                                Select Attributes for Packaging{" "}
                                <span className="star">*</span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-category">
                                      Attributes
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-info"
                                    className="ms-3"
                                  />
                                </OverlayTrigger>
                              </Form.Label>
                              {/* <Select placeholder="please select" options={attribute} /> */}
                              <Controller
                                name={`s_product_customizations_details[${index}].s_attribute_id`}
                                {...register(
                                  `s_product_customizations_details[${index}].s_attribute_id`,
                                  {
                                    required: "Packaging is required",
                                  }
                                )}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.s_attribute_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={attributespackaging}
                                  />
                                )}
                              />
                            </div>
                            <div className="mt-3">
                              <p className="mb-1 minitext">Document</p>

                              <Controller
                                control={control}
                                name={`s_product_customizations_details[${index}].document`}
                                render={({ field }) => (
                                  <Form.Check
                                    type="switch"
                                    id={`custom-switch-${index}`}
                                    className={classNames("", {
                                      "is-invalid":
                                        errors.data &&
                                        errors.data[index] &&
                                        errors.data[index].document,
                                    })}
                                    name={`s_product_customizations_details[${index}].document`}
                                    checked={field.value} // Set the checked attribute based on field value
                                    onChange={(e) =>
                                      field.onChange(e.target.checked)
                                    } // Call field.onChange to update value
                                    {...field} // Spread the field object to ensure onChange, onBlur, and value are passed
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-md-6 col-6">
                            <div>
                              <Form.Label>
                                Attributes for Packaging Description
                              </Form.Label>

                              <Controller
                                name={`s_product_customizations_details[${index}].description`}
                                {...register(
                                  `s_product_customizations_details[${index}].description`,
                                  {
                                    required: "Description is required",
                                  }
                                )}
                                control={control}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={content}
                                    tabIndex={1}
                                    onBlur={(newContent) =>
                                      setContent(newContent)
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-1 col-md-1 col-1 align-items-center d-flex mt-auto">
                            <button
                              className="addrowiccc"
                              type="button"
                              onClick={() =>
                                append({
                                  s_attribute_id: "",
                                  description: "",
                                  document: false,
                                })
                              }
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                                }
                              />
                            </button>
                            {index !== 0 && (
                              <button
                                className="delet-row"
                                onClick={() => remove(index)}
                                type="button"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-minus" />
                              </button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="common-steps-btn">
                {/* <button type="button" onClick={props.handlePrevious} className='back-btn'>Back</button> */}
                <Button onClick={props.handlePrevious} className=" back-btn">
                  back
                </Button>

                <Button
                  className="next-btn"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save & Next
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default CommonDetails;
