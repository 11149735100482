import React from 'react';
import Lottie from 'react-lottie';
// import './menu.css';
import * as animationData from '../Animation/Animation - 1713356172598.json';
import './google_meet.css'

function Google_meet() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className='main'>
            <div className='googleicon-btn'>
                <Lottie className="me-auto"
                    options={defaultOptions}

                />
            </div>
        </section>
    );
}
export default Google_meet;