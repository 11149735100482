import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

import { formatDate } from "../Product.js";

export const getOuterWebSiteAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  status = "",
  category_id = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/outer-website/order-process/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getOuterWebSiteAllOrderStatus = async () => {
  try {
    return await getData(
      `/outer-website/order-process/all-order-status`
    );
  } catch (error) {
    console.log(error);
  }
};

export const postOuterWebSiteAllOrderStatus = async (data,order) => {
  
  try {
    return await postData(
      `/outer-website/order-process/all-order-status`,{ ids: data ,order:order?.value},);
  } catch (error) {
    console.log(error);
  }
};


