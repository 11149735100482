import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BestSellersCategories.css";
import React, { useContext, useEffect, useState } from "react";
import Furniture from "./Furniture/Furniture";
import Beauty from "./Beauty/Beauty";
import { Context } from "../../../../utils/context";
import { getBestSellersCategories } from "../../../../utils/apis/btb/Home_Page";

function BestSellersCategories() {
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getBestSellersCategories());
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="BestSellersCategories">
        <div className="container">
          <div className="heading-holder">
            <h4 className="heading">Best Sellers Categories</h4>
            <div className="subtitle-holder">
              <p>Browse our diverse array of product</p>
              {/* <p>
                See all categories
                <FontAwesomeIcon
                  className="ms-2"
                  icon="fa-solid fa-angle-right"
                />
              </p> */}
            </div>
          </div>
          {data?.data?.map((data, index) => (
            <div className="BestSellersCategories-slider-sec">
              <Furniture data={data} />
            </div>
          ))}

          {/* <div className="BestSellersCategories-slider-sec">
            <Beauty />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BestSellersCategories;
