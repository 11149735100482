import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllUserMotivation = async (currentPage, perPage, search,searchStatus) => {
  try {
    let url = `/seller/outer-website/masters/user-motivation?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if(searchStatus?.value || searchStatus?.value == 0){
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeUserMotivationStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/outer-website/masters/user-motivation/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteUserMotivation = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/outer-website/masters/user-motivation/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addUserMotivation = async (finalData) => {
  try {
    return await postData(
      `/seller/outer-website/masters/user-motivation`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const editUserMotivation = async (id, finalData) => {
  try {
    return await postData(
      `/seller/outer-website/masters/user-motivation/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleUserMotivation = async (id) => {
  try {
    return await getData(`/seller/outer-website/masters/user-motivation/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
