import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
// import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../Master/Brand/Tables";
import FAQ from "../../Master/Faq/Table";
import SubFaq from "../../Master/SubFaq/Table";
// import WorkingType from "../Master/WorkingType/Table";
import Category from "../../Master/Category/Table";
import SubCategory from "../../Master/SubCategory/Table";
import ChildCategory from "../../Master/ChildCategory/Table";
import ChildSubCategory from "../../Master/ChildSubCategory/Table";
import Blogs from "../../Master/Blogs/Tables";
// import CouponCodes from "../../Master/CouponCodes/Table";
// import TaxType from "../Master/Tax_Types/Tables";
// import TaxPercentage from "../Master/Tax_Percentage/Tables";
import WebHeader from "../../Master/WebHeader/Tables";
import AppSetup from "../../Master/AppSetup/Tables";
// import ContactUs from "../Master/contact_us/Tables";
import Users from "../../Master/users/Tables";
import CrazyDeals from "../../Master/CrazyDeals/Table";
import ExclusiveCollection from "../../Master/ExclusiveCollection/Table";
import Attribute from "../../Master/Attribute/Table";
import SubAttribute from "../../Master/SubAttribute/Table";
import AttributePackaging from "../../Master/AttributePackaging/Table";
import ShopTrends from "../../Master/ShopTrends/Table";
import HomePageBanner from "../../Master/HomePageBanner/Table";
// import AboutUsBanner from "../../Master/banner/Tables";
import OurStory from "../../Master/OurStory/Tables";
import HappyCustomer from "../../Master/HappyCustomer/Tables";
import WhyChoose from "../../Master/whyChoose/Tables";
import PopularProduct from "../../Master/PopularProduct/Table";
import SaleBanner from "../../Master/SaleBanner/Table";
import CategoryBanner from "../../Master/CategoryBanner/Table";
import UserMotivation from "../../Master/UserMotivation/Table";
import SocialMedia from "../../Master/SocialMedia/Table";

import ReturnPolicy from "../../Master/ReturnPolicy/Edit";
import AboutUsBanner from "../../Master/banner/Edit";
import "./Setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TadayDeal from "../../Master/TodayDeal/Table";
import BestSelling from "../../Master/BestSelling/Table";
import {
  faAddressCard,
  faArrowRightArrowLeft,
  faCircleQuestion,
  faCodeBranch,
  faGlobe,
  faList,
  faListAlt,
  faQuestion,
  faTableCellsLarge,
  faTree,
  faUser,
  faUsers,
  faWindowRestore,faHouseLock
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import {
  faBandcamp,
  faBloggerB,
  faIdeal,
  faOptinMonster,
  faProductHunt,
} from "@fortawesome/free-brands-svg-icons";

const Setting = () => {
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState("0");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };
  const tabsArray = [
    {
      key: 0,
      name: (
        <>
          <FontAwesomeIcon icon={faBandcamp} />
        </>
      ),
      link: "masters/brand",
      component: <Brand />,
      tooltip: "Brand",
    },
    {
      key: 1,
      name: (
        <>
          <FontAwesomeIcon icon={faCircleQuestion} />
        </>
      ),
      link: "masters/faq",
      component: <FAQ />,
      tooltip: "FAQ",
    },
    {
      key: 2,
      name: (
        <>
          <FontAwesomeIcon icon={faQuestion} />
        </>
      ),
      link: "masters/sub-faq",
      component: <SubFaq />,
      tooltip: "Sub FAQ",
    },

    {
      key: 3,
      name: (
        <>
          <FontAwesomeIcon icon={faBloggerB} />
        </>
      ),
      link: "masters/blogs",
      component: <Blogs />,
      tooltip: "Blogs",
    },
    {
      key: 4,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
        </>
      ),
      link: "masters/users",
      component: <Users />,
      tooltip: "Users",
    },

    {
      key: 5,
      name: (
        <>
          <FontAwesomeIcon icon={faIdeal} />
        </>
      ),
      link: "masters/crazy-deals",
      component: <CrazyDeals />,
      tooltip: "Crazy Deals",
    },
    {
      key: 6,
      name: (
        <>
          <FontAwesomeIcon icon={faListAlt} />
        </>
      ),
      link: "masters/exclusive-collections",
      component: <ExclusiveCollection />,
      tooltip: "Exclusive Collection",
    },

    {
      key: 7,
      name: (
        <>
          <FontAwesomeIcon icon={faWindowRestore} />
        </>
      ),
      link: "masters/about-our-story",
      component: <ExclusiveCollection />,
      tooltip: "Our Story",
    },
    {
      key: 8,
      name: (
        <>
          <FontAwesomeIcon icon={faUsers} />
        </>
      ),
      link: "masters/happy-customer",
      component: <HappyCustomer />,
      tooltip: "Happy Customer",
    },
    {
      key: 9,
      name: (
        <>
          <FontAwesomeIcon icon={faOptinMonster} />
        </>
      ),
      link: "masters/why-choose",
      component: <WhyChoose />,
      tooltip: "Why Choose Us",
    },
    {
      key: 10,
      name: (
        <>
          <FontAwesomeIcon icon={faTree} />
        </>
      ),
      link: "masters/shop-trends",
      component: <ShopTrends />,
      tooltip: "Shop Trends",
    },

    {
      key: 11,
      name: (
        <>
          <FontAwesomeIcon icon={faProductHunt} />
        </>
      ),
      link: "masters/popular_product",
      component: <PopularProduct />,
      tooltip: "Popular Product",
    },
    {
      key: 12,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
        </>
      ),
      link: "masters/user-motivation",
      component: <UserMotivation />,
      tooltip: "User Motivation",
    },
    {
      key: 13,
      name: (
        <>
          <FontAwesomeIcon icon={faGlobe} />
        </>
      ),
      link: "masters/social-media",
      component: <SocialMedia />,
      tooltip: "Social Media",
    },
    {
      key: 14,
      name: (
        <>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
        </>
      ),
      link: "masters/return-policy",
      component: <ReturnPolicy />,
      tooltip: "Return Policy",
    },
    {
      key: 15,
      name: (
        <>
          <FontAwesomeIcon icon={faAddressCard} />
        </>
      ),
      link: "masters/about-us-banner",
      component: <AboutUsBanner />,
      tooltip: "About Us",
    },
    {
      key: 16,
      name: (
        <>
          <FontAwesomeIcon icon={faAddressCard} />
        </>
      ),
      link: "masters/today-deal",
      component: <TadayDeal />,
      tooltip: "Today Deal",
    },
    {
      key: 17,
      name: (
        <>
          <FontAwesomeIcon icon={faHouseLock} />
        </>
      ),
      link: "masters/best-selling",
      component: <BestSelling />,
      tooltip: "Best Selling",
    },
  ];

  // const tabContent = [
  //   { key: 0, component: <Brand /> },
  //   { key: 1, component: <FAQ /> },
  //   { key: 2, component: <SubFaq /> },
  //   { key: 3, component: <Blogs /> },
  //   { key: 4, component: <Users /> },
  //   { key: 5, component: <CrazyDeals /> },
  //   { key: 6, component: <ExclusiveCollection /> },
  //   { key: 7, component: <OurStory /> },
  //   { key: 8, component: <HappyCustomer /> },
  //   { key: 9, component: <WhyChoose /> },
  //   { key: 10, component: <ShopTrends /> },
  //   { key: 11, component: <PopularProduct /> },
  //   { key: 12, component: <UserMotivation /> },
  //   { key: 13, component: <SocialMedia /> },
  //   { key: 14, component: <ReturnPolicy /> },
  //   { key: 15, component: <AboutUsBanner /> },
  // ];

  // const [activeTab, setActiveTab] = useState(0);
  // const onTabClick = (e, index) => {
  //   console.log(e);
  //   setActiveTab(index);
  // };
  // const tabsArray = [...Array(20).keys()];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );

  return (
    <>
      {/* <Tabs activeTab={activeTab} onTabClick={onTabClick}>
        {tabsArray.map((item, index) => (
          <Tab key={item.eventKey} eventKey={item.key}>
            {item.name}
          </Tab>
        ))}
      </Tabs>

      {tabContent.map((item) => (
        <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
          {item.component}
        </TabScreen>
      ))} */}
      <div className="sidebar-tab-holder">
        <Tab.Container
          className="sidebar-tab-holder"
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(e) => setActiveTab(e)}
        >
          <Row>
            <Col sm={1} className="col-width scale-up-hor-left">
              <Nav variant="pills" className="flex-column">
                {tabsArray.map((item) => (
                  <OverlayTrigger
                    key={item.key}
                    placement="right"
                    delay={{ show: 200, hide: 0 }}
                    overlay={renderTooltip({ tooltip: item.tooltip })}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ))}
              </Nav>
            </Col>
            <Col sm={11} className="col2-width">
              <Tab.Content>
                {tabsArray.map((item) => (
                  <Tab.Pane eventKey={item.key} key={item.key}>
                    {item.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Setting;
