import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllPopularProduct = async (currentPage, perPage, search,searchStatus) => {
  try {
    let url = `/seller/inquiry-website/masters/popular-product?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if(searchStatus?.value || searchStatus?.value == 0){
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changePopularProductStatus = async (id) => {
  try {
    return await editStatusData(
      `/seller/inquiry-website/masters/popular-product/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const deletePopularProduct = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/inquiry-website/masters/popular-product/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addPopularProduct = async (finalData) => {
  try {
    return await postData(
      `/seller/inquiry-website/masters/popular-product`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const editPopularProduct = async (id, finalData) => {
  try {
    return await postData(
      `/seller/inquiry-website/masters/popular-product/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsinglePopularProduct = async (id) => {
  try {
    return await getData(`/seller/inquiry-website/masters/popular-product/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
