import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const loginCart = async () => {
  let cartItems = [];
  const existingCart = Cookies.get('btb_cart');
  if (existingCart) {
    cartItems = JSON.parse(existingCart);
  }
  console.log("cartItems", cartItems);
  if (cartItems.length > 0) {
    await Promise.all(cartItems.map(async (value) => {
      console.log("value", value);
      const data = await postData(`/btb/cart/add`, {
        product_id: value?.product_id,
        seller_id: value?.seller_id,
        s_p_v_a_d_id: value?.s_p_v_a_d_id,
        s_p_v_a_one_d_a_d_id: value?.s_p_v_a_one_d_a_d_id,
        s_p_v_a_o_d_a_d_a_d_id: value?.s_p_v_a_o_d_a_d_a_d_id,
        quantity: value?.quantity,
        min_quantity: value?.min_quantity,
      });
      console.log("datahhhhhhh", data);
    }));
  }
  await Cookies.remove('btb_cart');
}


export const btobCartAdd = async (data) => {
  try {
    if (Cookies.get('net_purti_security')) {
      const res = await postData(`/btb/cart/add`, data);
      return true;
    } else {

      let cartItems = [];

      const existingCart = Cookies.get('btb_cart');
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex(item =>
        item.product_id === data?.product_id &&
        item.seller_id === data?.seller_id &&
        item.s_p_v_a_d_id === data?.s_p_v_a_d_id &&
        item.s_p_v_a_one_d_a_d_id === data?.s_p_v_a_one_d_a_d_id &&
        item.s_p_v_a_o_d_a_d_a_d_id === data?.s_p_v_a_o_d_a_d_a_d_id
      );

      if (existingItemIndex === -1) {
        const newItem = {
          cart_id: uuidv4(), // Generate a unique ID
          product_id: data.product_id,
          seller_id: data.seller_id,
          s_p_v_a_d_id: data.s_p_v_a_d_id,
          s_p_v_a_one_d_a_d_id: data.s_p_v_a_one_d_a_d_id,
          s_p_v_a_o_d_a_d_a_d_id: data.s_p_v_a_o_d_a_d_a_d_id,
          min_quantity: data.min_quantity,
          quantity: data.min_quantity,
          selling_price: data.selling_price,
          sku_id: data.sku_id,
          product_title: data.product_title,

        }
        cartItems.push(newItem);
      } else {

        cartItems[existingItemIndex].quantity++;
      }
      Cookies.set('btb_cart', JSON.stringify(cartItems));
    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const btobCartList = async (data) => {
  console.log("All");
  try {
    const res = await getData(`/btb/cart/show`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobCartStatus = async (id, data) => {

  try {
    const res = await postData(`/btb/cart/status/${id}`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};


export const btobseletedCartList = async (data) => {
  console.log("seleted");
  try {
    const res = await getData(`/btb/cart/seleted-show`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const btobCartQuentity = async (data) => {
  try {
    if (Cookies.get('net_purti_security')) {
      await postData(`/btb/cart/quantity`, data);
      return true;
    } else {

      let cartItems = [];

      const existingCart = Cookies.get('btb_cart');
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex(item => item.cart_id === data?.cart_id);
      if (existingItemIndex === -1) {

      } else {

        if (cartItems[existingItemIndex]?.quantity === 1 && data?.type == 'minus') {
          cartItems.splice(existingItemIndex, 1);
        } else {
          if (data?.type == 'minus') {
            cartItems[existingItemIndex].quantity -= 1;
          } else {
            cartItems[existingItemIndex].quantity += 1;
          }
        }
        Cookies.set('btb_cart', JSON.stringify(cartItems));
      }
    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const btobCartRemove = async (cart_id) => {
  try {
    if (Cookies.get('net_purti_security')) {
      await getData(`/btb/cart/remove?id=${cart_id}`);
      return true;
    } else {

      let cartItems = [];
      const existingCart = Cookies.get('btb_cart');
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }
      const updatedCart = cartItems.filter(item => item.cart_id !== cart_id);

      if (updatedCart.length > 0) {
        Cookies.set('btb_cart', JSON.stringify(updatedCart));
      } else {
        Cookies.remove('btb_cart');
      }
      return true;
    }
  } catch (error) {
    console.log(error);
    return false; // Return false if there's an error
  }
};
