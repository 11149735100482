import { React, useContext, useEffect, useState } from "react";
import "./NewArrivals.css";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Grid, Pagination, Navigation } from "swiper/modules";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../../../utils/context";
import { getNew_Arrivals } from "../../../../../utils/apis/btb/Home_Page";

function NewArrivals() {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getNew_Arrivals());
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="NewArrivals">
        <Swiper
          slidesPerView={4}
          grid={{
            rows: 2,
          }}
          spaceBetween={25}
          pagination={{
            clickable: true,
          }}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[Grid, Pagination, Navigation]}
          className="NewArrivals_Swiper"
          breakpoints={{
            280: {
              slidesPerView: 2,
            },

            320: {
              slidesPerView: 2,
            },

            768: {
              slidesPerView: 2,
            },

            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          {/* Map over the imagePaths array and generate SwiperSlides */}
          {data?.data?.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="NewArrivals-img-holder">
                <img
                  className="NewArrivals-img"
                  src={IMG_URL + image.thumbnail}
                  alt={`NewArrivals-img-${index}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          className="navigation-arrow-holder"
          onClick={() => handleMainSliderClick("prev")}
        >
          <div className="swiper-button-prev">
            <FontAwesomeIcon className="navigation-arrow " icon={faArrowLeft} />
          </div>
        </div>
        <div
          className="navigation-arrow-holder"
          onClick={() => handleMainSliderClick("next")}
        >
          <div className="swiper-button-next">
            <FontAwesomeIcon
              className="navigation-arrow "
              icon={faArrowRight}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default NewArrivals;
