import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";

import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import classNames from "classnames";

import Select from "react-select";
import { Context } from "../../../../utils/context";
import {
  SocialPost,
  getSocial,
  SocialUrlPost,
  getSocialUrl,
} from "../../../../utils/apis/affiliate/affiliate";

library.add(fas);
function Steptwo({ affiliateID, ...props }) {

  useEffect(() => {
    console.log("props :-", props);
    console.log("afiliate id :-", affiliateID);
  }, [props]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [platformCount, setPlatformCount] = useState(1);
  const [websiteCount, setWebsiteCount] = useState(1);

  const handleAddPlatform = () => {
    setPlatformCount((prevCount) => prevCount + 1);
  };

  const handleRemovePlatform = () => {
    setPlatformCount((prevCount) => prevCount - 1);
  };

  const handleAddWebsite = () => {
    setWebsiteCount((prevCount) => prevCount + 1);
  };

  const handleRemoveWebsite = () => {
    setWebsiteCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data1: [
        {
          platform: "",
          page_link: "",
          page_name: "",
        }
      ],
      data2: [
        {
          website_url: "",
        }
      ]
    }
  });

  const {
    fields: fields1,
    append: append1,
    remove: remove1,
  } = useFieldArray({
    control,
    name: "data1",
  });
  const {
    fields: fields2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    control,
    name: "data2",
  });

  const onSubmit = async (d) => {
    d.a_personal_details_id = affiliateID;
    const res1 = await SocialPost(d);
    const res2 = await SocialUrlPost(d);

    if (res1?.success && res2?.success) {
      props.nextStep();
    }
  };

  const getSocialIn = async () => {
    try {
      const res1 = await getSocial(affiliateID);
      const res2 = await getSocialUrl(affiliateID);

      if (res1?.success && res2?.success) {
        const Data1 = [...res1.data];
        const Data2 = [...res2.data];

        let formattedData = [];
        let formattedData2 = [];

        if (Data1.length > 0) {
          formattedData = Data1.map((item, index) => ({
            platform: item.platform,
            page_link: item.page_link,
            page_name: item.page_name,
          }));
        }

        if (Data2.length > 0) {
          formattedData2 = Data2.map((item, index) => ({
            website_url: item.website_url,
          }));
        }

        reset({
          data1: [...formattedData],
          data2: [...formattedData2],
        });
      } else {
        reset({
          data1: [
            {
              platform: "",
              page_link: "",
              page_name: "",
            }
          ],
          data2: [
            {
              website_url: "",
            }
          ]
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(getValues());
  useEffect(() => {
    getSocialIn();
  }, []);

  return (
    <>
      <div className="personal-details-form ">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="form-container">
            <Form onSubmit={handleSubmit(onSubmit)} role="form">
              {/* Current Industry , Job Title*/}
              <div className=" ">
                {fields1.map((item, index) => (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Platform</Form.Label>
                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data1 &&
                                  errors.data1[index] &&
                                  errors.data1[index].platform,
                              })}
                              name={`data1[${index}].platform`}
                              placeholder="Enter Name of Platform"
                              defaultValue={item.platform}
                              {...register(`data1[${index}].platform`, {
                                required: "Name of platform is required",
                              })}
                            />
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Page Link</Form.Label>
                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data1 &&
                                  errors.data1[index] &&
                                  errors.data1[index].page_link,
                              })}
                              name={`data1[${index}].page_link`}
                              placeholder="Enter Link"
                              defaultValue={item.page_link}
                              {...register(`data1[${index}].page_link`, {
                                required: "Link is required",
                              })}
                            />
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-md-4">
                        <Form.Group controlId="">
                          <Form.Label>Page Name</Form.Label>

                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data1 &&
                                  errors.data1[index] &&
                                  errors.data1[index].page_name,
                              })}
                              name={`data1[${index}].page_name`}
                              placeholder="Enter Name of Page"
                              defaultValue={item.page_name}
                              {...register(`data1[${index}].page_name`, {
                                required: "Name of Page is required",
                              })}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      {index !== 0 && (
                        <div className="addremoveadress">
                          <div className="text-end Addnewadresstxt">
                            <p className="" onClick={() => remove1(index)}>
                              Remove Address{" "}
                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}

                <div className="addremoveadress">
                  {/* <div
                  className="text-end Addnewadresstxt"
                  onClick={handleRemovePlatform}
                >
                  <p className="">
                    Remove new Platform  {" "}
                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                  </p>
                </div> */}
                  <div
                    className="text-end Addnewadresstxt"
                    onClick={() =>
                      append1({
                        platform: "",
                        page_link: "",
                        page_name: "",
                      })
                    }
                  >
                    <p className="">
                      Add new Platform{" "}
                      <FontAwesomeIcon icon="fa-solid fa-plus" />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Form.Group controlId="">
                      <Form.Label>Website URL</Form.Label>
                      {fields2.map((item, index) => (
                        <>
                          <div className="verified">
                            <Form.Control
                              type="text"
                              className={classNames("", {
                                "is-invalid":
                                  errors.data2 &&
                                  errors.data2[index] &&
                                  errors.data2[index].website_url,
                              })}
                              name={`data2[${index}].website_url`}
                              placeholder="Enter Website url"
                              defaultValue={item.website_url}
                              {...register(`data2[${index}].website_url`, {
                                required: "URL required",
                              })}
                            />
                          </div>
                          {index !== 0 && (
                            <div className="addremoveadress">
                              <div className="text-end Addnewadresstxt">
                                <p className="" onClick={() => remove2(index)}>
                                  Remove Address{" "}
                                  <FontAwesomeIcon icon="fa-solid fa-minus" />
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </Form.Group>

                    <div className="addremoveadress">
                      {/* <div
                          className="text-end Addnewadresstxt"
                          onClick={handleRemoveWebsite}
                        >
                          <p className="">
                            Remove new Website  {" "}
                            <FontAwesomeIcon icon="fa-solid fa-minus" />
                          </p>
                        </div> */}
                      <div
                        className="text-end Addnewadresstxt"
                        onClick={() =>
                          append2({
                            website_url: "",
                          })
                        }
                      >
                        <p className="">
                          Add new Website{" "}
                          <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="text-end apply_now_btn">
                    {/* <p onClick={() => setModalShow(true)}>
                      By continuing, I agree to Netpurti's
                      <span className="termstxt">Terms of Use & Privacy Policy</span>
                    </p> */}

                    <Button onClick={props.prevStep} className="back-btn">
                      Back
                    </Button>

                    <Button
                      type="submit"
                      // onClick={nextStep}
                      className=" tabs-btn"
                    >
                      Register & Continue
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>

          {/* <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p>

              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

              <Button onClick={props.nextStep} className=" tabs-btn">
                Register & Continue
              </Button>
            </div>
          </div> */}

          <TermsAndConditionModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Steptwo;
