import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CommonDetails from "./CommonDetails/CommonDetails";
import Variants from "./Variants/Variants";
import ProductInfo from "./ProductInfo/ProductInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import "../CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal.css";
import Animation from "../Animation/SuccessAnimation/Animation";

const ProductSave = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="Successful-img">
              <div className="lotty-div">
                <Animation />
              </div>
            </div>

            <h2>Product Created Successfully...!</h2>
          </Modal.Title>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductSave;
