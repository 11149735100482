import React, {useContext, useEffect, useState} from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";

const DepositModal = (props) => {

 


const {IMG_URL} = useContext(Context);

const [error, setError] = useState("");

  const onSubmit = async () => {
      
      if(props.depositAmount == 0){
        setError("Amount should not be zero");
      }else{
          props.handleRazorpay();
          props.onHide();
      }
    }
    useEffect(() => {
      setError("");
    },[props.show]);
  return (
    <>
      <Modal className='mrp-modal' {...props} centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-10'>
              {/* <h6 className='gamimg-h6'>Gaming Keyboard <span>(SKU ID:KS34353)</span> </h6> */}
            </div>
            <div className='col-2'>
              {/* <img className='dgfahdg' src={IMG_URL + props?.data?.s_p_v_a_one_d_a_d_a_d_images[0]?.image} /> */}
            </div>
          </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                placeholder="Enter Amount"
                defaultValue={props.depositAmount}
                onChange={(e) => {props.setAmount(e.target.value); setError("");}}
              />


            </Form.Group>
              
            <button className="update-btn" type="button" onClick={onSubmit}>Deposit</button>
     
          <span className="text-danger">{error}</span>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepositModal;
