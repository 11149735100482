// NewNotification.jsx
import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./NewNotification.css"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


const NewNotification = ({ shownoti, handleClosenoti }) => {
    const quotationData = [
        {
            companyName: 'Zungei Pvt. Ltd.',
            timeAgo: '1 minutes ago',
            imageSrc: "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png"
        },
        {
            companyName: 'Profcyma Pvt. Ltd.',
            timeAgo: '5 minutes ago',
            imageSrc: "/assest/images/B2Bbuyer/B2BSellerDetail/customer.png"
        },
        
    ];
    return (
        <>
            <div className='b2b-buyer-notificaton'>
                <Offcanvas show={shownoti} onHide={handleClosenoti} backdrop="static" placement='end' className="b2b-buyer-notificaton-off">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Notifications</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <img
                            className="notification-img"
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/images/seller-image/Header/drop.png"
                            }
                            alt="Logo"
                        />
                        <div className='my-3 nav-div'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="pills" className="">
                                    <Nav.Item className="me-2">
                                        <Nav.Link eventKey="first">All</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="me-2">
                                        <Nav.Link eventKey="second">Payment</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="me-2">
                                        <Nav.Link eventKey="third">General</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="me-2">
                                        <Nav.Link eventKey="forth">Daily</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="me-2">
                                        <Nav.Link eventKey="fifth">Transportation</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div>
                                            {quotationData.map((quotation, index) => (
                                                <div className='flex-div' key={index}>
                                                    <div className='img-div'>
                                                        
                                                        <img
                                                            className="profile-pic me-2"
                                                            src={process.env.PUBLIC_URL + quotation.imageSrc}
                                                            alt="Logo"
                                                        />
                                                        <div className='dot' />
                                                    </div>
                                                    <div>
                                                        <span className='from-text'>Quotation Received from </span>
                                                        <span className='com-name'>{quotation.companyName}</span>
                                                        <p className='grey-text'>{quotation.timeAgo}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                    <div>
                                            {quotationData.map((quotation, index) => (
                                                <div className='flex-div' key={index}>
                                                    <div className='img-div'>
                                                        
                                                        <img
                                                            className="profile-pic me-2"
                                                            src={process.env.PUBLIC_URL + quotation.imageSrc}
                                                            alt="Logo"
                                                        />
                                                        <div className='dot' />
                                                    </div>
                                                    <div>
                                                        <span className='from-text'>Quotation Received from </span>
                                                        <span className='com-name'>{quotation.companyName}</span>
                                                        <p className='grey-text'>{quotation.timeAgo}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third"><div>
                                            {quotationData.map((quotation, index) => (
                                                <div className='flex-div' key={index}>
                                                    <div className='img-div'>
                                                        
                                                        <img
                                                            className="profile-pic me-2"
                                                            src={process.env.PUBLIC_URL + quotation.imageSrc}
                                                            alt="Logo"
                                                        />
                                                        <div className='dot' />
                                                    </div>
                                                    <div>
                                                        <span className='from-text'>Quotation Received from </span>
                                                        <span className='com-name'>{quotation.companyName}</span>
                                                        <p className='grey-text'>{quotation.timeAgo}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div></Tab.Pane>
                                    <Tab.Pane eventKey="forth"><div>
                                            {quotationData.map((quotation, index) => (
                                                <div className='flex-div' key={index}>
                                                    <div className='img-div'>
                                                        
                                                        <img
                                                            className="profile-pic me-2"
                                                            src={process.env.PUBLIC_URL + quotation.imageSrc}
                                                            alt="Logo"
                                                        />
                                                        <div className='dot' />
                                                    </div>
                                                    <div>
                                                        <span className='from-text'>Quotation Received from </span>
                                                        <span className='com-name'>{quotation.companyName}</span>
                                                        <p className='grey-text'>{quotation.timeAgo}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div></Tab.Pane>
                                    <Tab.Pane eventKey="fifth"><div>
                                            {quotationData.map((quotation, index) => (
                                                <div className='flex-div' key={index}>
                                                    <div className='img-div'>
                                                        
                                                        <img
                                                            className="profile-pic me-2"
                                                            src={process.env.PUBLIC_URL + quotation.imageSrc}
                                                            alt="Logo"
                                                        />
                                                        <div className='dot' />
                                                    </div>
                                                    <div>
                                                        <span className='from-text'>Quotation Received from </span>
                                                        <span className='com-name'>{quotation.companyName}</span>
                                                        <p className='grey-text'>{quotation.timeAgo}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div></Tab.Pane>

                                </Tab.Content>

                            </Tab.Container>
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>
            </div>

        </>
    );
};

export default NewNotification;
