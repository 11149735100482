import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Stepthree.css";
import InnerPageTwo from "./InnerPageTwo/InnerPageTwo";
import InnerPageOne from "./InnerPageOne/InnerPageOne";

function Stepthree({ setPage, innerNext, affiliateDetails, affiliateID }) {
  const [activeTab, setActiveTab] = useState("office_address");
  const [selectedOption, setSelectedOption] = useState(null);

  const [firmName, setFirmname] = useState("");
  const [panData, setPanData] = useState();

  const navItems = [
    { eventKey: "office_address", label: "Office Address" },
    { eventKey: "communiaction_address", label: "Communication Address" },
  ];

  const handleSaveAndNext = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );

    if (currentIndex < navItems.length - 1) {
      const nextTab = navItems[currentIndex + 1].eventKey;
      setActiveTab(nextTab);
    } else {
      setPage(4);
    }
  };

  const handleBack = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );

    if (currentIndex > 0) {
      const prevTab = navItems[currentIndex - 1].eventKey;
      setActiveTab(prevTab);
    } else {
      setPage(0);
    }
  };

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [innerPage, setInnerPage] = useState(0);

  const nextInnerPage = () => {
    setInnerPage((currPage) => currPage + 1);
  };

  const prevInnerPage = () => {
    setInnerPage((currPage) => currPage - 1);
  };

  return (
    <>
      <div className="personal-details-form ">
        {innerPage === 0 ? (
          <InnerPageOne
            nextInnerPage={nextInnerPage}
            prevInnerPage={prevInnerPage}
            handleBack={handleBack}
            nextStep={innerNext}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
            setPanData={setPanData}
            firmName={firmName}
            setFirmname={setFirmname}
          />
        ) : (
          <InnerPageTwo
            setPage={setPage}
            handleBack={handleBack}
            handleSaveAndNext={handleSaveAndNext}
            activeTab={activeTab}
            navItems={navItems}
            handleTabClick={handleTabClick}
            innerNext={innerNext}
            prevInnerPage={prevInnerPage} // Pass prevInnerPage function
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
            panData={panData}
            firmName={firmName}
          />
        )}
      </div>
    </>
  );
}

export default Stepthree;
