import { useState, useEffect, useContext } from "react";
import "./SellerAffiliateStepform.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import Stepsix from "./Stepsix/Stepsix";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OverviewPage from "./OverviewPage/OverviewPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
function SellerAffiliateStepform({ onNext, onPrev }) {
  const [startDate, setStartDate] = useState();

  const [affiliateDetails, setAffiliateDetails] = useState();
  const [affiliateID, setAffiliateID] = useState();
  const [bankID, setBankId] = useState();

  const { getData, postData, IMG_URL } = useContext(Context);

  const getAffiliateDetails = async () => {
    const res = await getData(
      `/affiliate/affiliate-create/affiliate-personal-details/${Cookies.get(
        "affiliate_id"
      )}`
    );
    if (res?.success) {
      setAffiliateDetails(res.data);
      setAffiliateID(res.data.a_personal_details_id);
    }
  };

  useEffect(() => {
    console.log("affiliateDetails form main :-", affiliateDetails);
    console.log("affiliateID form main :-", affiliateID);
  }, [affiliateDetails]);

  useEffect(() => {
    getAffiliateDetails();
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = useState(0);

  const nextStep = () => {
    window.scrollTo(0, 0);
    setPage((currPage) => currPage + 1);
    if (page === 6 && onNext) {
      onNext();
    }
  };

  const prevStep = () => {
    window.scrollTo(0, 0);
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev();
    }
  };
  const prevStepInner = () => {
    window.scrollTo(0, 0);
  };

  const calculatePercentage = () => {
    const totalSteps = stepNames.length;
    const currentPage = page + 1;
    return (currentPage / totalSteps) * 100;
  };

  const calculateProgressPercentage = () => {
    const stepProgressPercentages = [0, 15, 35, 50, 85, 100];
    return stepProgressPercentages[page];
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
          />
        );
      case 1:
        return (
          <Steptwo
            nextStep={nextStep}
            prevStep={() => prevStep(1)}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
          />
        );
      case 2:
        return (
          <Stepthree
            prevStep={() => prevStep(1)}
            setPage={setPage}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
          />
        );
      case 3:
        return (
          <Stepfour
            nextStep={nextStep}
            prevStep={() => prevStep(2)}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
            setBankId={setBankId}
          />
        );
      case 4:
        return (
          <Stepfive
            nextStep={nextStep}
            prevStep={() => prevStep(3)}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
          />
        );
      case 5:
        return (
          <Stepsix
            nextStep={nextStep}
            prevStep={() => prevStep(4)}
            affiliateDetails={affiliateDetails}
            affiliateID={affiliateID}
          />
        );
      default:
        return null;
    }
  };

  const stepNames = [
    "Personal Details",
    "Social Details",
    "Firm Type",
    "Bank Details",
    "Attachments",
    "Password",
  ];

  return (
    <>
      <section className="SelleraffiliateStepMain">
        <div className="container">
          <div className=" mt-5">
            <div className="d-md-flex d-flex-unset">
              <div className="">
                <Link to="/seller/seller_home" className="left-goto-homee">
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go to home
                </Link>
              </div>
              <div className="title-stttee-align">
                <h6>Affiliate Registration Form</h6>
              </div>
            </div>
          </div>
          {page !== 6 ? (
            <div className="ProgressBar">
              <ProgressBar
                percent={calculatePercentage()}
                filledBackground="#1C42A9"
                height="3px"
                style={{ margin: "auto" }}
              >
                {[1, 2, 3, 4, 5, 6].map((icon, index) => (
                  <Step key={index}>
                    {({ accomplished }) => (
                      <div
                        style={{
                          backgroundColor: accomplished ? "#1C42A9" : "#ffffff",
                          color: accomplished ? "#fff" : "#000000",
                          border: accomplished
                            ? "1px solid #1C42A9"
                            : "1px solid #FFFFFF",
                          boxShadow: accomplished
                            ? "none"
                            : " 0px 0px 6px #00000029",
                        }}
                        className={`step ${accomplished ? "completed" : null}`}
                      >
                        <p className="mb-0">{icon}</p>
                        <div className="step-name">{stepNames[index]}</div>
                      </div>
                    )}
                  </Step>
                ))}
              </ProgressBar>
            </div>
          ) : (
            <></>
          )}

          <div className="userForm">
            <div className="personal-details-form step-Form-Conteiner ">
              {/* form-heading-holder */}
              <div className="form-header-holder">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="heading-holder">
                      <h6>
                        {page === 0
                          ? "Personal Details"
                          : page === 1
                            ? "Please fill the below fields"
                            : page === 2
                              ? "Firm Type"
                              : page === 3
                                ? "Please fill the below fields"
                                : page === 4
                                  ? "Please fill the below fields"
                                  : page === 5
                                    ? "Create password for your account"
                                    : null}
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="span-holder">
                      <span>Just a few steps to go</span>
                      <div style={{ width: "50px", height: "50px" }}>
                        <CircularProgressbar
                          value={calculateProgressPercentage()}
                          text={`${calculateProgressPercentage()}%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">{PageDisplay()}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SellerAffiliateStepform;
