import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { motion } from "framer-motion";

import Advertisement_add_task_modal from "../../../../common-components/modals/advertisement_add_task_modal/Advertisement_add_task_modal";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AnimatePresence } from "framer-motion";
import { ShimmerTable } from "react-shimmer-effects";
import { type } from "@testing-library/user-event/dist/type";
import {
  Category,
  ProductStatus,
} from "../../../../../../../utils/apis/master/Master";
import {
  getAllProducts,
  productStatusChange,
} from "../../../../../../../utils/apis/seller/Product";
import CategoryDetails from "../../../../../../CommonForAll/CategoryDetails/CategoryDetails";
import View_all_varients_offcanvas from "../../../../common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";
import CustomPaginate from "../../../../../../CommonForAll/CustomPaginate/CustomPaginate";
import VeiwEditModal from "../../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
import Mrp_modal from "../../../../../../CommonForAll/mrp-modal/Mrp_modal";
import { Context } from "../../../../../../../utils/context";
import ModalDelete from "../../../../../../SellerPanel/Seller_Dashboard/SidebarComponents/common/ModelDelete";

import Report_flag_modal from "../../../../common-components/modals/report-flag-modal/Report_flag_modal";
import { getAdvertisementAllProducts } from "../../../../../../../utils/apis/seller/advertisement";

const Influencertable = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader, formatDateExpire } =
    useContext(Context);

  const [modalreport, setModalreport] = React.useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { IMG_URL, userdata, getData, deleteData, ErrorNotify } =
    useContext(Context);
  const [products, setProducts] = useState([]);
  const [taskId, setTaskId] = useState();

  const [allData, setAllData] = useState([]);
  const [strickData, setStrickData] = useState([]);
  const [productId, setProductId] = useState("");
  const [modaladvertise, setModaladvertise] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modaleye, setModaleye] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");
  const [key, setKey] = useState("home");
  const [deleteshow, setdeleteShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const InitialTransition = () => {};
  const blackBox = {
    initial: {
      height: "100vh",
    },
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  const handleDeleteRecord = async (e) => {
    setShowDeleteModal(true);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/seller/seller-task/task/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      //  setChangeStatus(response);
    }
  };

  const [newTypes, setType] = useState("influncer");
  const getProducts = async (page) => {
    setShimmerLoader(true);
    const res = await getAdvertisementAllProducts(
      page,
      newTypes,
      productTitle,
      startDate,
      endDate
    );
    if (res?.success) {
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setAllData(res.data);
      setShimmerLoader(false);
    }
  };
  const [varIndex, setIndex] = useState("");
  const [variantData, setVariantData] = useState();
  const [reasons, setReportreason] = useState();
  const handleVariantModal = async (data, index) => {
    await setIndex(index);
    await setVariantData(data);
    setVarientsoffcanvas(true);
  };

  const getVariantProducts = async () => {
    const res = await getAllProducts(currentPage);
    if (res?.success) {
      setProducts(res?.data);
      await setVariantData(res?.data?.data[varIndex]);
    }
  };

  const handleStatus = async (id) => {
    setShimmerLoader(true);
    const res = await productStatusChange(id);
    if (res?.success) {
      getProducts(currentPage);
      setShimmerLoader(false);
    }
  };

  const [productStatus, setProductStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const getMasters = async () => {
    {
      const res = await ProductStatus();
      if (res?.success) {
        setProductStatus(res?.data);
      }
    }
    {
      const res = await Category(userdata?.s_sector?.s_working_type_id);
      if (res?.success) {
        setCategories(res?.data);
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const getReportResons = async () => {
    const res = await getData("/seller/masters/all-strick-reason");
    if (res?.success) {
      setReportreason(res.data);
    }
  };
  const getTaskStricks = async () => {
    const res = await getData("/seller/seller-task/influncer-strick");
    if (res?.success) {
      console.log(res.data, "Stick");
      setStrickData(res.data);
    }
  };

  useEffect(() => {
    getMasters();
    getTaskStricks();
    getReportResons();
    getProducts(currentPage);
  }, [
    currentPage,
    productTitle,
    searchStatus,
    category,
    startDate,
    endDate,
    props.advertisement,
  ]);

  return (
    <>
      <section className="B2b_table_section">
        <AnimatePresence />
        {/* <h6 className="pdlsis">Product List</h6> */}
        <div className="product-main">
          <div className="search-main">
            <Form>
              <div className="row">
                <div className="col-xxl-2 co-xl-2 col-lg-3 col-md-6 my-auto">
                  <div className="Addtaskbtn">
                    <Button
                      className="btn addtaskbtn"
                      onClick={() => setModaladvertise(true)}
                    >
                      Add Task
                    </Button>
                  </div>
                </div>
                <div className="col-xxl-3 co-xl-3 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search by product title"
                      onChange={(e) => setProductTitle(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="serch-icoo"
                      icon="fa-solid fa-magnifying-glass"
                    />
                  </Form.Group>
                </div>

                <div className="col-xxl-5 co-xl-5 col-lg-8 col-md-6">
                  <div className="d-flex product-status-div">
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          name="start_date"
                          id="start_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="DD/MM/YYYY"
                        />
                      </Form.Group>
                    </div>

                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <hr></hr>
              {/* <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-3">
                                    <button
                                        className="status-change-btn"
                                        type="button"
                                        onClick={changeStatus}
                                    >
                                        Status Change
                                    </button>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-6 text-end">
                                    <div className="d-flex justify-content-end">

                                        <div className="">
                                            <Link to="/seller/seller-dashboard/btobprod">
                                                <button className="listnewpro-btn">
                                                    <FontAwesomeIcon
                                                        className="me-2"
                                                        icon="fa-solid fa-plus"
                                                    />
                                                    List New Product
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="OrderTabs mb-3"
              >
                <Tab eventKey="home" title="Live Tasks">
                  <div className="table-responsive table-overflow">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          <td>Task ID</td>

                          <td>Affiliater ID</td>

                          <td>Product/website</td>

                          <td>Brand name</td>

                          <td>Img</td>

                          <td>Category</td>

                          <td>Product review</td>

                          <td>Discount (%)</td>

                          <td>Platform</td>

                          <td>Task Amount(₹)</td>
                          <td>Happy Code</td>
                          <td>Total Views</td>
                          <td> Deadline</td>
                          {/* <td>Status</td>
                                                    <td>Action</td> */}

                          {/* <td>
Discountable Product

</td> */}
                        </tr>
                      </thead>

                      <tbody>
                        {allData?.data?.map((val, index) => (
                          <tr>
                            <td>{val?.task_id}</td>

                            <td>#{val?.a_personal_detail?.af_id}</td>
                            <td>{val?.s_product?.product_title}</td>

                            <td>{val?.s_product?.s_brand?.name}</td>

                            <td>
                              <div className=" imgMain">
                                {val?.s_product?.s_brand?.image ? (
                                  <img
                                    className="bjvbhbvh-img"
                                    src={
                                      IMG_URL + val?.s_product?.s_brand?.image
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td>{val?.s_product?.s_category?.name}</td>
                            <td>
                              {val?.product_available == 1 ? "Yes" : "No"}
                            </td>
                            <td>0</td>
                            <td>{val?.a_social_detail?.platform}</td>
                            <td>₹ {val?.insentive}</td>
                            <td>{val?.happy_code}</td>
                            <td>{val?.target_view}</td>
                            <td>
                              {formatDateExpire(val?.createdAt, val?.validity)}
                            </td>
                            {/* <td>
                                                            {val?.status === 1
                                                                ? <span style={{ color: 'green' }}>Active</span>
                                                                : <span style={{ color: 'red' }}>Inactive</span>
                                                            }
                                                        </td> */}
                            {/* <td>
                                                            <div className="d-flex">
                                                                <FontAwesomeIcon onClick={() => { setModalreport(true); setTaskId(val?.id) }} icon="fa-solid fa-flag" className="actionIcon" />
                                                                <FontAwesomeIcon icon="fa-solid fa-eye" className="actionIcon" />
                                                                <FontAwesomeIcon onClick={() => handleDeleteRecord(setRecordToDeleteId(val?.id))} icon="fa-solid fa-trash" className="actionIcon" />
                                                            </div>
                                                        </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Strick Status">
                  <div className="table-responsive table-overflow">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          <td>Task ID</td>

                          <td>Influencer ID</td>

                          <td>Amount(₹)</td>

                          <td>Strick Reason</td>

                          <td>Company Remark</td>

                          <td>Status</td>

                          {/* <td>
Discountable Product

</td> */}
                        </tr>
                      </thead>

                      <tbody>
                        {strickData?.data?.map((val, index) => (
                          <tr>
                            <td> {val?.id}</td>

                            <td>{val?.seller_task_id}</td>
                            <td>0</td>

                            <td>{val?.strick_reason?.name}</td>

                            <td>Lorem Ipsum dolor...</td>

                            <td>{val?.strick_status?.name} </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              </Tabs>
            </Form>
          </div>

          <CategoryDetails show={modaleye} onHide={() => setModaleye(false)} />

          <div className="pagination-div">
            <CustomPaginate
              currentPage={currentPage}
              totalPages={products?.totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          <View_all_varients_offcanvas
            show={varientsoffcanvas}
            variantData={variantData}
            getVariantProducts={getVariantProducts}
            onHide={() => setVarientsoffcanvas(false)}
            placement="top"
          />

          <VeiwEditModal
            show={modalShowedit}
            getAllProducts={getAllProducts}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
          />

          <Advertisement_add_task_modal
            show={modaladvertise}
            onHide={() => setModaladvertise(false)}
            getAdvertisement={props.getAdvertisement}
          />

          <Report_flag_modal
            show={modalreport}
            onHide={() => setModalreport(false)}
            reasons={reasons}
            taskId={taskId}
            getAdvertisement={props.getAdvertisement}
          />
          <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
        <ModalDelete
          show={showDeleteModal}
          handleDeleteRecord={handleDeleteRecord}
          handleDeleteCancel={handleDeleteCancel}
        />
      </section>
    </>
  );
};

export default Influencertable;
