import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../../api";

export const getAllData = async (currentPage, perPage, search,searchStatus) => {
  try {
    let url = `/seller/inquiry-website/masters/about-us/our-story?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if(searchStatus?.value || searchStatus?.value == 0){
      url += `&status=${searchStatus?.value}`
    }
    return await getData(
      url
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeDataStatus = async (id) => {
  try {
    return await editStatusData(`/seller/inquiry-website/masters/about-us/our-story/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Delete = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/inquiry-website/masters/about-us/our-story/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const add = async (finalData) => {
  try {
    return await postData(`/seller/inquiry-website/masters/about-us/our-story`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const edit = async (id, finalData) => {
  try {
    return await postData(
      `/seller/inquiry-website/masters/about-us/our-story/${id}`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/seller/inquiry-website/masters/about-us/our-story/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
