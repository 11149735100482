import React from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./Advertisement.css";
import AdvertisementStock from "../Advertisement/Affiliate/AdvertisementStock/AdvertisementStock";
import Advertisementtable from "../Advertisement/Affiliate/Advertisementtable/Advertisementtable"
import InfluencerStock from "./Influencer/InfluencerStock/InfluencerStock";
import Influencertable from "./Influencer/Influencertable/Influencertable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SettingModal from "../../common-components/modals/SettingModal/SettingModal";
import { useState, useContext, useEffect } from 'react';
import { Context } from "../../../../../utils/context";
const Advertisement = () => {
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const [advertisement, setAdvertisement] = useState("");
  const [newTypes, setType] = useState("affiliate");
  console.log(advertisement, "Fecth All Data");
  const getAdvertisement = async () => {
    const res = await getData(`/seller/seller-task/task?type=${newTypes}`);
    if (res?.success) {
      setAdvertisement(res.data)
    }
  };

  const handleClick = async (newType) => {
    setType(newType);
    const res = await getData(`/seller/seller-task/task?type=${newType}`);
    if (res?.success) {
      setAdvertisement(res.data)
    }
    
  };
  useEffect(() => {
    getAdvertisement();
  }, []);
  return (
    <>
      <section className="Advertisement">
        <div className="advetTabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
              <div className="advertmaintabs">
                <Nav variant="pills" className="flex-row ">
                  <Nav.Item>
                    <Nav.Link eventKey="1" onClick={() => handleClick("affiliate")}>Affiliate</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2" onClick={() => handleClick("influncer")}>Influencer</Nav.Link>
                  </Nav.Item>

                  <FontAwesomeIcon icon="fa-solid fa-gear" className="settingIcon" onClick={() => setModalShow(true)} />
                  <SettingModal show={modalShow}
                    onHide={() => setModalShow(false)} />
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <AdvertisementStock />
                  <Advertisementtable advertisement ={advertisement} getAdvertisement={getAdvertisement}/>
                </Tab.Pane>

                <Tab.Pane eventKey="2">
                  <InfluencerStock />
                  <Influencertable  advertisement ={advertisement} getAdvertisement={getAdvertisement}/>
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Advertisement;
