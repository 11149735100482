import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import "../../HeaderAllCategories/Categorione.css"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import SkinCare from '../../Home/Skin_Care/SkinCare';
import SelectedTrendingProducts_NewArrivals from '../../Home/SelectedTrendingProducts_NewArrivals/SelectedTrendingProducts_NewArrivals';
library.add(fas);

const Categorione = () => {
    return (
        <>

            <section className='categories-mobile-page'>
                <div className='container'>
                    <div className='heading'>
                        <Link to="/b2bbuyer/buyer-home">
                            <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                        </Link>
                        <div className='mx-auto'>
                            <h1>All categories</h1>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='nav-head'>
                            <Nav variant="pills" className="d-flex">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><p>Manufacturing & Processing Machinery</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"><p>Electrical & Electronics</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third"><p>Light Industry & Daily Use</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="forth"><p>Apparel & Accessories</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth"><p>Transportation</p></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sixth"><p>Arts & crafts</p></Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content className='my-4'>
                            <Tab.Pane eventKey="first">
                                <div className='row-div'>
                                    <div className='row'>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            <div className='row-div'>
                                    <div className='row'>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                            <div className='row-div'>
                                    <div className='row'>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                            <div className='row-div'>
                                    <div className='row'>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                            <div className='row-div'>
                                    <div className='row'>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                            <div className='row-div'>
                                    <div className='row'>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-4 text-center'>
                                            <Link to="/b2bbuyer/second-categori">
                                                <div className='img-bg'>
                                                    <img src="/assest/images/B2Bbuyer/RFQ/Ergonomic-Living-Room-Chairs-for-Back-Bliss.png" class="product-img" alt="..." />

                                                </div>
                                                <p>Agriculture <br></br> Machinery</p>
                                            </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>


                    </Tab.Container>

                  
                    <SelectedTrendingProducts_NewArrivals/>


                   
                </div>
            </section>
        </>
    )
}

export default Categorione