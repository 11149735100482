import React, { useRef, useState, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./MyPortfolio.css";
import Container from "react-bootstrap/Container";
import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import { PortfolioGet } from "../../../../utils/apis/myWebsite/mywebsite";
import { Context } from "../../../../utils/context";

const MyPortfolio = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const PortfolioswiperDetails = [
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
        {
            image: process.env.PUBLIC_URL + "/assest/images/MyWebsite/HomeImages/Portfolio/women.png",
            text: "Lorem ipsum dolor sit amet, consecte tuer adipisc ing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,",
            name: "Sarika Patil",
            city: "Pune, Maharastra",
        },
    ];


    const { IMG_URL } = useContext(Context);


    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const [portfolioData, setPortfolioData] = useState();
    const getPortfolioData = async () => {

        const res = await PortfolioGet();
        if (res?.success) {
            setPortfolioData(res?.data);
        }
    }

    useEffect(() => {
        getPortfolioData();
    }, [])

    return (
        <>
            <section className="My-Portfolio">

                <Container>
                    <div className="swiper-portfolio">
                        <Swiper
                            className="mySwiper"
                            spaceBetween={20}
                            slidesPerView={4}
                            initialSlide={2}
                            centeredSlides={true}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                280: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {portfolioData?.map((item, index) => (
                                <SwiperSlide>
                                    <div className="blue-background">
                                        <div className="white-background">
                                            <div className="content">
                                                <div className="women-image">
                                                    <img src={IMG_URL + item?.image} className="women" />
                                                </div>
                                                <p className="maintenance-text">{htmlToReactParser.parse(item?.description)}</p>
                                                <div className="name-content">
                                                    <p className="name maintenance-text">{item?.name}</p>
                                                    <p className="maintenance-text">{item?.address}</p>
                                                </div>
                                                <div className="star">
                                                    <Rating
                                                        initialValue={item?.rating}
                                                        SVGclassName="star-svg"
                                                        fillColor="#FDDA0D"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="silder-btn">
                            <div className="back-btn" onClick={() => handleMainSliderClick("next")}></div>
                            <div className="next-btn" onClick={() => handleMainSliderClick("prev")}></div>
                        </div>
                    </div>
                    <div className="view-portfolio">
                        <Button variant="portfolio" className="maintenance-text">
                            View My Portfolio
                        </Button>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MyPortfolio;
