import React from 'react'
import './HelpCenterTab.css'
import { Accordion, Col, Nav, Row, Tab } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HelpCenterTab = () => {
    return (
        <section className='help-center'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={5} sm={12} xs={12}>
                        <div className='title-help-center'>
                            <h2>Help Center</h2>
                            <p>Learn how to start using our product. Understand the key features.</p>
                        </div>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first"><FontAwesomeIcon icon="fa-solid fa-house" className='me-2' /> Getting Started <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"><FontAwesomeIcon icon="fa-solid fa-user" className='me-2' /> Account Management <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third"><FontAwesomeIcon icon="fa-solid fa-bag-shopping" className='me-2' /> Ordering and Transactions <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="forth"><FontAwesomeIcon icon="fa-solid fa-headset" className='me-2' /> Customer Support <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth"><FontAwesomeIcon icon="fa-solid fa-question" className='me-2' /> FAQs <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth"><FontAwesomeIcon icon="fa-solid fa-shield-halved" className='me-2' /> Security and Privacy <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="seventh"><FontAwesomeIcon icon="fa-solid fa-file-circle-plus" className='me-2' />  Additional Resources <FontAwesomeIcon icon="fa-solid fa-angle-right" className='right-arrow' /></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={7} sm={12} xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className='white-help-bg'>
                                    <p className='help-text-main'>Welcome to Netpurti, your gateway to efficient and effective B2B e-commerce transactions. Whether you're a seasoned professional or new to the world of online business, our Getting Started section is designed to provide you with all the resources you need to hit the ground running.</p>
                                    <p className='help-text-sub'>Setting Up Your Account</p>
                                    <p className='help-text-main'>The first step towards harnessing the power of Netpurti is setting up your account. Our step-by-step guide will walk you through the account creation process, ensuring that you have all the necessary information at your fingertips to get started. From entering your company details to verifying your email address, we'll make sure that your account setup experience is smooth and hassle-free.</p>
                                    <p className='help-text-sub'>Navigating Our Platform</p>
                                    <p className='help-text-main'>Once your account is set up, it's time to familiarize yourself with our platform. Our intuitive interface is designed to make navigation a breeze, but we understand that getting started with a new platform can sometimes be overwhelming. That's why we've put together comprehensive guides and tutorials to help you navigate Netpurti with confidence. Learn how to browse product listings, search for specific items, and utilize advanced filters to find exactly what you're looking for.</p>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className='white-help-bg'>
                                    <p className='help-text-main'>Managing your account is essential for a seamless B2B e-commerce experience. In this section, you'll learn how to update your profile, manage your payment methods, and ensure that your account information is always up to date.</p>
                                    <p className='help-text-sub'>Updating Your Profile</p>
                                    <p className='help-text-main'>Your profile is your digital identity on Netpurti, and keeping it current is essential for effective communication and transactions. Learn how to update your contact information, including your name, email address, and phone number, to ensure that suppliers and partners can reach you when needed. Additionally, explore how to add or update your company details, such as your business address and industry classification, to provide suppliers with relevant information about your organization.</p>
                                    <p className='help-text-sub'>Managing Payment Methods</p>
                                    <p className='help-text-main'>Managing your payment methods is key to completing transactions securely and efficiently on Netpurti. Whether you prefer credit cards, bank transfers, or other payment options, our platform offers flexibility to accommodate your needs. Discover how to add, edit, or remove payment methods from your account, and explore our secure payment processing options to ensure that your financial information remains protected at all times.</p>
                                    <p className='help-text-sub'>Keeping Account Information Up to Date</p>
                                    <p className='help-text-main'>Keeping your account information up to date is crucial for maintaining accurate records and facilitating smooth transactions. Learn how to review and update your account settings, preferences, and notifications to ensure that you receive timely updates and alerts about your orders, payments, and other activities on Netpurti. Additionally, explore how to manage your privacy settings and communication preferences to tailor your Netpurti experience to your preferences and needs.</p>
                                    <p className='help-text-sub'>Staying Secure</p>
                                    <p className='help-text-main'>Security is our top priority at Netpurti, and we're committed to protecting your account and personal information from unauthorized access or misuse. Learn about the security measures we have in place to safeguard your data, including encryption, multi-factor authentication, and regular security audits. Additionally, discover best practices for creating strong passwords, avoiding phishing scams, and protecting yourself against online threats to ensure that your Netpurti account remains secure at all times.</p>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className='white-help-bg'>
                                    <p className='help-text-main'>In the dynamic world of B2B e-commerce, making transactions lies at the core of every successful business interaction. At Netpurti, we understand the importance of a seamless purchasing process, which is why our Ordering and Transactions section is meticulously designed to provide you with all the tools and information you need to streamline your procurement journey.</p>
                                    <p className='help-text-sub'>Placing Orders with Ease</p>
                                    <p className='help-text-main'>The process of placing orders should be intuitive and efficient, allowing you to swiftly complete transactions and move forward with your business operations. In our Ordering and Transactions section, you'll discover step-by-step guides on how to browse products, add items to your cart, and finalize your purchase. Explore features such as bulk ordering, quick reordering, and saved shopping lists to expedite the ordering process and maximize your productivity.</p>
                                    <p className='help-text-sub'>Tracking Shipments in Real-Time</p>
                                    <p className='help-text-main'>Once your order is placed, tracking its progress is essential for ensuring timely delivery and managing your inventory effectively. Netpurti's advanced shipment tracking tools provide you with real-time updates on the status of your orders, including shipping notifications, delivery estimates, and package tracking information. Stay informed every step of the way and proactively address any issues that may arise during the shipping process.</p>
                                    <p className='help-text-sub'>Managing Invoices and Payments</p>
                                    <p className='help-text-main'>Efficiently managing invoices and payments is crucial for maintaining financial transparency and accountability within your organization. Our platform offers comprehensive invoicing tools that allow you to view, download, and manage your invoices seamlessly. Explore features such as invoice reconciliation, payment reminders, and payment scheduling to streamline your accounts payable process and ensure timely payment to your suppliers.</p>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                                <div className='white-help-bg'>
                                    <p className='help-text-main'>At Netpurti, we pride ourselves on delivering exceptional customer support to ensure that your B2B e-commerce experience is smooth and seamless. We understand that navigating a new platform or encountering challenges along the way can be daunting, which is why our dedicated Customer Support section is here to provide you with the assistance you need, whenever you need it.</p>
                                    <p className='help-text-sub'>Accessible Resources</p>
                                    <p className='help-text-main'>Our Customer Support section is your one-stop destination for accessing a wide range of resources to address any questions or concerns you may have. Whether you're a new user seeking guidance on getting started or a seasoned professional looking for advanced troubleshooting tips, our comprehensive resources are designed to meet your needs.</p>
                                    <p className='help-text-sub'>Knowledge Base Articles</p>
                                    <p className='help-text-main'>
                                        Explore our extensive library of knowledge base articles, which cover a wide range of topics related to our platform, services, and features. From step-by-step tutorials on how to navigate our platform to in-depth explanations of advanced functionalities, our knowledge base is your go-to resource for finding answers to common questions and learning more about how to make the most out of Netpurti.
                                    </p>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <div className='white-help-bg'>
                                    <div className='help-text-main'>
                                        Welcome to the FAQ section for B2B e-commerce. Here, we address common questions and provide answers to help you navigate the world of business-to-business online transactions more effectively.
                                    </div>
                                    <Accordion defaultActiveKey="0" className='mt-4'>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>1. What is B2B e-commerce?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>2. How is B2B e-commerce different from B2C e-commerce?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>3. What are the benefits of B2B e-commerce?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>4. How do I get started with B2B e-commerce?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>5. What features should I look for in a B2B e-commerce platform?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>6. How can I ensure the security of my B2B e-commerce transactions?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>7. How can I attract customers to my B2B e-commerce platform?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>8. How can I optimize my B2B e-commerce website for better performance?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>9. What are some common challenges in B2B e-commerce?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header>10. How can I measure the success of my B2B e-commerce initiatives?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth"> Second tab content</Tab.Pane>
                            <Tab.Pane eventKey="seventh">Second tab content</Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </section>
    )
}

export default HelpCenterTab