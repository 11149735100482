import {
  React,
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import { Context } from "../../../../../../../../../../utils/context";
import "../../../Variants/variants.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import MultiVarientInner from "../MultiVarientInner/MultiVarientInner";

import { Button } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";

import {
  Attributes,
  ProductAttributes,
  ProductInfoIcon,
} from "../../../../../../../../../../utils/apis/master/Master";
import Select from "react-select";
import classNames from "classnames";
import Cookies from "js-cookie";

import {
  multipleVariantPost,
  multipleVariantGet,
  AttributeDelete,
} from "../../../../../../../../../../utils/apis/seller/BTBProduct";
import { IDS } from "../../../../../../../../../../utils/common";
import { useLocation } from "react-router-dom";

const MultiVariant = ({
  prevInnerPage,
  nextInnerPage,
  nextStep,
  setSelectedValue,
}) => {
  const location = useLocation();

  const [redirectToNext, setRedirectToNext] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      attribute_data: [
        { attribute_id: "", sub_attribute_id: [] },
        { attribute_id: "", sub_attribute_id: [] },
      ],
    },
  });

  const [error, setError] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const {
    getData,
    IMG_URL,
    Select2Data,
    Id,
    ProductNarrationChangeComa,
    Select2AttributeData,
  } = useContext(Context);

  const [subAtributes, setSubAtributes] = useState([]);
  const [attributeCount, setAttributeCount] = useState(1);
  const [productInfoIcon, setProductInfoIcon] = useState([]);
  const { append, fields, remove } = useFieldArray({
    control,
    name: "attribute_data",
  });

  const [tableData, setTableData] = useState([]);
  const getMultipleVariant = async () => {
    const cookie = Cookies.get("s_product_id");
    if (cookie) {
      const res = await multipleVariantGet(cookie);
      if (res?.success) {
        if (res.data.s_p_v_a_ones.length) {
          setSubmitStatus(true);
        }
        setTableData(res.data.s_p_v_a_ones);
      }
    }
  };

  useEffect(() => {
    getMultipleVariant();
  }, []);

  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const getDataAll = async () => {
    try {
      const id = Cookies.get("s_product_id");
      // if (location.pathname == Paths.o_w_seller_product) {
      const response = await ProductAttributes(id, Id, "admin");
      if (response?.success) {
        setCategory(await Select2AttributeData(response?.data, "attribute_id"));
      }
      // }
      // const id = Cookies.get("s_product_id");
      // const response = await ProductAttributes(id);
      // // const response = await Attributes();
      // setCategory(await Select2Data(response?.data, "attribute_id"));
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    {
      const res = await ProductInfoIcon();
      if (res?.success) {
        setProductInfoIcon(res?.data);
      }
    }
  };


  const getSubAttributes = async (id, index) => {
    try {
      const response = await getData(`/seller/home/s-sub-attributes/${id}`);
      setSubAtributes(await Select2Data(response?.data, "sub_attribute_id"));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const handleNext = () => {
    setRedirectToNext(true);
  };

  const onSubmit = async (d) => {
    var data = {
      s_product_id: Cookies.get("s_product_id"),
      variant_type: "Multiple",
      attribute_one_id: "",
      attribute_two_id: "",
      attribute_three_id: "",
      sub_one: [],
      sub_two: [],
      sub_three: [],
    };
    let total_count = 1;
    getValues("attribute_data")?.forEach((value, index) => {
      if (index === 0) {
        data.attribute_one_id = value.attribute_id.value;
        data.sub_one = JSON.stringify(selectedOptions1);
        total_count *= selectedOptions1.length;
      }
      if (index === 1) {
        data.attribute_two_id = value.attribute_id.value;
        data.sub_two = JSON.stringify(selectedOptions2);
        total_count *= selectedOptions2.length;
      }
      if (index === 2) {
        data.attribute_three_id = value.attribute_id.value;
        data.sub_three = JSON.stringify(selectedOptions3);
        total_count *= selectedOptions3.length;
      }
    });


    if (total_count <= 16) {
      setError("");
      const res = await multipleVariantPost(data);
      if (res?.success) {
        setSubmitStatus(true);
        getMultipleVariant();
      }
    } else {
      setError("Please Select Variants less than 16 or equal");
    }
  };

  const deleteAttribute = async (type, id) => {
    const res = await AttributeDelete(type, id);
    if (res?.success) {
      getMultipleVariant();
    }
  };

  const [selectedOptions1, setSelectedOption1] = useState([]);
  const [selectedOptions2, setSelectedOption2] = useState([]);
  const [selectedOptions3, setSelectedOption3] = useState([]);

  const handleFields = async (selectedOption, index) => {
    setError("");
    if (index == 0) {
      const removedOption = selectedOptions1.find(
        (option) => !selectedOption.includes(option)
      );
      if (removedOption) {
        const removedIndex = selectedOptions1.indexOf(removedOption);

        // remove(removedIndex);
      }
      setSelectedOption1(selectedOption);
    } else if (index == 1) {
      const removedOption = selectedOptions2.find(
        (option) => !selectedOption.includes(option)
      );
      if (removedOption) {
        const removedIndex = selectedOptions2.indexOf(removedOption);

        // remove(removedIndex);
      }
      setSelectedOption2(selectedOption);
    } else if (index == 2) {
      const removedOption = selectedOptions3.find(
        (option) => !selectedOption.includes(option)
      );
      if (removedOption) {
        const removedIndex = selectedOptions3.indexOf(removedOption);

        // remove(removedIndex);
      }
      setSelectedOption3(selectedOption);
    }
  };

  if (redirectToNext) {
    return (
      <MultiVarientInner nextStep={nextStep} prevInnerPage={prevInnerPage} />
    );
  }

  return (
    <>
      <section className="single-varient-sec">
        <div className="sec-div ">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6">
              <div className="varient-firsttt">
                {fields.map((item, index) => (
                  <div key={item.id}>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        // controlId={`attribute-${index + 1}`}
                      >
                        <Form.Label>
                          Attribute {index + 1}
                          <span className="mandatory-star"> *</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">
                                Attribute {index + 1}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>

                        <Controller
                          // name={`attribute_id_${index + 1}`}
                          name={`attribute_data[${index}].attribute_id`}
                          {...register(
                            `attribute_data[${index}].attribute_id`,
                            {
                              required: "Select Attribute",
                            }
                          )}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.attribute_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={category}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption); // Update Controller's value
                                getSubAttributes(selectedOption.value, index);
                                setValue(
                                  `attribute_data[${index}].attribute_id`,
                                  selectedOption
                                );
                              }}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {
                            getValues(`attribute_data[${index}].attribute_id`)
                              ?.label
                          }{" "}
                          {index + 1}
                          <span className="mandatory-star"> *</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-title">
                                {productInfoIcon?.sub_attribute} {index + 1}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </OverlayTrigger>
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name={`attribute_data[${index}].sub_attribute_id`}
                          placeholder="Attributes"
                          // accept={InputeType[`${getValues("input_type_id")}`]?.value}
                          className={classNames("", {
                            "is-invalid": errors?.s_s_a_id,
                          })}
                          {...register(
                            `attribute_data[${index}].sub_attribute_id`,
                            {
                              // pattern: InputeType[`${getValues("input_type_id")}`],
                              // required: "Name is required",
                            }
                          )}
                          onChange={(e) => {
                            // var valuesArray = e.target.value
                            //   .split(",")
                            //   .filter(function (item) {
                            //     return item.trim() !== "";
                            //   });
                            // setTimeout(() => {
                            var valuesArray = ProductNarrationChangeComa(e)
                              .split(",")
                              .filter(function (item) {
                                return item.trim() !== "";
                              })
                              .filter(function (item, index, self) {
                                return self.indexOf(item) === index;
                              });
                            handleFields(valuesArray, index);
                            setValue(
                              `attribute_data[${index}].sub_attribute_id`,
                              ProductNarrationChangeComa(e)
                            );
                            // }, 2000);
                          }}
                        />

                        {/* <Controller
                          name={`attribute_data[${index}].sub_attribute_id`} // name of the field
                          {...register(
                            `attribute_data[${index}].sub_attribute_id`,
                            {
                              required: "Select Sub Attribute",
                            }
                          )}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.sub_attribute_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={subAtributes}
                              isMulti
                            />
                          )}
                        /> */}
                      </Form.Group>

                      {index > 1 && (
                        <Button
                          className="remove-btn-class"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </Form>
                  </div>
                ))}
                <div className="text-end">
                  {fields.length < 3 && (
                    <Button
                      className="add-new-attribute-class"
                      onClick={() =>
                        append({ attribute_id: "", sub_attribute_id: [] })
                      }
                    >
                      + Add New Attributes
                    </Button>
                  )}

                  {error && <sub className="text-danger">{error}</sub>}
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6">
              <Table bordered style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    {tableData?.map((value, index) => (
                      <>
                        <th>
                          {value?.s_attribute?.name}{" "}
                          {value?.s_attribute?.defind}
                        </th>
                        {value?.s_p_v_a_one_ds?.map(
                          (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                            s_p_v_a_one_ds_index === 0 &&
                            s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                              (
                                s_p_v_a_one_d_as_value,
                                s_p_v_a_one_d_as_index
                              ) =>
                                s_p_v_a_one_d_as_index === 0 && (
                                  <th
                                    key={`${index}-${s_p_v_a_one_ds_index}-${s_p_v_a_one_d_as_index}`}
                                  >
                                    {s_p_v_a_one_d_as_value?.s_attribute?.name}{" "}
                                    {
                                      s_p_v_a_one_d_as_value?.s_attribute
                                        ?.defind
                                    }
                                  </th>
                                )
                            )
                        )}
                        {value?.s_p_v_a_one_ds?.map(
                          (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                            s_p_v_a_one_ds_index === 0 &&
                            s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                              (
                                s_p_v_a_one_d_as_value,
                                s_p_v_a_one_d_as_index
                              ) =>
                                s_p_v_a_one_ds_index === 0 &&
                                s_p_v_a_one_d_as_index === 0 &&
                                s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map(
                                  (
                                    s_p_v_a_one_d_a_ds_value,
                                    s_p_v_a_one_d_a_ds_index
                                  ) =>
                                    s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map(
                                      (
                                        s_p_v_a_one_d_a_d_as_value,
                                        s_p_v_a_one_d_a_d_as_index
                                      ) =>
                                        s_p_v_a_one_d_a_d_as_index === 0 &&
                                        s_p_v_a_one_d_a_ds_index === 0 && (
                                          <th
                                            key={`${index}-${s_p_v_a_one_ds_index}-${s_p_v_a_one_d_as_index}-${s_p_v_a_one_d_a_ds_index}-${s_p_v_a_one_d_a_d_as_index}`}
                                          >
                                            {
                                              s_p_v_a_one_d_a_d_as_value
                                                ?.s_attribute?.name
                                            }
                                            {
                                              s_p_v_a_one_d_a_d_as_value
                                                ?.s_attribute?.defind
                                            }
                                          </th>
                                        )
                                    )
                                )
                            )
                        )}
                      </>
                    ))}

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((value, index) =>
                    value?.s_p_v_a_one_ds?.map(
                      (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                        s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                          (s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) =>
                            s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map(
                              (
                                s_p_v_a_one_d_a_ds_value,
                                s_p_v_a_one_d_a_ds_index
                              ) =>
                                // level 2
                                s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as
                                  .length ? (
                                  s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map(
                                    (
                                      s_p_v_a_one_d_a_d_as_value,
                                      s_p_v_a_one_d_a_d_as_index
                                    ) =>
                                      s_p_v_a_one_d_a_d_as_value?.s_p_v_a_one_d_a_d_a_ds?.map(
                                        (
                                          s_p_v_a_one_d_a_d_a_ds_value,
                                          s_p_v_a_one_d_a_d_a_ds_index
                                        ) => (
                                          //level 3

                                          <tr>
                                            <td>
                                              {
                                                s_p_v_a_one_ds_value
                                                  ?.s_sub_attribute?.name
                                              }{" "}
                                              {value?.s_attribute?.defind}{" "}
                                            </td>

                                            <td>
                                              {
                                                s_p_v_a_one_d_a_ds_value
                                                  ?.s_sub_attribute?.name
                                              }{" "}
                                              {
                                                s_p_v_a_one_d_as_value
                                                  ?.s_attribute?.defind
                                              }
                                            </td>

                                            <td>
                                              {
                                                s_p_v_a_one_d_a_d_a_ds_value
                                                  ?.s_sub_attribute?.name
                                              }{" "}
                                              {
                                                s_p_v_a_one_d_a_d_as_value
                                                  ?.s_attribute?.defind
                                              }{" "}
                                            </td>
                                            <td>
                                              {s_p_v_a_one_d_a_d_a_ds_value?.status ===
                                              0 ? (
                                                <Button
                                                  variant="success"
                                                  onClick={() =>
                                                    deleteAttribute(
                                                      3,
                                                      s_p_v_a_one_d_a_d_a_ds_value?.id
                                                    )
                                                  }
                                                >
                                                  Add
                                                </Button>
                                              ) : (
                                                <Button
                                                  variant="danger"
                                                  onClick={() =>
                                                    deleteAttribute(
                                                      3,
                                                      s_p_v_a_one_d_a_d_a_ds_value?.id
                                                    )
                                                  }
                                                >
                                                  Remove
                                                </Button>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )
                                  )
                                ) : (
                                  <tr>
                                    <td>
                                      {
                                        s_p_v_a_one_ds_value?.s_sub_attribute
                                          ?.name
                                      }{" "}
                                      {value?.s_attribute?.defind}{" "}
                                    </td>

                                    <td>
                                      {
                                        s_p_v_a_one_d_a_ds_value
                                          ?.s_sub_attribute?.name
                                      }{" "}
                                      {
                                        s_p_v_a_one_d_as_value?.s_attribute
                                          ?.defind
                                      }
                                    </td>

                                    <td>
                                      {s_p_v_a_one_d_a_ds_value?.status ===
                                      0 ? (
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            deleteAttribute(
                                              2,
                                              s_p_v_a_one_d_a_ds_value?.id
                                            )
                                          }
                                        >
                                          Add
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            deleteAttribute(
                                              2,
                                              s_p_v_a_one_d_a_ds_value?.id
                                            )
                                          }
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                )
                            )
                        )
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="common-steps-btn">
          <button type="button" onClick={prevInnerPage} className="back-btn">
            Back
          </button>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="next-btn"
          >
            Save
          </button>
          {submitStatus && (
            <button type="button" onClick={handleNext} className="next-btn">
              Next
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default MultiVariant;
