import React, { useEffect, useState, Fragment, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Button } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../../../../Terms&Condition/TermsAndConditionModal";
import Select from "react-select";

import { Errors, Placeholders } from "../../../../../../../utils/errors";

import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../../../utils/apis/master/Master";
import {
  firmDetailNamePost,
  getFirmDetailName,
  firmAddressDetailPost,
  getfirmAddressDetail,
  firmDetailsPost,
  getfirmDetails,
} from "../../../../../../../utils/apis/affiliate/affiliate";

import { Select2Data } from "../../../../../../../utils/common";
import { Context } from "../../../../../../../utils/context";
import classNames from "classnames";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
function CommunicationAddress({ affiliateID, setSuccessModalShow }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [firmType, setFirmType] = useState([]);
  const [cities, setCities] = useState("");
  const [states, setStates] = useState("");
  const [countries, setCountries] = useState("");
  const [pincodes, setPincodes] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onFirmNameSubmit = async (d) => {
    d.firm_type_id = d.firm_type_id?.value;
    d.a_personal_details_id = affiliateID;
    if (firmDetailNameId != "") {
      d.id = firmDetailNameId;
    }
    const res = await firmDetailNamePost(d);
    if (res?.success) {
    }
  };

  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };

  const handlePincodeSelect = async (id) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      setCities(res.data.city?.name);
      setStates(res.data.state?.name);
      setCountries(res.data.country?.name);
      reset({
        city_id: res.data.city_id,
        state_id: res.data.state_id,
        country_id: res.data.country_id,
        pincode_id: {
          value: res.data?.id,
          label: res.data?.name,
        },
      });
    }
  };

  const getMasters = async () => {
    {
      const res = await FirmType();
      if (res?.success) {
        const data = await Select2Data(res.data, "firm_type_id", false);
        setFirmType(data);
      }
    }
    {
      const res = await firmAddressType();
      if (res?.success) {
        setFirmAddressType(res.data);
      }
    }
  };

  const [firmDetailNameId, setFirmDetailNameId] = useState("");
  const getFirmDetailNames = async () => {
    const res = await getFirmDetailName(affiliateID);
    if (res?.success) {
      setFirmDetailNameId(res.data.id);
      reset(res.data);
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
  }, []);

  const [firmAddressTypes, setFirmAddressType] = useState([]);
  const onSubmit = async (data) => {
    if (addId != "") {
      data.id = addId;
    }
    data.pincode_id = data?.pincode_id?.value;
    data.a_firm_detail_id = firmDetailNameId;
    data.a_personal_details_id = affiliateID;
    const res = await firmAddressDetailPost(data);
    if (res?.success) {
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  const [addId, setAddId] = useState("");
  const [defaultCountryId, setDefaultCountry] = useState("");
  const [defaultPincode, setDefaultPincode] = useState("");

  const getfirmAddressDetailIn = async () => {
    const res = await getfirmAddressDetail(affiliateID);
    if (res?.success) {
      const resetData = {
        add_line_one: res.data?.add_line_one,
        add_line_two: res.data?.add_line_two,
        address_name: res.data?.address_name,
        pincode_id: res.data?.pincode_id,
        c_personal_details_id: res.data?.c_personal_details_id,
      };
      handlePincodeSelect(res.data.pincode_id.value);

      setAddId(res.data.id);
      setFirmType(res.data.firm_type_id);
      setDefaultPincode(resetData.pincode_id);
      reset(resetData);
    }
  };

  const getFirmTypes = async () => {
    const res = await FirmType();
    if (res?.success) {
      setFirmType(await Select2Data(res.data, "firm_type_id"));
    }
  };

  useEffect(() => {
    getMasters();
    getFirmDetailNames();
    getFirmTypes();
    getfirmAddressDetailIn();
  }, []);

  return (
    <>
      <div className="stepthreeMain">
        {/* form-holder */}
        <div className="form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-heading-text">
              <h6 className=" firmmmdd">Communication Address</h6>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="Education_Level">
                  <Form.Label className="">Enter name of address</Form.Label>

                  <Form.Control
                    type="text"
                    name="address_name"
                    placeholder={Placeholders.address_name}
                    {...register("address_name", {
                      required: Errors.address_name,
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.address_name,
                    })}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="Education_Level">
                  <Form.Label className="">Address line 1</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.add_line_one,
                    })}
                    type="text"
                    name="add_line_one"
                    placeholder={Placeholders.address_line_one}
                    {...register("add_line_one", {
                      required: Errors.address_line_one,
                    })}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form className="mb-3">
                  <Form.Group controlId="Awarded_Year">
                    <Form.Label className="">Address line 2</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.add_line_two,
                      })}
                      type="text"
                      name="add_line_two"
                      placeholder={Placeholders.address_line_two}
                      {...register("add_line_two", {
                        required: Errors.address_line_two,
                      })}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 ">
                <Form.Group controlId="Authourity">
                  <Form.Label className="">City/District:</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.city,
                    })}
                    type="text"
                    name="city_id"
                    readOnly
                    value={cities}
                    placeholder={Placeholders.city}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">State</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.state,
                    })}
                    type="text"
                    name="state_id"
                    value={states}
                    placeholder={Placeholders.state}
                    readOnly
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">Pincode</Form.Label>

                  <Controller
                    name="pincode_id"
                    {...register("pincode_id", {
                      required: Errors.pincode,
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.pincode_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={pincodes}
                        defaultValue={{ defaultPincode }}
                        onInputChange={(selectedOption) => {
                          handlePincodeChange(selectedOption);
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          handlePincodeSelect(selectedOption.value);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Degree_Name:">
                  <Form.Label className="">Country</Form.Label>

                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.country,
                    })}
                    defaultValue={{ defaultCountryId }}
                    type="text"
                    name="country_id"
                    readOnly
                    value={countries}
                    placeholder={Placeholders.country}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="text-end">
              <Button type="submit" className="btn StepfoursaveBtn">
                Save
              </Button>
            </div>
          </Form>

          {/* <div className="text-end">
              <Button className="btn StepfoursaveBtn">Save</Button>
            </div> */}
        </div>
      </div>
    </>
  );
}

export default CommunicationAddress;
