import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AffiliateMainRoutes from "../AffiliateMainRoutes/AffiliateMainRoutes";
import Affiliate_Login from "../../Affiliate-Dashboard/AffiliateLogin/AffiliateLogin"

const AffiliateRoutes = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Routes>
        <Route path={`/affiliate/*`} element={<AffiliateMainRoutes />} />
        <Route path={"/affiliate-login"} element={<Affiliate_Login />} />

      </Routes>
    </>
  );
};

export default AffiliateRoutes;
