import React from 'react'
import { Route, Routes } from 'react-router-dom'
import B2b_Routes_main from '../B2b_Routes_main/B2b_Routes_main'

const B2b_Routes = () => {
  return (
    <>
      <Routes>
        <Route path={`/b2bbuyer/*`} element={<B2b_Routes_main />} />
      </Routes>
    </>
  )
}

export default B2b_Routes