import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../../../api";

export const getAllData = async (
  currentPage,
  perPage,
  search,
  searchStatus,
  searchOrder
) => {
  try {
    let url = `/seller/info-website/masters/term-condition?page=${currentPage}&per_page=${perPage}&term=${search || ""}&order=${searchOrder.value || ""}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const changeStatusData = async (id) => {
  try {
    return await editStatusData(`/seller/info-website/masters/term-condition/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteSingleData = async (recordToDeleteId) => {
  try {
    return await deleteData(
      `/seller/info-website/masters/term-condition/${recordToDeleteId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const addData = async (finalData) => {
  try {
    return await postData(`/seller/info-website/masters/term-condition`, finalData);
  } catch (error) {
    console.log(error);
  }
};

export const editData = async (finalData) => {
  try {
    return await postData(
      `/seller/info-website/masters/term-condition/data`,
      finalData
    );
  } catch (error) {
    console.log(error);
  }
};

export const getsingleData = async (id) => {
  try {
    return await getData(`/seller/info-website/masters/term-condition/data`);
  } catch (error) {
    console.log(error);
  }
};

export const getsingleOfSeller = async (id) => {
  try {
    return await getData(
      `/seller/info-website/masters/term-condition/${id}`
    );
  } catch (error) {
    console.log(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.log(error);
//   }
// };
