import React, { useState, useContext, useEffect } from 'react'
import './HelpCenterTab.css'
import { Accordion, Col, Container, Row } from 'react-bootstrap'

const Purchased = ({ data }) => {


  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const [activekey, setActiveKey] = useState(0)

  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  console.log(data);

  return (
    <section className='mt-5'>
      <Container>
        <Row>
          {data?.m_w_faqs?.map((data, index) => (
            <>
              {/* <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{fAQSFirstDetailsData?.name}</Accordion.Header>
                <Accordion.Body className='mt-3'>
                  {htmlToReactParser.parse(fAQSFirstDetailsData?.description)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col> */}


              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='mt-md-0 mt-4' >
                <Accordion onClick={async () => { await setActiveKey(index) }}>
                  <Accordion.Item eventKey={activekey} className='mt-4'>
                    <Accordion.Header>{data?.name}</Accordion.Header>
                    <Accordion.Body className='mt-3'>
                      {htmlToReactParser.parse(data?.description)}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </>
          ))}

        </Row>
      </Container>
    </section>
  );
};

export default Purchased;
