import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../../../api";
  


  export const formatDate = (date) => {
    if (!date) return null;
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  export const getAffiliateTask = async (
    page = 1,
    title = "",
    status = "",
    category_id = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      console.log();
      if (status !== "" && category_id !== "") {
        return await getData(
          `/affiliate/affiliate-task/task?page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
        );
      }
      if (status === "" && category_id !== "") {
        return await getData(
          `/affiliate/affiliate-task/task?page=${page}&product_title=${title}&category_id=${category_id}`
        );
      }
      if (status !== "") {
        return await getData(
          `/affiliate/affiliate-task/task?page=${page}&product_title=${title}&product_status=${status}`
        );
      }
      if (startDate !== "" && endDate !== "") {
        return await getData(
          `/affiliate/affiliate-task/task?page=${page}&product_title=${title}&from=${formatDate(
            startDate
          )}&to=${formatDate(endDate)}`
        );
      }
  
      return await getData(
        `/affiliate/affiliate-task/task?page=${page}&product_title=${title}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  export const affiliateProductGet = async (type) => {
    try {
      const res = await getData(`/seller/seller-task/task/all-products/data?type=${type}`);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const getInfluencerTask = async (
    page = 1,
    title = "",
    status = "",
    category_id = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      console.log();
      if (status !== "" && category_id !== "") {
        return await getData(
          `/affiliate/influencer-task/task?page=${page}&product_title=${title}&product_status=${status}&category_id=${category_id}`
        );
      }
      if (status === "" && category_id !== "") {
        return await getData(
          `/affiliate/influencer-task/task?page=${page}&product_title=${title}&category_id=${category_id}`
        );
      }
      if (status !== "") {
        return await getData(
          `/affiliate/influencer-task/task?page=${page}&product_title=${title}&product_status=${status}`
        );
      }
      if (startDate !== "" && endDate !== "") {
        return await getData(
          `/affiliate/influencer-task/task?page=${page}&product_title=${title}&from=${formatDate(
            startDate
          )}&to=${formatDate(endDate)}`
        );
      }
  
      return await getData(
        `/affiliate/influencer-task/task?page=${page}&product_title=${title}`
      );
    } catch (error) {
      console.log(error);
    }
  };

 