import React from "react";
import { Route, Routes } from "react-router-dom";
import Maindashboard from "../SidebarComponents/main-dashboard/Maindashboard";
import B2BProduct from "../SidebarComponents/Inventory/B2BProduct/B2BProduct";
import B2bproducts_table from "../SidebarComponents/Inventory/B2bproducts-table/B2bproducts_table";
import Api_documentattions from "../SidebarComponents/Api-documentation/Api_documentattions";
import Orders from "../SidebarComponents/Orders/BTBOrders/Orders";
import Wallet from "../SidebarComponents/Wallet/Wallet";
import Setting from "../SidebarComponents/Mywebsite/Setting/Setting";
import Product from "../SidebarComponents/Mywebsite/Product/Product";
import Listnewproduct from "../SidebarComponents/Mywebsite/Product/Listnewproduct/Listnewproduct";
import Inquiryaddproduct from "../SidebarComponents/InquiryWebsite/Product/Listnewproduct/Listnewproduct";
import Infoaddproduct from "../SidebarComponents/InfoWebsite/Product/Listnewproduct/Listnewproduct";
import Roletables from "../SidebarComponents/Master/EmployeeManagaement/Roles/Tables";
import Employeetables from "../SidebarComponents/Master/EmployeeManagaement/EmployeeDetails/Tables";
import Advertisement from "../SidebarComponents/Advertisement/Advertisement";
import OrderManagement from "../SidebarComponents/order_management/Orders/Table";
import QueryManagement from "../SidebarComponents/Mywebsite/QueryManagement/Table";
import LookingFor from "../SidebarComponents/Mywebsite/LookingFor/Table";
import ContactUsOuterWebsite from "../SidebarComponents/Mywebsite/ContactUsOuterWebsite/Table";
import Category from "../SidebarComponents/AllCategory/Category/Category";
import ChildCategory from "../SidebarComponents/AllCategory/ChildCategory/ChildCategory";
import SubCategory from "../SidebarComponents/AllCategory/SubCategory/SubCategory";
import ChildSubcategory from "../SidebarComponents/AllCategory/ChildSubcategory/ChildSubcategory";
import Mywebsite from "../SidebarComponents/Mywebsite/Mywebsite";
import Infowebsite from "../SidebarComponents/InfoWebsite/InfoWebsite";
import Theme from "../SidebarComponents/Theme/Theme";
import ThemePage from "../SidebarComponents/Mywebsite/PackageSeller/ThemePage/ThemePage";
import ThemeDetails from "../SidebarComponents/Mywebsite/PackageSeller/ThemeDetails/ThemeDetails";
import InquiryWebsite from "../SidebarComponents/InquiryWebsite/InquiryWebsite";

const DashboardRoutes = ({ setSidebarclosed, sidebarclosed }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Maindashboard />} />
        {/* <Route path="/inventory" element={<Inventory />} /> */}
        <Route path="/btobprod" element={<B2BProduct />} />
        <Route path="/b2b-products" element={<B2bproducts_table />} />
        <Route
          path="/b2b-api-documentation"
          element={<Api_documentattions />}
        />
        <Route path="/orders" element={<Orders />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/sellersetting" element={<Setting />} />
        <Route path="/sellerproduct" element={<Product />} />
        <Route path="/sellerlistnewproduct" element={<Listnewproduct />} />
        <Route path="/roletable" element={<Roletables />} />
        <Route path="/employeetable" element={<Employeetables />} />
        <Route path="/advertisement" element={<Advertisement />} />
        <Route path="/order_management" element={<OrderManagement />} />
        <Route path="/query" element={<QueryManagement />} />
        <Route path="/looking-for" element={<LookingFor />} />
        <Route path="/contact-us" element={<ContactUsOuterWebsite />} />
        <Route path="/category" element={<Category />} />
        <Route path="/child-category" element={<ChildCategory />} />
        <Route path="/sub-category" element={<SubCategory />} />
        <Route path="/child-subcategory" element={<ChildSubcategory />} />
        <Route
          path="/mywebsite"
          element={
            <Mywebsite
              sidebarclosed={sidebarclosed}
              setSidebarclosed={setSidebarclosed}
            />
          }
        />
        <Route
          path="/info-website"
          element={
            <Infowebsite
              sidebarclosed={sidebarclosed}
              setSidebarclosed={setSidebarclosed}
            />
          }
        />
        <Route
          path="/theme"
          element={
            <Theme
              sidebarclosed={sidebarclosed}
              setSidebarclosed={setSidebarclosed}
            />
          }
        />
        <Route
          path="/inquiry-websites"
          element={
            <InquiryWebsite
              sidebarclosed={sidebarclosed}
              setSidebarclosed={setSidebarclosed}
            />
          }
        />
        <Route path="/themepage/:id" element={<ThemePage />} />
        <Route path="/theme-details/:id" element={<ThemeDetails />} />

        <Route path="/inquiry-add-product" element={<Inquiryaddproduct />} />
        <Route path="/info-add-product" element={<Infoaddproduct />} />
      </Routes>
    </>
  );
};

export default DashboardRoutes;
