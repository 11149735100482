import React, { useContext, useEffect, useState } from "react";
import "./HomeBanner.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import SkinCare from "../Skin_Care/SkinCare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../../utils/context";
import { getbtb_banner_section } from "../../../../utils/apis/btb/Home_Page";
import HomeinquiryModal from "../../common-components/AllModal/Home-Inquiry-Modal/HomeinquiryModal";
const HomeBanner = () => {
  const [modalHomeInqiry, setModalHomeInqiry] = React.useState(false);
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getbtb_banner_section());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="B2BHomeBanner">
        <div className="bg-img-holder">
          <img
            className="banner-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assest/images/B2B_panel/Home/HomeBanner/Banner_bg_img.png"
            }
            alt="banner-bg-img"
          />
        </div>
        <div className="B2BHomeBanner_content">
          <div className="container p-0">
            <div className="B2B_HomePage_banner">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  prevEl: ".swiper-button-prev",
                  nextEl: ".swiper-button-next",
                }}
                modules={[Pagination, Navigation]}
                className="B2B_Banner_Swiper"
              >
                {data?.data?.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className="bg-color">
                      {/* <div className="row me-0 ms-0">
                        <div className="col-md-6">
                          <div className="banner-text-holder">
                            <div className="heading-holder">
                              <h5>{slide.heading}</h5>
                              <p>{slide.description}</p>
                            </div>
                            <div className="button-holder">
                              <button className="btn sell-btn">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="imge-holder text-center">
                            <img
                              className="bannn-img"
                              src={process.env.PUBLIC_URL + slide.imgSrc}
                              alt={`banner_img_${index}`}
                            />
                          </div>
                        </div>
                      </div> */}

                      <div className="imge-holder text-center">
                        <img
                          className="bannn-img"
                          src={IMG_URL + slide.image}
                          alt={`banner_img_${index}`}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

                <div className="navigation-arrow-holder">
                  <div className="swiper-button-prev">
                    <FontAwesomeIcon
                      className="navigation-arrow "
                      icon={faArrowLeft}
                    />
                  </div>
                  <div className="swiper-button-next">
                    <FontAwesomeIcon
                      className="navigation-arrow "
                      icon={faArrowRight}
                    />
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div onClick={() => { setModalHomeInqiry(true) }} >
            <img src={process.env.PUBLIC_URL + "/assest/images/B2Bbuyer/icons/inquiry-icon.png"} className="fixed_image" alt="fixed-img" />
          </div>
        </div>
      </section>
      <HomeinquiryModal
        show={modalHomeInqiry}
        onHide={() => setModalHomeInqiry(false)}
      />
    </>
  );
};

export default HomeBanner;
