import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import CreateAccountSeccseccfullyModal from "../../../../CommonForAll/CreateAccountSeccseccfullyModal/CreateAccountSeccseccfullyModal";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import PasswordChecklist from "react-password-checklist";
import { firmPasswordPost } from "../../../../../utils/apis/three_p_support/three_p_support";

const Stepsix = ({ nextStep, prevStep, userDetails }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };
  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [error, setErrors] = useState("");

  const onSubmit = async (data) => {
    data.three_p_personal_details_id = userDetails?.three_p_personal_details_id;
    if (data.password === data.re_password) {
      const res = await firmPasswordPost(data);
      if (res?.success) {
        Cookies.remove("three_p_id");
        setModalSuccess(true);
        setTimeout(() => {
          setModalSuccess(false);
          navigate("/seller/seller_home");
        }, 3000);
      }
    } else {
      setErrors("Password Must Match");
    }

  };

  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="personal-details-form  employee-details-step">
          <div>
            <div className="row stepsixform">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.password,
                      })}
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                        }
                      })}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <PasswordChecklist
                      rules={["minLength", "specialChar", "number", "capital", "match"]}
                      minLength={8}
                      value={password}
                      valueAgain={passwordAgain}
                      onChange={(isValid) => { }}
                    />
                    <FontAwesomeIcon
                      icon={iconOne}
                      className="StepsixeditIcon"
                      onClick={() => togglePasswordVisibility("password")}
                    />
                  </div>
                  {errors?.password && (
                    <span className="text text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="reenterPassword">
                  <Form.Label>Re-Enter Password*</Form.Label>
                  <div className="StepsixPassword">
                    <Form.Control
                      type={showPasswordOne ? "text" : "password"}
                      placeholder="Re-Enter Password*"
                      className={classNames("", {
                        "is-invalid": errors?.re_password,
                      })}
                      name="re_password"
                      {...register("re_password", {
                        required: "Re-Password is required",
                      })}
                      onChange={e => setPasswordAgain(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={iconTwo}
                      className="StepsixeditIcon"
                      onClick={() =>
                        togglePasswordVisibility("reenterPassword")
                      }
                    />
                  </div>
                  {errors?.re_password && (
                    <span className="text text-danger">
                      {errors.re_password.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              {error && <span className="text text-danger">{error}</span>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-end btnMainn apply_now_btn">
              {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}

              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn">
                  Back
                </Button>

              </div>
              <div className="registerContinueMain">
                <Button
                  className="tabs-btn"
                  // onClick={() => setModalSuccess(true)}
                  type="submit"
                >
                  Submit
                </Button>

              </div>


              <TermsAndConditionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />

              <CreateAccountSeccseccfullyModal
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Stepsix;
