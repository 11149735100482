import React, { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import {
  Col,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ErrorModal from "../../CommonForAll/ErrorModal/ErrorModal";
import SuccessModal from "../../CommonForAll/SuccessModal/SuccessModal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";

import { Pagination, Navigation } from "swiper/modules";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import {
  AllCategory,
  ChildCategory,
  SubCategory,
} from "../../../utils/apis/master/Master";
import NewNotification from "../common-components/offcanvas/NewNotification/NewNotification";
import ButtonComponent from "../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import { Context } from "../../../utils/context";
import { UserType } from "../../../utils/common";
import Cookies from "js-cookie";
const Header = () => {
  const { IMG_URL, signin, usertype, setSignin, setUsertype } =
    useContext(Context);
  const [show, setShow] = useState(false);
  const handleClose = () => setShowmoboffcan(false);
  const handleShow = () => setShowmoboffcan(true);

  const [shownoti, setShownoti] = useState(false);
  const handleClosenoti = () => setShownoti(false);
  const handleShownoti = () => setShownoti(true);

  const [searchdropshow, setsearchdropShow] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const [showmoboffcan, setShowmoboffcan] = useState(false);

  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [childcategory, setChildCategory] = useState([]);

  const getData = async () => {
    setCategory(await AllCategory());
  };

  useEffect(() => {
    getData();
  }, []);
  const handleCloseInternal = () => {
    handleClose();
  };

  const navigate = useNavigate();

  const logout = async () => {
    Cookies.remove("net_purti_security", { path: "/" });
    await setSignin(false);
    await setUsertype("");
    await navigate("/b2bbuyer/buyer-home");
  };
  return (
    <>
      <section className="b2bmain-header-section">
        <Container fluid>
          <Container>
            <div className="header-main roboto-family">
              <Navbar collapseOnSelect expand="lg" className=" p-0 m-0">
                <Navbar.Brand className="header-img">
                  <Link to={"/b2bbuyer/buyer-home"}>
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/seller-image/logo/netpurtilogo.png"
                      }
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <div className="search-prod-div my-auto">
                      <Form.Control
                        placeholder="Search product, Category, Brand..."
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onClick={() => setsearchdropShow(!searchdropshow)}
                      />
                      <button type="button" className="search-icc">
                        <FontAwesomeIcon
                          className="search"
                          icon="fa-solid fa-magnifying-glass"
                        />
                      </button>

                      {searchdropshow && (
                        <div className="trending-searches-div">
                          <p className="trend-ppp">Trending Searches</p>

                          <Nav defaultActiveKey="" as="ul">
                            <Nav.Item>
                              <Nav.Link>Safety Shoes</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link>Fans</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link>Wires & cables</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link>Faucets</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link>Water Pumps</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link>Printers</Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <p className="trend-ppp">Top Categories</p>

                          <div className="trending-slider-list">
                            <Swiper
                              slidesPerView={1}
                              spaceBetween={10}
                              onSwiper={(swiper) => setSwiperInstance(swiper)}
                              breakpoints={{
                                575: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                991: {
                                  slidesPerView: 3,
                                  spaceBetween: 40,
                                },
                                12000: {
                                  slidesPerView: 3,
                                  spaceBetween: 50,
                                },
                              }}
                              className="mySwiper"
                            >
                              <SwiperSlide>
                                <div className="cattt-sub-prod">
                                  <div className="img-div">
                                    <img
                                      className="slidee"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                      }
                                    />
                                  </div>
                                  <p className="catt-nam">
                                    Agricultural Machinery
                                  </p>
                                </div>
                              </SwiperSlide>

                              <SwiperSlide>
                                <div className="cattt-sub-prod">
                                  <div className="img-div">
                                    <img
                                      className="slidee"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                      }
                                    />
                                  </div>
                                  <p className="catt-nam">
                                    Livestock Machinery
                                  </p>
                                </div>
                              </SwiperSlide>

                              <SwiperSlide>
                                <div className="cattt-sub-prod">
                                  <div className="img-div">
                                    <img
                                      className="slidee"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                      }
                                    />
                                  </div>
                                  <p className="catt-nam">
                                    Feed Processing Machinery
                                  </p>
                                </div>
                              </SwiperSlide>

                              <SwiperSlide>
                                <div className="cattt-sub-prod">
                                  <div className="img-div">
                                    <img
                                      className="slidee"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                      }
                                    />
                                  </div>
                                  <p className="catt-nam">
                                    Feed Processing Machinery
                                  </p>
                                </div>
                              </SwiperSlide>
                            </Swiper>

                            <div
                              className="navigation-arrow-holder"
                              onClick={() => handleMainSliderClick("prev")}
                            >
                              <div className="swiper-button-prev">
                                <FontAwesomeIcon
                                  className="navigation-arrow "
                                  icon={faArrowLeft}
                                />
                              </div>
                            </div>
                            <div
                              className="navigation-arrow-holder"
                              onClick={() => handleMainSliderClick("next")}
                            >
                              <div className="swiper-button-next">
                                <FontAwesomeIcon
                                  className="navigation-arrow"
                                  icon={faArrowRight}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <Nav.Link
                      as={Link}
                      to={"/b2bbuyer/shopping-card"}
                      className="header-lists"
                    >
                      <FontAwesomeIcon
                        className="fontaw-icc"
                        icon="fa-solid fa-cart-shopping"
                      />
                      <br></br>
                      <span>Shopping Cart</span>
                    </Nav.Link>

                    <Nav.Link
                      className="header-lists"
                      as={Link}
                      to={"/b2bbuyer/b2bcontact"}
                    >
                      <FontAwesomeIcon
                        className="fontaw-icc"
                        icon="fa-solid fa-phone-volume"
                      />
                      <br></br>
                      <span>Contact Us</span>
                    </Nav.Link>

                    <ErrorModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />

                    <Nav.Link
                      className="header-lists"
                      // onClick={() => setModalShowSuccess(true)}
                      as={Link}
                      to="/b2bbuyer/rfq"
                    >
                      <FontAwesomeIcon
                        className="fontaw-icc"
                        icon="fa-solid fa-file-invoice"
                      />
                      <br></br>
                      <span>Inquiry</span>
                    </Nav.Link>

                    <SuccessModal
                      show={modalShowSuccess}
                      onHide={() => setModalShowSuccess(false)}
                    />

                    {signin && usertype === UserType.BTB ? (
                      <Dropdown className="accountDrop">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="header-lists"
                        >
                          <FontAwesomeIcon
                            className="fontaw-icc"
                            icon="fa-solid fa-user"
                          />
                          <br></br>
                          <span>Accounts</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={"/b2bbuyer/accounts/b2bmyprofile"}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon="fa-solid fa-user"
                              className="me-2"
                            />{" "}
                            My Profile
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={"/b2bbuyer/my-wallet"}>
                            <FontAwesomeIcon
                              icon="fa-solid fa-wallet"
                              className="me-2"
                            />{" "}
                            Wallet
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={"/b2bbuyer/order"}>
                            <FontAwesomeIcon
                              icon="fa-solid fa-bag-shopping"
                              className="me-2"
                            />
                            Orders
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={"/b2bbuyer/b2bdeshboard/b2bdashboard"}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-grip"
                              className="me-2"
                            />
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            // as={Link}
                            onClick={logout}
                            // to={"/b2bbuyer/b2bdeshboard/b2bdashboard"}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-grip"
                              className="me-2"
                            />
                            Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Nav.Link
                        className="header-lists"
                        to="/b2bbuyer/login"
                        as={Link}
                      >
                        <FontAwesomeIcon
                          className="fontaw-icc"
                          icon="fa-solid fa-user"
                        />
                        <br></br>
                        <span>Login / Signup</span>
                      </Nav.Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div className="b2bsecond-head">
                <Nav>
                  <Nav.Item className="cattt-droppppp-pos">
                    <Nav.Link onClick={() => setShow(!show)}>
                      <FontAwesomeIcon icon="fa-solid fa-list" /> All Categories
                    </Nav.Link>

                    {show && (
                      <div className="category-list-main-drop">
                        <div className="main-cat-div">
                          <h6 className="list-title">Categories</h6>

                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="first"
                          >
                            <div className="listdata-overflow">
                              <Nav variant="pills" className="flex-column">
                                {category?.data?.map((val, index) => (
                                  <div className="name-div">
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey={index}
                                        onClick={async () => {
                                          setSubCategory(
                                            await SubCategory(val?.id)
                                          );
                                        }}
                                      >
                                        {val?.name}
                                      </Nav.Link>
                                    </Nav.Item>
                                  </div>
                                ))}

                                {/* <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list2">
                                      Electrical & Electronics
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list3">
                                      Light Industry & Daily Use
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list4">
                                      Apparel & Accessories
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list5">
                                      Sporting Goods & Recreation
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list6">
                                      Transportation
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list7">
                                      Arts & Crafts
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list8">
                                      Health & Medicine
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list9">
                                      Packaging & Printing
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="name-div">
                                  <Nav.Item>
                                    <Nav.Link eventKey="list10">
                                      Construction & Decoration
                                    </Nav.Link>
                                  </Nav.Item>
                                </div> */}
                                <div className="name-div">
                                  <Link
                                    onClick={() => {
                                      setShow(false);
                                    }}
                                    to="/b2bbuyer/b2b-all-categories"
                                  >
                                    <p className="see-cat">
                                      See All Categories
                                    </p>
                                  </Link>
                                </div>
                              </Nav>
                            </div>
                          </Tab.Container>

                          <div className="subcat-div">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="first"
                            >
                              <div className="listdata-overflow">
                                <Nav variant="pills" className="flex-column">
                                  {subcategory?.data?.map((val, index) => (
                                    <div className="name-div">
                                      <Nav.Item>
                                        <Nav.Link
                                          eventKey={index}
                                          onClick={async () => {
                                            setChildCategory(
                                              await ChildCategory(val?.id)
                                            );
                                          }}
                                        >
                                          {val?.name}
                                        </Nav.Link>
                                      </Nav.Item>
                                    </div>
                                  ))}

                                  {/* <div className="name-div">
                                    <Nav.Item>
                                      <Nav.Link eventKey="list22">
                                        Machine Tools
                                      </Nav.Link>
                                    </Nav.Item>
                                  </div>
                                  <div className="name-div">
                                    <Nav.Item>
                                      <Nav.Link eventKey="list33">
                                        Other Machinery & Parts
                                      </Nav.Link>
                                    </Nav.Item>
                                  </div> */}
                                </Nav>
                              </div>
                            </Tab.Container>

                            <div className="slider-list-div">
                              <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                  clickable: true,
                                }}
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                                breakpoints={{
                                  575: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                  },
                                  768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                  },
                                  991: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                  },
                                  12000: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                  },
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                              >
                                {childcategory?.data?.map((val, index) => (
                                  <SwiperSlide>
                                    <div className="cattt-sub-prod">
                                      <div className="img-div">
                                        {/* <img
                                          className="slidee"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                          }
                                        /> */}
                                      </div>
                                      <p className="catt-nam">{val?.name}</p>
                                    </div>
                                  </SwiperSlide>
                                ))}

                                {/* <SwiperSlide>
                                  <div className="cattt-sub-prod">
                                    <div className="img-div">
                                      <img
                                        className="slidee"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                        }
                                      />
                                    </div>
                                    <p className="catt-nam">
                                      Livestock Machinery
                                    </p>
                                  </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                  <div className="cattt-sub-prod">
                                    <div className="img-div">
                                      <img
                                        className="slidee"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                        }
                                      />
                                    </div>
                                    <p className="catt-nam">
                                      Feed Processing Machinery
                                    </p>
                                  </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                  <div className="cattt-sub-prod">
                                    <div className="img-div">
                                      <img
                                        className="slidee"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assest/images/B2Bbuyer/category-slider-images/agri-machine.png"
                                        }
                                      />
                                    </div>
                                    <p className="catt-nam">
                                      Feed Processing Machinery
                                    </p>
                                  </div>
                                </SwiperSlide> */}
                              </Swiper>

                              <div
                                className="navigation-arrow-holder"
                                onClick={() => handleMainSliderClick("prev")}
                              >
                                <div className="swiper-button-prev">
                                  <FontAwesomeIcon
                                    className="navigation-arrow "
                                    icon={faArrowLeft}
                                  />
                                </div>
                              </div>
                              <div
                                className="navigation-arrow-holder"
                                onClick={() => handleMainSliderClick("next")}
                              >
                                <div className="swiper-button-next">
                                  <FontAwesomeIcon
                                    className="navigation-arrow"
                                    icon={faArrowRight}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link>Top Ranking Products</Nav.Link>
                  </Nav.Item>

                  {/* <Nav.Item>
                    <Nav.Link>
                      {" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-crown"
                        className="kingicn"
                      />{" "}
                      Netpurti Membership
                    </Nav.Link>
                  </Nav.Item> */}

                  <Nav.Item>
                    <Nav.Link>Become a Supplier</Nav.Link>
                  </Nav.Item>

                  {/* <Nav.Item>
                    <Nav.Link>Become a Supplier</Nav.Link>
                  </Nav.Item> */}

                  <Nav.Item>
                    <div className="notifi-btn-div">
                      <ButtonComponent
                        onClick={handleShownoti}
                        className={"noti-btn"}
                      >
                        Notification
                      </ButtonComponent>
                      <div className="dot" />
                    </div>
                  </Nav.Item>

                  <NewNotification
                    shownoti={shownoti}
                    handleClosenoti={handleClosenoti}
                  />

                  <NavDropdown
                    title="Start your journey with"
                    id="collapsible-nav-dropdown"
                    className="ms-auto"
                  >
                    <NavDropdown.Item as={Link} to={"/b2bbuyer/b2bstepform"}>
                      Start as B2B Buyer
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/b2bbuyer/b2b-checker-stepform"}
                    >
                      Start as Checker
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={"/b2bbuyer/b2b-media-stepform"}
                    >
                      Start As Media
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/seller/seller_stepform"}>
                      Start As Seller
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </div>
            </div>
          </Container>
        </Container>
      </section>

      <section className="b2bmain-mobview-hed-sec">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="flexlog-bar">
                <FontAwesomeIcon
                  type="button"
                  onClick={handleShow}
                  className="fabar"
                  icon="fa-solid fa-bars"
                />
                <Link to="/b2bbuyer/buyer-home">
                  <img
                    className="header-logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/Seller_panel/seller-image/logo/netpurtilogo.png"
                    }
                    alt="Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-6 my-auto">
              <div className="flex-logcart">
                <Link to="/b2bbuyer/b2bcontact">
                  <FontAwesomeIcon className="falog" icon="fa-solid fa-phone" />
                </Link>
                <Link to="/b2bbuyer/login">
                  <FontAwesomeIcon className="falog" icon="fa-solid fa-user" />
                </Link>
                <Link to="/b2bbuyer/shopping-card">
                  <FontAwesomeIcon
                    className="falog"
                    icon="fa-solid fa-cart-shopping"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="searchhh-divvv">
            <div className="row">
              <div className=" col-sm-9 col-7">
                <div className="group">
                  <Form.Control
                    placeholder="Search Here..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <div className="serchicdiv">
                    <button>
                      <FontAwesomeIcon
                        className="icon"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-3 col-5 ps-0 text-center logggg my-auto">
                <NavDropdown
                  title="Start your Journey"
                  id="collapsible-nav-dropdown"
                  className="ms-auto"
                >
                  <NavDropdown.Item as={Link} to={"/b2bbuyer/b2bstepform"}>
                    Start as B2B Buyer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={"/b2bbuyer/b2b-checker-stepform"}
                  >
                    Start as Checker
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={"/b2bbuyer/b2b-media-stepform"}
                  >
                    Start As Media
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={"/seller/seller_stepform"}>
                    Start As Seller
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>

          <Offcanvas
            className="mobhedoffcan"
            show={showmoboffcan}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="mobnav-links-div">
                <div className="navdiv">
                  <Link className="" to="/b2bbuyer/first-categori">
                    <span type="button" onClick={handleCloseInternal}>
                      All Categories
                    </span>
                  </Link>
                </div>
                <div className="navdiv">
                  <Link className="" to="">
                    <span type="button" onClick={handleCloseInternal}>
                      Top Ranking Products
                    </span>
                  </Link>
                </div>

                {/* <div className="navdiv">
                  <Link className="" to="/">
                    Netpurti Membership
                  </Link>
                </div> */}
                <div className="navdiv" onClick={handleCloseInternal}>
                  <Link className="" to="/b2bbuyer/rfq">
                    Inquiry
                  </Link>
                </div>
                <div className="navdiv">
                  <Link className="">
                    <span type="button" onClick={handleCloseInternal}>
                      Become a Supplier
                    </span>
                  </Link>
                </div>
                <div className="navdiv" onClick={handleCloseInternal}>
                  <div className="notifi-btn-div">
                    <ButtonComponent
                      onClick={handleShownoti}
                      className={"noti-btn-mo"}
                    >
                      Notification
                    </ButtonComponent>
                    <div className="dot" />
                  </div>
                </div>
                <NavDropdown
                  title="Accounts"
                  id="collapsible-nav-dropdown"
                  className="ms-auto"
                >
                  <NavDropdown.Item
                    onClick={handleCloseInternal}
                    as={Link}
                    to={"/b2bbuyer/accounts/b2bmyprofile"}
                  >
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={handleCloseInternal}
                    as={Link}
                    to={"/b2bbuyer/my-wallet"}
                  >
                    Wallet
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={handleCloseInternal}
                    as={Link}
                    to={"/b2bbuyer/order"}
                  >
                    Orders
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={handleCloseInternal}
                    as={Link}
                    to={"/b2bbuyer/b2bdeshboard/b2bdashboard"}
                  >
                    Dashboard
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </section>
    </>
  );
};

export default Header;
