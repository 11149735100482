import React, { useContext, useEffect, useState } from "react";
import "./SummaryCard.css";
import ButtonComponent from "../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import {
  Link,
  BrowserRouter as Router,
  useLocation,
  useParams,
} from "react-router-dom";
// import Button from 'react-bootstrap/Button';
// import Animation from "../../../CommonForAll/Animation/SuccessAnimation/Animation";
// import Modal from 'react-bootstrap/Modal';
import InquiryModal from "../../common-components/AllModal/inquiry-Modal/InquiryModal";
const SummaryCard = ({ text, link, getValues, handleRazorpay }) => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const openSetModal = () => {
    setShow(true);
    if ("/b2bbuyer/start-order" == location.pathname) {
      console.log(location.pathname);
      handleRazorpay();
    }
    // setTimeout(() => {
    //   window.location = "/b2bbuyer/buyer-home"
    //   setShow(false);
    // }, 3000);
  };

  return (
    <>
      <div className="order-summary-card">
        <div className="flex-div">
          <h6>Order Summary</h6>
          <p>Total items: {getValues("total_items")}</p>
        </div>
        <div className="flex-div">
          <p>item(s) Total</p>
          <p>₹ {getValues("total_mrp")}</p>
        </div>
        <div className="flex-div">
          <p>Discount</p>
          <p>- ₹ {getValues("discount")}</p>
        </div>

        <div className="flex-div">
          <p>Tax</p>
          <p>+ ₹ {getValues("total_tax")}</p>
        </div>
        <div className="border-div" />
        <div className="flex-div mt-2">
          <p>Order total</p>
          <p>₹ {getValues("total")}</p>
        </div>
        <div className="text-center">
          <Link to={link} getValues={getValues}>
            <ButtonComponent
              type="button"
              className="proceed-btn"
              onClick={openSetModal}
            >
              {text}
            </ButtonComponent>
          </Link>

          {/* <Modal show={show} onHide={handleClose} size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='success-modal-payment'>
            <Modal.Header closeButton />
            <Modal.Body>
              <Animation />
              Payment done successfully
            </Modal.Body>
          </Modal> */}

          <InquiryModal
            message="Payment done successfully"
            show={show}
            onHide={() => setShow(false)}
          />
        </div>
      </div>
    </>
  );
};

export default SummaryCard;
