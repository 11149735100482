import React from "react";
import "./Furniture.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Furniture_Slider from "./Furniture_Slider/Furniture_Slider";
import { Link } from "react-router-dom";

function Furniture(props) {
  const { data } = props;
  return (
    <>
      <div className="Furniture_sec">
        <div className="Furniture">
          <div className="row me-0 ms-0">
            <div className="col-lg-3  col-md-4 p-0">
              <div className="Furniture-main-holder">
                <h4>{data?.name}</h4>

                <div className="button-holder ">
                  <Link
                    to={`/b2bbuyer/b2b-bestSeller/${data.id}/${data?.name}`}
                  >
                    <button className="btn sell-btn">
                      See More
                      <FontAwesomeIcon
                        className="ms-2"
                        icon="fa-solid fa-arrow-right"
                      />
                    </button>
                  </Link>
                </div>
                {/* <div className="Furniture-main-img">
                  <img
                    className="Furniture-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assest/images/B2B_panel/Home/Furniture/Furniture_main_img.png"
                    }
                    alt="Furniture-img"
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-9  col-md-8 p-0">
              <Furniture_Slider products={data.products} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Furniture;
