import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditionModal from "../Terms&Condition/TermsAndConditionModal";
import { Link } from "react-router-dom";

import { Errors, Placeholders } from "../../../../../utils/errors";
import {
  FirmType,
  firmAddressType,
  City,
  State,
  Country,
  Pincode,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import { Context } from "../../../../../utils/context";

import {
  getServiceAddress,
  postServiceAddress,
} from "../../../../../utils/apis/three_p_support/three_p_support";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import classNames from "classnames";
import SuccessModal from "../../../../CommonForAll/SuccessModal/SuccessModal";

library.add(fas);

function Stepfive(props) {
  const [modalShow, setModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country_id: "",
      data: [
        {
          city_id: "",
          state_id: "",
        },
      ],
    },
  });

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const GetAllCountries = async () => {
    const response = await Country();

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await State(id);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCity = async (id, index) => {
    const response = await City(id);

    if (response?.success) {
      if (index)
        setValue(`data[${index}].city_id`, "");
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCountries();
  }, []);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const getfirmDropAddressIn = async () => {
    const res = await getServiceAddress(
      props?.userDetails?.three_p_personal_details_id
    );
    if (res?.success) {
      // setDropCount(res.data.length);
      if (res.data.length > 0) {
        var data = [];
        res.data.forEach((item, index) => {
          let nextData = {
            city_id: { value: item.city_id, label: item.city.name },
            state_id: { value: item.state_id, label: item.state.name },
          };
          data.push(nextData);
          GetAllStates(item.country_id);
        });

        reset({
          country_id: {
            value: res.data[0].country_id,
            label: res.data[0].country.name,
          },
          data: data,
        });
      } else {
        reset({
          data: [
            {
              city_id: "",
              state_id: "",
            },
          ],
        });
      }
    }
  };

  useEffect(() => {
    getfirmDropAddressIn();
  }, []);

  const onSubmit = async (d) => {
    const keys = Object.keys(d.data);
    var data = [];
    keys.forEach((key) => {
      const value = d.data[key];

      var cityData = {
        city_id: value.city_id.value,
        state_id: value.state_id.value,
      };

      data.push(cityData);
    });

    var sendData = {
      country_id: d.country_id.value,
      data: data,
      three_p_p_details_id: props?.userDetails?.three_p_personal_details_id,
    };

    const res = await postServiceAddress(sendData);
    if (res?.success) {
      setSuccessModalShow(true);
      setTimeout(() => {
        setSuccessModalShow(false);
      }, 2000);
    }
  };

  return (
    <>
      <div className="personal-details-form ">
        {/* form-holder */}
        <Form onSubmit={handleSubmit(onSubmit)} role="form">
          <div className="details-form-holder">
            <div className="form-container">
              <h6 className="mb-3 firmmmdd">Service Area</h6>

              {/* Current Industry , Job Title*/}
              {/* {[...Array(stateCount)].map((_, index) => ( */}
              <>
                <div className=" mb-3">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Form.Group controlId="">
                        <Form.Label>Country</Form.Label>
                        {/* <Select options={options} /> */}
                        <Controller
                          name={`country_id`}
                          {...register(`country_id`, {
                            required: Errors.country,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.country_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              // defaultValue={item.country_id}
                              // value={field.value}
                              options={countries}
                              // onInputChange={() => {
                              //   GetAllCountries();
                              // }}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                GetAllStates(selectedOption.value);
                                setValue("country_id", selectedOption);
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {fields.map((item, index) => (
                    <div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <Form.Group controlId="">
                            <Form.Label>State</Form.Label>
                            {/* <Select options={options} /> */}
                            <Controller
                              name={`data[${index}].state_id`}
                              {...register(`data[${index}].state_id`, {
                                required: Errors.state,
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.state_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  // defaultValue={item.state_id}
                                  options={states}
                                  onFocus={() => GetAllStates(getValues(`country_id.value`))}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption); // Update Controller's value
                                    GetAllCity(selectedOption.value, index);
                                    setValue("state_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="">
                            <Form.Label>City <span className="multiple-selection"></span></Form.Label>

                            <Controller
                              name={`data[${index}].city_id`}
                              {...register(`data[${index}].city_id`, {
                                required: Errors.city,
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.city_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={cities}
                                  // defaultValue={item.city_id}
                                  onFocus={() => GetAllCity(getValues(`data[${index}].state_id.value`))}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption); // Update Controller's value
                                    // GetAllCity(selectedOption.value);
                                    setValue("city_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      {index !== 0 && (
                        <div className="addremoveadress">
                          <div className="text-end Addnewadresstxt">
                            <p className="" onClick={() => remove(index)}>
                              Remove Address{" "}
                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  <div className="addremoveadress">
                    <div className="text-end Addnewadresstxt">
                      <p
                        onClick={() =>
                          append({
                            state_id: "",
                            city_id: "",
                          })
                        }
                      >
                        Add New State{" "}
                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 p-0 ">
                    <div className="text-end">
                      <Button
                        className="btn StepfoursaveBtn"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </>
              {/* // ))} */}

              {/* <div className="addremoveadress">
              <div
                className="text-end Addnewadresstxt"
                onClick={handleRemoveState}
              >
                <p className="">
                  Remove new state  {" "}
                  <FontAwesomeIcon icon="fa-solid fa-minus" />
                </p>
              </div>
              <div className="text-end Addnewadresstxt" onClick={handleAddState}>
                <p className="">
                  Add new state  <FontAwesomeIcon icon="fa-solid fa-plus" />
                </p>
              </div>
            </div> */}
            </div>
          </div>
        </Form>
        <div className="col-md-12">
          <div className="text-end btnMainn apply_now_btn">
            {/* <p onClick={() => setModalShow(true)}>
                By continuing, I agree to Netpurti's
                <span className="termstxt">Terms of Use & Privacy Policy</span>
              </p> */}

            <div className="registerContinueMain">
              <Button onClick={props.prevStep} className="back-btn">
                Back
              </Button>

            </div>

            <div className="registerContinueMain">
              <Button
                onClick={props.nextStep}
                className=" tabs-btn"
              // type="button"
              // onClick={handleSubmit(onSubmit)}
              >
                Register & Continue
              </Button>

            </div>
          </div>
        </div>
        <TermsAndConditionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />{" "}
        <SuccessModal show={successModalShow} />
      </div>
    </>
  );
}

export default Stepfive;
