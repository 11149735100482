import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getbtb_banner_section = async (data) => {
  try {
    const res = await getData(`/without-login/btb/masters/btb-banner-section`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getbtb_advertisement_section = async (data) => {
  try {
    const res = await getData(
      `/without-login/btb/masters/btb-advertisement-section`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getbtb_adv_two_section = async (data) => {
  try {
    const res = await getData(`/without-login/btb/masters/btb-adv-two-section`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getbtb_adv_two_side_section = async (data) => {
  try {
    const res = await getData(
      `/without-login/btb/masters/btb-adv-two-side-section`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getNew_Arrivals = async (data) => {
  try {
    const res = await getData(`/btb/home/new-arrivals`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSelectedTrendingProducts = async (data) => {
  try {
    const res = await getData(`/btb/home/selected-trending-products`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getBestSellersCategories = async (data) => {
  try {
    const res = await getData(`/btb/home/best-sellers-categories`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
