import React, { useContext, useEffect, useState } from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { Errors, Placeholders } from "../../../utils/errors";

const Form_modal = (props) => {

  const  {selectedPlan} = props;
  const {
    control,
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const { IMG_URL } = useContext(Context);

const [optionError , setOptionError] = useState(false);
  const onSubmit = async (d) => {
    if(d.new_domain !== ""){
      props.handleRazorpay(selectedPlan?.selling_price,selectedPlan?.id,d.new_domain,"");
    }else if(d.domain && d.dns){
      props.handleRazorpay(selectedPlan?.selling_price,selectedPlan?.id,d.domain,d.dns);
    }else{
      setOptionError(true);
    }


  }
  useEffect(() => {

  }, []);
  return (
    <>
      <Modal className='mrp-modal' size="lg" {...props} centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <div className='row text-center'>
            <div className='col-12'>
                <h4>Please mention details of your domain</h4>
                {optionError && (<sub className="text-danger">Mention your domain details</sub>)}
            </div>
          </div>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Domain</Form.Label>
            <Form.Control
              type="text"
              name="domain"
              placeholder="Enter Domain"
              {...register("domain", {
                // required: "Domain required",
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
                  message: "Enter a valid Domain URL",
              },
              })}
              className={classNames("", {
                "is-invalid": errors?.domain,
                "is-valid": getValues("domain"),
              })}
              onKeyDown={(event) => {
                if (!RegxExpression.name.test(event.key)) {
                  event.preventDefault();
                }
                setValue('new_domain',"");
                setOptionError(false);
              }}
            />

          </Form.Group>
          <Form.Group className="mb-3" controlId="">
            <Form.Label>DNS</Form.Label>
            <Form.Control
              type="text"
              name="dns"
              placeholder="Enter DNS"
              {...register("dns", {
                required: getValues('new_domain') !== "" ? false : true,
              })}
              className={classNames("", {
                "is-invalid": errors?.dns,
                "is-valid": getValues("dns"),
              })}
              onKeyDown={(event) => {
                if (!RegxExpression.name.test(event.key)) {
                  event.preventDefault();
                }
                setOptionError(false);
              }}
            />

          </Form.Group>

          <hr class="hr-text mt-5 mb-5" data-content="OR"></hr>


          <div className='row text-center'>
            <div className='col-12'>
                <h4>Make new domain of your choice</h4>
            </div>
          </div>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Domain</Form.Label>
            <Form.Control
              type="text"
              name="new_domain"
              placeholder="Enter Domain"
              {...register("new_domain", {
                // required: "Domain required",
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
                  message: "Enter a valid Domain URL",
              },
              })}
              className={classNames("", {
                "is-invalid": errors?.new_domain,
                "is-valid": getValues("new_domain"),
              })}
              onKeyDown={(event) => {
                if (!RegxExpression.name.test(event.key)) {
                  event.preventDefault();
                }
                setValue('domain',"");
                setValue('dns',"");
                setOptionError(false);
              }}
            />

          </Form.Group>

          <button className="update-btn" type="button" onClick={handleSubmit(onSubmit)}>Purchase</button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Form_modal;
