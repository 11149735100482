import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-step-progress-bar/styles.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const B2bSetPass = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [iconOne, setIconOne] = useState(faEyeSlash);
    const [display, setDisplay] = useState(true);
    const togglePasswordVisibility = (field) => {
      if (field === "password") {
        setShowPassword(!showPassword);
        setIconOne(showPassword ? faEyeSlash : faEye);
      }
    };
  
    const displayClick = () => {
      setDisplay(false);
    };
  return (
    <>
    <section className="B2b-Login">
        {display ? (
          <>
            <div className="container">
              <div className="loginHead">
                <Link to={"/b2bbuyer/buyer-home"}>
                  <p>
                    <FontAwesomeIcon
                      icon="fa-solid fa-less-than"
                      className="lessThanSymbol"
                    />
                    Go to Home
                  </p>
                </Link>
                <h3>Set New Password</h3>
                <p className="headbelowtxt">
                Your new password must be different to previously used password.
                </p>
              </div>

              <div className="col-lg-6 mx-auto">
                <div className="personal-details-form-b2b">
                  {/* form-holder */}

                  <div className="details-form-holder">
                    <div className="form-container">
                      <Form>
                        <div className="">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="field-bottom">
                                <Form.Group controlId="Last_Name">
                                  <Form.Label className="required form-field">
                                    New Password
                                  </Form.Label>
                                  <div className="get-otp-text">
                                    <Form.Control
                                      type={showPassword ? "text" : "password"}
                                      name="email"
                                      placeholder="Enter Email"
                                      className="is-invalid"
                                    />

                                    <FontAwesomeIcon
                                      icon={iconOne}
                                      className="StepsixeditIcon"
                                      onClick={() =>
                                        togglePasswordVisibility("password")
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="field-bottom">
                                <Form.Group controlId="Last_Name">
                                  <Form.Label className="required form-field">
                                    Confirm New Password
                                  </Form.Label>
                                  <div className="get-otp-text">
                                    <Form.Control
                                      type="text"
                                      name="email"
                                      placeholder="Enter Email"
                                      className="is-invalid"
                                    />
                                    <FontAwesomeIcon
                                      icon={iconOne}
                                      className="StepsixeditIcon"
                                      onClick={() =>
                                        togglePasswordVisibility("password")
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-md-12">
                                <div className="text-center ">
                                  <Button
                                    className="tabs-btn login_now_btn"
                                    type="submit"
                                    onClick={displayClick}
                                  >
                                    Reset password
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container">
            <div className="loginHead ">
                <Link to={"/b2bbuyer/buyer-home"}>
                  <p>
                    <FontAwesomeIcon
                      icon="fa-solid fa-less-than"
                      className="lessThanSymbol"
                    />
                    Go to Home
                  </p>
                </Link>
                
              </div>
              <div className="passwordreset">
                <h3>Password Reset</h3>
                <p>Your password has been successfully reset</p>
                <Link to={"/b2bbuyer/login"}>
                  <p className="backlogin">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> Back to
                    login
                  </p>
                </Link>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  )
}

export default B2bSetPass