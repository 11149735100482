import React, { useState, useEffect } from "react";
import "./Sidebar_seller_dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation } from "react-router-dom";
import {
  faAngleRight,
  faArrowRight,
  faCar,
  faCarSide,
  faCheck,
  faCrop,
  faDriversLicense,
  faFile,
  faFileLines,
  faGlobe,
  faIdCard,
  faInfo,
  faPhoneVolume,
  faSms,
  faStore,
  faTentArrowTurnLeft,
  faTicket,
  faTractor,
  faWheatAwn,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faPage4,
  faPagelines,
  faPaypal,
  faThemeisle,
} from "@fortawesome/free-brands-svg-icons";

const Sidebar_seller_dashboard = ({ setSidebarclosed, sidebarclosed }) => {
  const [activeKey, setActiveKey] = useState("0");
  const location = useLocation();
  const [show, setShow] = useState(false);

  const [close, setClose] = useState(false);
  const [activeLink, setActiveLink] = useState(""); // Add state for active link

  const toggleSidebar = () => {
    setSidebarclosed(!sidebarclosed);
  };

  useEffect(() => {
    // Extract the pathname from the location object
    const { pathname } = location;

    // Define a function to determine the active key based on the pathname
    const determineActiveKey = () => {
      switch (pathname) {
        case "/seller/seller-dashboard/":
          return "0";
        case "/seller/seller-dashboard/b2b-products":
          return "1";
        case "/seller/seller-dashboard/orders":
          return "2";
        case "/seller/seller-dashboard/b2b-api-documentation":
          return "3";
        case "/seller/seller-dashboard/wallet":
          return "5";
        case "/seller/seller-dashboard/mywebsite":
          return "6";
        case "/seller/seller-dashboard/advertisement":
          return "17";
        case "/seller/seller-dashboard/sidebar_component":
          return "13";
        case "/seller/seller-dashboard/query":
          return "14";
        case "/seller/seller-dashboard/looking-for":
          return "15";
        case "/seller/seller-dashboard/contact-us":
          return "16";
        case "/seller/seller-dashboard/info-website":
          return "30";
        case "/seller/seller-dashboard/theme":
          return "31";
        case "/seller/seller-dashboard/inquiry-websites":
          return "32";

        default:
          return "0"; // Default active key
      }
    };

    // Set the active key based on the current pathname
    setActiveKey(determineActiveKey());
  }, [location]);

  return (
    <>
      <section
        className={`Sidebar_seller_dashboard ${
          sidebarclosed ? "scale-down-hor-left" : "scale-up-hor-left"
        }`}
      >
        <div className="collapse-toggle-btn-holder">
          <div className="collapse-toggle-btn" onClick={toggleSidebar}>
            <FontAwesomeIcon
              className={`toggle ${sidebarclosed ? "rotate-0" : "rotate-180"}`}
              icon={faAngleRight}
            />
          </div>
        </div>
        <div className="sidebar-holder">
          <div className="logo-image-main">
            <Link to="/seller/seller-dashboard">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assest/images/Seller_panel/seller-image/logo/netpurti_logo.png"
                }
                className="logo-img"
                alt="..."
              />
            </Link>
          </div>

          {/* <div className="brown-border"></div> */}

          <div className="tabs-main-secttt">
            <Accordion
              activeKey={activeKey}
              onSelect={(key) => setActiveKey(key)}
            >
              {/* Dashboard */}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Dashboard</Tooltip>}
              >
                <Accordion.Item eventKey="0">
                  <Link to={"/seller/seller-dashboard/"}>
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon="fa-solid fa-house" />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Dashboard
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              <Accordion.Item eventKey="1">
                {/* Inventory */}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Inventory</Tooltip>}
                >
                  <Accordion.Header>
                    <span className="ico-span">
                      <FontAwesomeIcon icon="fa-solid fa-cube" />
                    </span>
                    <span style={{ display: sidebarclosed ? "none" : "block" }}>
                      Inventory
                    </span>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body className="inner-dropp">
                  {/* B2B Products */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>B2B Products</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "/seller/seller-dashboard/b2c-products"
                          ? ""
                          : "active"
                      }`}
                    >
                      <Link
                        className="inner-menu"
                        to={"/seller/seller-dashboard/b2b-products"}
                      >
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            B2B Products
                          </span>
                          <FontAwesomeIcon
                            icon="fa-solid fa-angle-right"
                            className="next-fafaf"
                          />
                        </div>
                      </Link>

                      {/* {show && (
                    <div className="sublistt-div">
                      <div className="sub-men-div">
                        <Link to="/#" className="sublist-menu">
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            Active Products
                          </span>
                        </Link>
                      </div>
                      <div className="sub-men-div">
                        <Link to="/#" className="sublist-menu">
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            {" "}
                            Inactive Products
                          </span>
                        </Link>
                      </div>
                      <div className="sub-men-div">
                        <Link to="/#" className="sublist-menu">
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            {" "}
                            Drafted Products
                          </span>
                        </Link>
                      </div>
                    </div>
                  )} */}
                    </div>
                  </OverlayTrigger>

                  {/* B2C Products */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>B2C Products</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "" ? "" : "active"
                      }`}
                    >
                      <Link className="inner-menu">
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            B2C Products
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger>

                  {/*  My Website */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> My Website</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "" ? "" : "active"
                      }`}
                    >
                      <Link className="inner-menu">
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            My Website
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                {/* Order */}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Order</Tooltip>}
                >
                  <Accordion.Header>
                    <span className="ico-span">
                      <FontAwesomeIcon icon="fa-solid fa-bag-shopping" />
                    </span>
                    <span style={{ display: sidebarclosed ? "none" : "block" }}>
                      Orders
                    </span>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body className="inner-dropp">
                  {/*  B2B Orders */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> B2B Orders</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "/seller/seller-dashboard/orders"
                          ? ""
                          : "active"
                      }`}
                    >
                      <Link
                        className="inner-menu"
                        to={"/seller/seller-dashboard/orders"}
                      >
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            B2B Orders
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger>

                  {/*  B2C Orders */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> B2C Orders</Tooltip>}
                  >
                    <div
                      className={`inner-menu-div ${
                        activeLink === "" ? "" : "active"
                      }`}
                    >
                      <Link className="inner-menu">
                        <div className="drippp">
                          <span className="inner-menu-icc">
                            <FontAwesomeIcon icon="fa-solid fa-kaaba" />
                          </span>
                          <span
                            style={{
                              display: sidebarclosed ? "none" : "block",
                            }}
                          >
                            B2C Orders
                          </span>
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger>
                </Accordion.Body>
              </Accordion.Item>

              {/* API Documentation */}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>API Documentation</Tooltip>}
              >
                <Accordion.Item eventKey="3">
                  <Link to="/seller/seller-dashboard/b2b-api-documentation">
                    <Accordion.Header>
                      <span className="ico-span">
                      <FontAwesomeIcon   icon={faFile} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        API Documentation
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Wallet */}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Wallet</Tooltip>}
              >
                <Accordion.Item eventKey="5">
                  <Link to="/seller/seller-dashboard/wallet">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon="fa-solid fa-wallet" />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Wallet
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* My websites start */}

              {/*  My Websites */}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> My Websites</Tooltip>}
              >
                <Accordion.Item eventKey="6">
                  <Link to="/seller/seller-dashboard/mywebsite">
                    <Accordion.Header>
                      <span className="ico-span">
                      <FontAwesomeIcon   icon={faGlobe} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        My Websites
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Info Websites*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Info Websites</Tooltip>}
              >
                <Accordion.Item eventKey="30">
                  <Link to="/seller/seller-dashboard/info-website">
                    <Accordion.Header>
                      <span className="ico-span">
                      <FontAwesomeIcon   icon={faInfo} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Info Websites
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Inquiry Websites*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Inquiry Websites</Tooltip>}
              >
                <Accordion.Item eventKey="32">
                  <Link to="/seller/seller-dashboard/inquiry-websites">
                    <Accordion.Header>
                      <span className="ico-span">
                      <FontAwesomeIcon   icon={faPhoneVolume} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Inquiry Websites
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*Advertisement*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Advertisement</Tooltip>}
              >
                <Accordion.Item eventKey="17">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon="fa-solid fa-rectangle-ad" />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Advertisement
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*Message*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Message</Tooltip>}
              >
                <Accordion.Item eventKey="18">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faSms} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Message
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*Transport*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Transport</Tooltip>}
              >
                <Accordion.Item eventKey="19">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCar} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Transport
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*RTO*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> RTO</Tooltip>}
              >
                <Accordion.Item eventKey="20">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faDriversLicense} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        RTO
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Refund & Return*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Refund & Return</Tooltip>}
              >
                <Accordion.Item eventKey="21">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faTentArrowTurnLeft} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        {" "}
                        Refund & Return
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Compliant*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Compliant</Tooltip>}
              >
                <Accordion.Item eventKey="22">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faFileLines} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Compliant
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Checker*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Checker</Tooltip>}
              >
                <Accordion.Item eventKey="23">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Checker
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Payment*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Payment</Tooltip>}
              >
                <Accordion.Item eventKey="24">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faPaypal} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Payment
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Coupon*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Coupon</Tooltip>}
              >
                <Accordion.Item eventKey="25">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faIdCard} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Coupon
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*  Farmer | Add Inventory*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Farmer | Add Inventory</Tooltip>}
              >
                <Accordion.Item eventKey="26">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header className="text-start">
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faTractor} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Farmer | Add Inventory
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Farmer | Crops Planing*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Farmer | Crops Planing</Tooltip>}
              >
                <Accordion.Item eventKey="27">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faWheatAwn} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Farmer | Crops Planing
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* My Purchase*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> My Purchase</Tooltip>}
              >
                <Accordion.Item eventKey="28">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faTicket} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        My Purchase
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*  My Store*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> My Store</Tooltip>}
              >
                <Accordion.Item eventKey="29">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon className="me-2" icon={faStore} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        My Store
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/*  3P Team Support*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> 3P Team Support</Tooltip>}
              >
                <Accordion.Item eventKey="40">
                  <Link to="/seller/seller-dashboard/advertisement">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon
                          className="me-2"
                          icon={faPhoneVolume}
                        />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        3P Team Support
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* Theme*/}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Theme</Tooltip>}
              >
                <Accordion.Item eventKey="31">
                  <Link to="/seller/seller-dashboard/theme">
                    <Accordion.Header>
                      <span className="ico-span">
                        <FontAwesomeIcon icon={faThemeisle} />
                      </span>
                      <span
                        style={{ display: sidebarclosed ? "none" : "block" }}
                      >
                        Theme
                      </span>
                    </Accordion.Header>
                  </Link>
                </Accordion.Item>
              </OverlayTrigger>

              {/* My webaites end */}

              {/* Category starts */}

              {/* Category ends */}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar_seller_dashboard;
