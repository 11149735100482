import React from 'react'
import { Form, Modal } from 'react-bootstrap';
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import InquiryModal from '../inquiry-Modal/InquiryModal';
const WorkingTypeModal = (props) => {
    const [modalUpdate, setModalUpdate] = React.useState(false);
    const updateSetModal = () => {
        setModalUpdate(true);
        setTimeout(() => {
            //   window.location = ""
            setModalUpdate(false);
        }, 3000);
    };
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='edit-details-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Working Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="mb-3">
                            <Form.Check
                                type='radio'
                                id={`default-satisfactory-quotations`}
                                label={`Farmer`}
                                className='radio-btn-class'
                                name='name'
                            />
                            <Form.Check
                                type='radio'
                                id={`default-unsuitable-quotations`}
                                label={`Supplier`}
                                className='radio-btn-class'
                                name='name'
                            />
                            <Form.Check
                                type='radio'
                                id={`default-sourcing-plans-changed`}
                                label={`Manufacturer (OEM)`}
                                className='radio-btn-class'
                                name='name'
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent onClick={props.onHide} className={'btn-cancle'}>Cancel</ButtonComponent>
                    <ButtonComponent className={'btn-update'} onClick={() => { updateSetModal(true); props.onHide(); }}> Update</ButtonComponent>
                </Modal.Footer>
            </Modal>
            <InquiryModal
                message="Working Type Changed Successfully"
                show={modalUpdate}
                onHide={() => setModalUpdate(false)}
            />
        </>
    )
}

export default WorkingTypeModal