import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import ColorPickerInput from "../../common/ColorPicker";

import {
  getsingledata,
  edit,
} from "../../../../../../utils/apis/seller/info-website/masters/appSetup/appSetup";
import {
  Currency
} from "../../../../../../utils/apis/master/Master";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension, Select2Data } = useContext(Context);
  const [currency, setCurrency] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [headerColor, setHeaderColor] = useState("");
  const [footerColor, setFooterColor] = useState("");
  const [headerFontColor, setHeaderFontColor] = useState("");
  const [footerFontColor, setFooterFontColor] = useState("");

  const GetEditData = async () => {
    const response = await getsingledata();
    reset(response?.data);
    setHeaderColor(response?.data?.header_color);
    setFooterColor(response?.data?.footer_color);
    setHeaderFontColor(response?.data?.header_font_color);
    setFooterFontColor(response?.data?.footer_font_color);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("contact_no", data?.contact_no);
      finalData.append("address", data?.address);
      finalData.append("email", data?.email);
      finalData.append("header_color", headerColor);
      finalData.append("footer_color", footerColor);
      finalData.append("header_font_color", headerFontColor);
      finalData.append("footer_font_color", footerFontColor);

      finalData.append("meta_tag", data?.meta_tag);
      finalData.append("meta_description", data?.meta_description);
      finalData.append("currency_id", data?.currency_id?.value);
      finalData.append("whatapp_no", data?.whatapp_no);
      finalData.append("google_tag", data?.google_tag);

      finalData.append("image", data?.image[0]);

      // console.log(finalData,'ffffffffffffffff');
      const response = await edit(finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        // props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllCurrency = async () => {
    const response = await Currency();

    if (response?.success) {
      setCurrency(await Select2Data(response?.data, "currency_id"));
    }
  };
  useEffect(() => {
    GetAllCurrency();

  }, []);

 
  return (
    <>
      {/* <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
      <div class="container mt-4">
        <h3>App Setup</h3>
      </div>
      <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
        <Row>
          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Name</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Banner"
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Contact No.</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="Contact No"
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "Contact No is required",
                      })}
                    />
                  </InputGroup>
                  {errors.contact_no && (
                    <span className="text-danger">
                      {errors.contact_no.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Address</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                      {...register("address", {
                        required: "Address is required",
                      })}
                    />
                  </InputGroup>
                  {errors.address && (
                    <span className="text-danger">
                      {errors.address.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Email</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                      {...register("email", {
                        required: "Email is required",
                      })}
                    />
                  </InputGroup>
                  {errors.email && (
                    <span className="text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Meta Tag</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="meta_tag"
                      placeholder="meta tag"
                      className={classNames("", {
                        "is-invalid": errors?.meta_tag,
                      })}
                      {...register("meta_tag", {
                        required: "meta tag is required",
                      })}
                    />
                  </InputGroup>
                  {errors.meta_tag && (
                    <span className="text-danger">
                      {errors.meta_tag.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Meta Description</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="meta_description"
                      placeholder="meta description"
                      className={classNames("", {
                        "is-invalid": errors?.meta_description,
                      })}
                      {...register("meta_description", {
                        required: "meta description is required",
                      })}
                    />
                  </InputGroup>
                  {errors.meta_description && (
                    <span className="text-danger">
                      {errors.meta_description.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Whatapp No</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="whatapp_no"
                      placeholder="whatapp_no"
                      className={classNames("", {
                        "is-invalid": errors?.whatapp_no,
                      })}
                      {...register("whatapp_no", {
                        required: "Whatapp No is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Whatapp number must be a 10-digit number",
                        },
                      })}
                      onKeyDown={(e) => {
                        if (
                          !/[\d]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={10}
                    />
                  </InputGroup>
                  {errors.whatapp_no && (
                    <span className="text-danger">
                      {errors.whatapp_no.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Google Tag</Form.Label>
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="google_tag"
                      placeholder="Google Tag"
                      className={classNames("", {
                        "is-invalid": errors?.google_tag,
                      })}
                      {...register("google_tag", {
                        required: "google tag is required",
                      })}
                    />
                  </InputGroup>
                  {errors.google_tag && (
                    <span className="text-danger">
                      {errors.google_tag.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>
          {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Currency</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="currency"
                          placeholder="currency"
                          className={classNames("", {
                            "is-invalid": errors?.currency,
                          })}
                          {...register("currency", {
                            required: "Currency is required",
                          })}
                        />
                      </InputGroup>
                      {errors.currency && (
                        <span className="text-danger">
                          {errors.currency.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}
          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="row justify-content-center mb-2">
                <Form.Label>Currency</Form.Label>

                <Controller
                  name="currency_id" // name of the field
                  {...register("currency_id", {
                    required: "Select Currency",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.currency_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={currency}
                    />
                  )}
                />

                {errors.currency_id && (
                  <span className="text-danger">
                    {errors.currency_id.message}
                  </span>
                )}
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Header Color</Form.Label>
                <Form.Group>
                  <ColorPickerInput
                    value={headerColor}
                    onChange={(color) => setHeaderColor(color)}
                    errors={errors} // Pass errors to the component if needed
                  />
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Footer Color</Form.Label>
                <Form.Group>
                  <ColorPickerInput
                    value={footerColor}
                    onChange={(color) => setFooterColor(color)}
                    errors={errors} // Pass errors to the component if needed
                  />
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Header Font Color</Form.Label>
                <Form.Group>
                  <ColorPickerInput
                    value={headerFontColor}
                    onChange={(color) => setHeaderFontColor(color)}
                    errors={errors} // Pass errors to the component if needed
                  />
                </Form.Group>
              </Row>
            </div>
          </Col>



          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Footer Font Color</Form.Label>
                <Form.Group>
                  <ColorPickerInput
                    value={footerFontColor}
                    onChange={(color) => setFooterFontColor(color)}
                    errors={errors} // Pass errors to the component if needed
                  />
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col lg={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-start">
                <Form.Label className="text-left">Image</Form.Label>

                <Form.Group>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image,
                    })}
                    type="file"
                    {...register("image", {
                      // required: "Image is required",
                      // validate: async (value) => {
                      //   if (typeof value !== "string") {
                      //     const fileTypes = ["jpg", "png", "jpeg"];
                      //     const fileType = value[0].name?.split(".")[1];
                      //     if (!fileTypes.includes(fileType)) {
                      //       return `please upload a valid file format. (${fileTypes})`;
                      //     }
                      //     const sizes = await getDimension(value[0]);
                      //     if (
                      //       sizes.width !== 1296 &&
                      //       sizes.height !== 465
                      //     ) {
                      //       return "Image width and height must be 1296 px and 465 px";
                      //     }
                      //   }
                      // },
                    })}
                    accept=".jpg, .jpeg, .png"
                  />
                </Form.Group>
                {errors.image && (
                  <span className="text-danger">
                    {errors.image.message}
                  </span>
                )}
              </Row>
            </div>
          </Col>

          <Col className="main-form-section mt-3">
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Image Preview</Form.Label>

                {typeof getValues("image") == "string" ? (
                  <div
                    className="image-preview-container mt-3"
                    style={{ marginLeft: "110px" }}
                  >
                    <img
                      src={IMG_URL + getValues("image")}
                      alt="Preview"
                      className="image-preview"
                      style={{ width: "648px", height: "198px" }}
                    />
                  </div>
                ) : (
                  imageFile &&
                  imageFile?.length > 0 && (
                    <div
                      className="image-preview-container mt-3"
                      style={{ marginLeft: "110px" }}
                    >
                      <img
                        // src={URL.createObjectURL(getValues("image")[0])}
                        src={URL?.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "648px", height: "198px" }}
                      />
                    </div>
                  )
                )}
              </Row>
            </div>
          </Col>

          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              <Link>
                <CancelButton
                  name={"cancel"}
                  handleClose={props.handleClose}
                />
              </Link>

              <SaveButton
                name={"save"}
                handleSubmit={handleSubmit(onSubmit)}
              />
            </div>
          </Row>
        </Row>
      </Form>
      {/* </Modal.Body>
      </Modal> */}

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
