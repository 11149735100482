import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent";
import "./SelectAdress.css";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import {
  firmAddressDetailPost,
  getfirmAddressDetail,
} from "../../../../../utils/apis/seller/Seller";
import {
  Pincode,
  firmAddressType,
  pincodeWiseAll,
} from "../../../../../utils/apis/master/Master";
import { Context } from "../../../../../utils/context";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
import { Errors, Placeholders } from "../../../../../utils/errors";
import PhoneInput from "react-phone-input-2";
import {
  btobAddressAdd,
  btobEditGetAddress,
} from "../../../../../utils/apis/btb/Shipping_Address";
const EditAdress = (props) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pincode_id: null,
      address_type: "Business",
    },
  });

  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [firmAddressTypes, setFirmAddressType] = useState([]);
  const [cities, setCities] = useState("");
  const [states, setStates] = useState("");
  const [countries, setCountries] = useState("");
  const [pincodes, setPincodes] = useState([]);
  const getMasters = async () => {
    {
      const res = await firmAddressType();
      if (res?.success) {
        setFirmAddressType(res.data);
      }
    }
  };

  const handlePincodeChange = async (e) => {
    const res = await Pincode(e);
    if (res?.success) {
      const data = await Select2Data(res.data, "pincode_id", false);
      setPincodes(data);
    }
  };
  const handlePincodeSelect = async (id) => {
    const res = await pincodeWiseAll(id);
    if (res?.success) {
      console.log(res);
      setCities(res.data.city.name);
      setStates(res.data.state.name);
      setCountries(res.data.country.name);
      reset({
        city_id: res.data.city_id,
        state_id: res.data.state_id,
        country_id: res.data.country_id,
        pincode_id: {
          value: res.data.id,
          label: res.data.name,
        },
      });
    }
  };
  // s_personal_details_id: req.body.s_personal_details_id,

  const onSubmit = async (data) => {
    console.log(data, "form");
    const res = await btobAddressAdd(data);
    console.log(res);
    if (res?.success) {
      props.getData();
      props.onHide();
    }
  };

  const getAddressDetail = async () => {
    const res = await btobEditGetAddress(props.show);
    if (res?.success) {
      // setHomeAddressId(res.data.id);
      console.log(res, "getfirmAddressDetailgetfirmAddressDetail");
      setCities(res.data.city?.name);
      setStates(res.data.state?.name);
      setCountries(res.data.country?.name);

      const data = {
        id: res?.data?.id,
        name: res?.data?.name,
        phone: res?.data?.phone,
        address: res?.data?.address,
        address_type: res?.data?.address_type,
        btb_personal_details_id: res?.data?.btb_personal_details_id,
        country_id: res?.data?.country_id,
        state_id: res?.data?.state_id,
        city_id: res?.data?.city_id,
        pincode_id: {
          value: res?.data?.pincode_id,
          label: res?.data?.pincode?.name,
        },
      };
      reset(data);
    }
  };

  // console.log(errors);
  // console.log(getValues());
  useEffect(() => {
    // getMasters();
    getAddressDetail();
  }, [props.show]);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="adresss-modals"
      >
        <div className="">
          <div className="flex-div px-4 py-3 ">
            <h1>Select shipping address</h1>
            <div className="flex-div me-2">
              <FontAwesomeIcon
                icon="fa-solid fa-xmark"
                className="close-icon"
                onClick={props.onHide}
              />
            </div>
          </div>
        </div>

        <hr className="my-0 mx-3" />

        <Modal.Body>
          <div className="row">
            <p>
              Your personal information is securely encrypted and will solely be
              utilized for the purpose of delivery.
            </p>
            {/* <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control type="text" placeholder="Enter country" />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="Enter full name" />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" placeholder="Enter Number" />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Apartment, Unit, Building, Floor"
                />
              </Form.Group>
            </div>
            <div className="col-xxl-8 col-xl-8 col-md-8 ">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>State / Province</Form.Label>
                <Form.Control type="text" placeholder="Enter state" />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder="Enter state" />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>State / Province</Form.Label>
                <Form.Control type="text" placeholder="Enter state" />
              </Form.Group>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Postal code</Form.Label>
                <Form.Control type="text" placeholder="Enter postal code" />
              </Form.Group>
            </div> */}

            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="Full_Name:">
                    <Form.Label className="">Full Name</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      name="name"
                      {...register("name", {
                        required: Errors.name,
                      })}
                      placeholder={Placeholders.name}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="Phone_Number:">
                    <Form.Label className="">Phone Number</Form.Label>

                    {/* <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.phone,
                      })}
                      type="text"
                      name="phone"
                      {...register("phone", {
                        required: Errors.phone,
                      })}
                      placeholder={Placeholders.phone}
                    /> */}

                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <div style={{ position: "relative" }}>
                          <PhoneInput
                            country="in"
                            name="phone"
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            placeholder={Placeholders.phone}
                            inputStyle={{
                              borderColor: errors.phone ? "red" : "",
                            }}
                          />
                        </div>
                      )}
                      rules={{ required: Errors.phone }}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group controlId="Address">
                    <Form.Label className="">Address</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                      type="text"
                      name="address"
                      {...register("address", {
                        required: Errors.address,
                      })}
                      placeholder={Placeholders.address}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="Degree_Name:">
                    <Form.Label className="">Country</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.country,
                      })}
                      type="text"
                      name="country_id"
                      readOnly
                      value={countries}
                      placeholder={Placeholders.country}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="Degree_Name:">
                    <Form.Label className="">State</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.state,
                      })}
                      type="text"
                      name="state_id"
                      value={states}
                      placeholder={Placeholders.state}
                      readOnly
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="Authourity">
                    <Form.Label className="">City/District:</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.city,
                      })}
                      type="text"
                      name="city_id"
                      readOnly
                      value={cities}
                      placeholder={Placeholders.city}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="Degree_Name:">
                    <Form.Label className="">Pincode</Form.Label>

                    <Controller
                      name="pincode_id"
                      {...register("pincode_id", {
                        required: Errors.pincode,
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.pincode_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincodes}
                          onInputChange={(selectedOption) => {
                            handlePincodeChange(selectedOption);
                          }}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            handlePincodeSelect(selectedOption.value);
                          }}
                        />
                      )}
                    />

                    {/* <Controller
                      name="pincode_id"
                      control={control}
                      rules={{ required: Errors.pincode }}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.pincode_id
                                ? "red"
                                : baseStyles.borderColor,
                            }),
                          }}
                          {...field}
                          options={pincodes}
                          onInputChange={(inputValue) => {
                            handlePincodeChange(inputValue);
                          }}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            handlePincodeSelect(selectedOption.value);
                          }}
                        />
                      )}
                    /> */}
                  </Form.Group>
                </div>
              </div>

              <div className="col-lg-12 mb-3">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12">
                    <p className="wevvcwec">Select the name of address</p>
                  </div>
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12">
                    <div className="nam-add-tab">
                      <Nav
                        variant="pills"
                        defaultActiveKey={getValues("address_type")}
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Business"
                            onClick={() => {
                              setValue("address_type", "Business");
                            }}
                          >
                            Business
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Factory"
                            onClick={() => {
                              setValue("address_type", "Factory");
                            }}
                          >
                            Factory
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Warehouse"
                            onClick={() => {
                              setValue("address_type", "Warehouse");
                            }}
                          >
                            Warehouse
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Residential"
                            onClick={() => {
                              setValue("address_type", "Residential");
                            }}
                          >
                            Residential
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Other"
                            onClick={() => {
                              setValue("address_type", "Other");
                            }}
                          >
                            Other
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end m-2">
                <Button onClick={props.onHide} className="btn-cancle">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  //   onClick={() => {
                  //     handleSubmit(onSubmit);
                  //   }}
                  className="conform-btn"
                >
                  Confirm
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAdress;
