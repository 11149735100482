// InnerPageOne.js

import React, { useEffect, useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import TermsAndConditionModal from "../../Terms&Condition/TermsAndConditionModal";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { ShimmerTitle } from "react-shimmer-effects";
import {
  firmDetailsPost,
  getfirmDetails,
  sellerPercentageSend,
} from "../../../../../utils/apis/affiliate/affiliate";
import {
  gstVerify,
  panVerify,
} from "../../../../../utils/apis/third_party/third_party";
import { Context } from "../../../../../utils/context";

const InnerPageOne = ({
  nextInnerPage,
  prevInnerPage,
  handleBack,
  affiliateDetails,
  affiliateID,
  setPanData,
  firmName,
  setFirmname,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const handleNext = () => {
    nextInnerPage();
  };

  const { getData, postData, IMG_URL, shimmerLoader, setShimmerLoader } =
    useContext(Context);

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (d) => {
    const data = new FormData();

    if (firmId != "") {
      data.append("id", firmId);
    }
    data.append("gst_image", d.gst_image[0]);
    data.append("pan_image", d.pan_image[0]);
    data.append("gst_no", d.gst_no);
    data.append("pan_no", d.pan_no);
    if (firmName !== "") {
      data.append("name", firmName);
    }
    data.append("a_personal_details_id", affiliateID);
    // data.append("percentage", sellerPercentageSend.stepFour);

    const res = await firmDetailsPost(data);
    if (res?.success) {
      handleNext();
    }
  };

  const [selectedPANImages, setSelectedPANImage] = useState(null);

  const handleFilePANChange = (e) => {
    const file = e.target.files[0];
    setSelectedPANImage(file);
    setStorePan(null);
    clearErrors("pan_image");
  };

  const handleRemovePANFile = () => {
    setSelectedPANImage(null);
    const fileInput = document.getElementById("filePANInput");
    fileInput.value = "";
  };

  const [selectedGSTImages, setSelectedGSTImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedGSTImage(file);
    setStoreGst(null);
    clearErrors("gst_image");
  };

  const handleRemoveFile = () => {
    setSelectedGSTImage(null);
    const fileInput = document.getElementById("fileGSTInput");
    fileInput.value = "";
  };

  const [firmId, setfirmId] = useState("");
  const [storeGst, setStoreGst] = useState(null);
  const [storePan, setStorePan] = useState(null);

  const getUserFirmDetails = async () => {
    const res = await getfirmDetails(affiliateID);
    if (res?.success) {
      setStoreGst(IMG_URL + res.data.gst_image);
      setStorePan(IMG_URL + res.data.pan_image);
      setVerifyStatus(true);
      setVerifyPanStatus(true);
      setfirmId(res.data.id);
      reset(res.data);
    }
  };

  const [verifyStatus, setVerifyStatus] = useState(false);
  const verifyGst = async () => {
    setShimmerLoader(true);
    var data = {
      a_personal_details_id: affiliateID,
      gst_no: getValues("gst_no"),
    }
    const res = await gstVerify(data);
    if (res?.success) {
      setFirmname(res?.data?.data?.business_name);
      if (res.data.data.pan_number) {
        await setValue("pan_no", res?.data?.data?.pan_number);

        const response = await panVerify(res?.data?.data?.pan_number);
        if (response?.success) {
          setPanData(response?.data?.data?.address);
          clearErrors();
          setVerifyPanStatus(true);
        }
      }
      clearErrors();
      setVerifyStatus(true);
      setShimmerLoader(false);
    } else {
      setVerifyStatus(false);
      setError("gst_no", {
        type: "manual",
        message: res?.message?.message,
      });
      setShimmerLoader(false);
    }
  };

  const [verifyPanStatus, setVerifyPanStatus] = useState(false);
  const verifyPan = async () => {
    const res = await panVerify(getValues("pan_no"));
    setShimmerLoader(true);
    if (res?.success) {
      clearErrors();
      setShimmerLoader(false);
      setVerifyPanStatus(true);
    } else {
      setVerifyStatus(false);
      setError("pan_no", {
        type: "manual",
        message: res.message.message,
      });
      setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getUserFirmDetails();
  }, []);

  return (
    <div className="firmDetailsFirst">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <Form.Group className="mb-4 bankk-verrr" controlId="gst_no">
              <Form.Label>GST Number</Form.Label>
              {shimmerLoader ? (
                <ShimmerTitle line={1} gap={10} variant="primary" />
              ) : (
                <>
                  <Form.Control
                    type="text"
                    name="gst_no"
                    placeholder="Enter your GST number"
                    {...register("gst_no", {
                      required: "GST Number is required",
                      pattern: {
                        value:
                          /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                        message: "Invalid GST Number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.gst_no,
                      "is-valid": verifyStatus,
                    })}
                    onChange={() => {
                      setError("gst_no", "");
                      setVerifyStatus(false);
                    }}
                  />
                  {!verifyStatus && (
                    <button
                      type="button"
                      className="verrrr-bttt btn-info"
                      onClick={verifyGst}
                    >
                      Verify
                    </button>
                  )}
                </>
              )}
            </Form.Group>
          </div>
          <div className="col-lg-6">
            <Form.Group className="mb-4 bankk-verrr" controlId="pan_no">
              <Form.Label>PAN Number</Form.Label>
              {shimmerLoader ? (
                <ShimmerTitle line={1} gap={10} variant="primary" />
              ) : (
                <>
                  <Form.Control
                    type="text"
                    name="pan_no"
                    placeholder="Enter your PAN number"
                    {...register("pan_no", {
                      required: "PAN Number is required",
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Invalid PAN Number",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.pan_number,
                      "is-valid": verifyPanStatus,
                    })}
                    onChange={() => {
                      setError("pan_no", "");
                      setVerifyPanStatus(false);
                    }}
                  />
                  {!verifyPanStatus && (
                    <button
                      type="button"
                      className="verrrr-bttt btn-info"
                      onClick={verifyPan}
                    >
                      Verify
                    </button>
                  )}
                </>
              )}
            </Form.Group>
          </div>
          <div className="col-lg-6">
            <div className="uploadGsthead">
              <h3>Upload GST Document</h3>
            </div>
            <div className="d-flex">
              <div className="uploadBtnsec">
                {storeGst !== null ? (
                  <input
                    type="file"
                    id="fileGSTInput"
                    accept="image/*"
                    {...register("gst_image")}
                    onChange={handleFileChange}
                  />
                ) : (
                  <input
                    type="file"
                    id="fileGSTInput"
                    accept="image/*"
                    {...register("gst_image", {
                      required: "GST card image is required",
                    })}
                    onChange={handleFileChange}
                  />
                )}
              </div>
              {selectedGSTImages && (
                <div className="previewImgmain">
                  <img
                    src={URL.createObjectURL(selectedGSTImages)}
                    alt={`Preview of ${selectedGSTImages.name}`}
                    className="previewImg"
                  />
                  <div>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-xmark"
                      className="circlecrossMark"
                      onClick={handleRemoveFile}
                    />
                  </div>
                </div>
              )}
              {storeGst !== null && (
                <div className="previewImgmain">
                  <img src={storeGst} className="previewImg" />
                </div>
              )}
            </div>
            {errors.gst_image && (
              <span className="text-danger">{errors.gst_image.message}</span>
            )}
          </div>

          <div className="col-lg-6">
            <div className="uploadGsthead">
              <h3>Upload PAN Document</h3>
            </div>
            <div className="d-flex">
              <div className="uploadBtnsec">
                {storePan !== null ? (
                  <input
                    type="file"
                    id="filePANInput"
                    accept="image/*"
                    {...register("pan_image")}
                    onChange={handleFilePANChange}
                  />
                ) : (
                  <input
                    type="file"
                    id="filePANInput"
                    accept="image/*"
                    {...register("pan_image", {
                      required: "pan card image is required",
                    })}
                    onChange={handleFilePANChange}
                  />
                )}
              </div>
              {selectedPANImages && (
                <div className="previewImgmain">
                  <img
                    src={URL.createObjectURL(selectedPANImages)}
                    alt={`Preview of ${selectedPANImages.name}`}
                    className="previewImg"
                  />
                  <div>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-xmark"
                      className="circlecrossMark"
                      onClick={handleRemovePANFile}
                    />
                  </div>
                </div>
              )}
              {storePan !== null && (
                <div className="previewImgmain">
                  <img src={storePan} className="previewImg" />
                </div>
              )}
            </div>
            {errors.pan_image && (
              <span className="text-danger">{errors.pan_image.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="text-end apply_now_btn">
            {/* <p onClick={() => setModalShow(true)}>
              By continuing, I agree to Netpurti's
              <span className="termstxt">Terms of Use & Privacy Policy</span>
            </p> */}
            <Link to={"/seller/seller-dashboard/"}>
              <Button className="back-btn me-3">Skip</Button>
            </Link>
            <Button onClick={handleBack} className="back-btn">
              Back
            </Button>

            <Button
              // onClick={handleNext}
              type="submit"
              className="tabs-btn"
            >
              Save & Next
            </Button>

            <TermsAndConditionModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InnerPageOne;
