// import React, { useState } from "react";
// import InputGroup from "react-bootstrap/InputGroup";
// import Form from "react-bootstrap/Form";
// // import Header from "../../Header/Header";
// import Col from "react-bootstrap/Col";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
// // import Tab from "react-bootstrap/Tab";
// import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
// import Brand from "../../InfoMaster/Brand/Tables";
// import FAQ from "../../InfoMaster/Faq/Table";
// import SubFaq from "../../InfoMaster/SubFaq/Table";
// // import WorkingType from "../InfoMaster/WorkingType/Table";
// import Category from "../../InfoMaster/Category/Table";
// import SubCategory from "../../InfoMaster/SubCategory/Table";
// import ChildCategory from "../../InfoMaster/ChildCategory/Table";
// import ChildSubCategory from "../../InfoMaster/ChildSubCategory/Table";
// import Blogs from "../../InfoMaster/Blogs/Tables";
// // import CouponCodes from "../../InfoMaster/CouponCodes/Table";
// // import TaxType from "../InfoMaster/Tax_Types/Tables";
// // import TaxPercentage from "../InfoMaster/Tax_Percentage/Tables";
// import WebHeader from "../../InfoMaster/WebHeader/Tables";
// import AppSetup from "../../InfoMaster/AppSetup/Edit";
// // import ContactUs from "../InfoMaster/contact_us/Tables";
// import Users from "../../InfoMaster/users/Tables";
// import CrazyDeals from "../../InfoMaster/CrazyDeals/Table";
// import ExclusiveCollection from "../../InfoMaster/ExclusiveCollection/Table";
// import Attribute from "../../InfoMaster/Attribute/Table";
// import SubAttribute from "../../InfoMaster/SubAttribute/Table";
// import AttributePackaging from "../../InfoMaster/AttributePackaging/Table";
// import ShopTrends from "../../InfoMaster/ShopTrends/Table";
// import HomePageBanner from "../../InfoMaster/HomePageBanner/Table";
// import AboutUsBanner from "../../InfoMaster/banner/Edit";
// import OurStory from "../../InfoMaster/OurStory/Tables";
// import HappyCustomer from "../../InfoMaster/HappyCustomer/Tables";
// import WhyChoose from "../../InfoMaster/whyChoose/Tables";
// import PopularProduct from "../../InfoMaster/PopularProduct/Table";
// import SaleBanner from "../../InfoMaster/SaleBanner/Table";
// import CategoryBanner from "../../InfoMaster/CategoryBanner/Table";
// import UserMotivation from "../../InfoMaster/UserMotivation/Table";
// import ProductDealType from "../../InfoMaster/ProductDealType/Table";
// import ProductDeal from "../../InfoMaster/ProductDeal/Table";
// import OurPerformance from "../../InfoMaster/OurPerformnce/Tables";
// import SocialMedia from "../../InfoMaster/SocialMedia/Table";
// import PrivacyPolicy from "../../InfoMaster/PrivacyPolicy/Edit";
// import TermCondition from "../../InfoMaster/TermCondition/Edit";

// import "./Setting.css";

// const Setting = () => {
//   const [filter, setFilter] = useState("");
//   const [active, setActive] = useState(1);

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value.toLowerCase());
//   };

//   const tabsArray = [
//     { key: 0, name: "Brand", link: "masters/brand" },
//     { key: 1, name: "FAQ", link: "masters/faq" },
//     { key: 2, name: "Sub FAQ", link: "masters/sub-faq" },
//     { key: 3, name: "Blogs", link: "masters/blogs" },
//     // { key: 4, name: "Web Header", link: "masters/web-header" },
//     // { key: 5, name: "App Setup", link: "masters/app-setup" },
//     { key: 4, name: "Users", link: "masters/users" },

//     { key: 5, name: "Crazy Deals", link: "masters/crazy-deals" },
//     {
//       key: 6,
//       name: "Exclusive Collection",
//       link: "masters/exclusive-collections",
//     },

//     { key: 7, name: "Our Story", link: "masters/about-our-story" },
//     { key: 8, name: "Happy Customer", link: "masters/happy-customer" },
//     { key: 9, name: "Why Choose Us ?", link: "masters/why-choose" },
//     {
//       key: 10,
//       name: "Shop Trends",
//       link: "masters/shop-trends",
//     },

//     {
//       key: 11,
//       name: "Popular product",
//       link: "masters/popular_product",
//     },
//     {
//       key: 12,
//       name: " User Motivation",
//       link: "masters/user-motivation",
//     },
//     {
//       key: 13,
//       name: "Product Deal Type",
//       link: "masters/product-deal-type",
//     },
//     {
//       key: 14,
//       name: "Product Deal",
//       link: "masters/product-deal",
//     },
//     {
//       key: 15,
//       name: "Our Performance",
//       link: "masters/our-performance",
//     },
//     // {
//     //   key: 18,
//     //   name: "Social Media",
//     //   link: "masters/social-media",
//     // },

//     {
//       key: 16,
//       name: "Privacy Policy",
//       link: "masters/privacy-policy",
//     },
//     {
//       key: 17,
//       name: "Term Condition",
//       link: "masters/term-condition",
//     },
//     { key: 18, name: "About Us", link: "masters/about-us-banner" },

//   ];

//   const tabContent = [
//     { key: 0, component: <Brand /> },
//     { key: 1, component: <FAQ /> },
//     { key: 2, component: <SubFaq /> },
//     { key: 3, component: <Blogs /> },
//     // { key: 4, component: <WebHeader /> },
//     // { key: 5, component: <AppSetup /> },
//     { key: 4, component: <Users /> },
//     { key: 5, component: <CrazyDeals /> },
//     { key: 6, component: <ExclusiveCollection /> },
//     { key: 7, component: <OurStory /> },
//     { key: 8, component: <HappyCustomer /> },
//     { key: 9, component: <WhyChoose /> },
//     { key: 10, component: <ShopTrends /> },
//     { key: 11, component: <PopularProduct /> },
//     { key: 12, component: <UserMotivation /> },
//     { key: 13, component: <ProductDealType /> },
//     { key: 14, component: <ProductDeal /> },
//     { key: 15, component: <OurPerformance /> },
//     // { key: 18, component: <SocialMedia /> },

//     { key: 16, component: <PrivacyPolicy /> },
//     { key: 17, component: <TermCondition /> },
//     { key: 18, component: <AboutUsBanner /> },
//   ];

//   const [activeTab, setActiveTab] = useState(0);
//   const onTabClick = (e, index) => {
//     // console.log(e);
//     console.log(index);
//     setActiveTab(index);
//   };
//   // const tabsArray = [...Array(20).keys()];

//   return (
//     <>

//       <Tabs activeTab={activeTab} onTabClick={onTabClick}>
//         {tabsArray.map((item, index) => (
//           <Tab key={item.eventKey} eventKey={item.key}>
//             {item.name}
//           </Tab>
//         ))}
//       </Tabs>

//       {tabContent.map((item) => (
//         <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
//           {item.component}
//         </TabScreen> ))}
//     </>
//   );
// };

// export default Setting;

import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
import "react-tabs-scrollable/dist/rts.css";
import Brand from "../../InfoMaster/Brand/Tables";
import FAQ from "../../InfoMaster/Faq/Table";
import SubFaq from "../../InfoMaster/SubFaq/Table";
// import WorkingType from "../InfoMaster/WorkingType/Table";
import Category from "../../InfoMaster/Category/Table";
import SubCategory from "../../InfoMaster/SubCategory/Table";
import ChildCategory from "../../InfoMaster/ChildCategory/Table";
import ChildSubCategory from "../../InfoMaster/ChildSubCategory/Table";
import Blogs from "../../InfoMaster/Blogs/Tables";
// import CouponCodes from "../../InfoMaster/CouponCodes/Table";
// import TaxType from "../InfoMaster/Tax_Types/Tables";
// import TaxPercentage from "../InfoMaster/Tax_Percentage/Tables";
import WebHeader from "../../InfoMaster/WebHeader/Tables";
import AppSetup from "../../InfoMaster/AppSetup/Edit";
// import ContactUs from "../InfoMaster/contact_us/Tables";
import Users from "../../InfoMaster/users/Tables";
import CrazyDeals from "../../InfoMaster/CrazyDeals/Table";
import ExclusiveCollection from "../../InfoMaster/ExclusiveCollection/Table";
import Attribute from "../../InfoMaster/Attribute/Table";
import SubAttribute from "../../InfoMaster/SubAttribute/Table";
import AttributePackaging from "../../InfoMaster/AttributePackaging/Table";
import ShopTrends from "../../InfoMaster/ShopTrends/Table";
import HomePageBanner from "../../InfoMaster/HomePageBanner/Table";
import AboutUsBanner from "../../InfoMaster/banner/Edit";
import OurStory from "../../InfoMaster/OurStory/Tables";
import HappyCustomer from "../../InfoMaster/HappyCustomer/Tables";
import WhyChoose from "../../InfoMaster/whyChoose/Tables";
import PopularProduct from "../../InfoMaster/PopularProduct/Table";
import SaleBanner from "../../InfoMaster/SaleBanner/Table";
import CategoryBanner from "../../InfoMaster/CategoryBanner/Table";
import UserMotivation from "../../InfoMaster/UserMotivation/Table";
import ProductDealType from "../../InfoMaster/ProductDealType/Table";
import ProductDeal from "../../InfoMaster/ProductDeal/Table";
import OurPerformance from "../../InfoMaster/OurPerformnce/Tables";
import SocialMedia from "../../InfoMaster/SocialMedia/Table";
import PrivacyPolicy from "../../InfoMaster/PrivacyPolicy/Edit";
import TermCondition from "../../InfoMaster/TermCondition/Edit";
import "./Setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faArrowRightArrowLeft,
  faCircleQuestion,
  faCodeBranch,
  faGlobe,
  faList,
  faListAlt,
  faQuestion,
  faTableCellsLarge,
  faTree,
  faUser,
  faUsers,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import {
  faBandcamp,
  faBloggerB,
  faIdeal,
  faOptinMonster,
  faProductHunt,
} from "@fortawesome/free-brands-svg-icons";

const Setting = () => {
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState("0");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };
  const tabsArray = [
    {
      key: 0,
      name: (
        <>
          <FontAwesomeIcon icon={faBandcamp} />
        </>
      ),
      link: "masters/brand",
      component: <Brand />,
      tooltip: "Brand",
    },
    {
      key: 1,
      name: (
        <>
          <FontAwesomeIcon icon={faCircleQuestion} />
        </>
      ),
      link: "masters/faq",
      component: <FAQ />,
      tooltip: "FAQ",
    },
    {
      key: 2,
      name: (
        <>
          <FontAwesomeIcon icon={faQuestion} />
        </>
      ),
      link: "masters/sub-faq",
      component: <SubFaq />,
      tooltip: "Sub FAQ",
    },

    {
      key: 3,
      name: (
        <>
          <FontAwesomeIcon icon={faBloggerB} />
        </>
      ),
      link: "masters/blogs",
      component: <Blogs />,
      tooltip: "Blogs",
    },
    {
      key: 4,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
        </>
      ),
      link: "masters/users",
      component: <Users />,
      tooltip: "Users",
    },

    {
      key: 5,
      name: (
        <>
          <FontAwesomeIcon icon={faIdeal} />
        </>
      ),
      link: "masters/crazy-deals",
      component: <CrazyDeals />,
      tooltip: "Crazy Deals",
    },
    {
      key: 6,
      name: (
        <>
          <FontAwesomeIcon icon={faListAlt} />
        </>
      ),
      link: "masters/exclusive-collections",
      component: <ExclusiveCollection />,
      tooltip: "Exclusive Collection",
    },

    {
      key: 7,
      name: (
        <>
          <FontAwesomeIcon icon={faWindowRestore} />
        </>
      ),
      link: "masters/about-our-story",
      component: <OurStory />,
      tooltip: "Our Story",
    },
    {
      key: 8,
      name: (
        <>
          <FontAwesomeIcon icon={faUsers} />
        </>
      ),
      link: "masters/happy-customer",
      component: <HappyCustomer />,
      tooltip: "Happy Customer",
    },
    {
      key: 9,
      name: (
        <>
          <FontAwesomeIcon icon={faOptinMonster} />
        </>
      ),
      link: "masters/why-choose",
      component: <WhyChoose />,
      tooltip: "Why Choose Us ?",
    },
    {
      key: 10,
      name: (
        <>
          <FontAwesomeIcon icon={faTree} />
        </>
      ),
      link: "masters/shop-trends",
      component: <ShopTrends />,
      tooltip: "Shop Trends",
    },

    {
      key: 11,
      name: (
        <>
          <FontAwesomeIcon icon={faProductHunt} />
        </>
      ),
      link: "masters/popular_product",
      component: <PopularProduct />,
      tooltip: "Popular Product",
    },
    {
      key: 12,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
        </>
      ),
      link: "masters/user-motivation",
      component: <UserMotivation />,
      tooltip: "User Motivation",
    },

    {
      key: 13,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
        </>
      ),
      link: "masters/product-deal-type",
      component: <ProductDealType />,
      tooltip: "Product Deal Type",
    },

    {
      key: 14,
      name: (
        <>
          <FontAwesomeIcon icon={faUser} />
        </>
      ),
      link: "masters/product-deal",
      component: <ProductDeal />,
      tooltip: "Product Deal",
    },
    {
      key: 15,
      name: (
        <>
          <FontAwesomeIcon icon={faGlobe} />
        </>
      ),
      link: "masters/our-performance",
      component: <OurPerformance />,
      tooltip: "Our Performance",
    },
    {
      key: 16,
      name: (
        <>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
        </>
      ),
      link: "masters/privacy-policy",
      component: <PrivacyPolicy />,
      tooltip: "Privacy Policy",
    },
    {
      key: 17,
      name: (
        <>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
        </>
      ),
      link: "masters/term-condition",
      component: <TermCondition />,
      tooltip: "Term Condition",
    },
    {
      key: 18,
      name: (
        <>
          <FontAwesomeIcon icon={faAddressCard} />
        </>
      ),
      link: "masters/about-us-banner",
      component: <AboutUsBanner />,
      tooltip: "About Us",
    },
  ];

  // const tabContent = [
  //   { key: 0, component: <Brand /> },
  //   { key: 1, component: <FAQ /> },
  //   { key: 2, component: <SubFaq /> },
  //   { key: 3, component: <Blogs /> },
  //   { key: 4, component: <Users /> },
  //   { key: 5, component: <CrazyDeals /> },
  //   { key: 6, component: <ExclusiveCollection /> },
  //   { key: 7, component: <OurStory /> },
  //   { key: 8, component: <HappyCustomer /> },
  //   { key: 9, component: <WhyChoose /> },
  //   { key: 10, component: <ShopTrends /> },
  //   { key: 11, component: <PopularProduct /> },
  //   { key: 12, component: <UserMotivation /> },
  //   { key: 13, component: <SocialMedia /> },
  //   { key: 14, component: <ReturnPolicy /> },
  //   { key: 15, component: <AboutUsBanner /> },
  // ];

  // const [activeTab, setActiveTab] = useState(0);
  // const onTabClick = (e, index) => {
  //   console.log(e);
  //   setActiveTab(index);
  // };
  // const tabsArray = [...Array(20).keys()];

  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.tooltip}</Tooltip>
  );

  return (
    <>
      {/* <Tabs activeTab={activeTab} onTabClick={onTabClick}>
        {tabsArray.map((item, index) => (
          <Tab key={item.eventKey} eventKey={item.key}>
            {item.name}
          </Tab>
        ))}
      </Tabs>

      {tabContent.map((item) => (
        <TabScreen key={item.key} activeTab={activeTab} index={item.key}>
          {item.component}
        </TabScreen>
      ))} */}
      <div className="sidebar-tab-holder">
        <Tab.Container
          className="sidebar-tab-holder"
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(e) => setActiveTab(e)}
        >
          <Row>
            <Col sm={1} className="col-width scale-up-hor-left">
              <Nav variant="pills" className="flex-column">
                {tabsArray.map((item) => (
                  <OverlayTrigger
                    key={item.key}
                    placement="right"
                    delay={{ show: 200, hide: 0 }}
                    overlay={renderTooltip({ tooltip: item.tooltip })}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={item.key}>{item.name}</Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ))}
              </Nav>
            </Col>
            <Col sm={11} className="col2-width">
              <Tab.Content>
                {tabsArray.map((item) => (
                  <Tab.Pane eventKey={item.key} key={item.key}>
                    {item.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Setting;
