import React, { useState, useContext, useEffect } from 'react'
import '../MyWebsiteLogin/LoginPage.css'
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RegxExpression } from "../../../../utils/common";
import { userForgotPassPost } from '../../../../utils/apis/myWebsite/mywebsite';

const ForgetPassword = () => {


    const {
        control,
        register,
        handleSubmit,
        setError,
        getValues,
        setValue,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();
    const [emailerror, setemailErrors] = useState("");

    const onSubmit = async (data) => {
        const res = await userForgotPassPost(data)
        if (res?.success) {
            console.log(data, "data");
            // navigate("my")
        } else {
            setemailErrors(res?.message);
        }
        console.log(data, "data");
    }


    return (
        <section className='login-page' >
            <Container>
                <Row className='mx-auto justify-content-center'>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <div className='login-white'>
                            <div className='form-title text-center'>
                                <h3>Forgot Password?</h3>
                                <p>Don't worry, we'll send instructions for the reset</p>
                            </div>
                            <div className="log_in_form">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Email"
                                            placeholder="Enter Your Email Id"
                                            {...register("email", {
                                                required: "Email Id required",
                                                pattern: {
                                                    value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                    message: "Email Id Invalid",
                                                },
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.email,
                                            })}
                                        />
                                        {errors.email && (
                                            <span className="text-danger">
                                                {errors.email.message}
                                            </span>
                                        )}
                                        {emailerror && (
                                            <span className="text text-danger">{emailerror}</span>
                                        )}
                                    </Form.Group>
                                    <div className="btn-holder text-center">
                                        {/* <Link to='/mywebsites/check-email'><button className="submit_btn" type="submit"> */}
                                        <button className="submit_btn" type="submit">
                                            Reset Password
                                        </button>
                                        {/* </Link> */}
                                    </div>
                                </Form>
                            </div>
                            <div className='text-center mt-3'>
                                <p className='login-txtt'>Didn't receive the email?  <Link to='#'><span className='forget-txtt'>Click to resend</span></Link></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}    

export default ForgetPassword