import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Select from "react-select";
import JoditEditor from "jodit-react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./CommonDetails.css";
import Warrentytab from "../CommonDetails/Warrentytab/Warrentytab";
import Guarenteetab from "./Guarenteetab/Guarenteetab";
import Noservice from "./Noservice/Noservice";
import Expiry from "./Expiry/Expiry";
import Autosuggest from "react-autosuggest";
const CommonDetails = (props) => {
  const [content, setContent] = useState("");
  const selectday = [
    { value: "1 day", label: "1 day" },
    { value: "2 day", label: "2 day" },
    { value: "3 day", label: "3 day" },
  ];
  const selectdaytwo = [
    { value: "1 day", label: "1 day" },
    { value: "2 day", label: "2 day" },
    { value: "3 day", label: "3 day" },
  ];
  const area = [
    { value: "1 day", label: "1 day" },
    { value: "2 day", label: "2 day" },
    { value: "3 day", label: "3 day" },
  ];
  const attribute = [
    { value: "1 day", label: "1 day" },
    { value: "2 day", label: "2 day" },
    { value: "3 day", label: "3 day" },
  ];

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  // autosuggest

  const suggestions = [
    { name: "Apple" },
    { name: "Banana" },
    { name: "Cherry" },
    { name: "Grape" },
    { name: "Orange" },
  ];

  const [value, setValue] = useState("");
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = (inputValue) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    return suggestions.filter((item) =>
      item.name.toLowerCase().includes(inputValueLowerCase)
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestionsList(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const inputProps = {
    placeholder: "Type a fruit",
    value,
    onChange: onChange,
  };
  return (
    <>
      <section className="Common-details-step ">
        <div className="row">
          <div className="col-xxl-12 col-md-12">
            <div className="main-card">
              <div className="row">
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Product Title <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-title">Product</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Product Title" />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Category <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-category">Categpry</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Category" />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-lg-4 col-md-4">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Brand <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-brand">Brand</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Brand" />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="main-card">
              <h5 className="text-heading">Policy</h5>
              <div className="row">
                <div className="col-xxl-6 col-lg-6 col-md-6">
                  <div className="d-flex">
                    <p className="me-2 minitext">Is Returnable ?</p>
                    <Form.Check type="switch" id="custom-switch" />
                  </div>

                  <div className="select-to-div">
                    <Form.Label htmlFor="inputPassword5">
                      Select Days
                      <span className="span-text">(If Returnable ?)</span>
                    </Form.Label>
                    <Select placeholder="please select" options={selectday} />
                  </div>
                </div>
                <div className="col-xxl-6 col-lg-6 col-md-6">
                  <div className="d-flex">
                    <p className="me-2 minitext">Is Returnable ?</p>
                    <Form.Check type="switch" id="custom-switch" />
                  </div>
                  <div className="select-to-div">
                    <Form.Label htmlFor="inputPassword5">
                      Select Days
                      <span className="span-text">(If Returnable ?)</span>
                    </Form.Label>
                    <Select
                      placeholder="please select"
                      options={selectdaytwo}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card">
              <div className="row">
                <div className="select-to-div">
                  <Form.Label>
                    Supply Area <span className="star">*</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-title">Supply Area</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Select placeholder="please select" options={area} />
                </div>
              </div>
            </div>
            <div className="main-card">
              <div className="row">
                <div className="select-to-div">
                  <Form.Label>Product About</Form.Label>
                  <JoditEditor
                    value={content}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => {}}
                  />
                </div>
              </div>
            </div>

            <div className="main-card tab-section px-3">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Warranty">
                <h6>Service Term</h6>
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link eventKey="Warranty">Warranty</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Guarantee">Guarantee</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="NoService">No Service</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Expiry">Expiry</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Warranty">
                    <Warrentytab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Guarantee">
                    <Guarenteetab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="NoService">
                    <Noservice />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Expiry">
                    <Expiry />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>

            <div className="main-card video-sec">
              <h4 className="cust-text">Product Caution & Safety</h4>
              <JoditEditor
                value={content}
                tabIndex={1}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}
              />
            </div>

            <div className="main-card video-sec">
              <div className="row">
                <div className="col-xxl-3 col-md-3 col-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Product Video<span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-title">Product Video</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                  </Form.Group>
                  <div className="video-descriptn">
                    <h6 className="video-title">Video Resolution</h6>
                    <p className="sub-title">
                      Use clear video with a minimum resolution of 512*512 px.
                    </p>
                  </div>
                  <div className="video-descriptn">
                    <h6 className="video-title">Video Guidelines</h6>
                    <p className="sub-title">
                      Upload authentic product Video taken in bright lighting
                    </p>
                  </div>
                </div>
                <div className="col-xxl-3 col-md-3 col-3">
                  <UploadButton uploader={uploader} options={options}>
                    {({ onClick }) => (
                      <button onClick={onClick} className="uploadBtn">
                        Upload <FontAwesomeIcon icon="fa-solid fa-upload" />
                      </button>
                    )}
                  </UploadButton>

                  <p className="sub-title m-0">Video.mp4</p>
                  <p className="itlic-text">File attached</p>
                </div>
                <div className="col-xxl-6 col-md-6 col-6">
                  <div className="video-div ">
                    <video
                      className="online-video"
                      playsInline
                      autoPlay
                      mute
                      controls
                    >
                      <source
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/videos/home/banner/banner1.mp4"
                        }
                      ></source>
                    </video>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card custooo-overflow">
              <h4 className="cust-text">Customization</h4>
              <div className="">
                <Form.Check
                  inline
                  type="radio"
                  label="Packaging"
                  name="category"
                  id="category1"
                  className="my-2"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Customize product and Packaging"
                  name="category"
                  id="category2"
                  className="my-2"
                />
              </div>
              <div className="row mt-3">
                <div className="col-xxl-5 col-md-5 col-5">
                  <div className="select-to-div">
                    <Form.Label htmlFor="inputPassword5">
                      Select Attributes for Packaging{" "}
                      <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-category">Attributes</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    {/* <Select placeholder="please select" options={attribute} /> */}
                    <Autosuggest
                      suggestions={suggestionsList}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                    />
                  </div>
                  <div className="mt-3">
                    <p className="mb-1 minitext">Document</p>
                    <Form.Check type="switch" id="custom-switch" />
                  </div>
                </div>
                <div className="col-xxl-6 col-md-6 col-6">
                  <div>
                    <Form.Label>Description</Form.Label>
                    <JoditEditor
                      value={content}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(newContent) => {}}
                    />
                  </div>
                </div>

                <div className="col-xxl-1 col-md-1 col-1 align-items-center d-flex mt-auto">
                  <button className="addrowiccc">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                      }
                    />
                  </button>
                </div>
              </div>
              <hr />
              <div className="row mt-4">
                <div className="col-xxl-5 col-md-5 col-4">
                  <div className="select-to-div">
                    <Form.Label htmlFor="inputPassword5">
                      Select Attributes for Packaging
                      <span className="star">*</span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-category">Attributes</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-info"
                          className="ms-3"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <Select placeholder="please select" options={attribute} />
                  </div>
                  <div className="mt-3">
                    <p className="mb-1 minitext">Document</p>
                    <Form.Check type="switch" id="custom-switch" />
                  </div>
                </div>
                <div className="col-xxl-6 col-md-6 col-6">
                  <div>
                    <Form.Label>Description</Form.Label>
                    <JoditEditor
                      value={content}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(newContent) => {}}
                    />
                  </div>
                </div>
                <div className="col-xxl-1 col-md-1 col-1 align-items-center d-flex mt-auto">
                  <button className="addrowiccc">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/images/Seller_panel/dashboard/icons/addicooo.png"
                      }
                    />
                  </button>
                  <button className="delet-row">
                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                  </button>
                </div>
              </div>
            </div>

            <div className="common-steps-btn">
              {/* <button type="button" onClick={props.handlePrevious} className='back-btn'>Back</button> */}
              <Button onClick={props.handlePrevious} className=" back-btn">
                back
              </Button>
              <button
                type="button"
                onClick={props.nextStep}
                className="next-btn"
              >
                Save & Next
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonDetails;
