import React from 'react'
import MyWalletCard from './MyWalletCard/MyWalletCard'
import HelpSlider from '../../../HelpCenter/HelpSlider/HelpSlider';
import './MyWallet.css'
import ButtonComponent from '../../../../CommonForAll/CommonComponents/ButtonComponent/ButtonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddMoneywalletModal from '../../../common-components/AllModal/AddMoneywalletModal/AddMoneywalletModal';

const MyWallet = () => {
    const [modalMoney, setModalMoney] = React.useState(false);
    const uplodimg = [

        "/assest/images/B2Bbuyer/HelpCenter/add.png",
        "/assest/images/B2Bbuyer/HelpCenter/add.png"
    ];
    return (
        <>
            <section className='b2b-mywallet-main-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-12'>
                            <div className='mb-4'>
                                <div className='row'>
                                    <div className='col-xxl-7 col-xl-7 col-md-7 col-12'>
                                        <div className='main-banner-div mb-3'>
                                            <div>
                                                <img src="/assest/images/B2Bbuyer/Accounts/wallet-bg.png" class="bg-img" alt="..." />
                                            </div>
                                            <div className='overlay-color' />
                                            <div className='overlay-content'>
                                                <div className='row'>
                                                    <div className='col-xxl-7 col-xl-7 col-md-6 col-12'>
                                                        <h6>₹10,000</h6>
                                                        <p>Current Netpurti's Wallet Balance</p>
                                                    </div>
                                                    <div className='col-xxl-5 col-xl-5 col-md-6 col-12 my-auto'>
                                                        <ButtonComponent type="button" className="add-btn" onClick={ setModalMoney} >
                                                            Add money to wallet <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xxl-5 col-xl-5 col-md-5 col-12'>
                                        <div className='main-banner-div-two mb-3'>
                                            <div>
                                                <img src="/assest/images/B2Bbuyer/Accounts/rs.png" class="rs-img" alt="..." />
                                            </div>
                                            <p>Spend</p>
                                            <h6>₹1000</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MyWalletCard />
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-12 '>
                            <HelpSlider imagePaths={uplodimg} imageClassName="slider-img mt-xxl-0 mt-xl-0 mt-md-3 mt-sm-3 mt-3" />
                        </div>
                    </div>
                </div>
            </section>

            <AddMoneywalletModal
                show={modalMoney}
                onHide={() => setModalMoney(false)}
            />
        </>
    )
}

export default MyWallet