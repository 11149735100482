import React, { useContext, useEffect, useState } from "react";
import "./SkinCare.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { getbtb_advertisement_section } from "../../../../utils/apis/btb/Home_Page";
import { Context } from "../../../../utils/context";

function SkinCare() {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData(await getbtb_advertisement_section());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="SkinCare">
        <div className="container">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            className="SkinCare_Swiper"
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
              575: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
            }}
          >
            {data?.data?.map((imagePath, index) => (
              <SwiperSlide key={index}>
                <div className="img-holder">
                  <img
                    className="SkinCare-img"
                    src={IMG_URL + imagePath.image}
                    alt={`SkinCare-img-${index}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default SkinCare;
